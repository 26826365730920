import { IconButton, Paper, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import RejectedIcon from 'components/icons/cross_naked.svg?react';
import TrashIcon from 'components/icons/trash.svg?react';
import { useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

const MAX_CHARACTERS_LIMIT_FOR_DESCRIPTION = 100;
interface CommentFormProps {
  onClose: () => void;
  onSave: ({ propertyUtag, newComment }: { propertyUtag: string; newComment: string }) => void;
  utag: string;
  initialComment?: string;
}

export function CommentForm({ onClose, onSave, utag, initialComment = '' }: CommentFormProps) {
  const { t } = useTranslation(['communication']);
  const [comment, setComment] = useState(initialComment);
  const handleEditComment = () => {
    onSave({ propertyUtag: utag, newComment: comment });
    onClose();
  };
  return (
    <Stack direction="column" gap={1} sx={{ width: '320px' }}>
      <Paper elevation={0} sx={{ marginBottom: '-5px', padding: 1 }}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="body1">
            {initialComment
              ? t('communication:sendBulkEmailDialog.property.editComment')
              : t('communication:sendBulkEmailDialog.property.writeComment')}
          </Typography>
          <IconButton onClick={onClose}>
            <RejectedIcon />
          </IconButton>
        </Stack>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderTop: `1px solid ${theme.palette.shade.grey3}`, borderRadius: 0, padding: 2, paddingBottom: 1 }}
      >
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          helperText={
            <Stack>
              <Typography align={'right'} variant="body4" color="text.secondary">
                {comment.length}/{MAX_CHARACTERS_LIMIT_FOR_DESCRIPTION}
                {t('communication:sendBulkEmailDialog.property.comment.characters', {
                  characterLength: comment.length,
                  maxChars: MAX_CHARACTERS_LIMIT_FOR_DESCRIPTION,
                })}
              </Typography>
            </Stack>
          }
          inputProps={{ maxLength: MAX_CHARACTERS_LIMIT_FOR_DESCRIPTION }}
          sx={{ my: 1 }}
        />
      </Paper>
      <Stack
        direction="row"
        justifyContent={initialComment ? 'space-between' : 'flex-start'}
        gap={2}
        padding={2}
        alignSelf="bottom"
        sx={{ backgroundColor: theme.palette.shade.grey5 }}
      >
        <Stack direction="row" gap={3}>
          <Button variant="contained" type="submit" onClick={handleEditComment}>
            {t('common:save')}
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('common:cancel')}
          </Button>
        </Stack>
        {initialComment && (
          <IconButton
            onClick={() => {
              onSave({ propertyUtag: utag, newComment: '' });
              onClose();
            }}
          >
            <TrashIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}
