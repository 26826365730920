import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import LipaIcon from 'components/icons/city.svg?react';
import IntegrationIcon from 'components/icons/integrations.svg?react';
import ShopIcon from 'components/icons/shop_naked.svg?react';
import UserProfileIcon from 'components/icons/user_profile.svg?react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'util/i18next';
import { isSubPath } from 'util/isSubPath';
import { useDestination } from 'util/navigation/useDestination';
import { useEnabledPages } from 'util/useEnabledPages';

export function useProfileNavigationItems() {
  const isPageEnabled = useEnabledPages();
  const { t } = useTranslation(['user', 'communication']);
  const currentAgentId = useCurrentAgentId();
  const { pathname } = useLocation();

  const { toAgentProfile, toLiPaProfile, toGoogleIntegrations, toMyShopAndTeam } = useDestination();
  return [
    ...(isPageEnabled('AgentProfile')
      ? [
          {
            icon: <UserProfileIcon />,
            label: t('user:myProfile.title'),
            href: toAgentProfile({ agentId: currentAgentId ?? '' }),
            isActive: isSubPath(toAgentProfile({ agentId: currentAgentId ?? '' }), pathname),
            disabled: false,
          },
        ]
      : []),
    ...(isPageEnabled('Integrations')
      ? [
          {
            href: toGoogleIntegrations(),
            label: t('communication:integrations.title'),
            isActive: isSubPath(toGoogleIntegrations(), pathname),
            icon: <IntegrationIcon />,
            disabled: false,
          },
        ]
      : []),
    ...(isPageEnabled('MyShopTeam')
      ? [
          {
            icon: <ShopIcon />,
            label: t('myShop.title'),
            href: toMyShopAndTeam(),
            isActive: isSubPath(toMyShopAndTeam(), pathname),
            disabled: false,
          },
        ]
      : []),
    ...(isPageEnabled('LicensePartnerProfile')
      ? [
          {
            icon: <LipaIcon />,
            label: t('user:profileMenu.myLiPa'),
            href: toLiPaProfile(),
            isActive: isSubPath(toLiPaProfile(), pathname),
            disabled: false,
          },
        ]
      : []),
  ] as const satisfies readonly {
    icon: JSX.Element;
    label: string;
    href: string;
    isActive: boolean;
    disabled: boolean;
  }[];
}
