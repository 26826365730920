import { Divider, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LanguageEnum } from 'api/graphql/generated/graphql';
import { useGlobalEditorState } from 'components/forms/RichTextEditor/blots/GlobalEditorState';
import { NoPropertyPlaceholder } from 'components/forms/RichTextEditor/blots/expose/NoPropertyPlaceholder';
import { EvaImage } from 'components/general/EvaImage/EvaImage';
import { theme } from 'theme';
import { ListingWithLegacyFields } from 'util/go3';
import { useTranslation } from 'util/i18next';
import { getMarketingDetailsWithPremiumExpose } from 'util/propertyUtils';
import { useFormatNumber } from 'util/useFormatNumber';

export interface ExposeListWidgetProps {
  properties: ListingWithLegacyFields[];
  alreadyProposedProperties?: string[];
  setProperties?: (properties: ListingWithLegacyFields[]) => void;
  enableRemove?: boolean;
  hidePrice?: boolean;
  language: LanguageEnum;
}

const MAX_WIDTH = 520;

export function PremiumExposeWidget() {
  const { t } = useTranslation(['communication']);
  const { formatPrice } = useFormatNumber();
  const editorState = useGlobalEditorState();
  const { properties, language, hidePrice } = editorState.props.expose?.['global'] || {};

  const property = properties?.[0]?.property;

  if (!property) {
    return <NoPropertyPlaceholder sx={{ maxWidth: MAX_WIDTH }} allowsMultipleProperties={false} />;
  }

  const marketingDetails = getMarketingDetailsWithPremiumExpose(property, language || 'EN');
  const { bulletPoints, bulletPointsTitle, description, subject: title } = marketingDetails?.premiumExposeEmail || {};
  const price = property.askingPrice || property.monthlyNetRent;

  const propertyPriceExportAllowed =
    !property.internetProfile.priceOnRequest &&
    property.internetProfile.portals?.some((portal) => portal.includeInExport);

  const showPrice = !!price && !hidePrice && propertyPriceExportAllowed;

  return (
    <Tooltip title={t('communication:sendBulkEmailDialog.property.tooltip')} arrow placement="top">
      <Box
        sx={{
          maxWidth: MAX_WIDTH,
          marginY: 2,
          border: `1px solid ${theme.palette.shade.grey4}`,
          backgroundColor: theme.palette.backgroundExtension.white,
        }}
      >
        <Box
          sx={{ width: '100%', aspectRatio: 1.477, backgroundColor: theme.palette.shade.grey4, position: 'relative' }}
        >
          {property.previewImage && (
            <EvaImage sizes={`${MAX_WIDTH}px`} src={property.previewImage} fill style={{ objectFit: 'cover' }} />
          )}
        </Box>
        <Box sx={{ padding: theme.spacing(3, 2, 0, 2) }}>
          {title && <Typography variant="h1">{title}</Typography>}
          {description && (
            // We need !important to overwrite the quill styles because this rendered inside the editor
            <Typography variant="body3" sx={{ marginTop: '12px!important' }} component="p">
              {description}
            </Typography>
          )}
          {bulletPointsTitle && (
            <Typography variant="body2" fontWeight={'bold'} sx={{ marginTop: '12px!important' }} component="p">
              {bulletPointsTitle}
            </Typography>
          )}
          {!!bulletPoints?.length && (
            // We need !important to overwrite the quill styles because this rendered inside the editor
            <Box component="ul" sx={{ padding: '0px!important' }}>
              {bulletPoints.map((bulletPoint) => (
                <Typography component="li" variant="body3">
                  {bulletPoint}
                </Typography>
              ))}
            </Box>
          )}
          <Divider sx={{ marginY: 1.5 }} />
          <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={1}>
            <Box>
              {showPrice && (
                <Typography
                  variant="h3"
                  component="div"
                  fontWeight="bold"
                  sx={{ marginBottom: 1, whiteSpace: 'nowrap' }}
                >
                  {formatPrice(price, { currency: property.currency })}
                </Typography>
              )}
              <Typography variant="body3" color={theme.palette.text.secondary}>
                {property.utag}
              </Typography>
            </Box>
            <Button variant="contained" size="large">
              {t('communication:premiumExposeWidget.detailsButton')}
            </Button>
          </Stack>
        </Box>
        <Divider sx={{ marginTop: 1.5 }} />
        <Box sx={{ paddingY: 0.5, paddingX: 1 }}>{t('communication:sendBulkEmailDialog.property.linkPlaceholder')}</Box>
      </Box>
    </Tooltip>
  );
}
