import { Button, ListItemIcon, ListItemText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { CHATBOT_POPOVER_ID } from 'components/chatbot/Chatbot';
import CaretIcon from 'components/icons/arrow_1_down.svg?react';
import QuestionMarkIcon from 'components/icons/question.svg?react';
import { usePopover } from 'components/state/UIStateProvider';
import { StyledBadge } from 'eva-frame/EvaLayout/StyleBadge/style';
import React, { useRef, useState } from 'react';
import { useProductFruitsApi } from 'react-product-fruits';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useTrackUserInteractionWithoutPageContext } from 'util/snowplow/useSnowplowTracking';
import { userEvent } from 'util/snowplow/userEvent';

export function SupportMenuButton({ asMenuItem }: { asMenuItem?: boolean }) {
  const { t } = useTranslation();
  const [hasUnreadNews, setHasUnreadNews] = useState(false);
  const buttonRef = useRef<HTMLElement>(null);
  const { setOpen: setChatbotOpen } = usePopover(CHATBOT_POPOVER_ID);
  const trackUserEvent = useTrackUserInteractionWithoutPageContext();

  useProductFruitsApi((api) => {
    if (buttonRef.current) {
      api.inAppCenter.attachToCustomElement(buttonRef.current);
    }
    api.announcementsV2.listen('newsfeed-unread-count-changed', (event: { count: number }) => {
      setHasUnreadNews(event.count > 0);
    });

    const handleOutsideClick = (event: MouseEvent) => {
      if (api.inAppCenter.isOpened()) {
        trackUserEvent(userEvent.topbar.support.close);
      }

      if (api.inAppCenter.isOpened() && !buttonRef.current?.contains(event.target as Node)) {
        event.stopPropagation();
        event.preventDefault();
        api.inAppCenter.close();
      }
    };
    const reactRoot = document.getElementById('root');
    reactRoot?.addEventListener('mousedown', handleOutsideClick);

    return () => {
      reactRoot?.removeEventListener('mousedown', handleOutsideClick);
      if (api.inAppCenter.isOpened()) {
        api.inAppCenter.close();
        trackUserEvent(userEvent.topbar.support.close);
      }
    };
  }, []);

  useProductFruitsApi(
    (api) => {
      const openMyChatBot = () => {
        setChatbotOpen(true);
        api.inAppCenter.close();
        api.inAppCenter.show(); // Reset widget otherwise it can't be opened again
        trackUserEvent(userEvent.chatbot.open);
      };

      window.addEventListener('productfruits_button_ext_widget_init', openMyChatBot);

      return () => {
        window.removeEventListener('productfruits_button_ext_widget_init', openMyChatBot);
      };
    },
    [setChatbotOpen],
  );

  const onClick = () => {
    if (window.productFruitsIsReady && !window.productFruits?.api.inAppCenter.isOpened()) {
      trackUserEvent(userEvent.topbar.support.open);
    }
  };

  const icon = (
    <StyledBadge
      dotColor={theme.palette.primary.main}
      overlap="circular"
      invisible={!hasUnreadNews}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      backgroundColor={asMenuItem ? theme.palette.background.paper : theme.palette.information.light}
    >
      <QuestionMarkIcon style={{ color: theme.palette.shade.grey2 }} />
    </StyledBadge>
  );

  return (
    <>
      {asMenuItem ? (
        <MenuItem ref={buttonRef as React.RefObject<HTMLLIElement>} onMouseDown={onClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{t('helperMenu.newsAndHelpButton')}</ListItemText>
          <CaretIcon />
        </MenuItem>
      ) : (
        <Button
          ref={buttonRef as React.RefObject<HTMLButtonElement>}
          onMouseDown={onClick}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: theme.palette.information.light,
            borderRadius: 8,
            color: theme.palette.text.primary,
            whiteSpace: 'nowrap',
            fontSize: theme.typography.body4.fontSize,
          }}
          startIcon={icon}
        >
          {t('helperMenu.newsAndHelpButton')}
        </Button>
      )}
    </>
  );
}
