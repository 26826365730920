import { BreadcrumbState, Popover, UIContext } from '@ev/eva-container-api';
import { PropsWithChildren, useCallback, useContext, useState } from 'react';

export const MOBILE_TOP_BAR_HEIGHT = 56;
export const DESKTOP_TOP_BAR_HEIGHT = 68;

type UIStateProviderProps = PropsWithChildren<Record<string, unknown>>;

export function UIStateProvider({ children }: UIStateProviderProps) {
  const [openDrawerList, setOpenDrawerList] = useState<string[]>([]);
  const [openPopovers, setOpenPopovers] = useState<Popover[]>([]);
  const [isLayoutDeactivated, setLayoutDeactivated] = useState<boolean>(false);
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbState>({
    current: '',
    breadcrumbs: [],
  });
  const clearBreadcrumb = useCallback(() => setBreadcrumb({ current: '', breadcrumbs: [] }), []);

  const getIsDrawerOpen = useCallback((name: string) => openDrawerList.includes(name), [openDrawerList]);

  const setDrawerOpen = useCallback((name: string, open: boolean) => {
    setOpenDrawerList((state) => (open ? [...state, name] : state.filter((drawerName) => drawerName !== name)));
  }, []);

  const isAnyDrawerOpen = openDrawerList.length > 0;

  return (
    <UIContext.Provider
      value={{
        isAnyDrawerOpen,
        getIsDrawerOpen,
        setDrawerOpen,
        breadcrumb,
        setBreadcrumb,
        clearBreadcrumb,
        isLayoutDeactivated,
        deactivateLayout: setLayoutDeactivated,
        desktopTopBarHeight: DESKTOP_TOP_BAR_HEIGHT,
        mobileTopBarHeight: MOBILE_TOP_BAR_HEIGHT,
        openPopovers,
        setOpenPopovers,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

export function useIsAnyDrawerOpen() {
  const { isAnyDrawerOpen } = useContext(UIContext);
  return isAnyDrawerOpen;
}

export function usePopover(name: string) {
  const { openPopovers, setOpenPopovers } = useContext(UIContext);
  const isOpen = openPopovers.some((popover) => popover.name === name);
  const isMinimized = openPopovers.some((popover) => popover.name === name && popover.isMinimized);
  const minimizedOrder = openPopovers
    .filter((popover) => popover.isMinimized)
    .findIndex((popover) => popover.name === name);
  const numMinimized = openPopovers.filter((popover) => popover.isMinimized).length;
  const isEmailPopoverMaximized = !!openPopovers.find(
    (popover) => !popover.isMinimized && popover.name === 'emailPopover',
  );

  const setOpen = useCallback(
    (open: boolean, options?: { minimizedIfOtherPopoverIsOpen?: boolean }) => {
      setOpenPopovers((state) => {
        if (state.some((popover) => popover.name === name) === open) {
          return state;
        }

        const isOtherPopoverMaximized = state.some((popover) => !popover.isMinimized);
        const isOpenedMinimized = !!options?.minimizedIfOtherPopoverIsOpen && isOtherPopoverMaximized;
        return open
          ? [
              ...state.map((popover) => (isOpenedMinimized ? popover : { ...popover, isMinimized: true })),
              { name: name, isMinimized: isOpenedMinimized },
            ]
          : state.filter((popover) => popover.name !== name);
      });
    },
    [setOpenPopovers, name],
  );

  const setIsMinimized = useCallback(
    (isMinimized: boolean) => {
      setOpenPopovers((state) => {
        return state.map((popover) => ({
          ...popover,
          isMinimized: popover.name === name ? isMinimized : !isMinimized ? true : popover.isMinimized,
        }));
      });
    },
    [setOpenPopovers, name],
  );

  return { isOpen, setOpen, isMinimized, setIsMinimized, minimizedOrder, numMinimized, isEmailPopoverMaximized };
}
