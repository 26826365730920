import { IconButton, IconButtonProps, Stack, SxProps, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { ConfirmButton, ConfirmButtonCustomization } from 'components/general/ConfirmButton/ConfirmButton';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import InfoIcon from 'components/icons/info.svg?react';
import MinimizeIcon from 'components/icons/minimize.svg?react';
import React, { ReactNode, isValidElement } from 'react';
import { theme } from 'theme';
import { mergeSx, sxTruncate } from 'util/styleUtils';

export type CloseButtonCustomization = {
  icon: ReactNode;
  disabled?: boolean;
};

export const EVModalHeader = ({
  title,
  onClose,
  onMinimize,
  onConfirm,
  tooltipText,
  confirmButtonCustomization,
  closeButtonCustomization,
  mobileBreakPoint = theme.breakpoints.down('tablet'),
  sx,
}: {
  title: string;
  onClose: () => void;
  onMinimize?: () => void;
  onConfirm?: () => void;
  tooltipText?: string;
  confirmButtonCustomization?: ConfirmButtonCustomization;
  closeButtonCustomization?: CloseButtonCustomization;
  mobileBreakPoint?: string;
  sx?: SxProps;
}) => {
  const isMobile = useMediaQuery(mobileBreakPoint);

  const headerCss = {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.tertiary.active}`,
    backgroundColor: theme.palette.backgroundExtension.white,
    py: 1.5,
    //The side with the "X" has less padding, because icon button has its own padding
    pl: isMobile ? 0.5 : 3,
    pr: isMobile ? 2 : 1.5,
    ...sx,
  };

  if (isMobile) {
    return (
      <Stack
        gap={1.5}
        sx={{
          ...headerCss,
          position: 'sticky',
          top: 0,
          zIndex: 2,
        }}
      >
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton onClick={onClose} disabled={closeButtonCustomization?.disabled}>
            {closeButtonCustomization?.icon ?? <CrossIcon color={theme.palette.secondary.main} />}
          </IconButton>
          <Typography variant="body1" sx={mergeSx(sxTruncate, sx)}>
            {title}
          </Typography>
          {onConfirm ? <ConfirmButton onConfirm={onConfirm} customization={confirmButtonCustomization} /> : <Box />}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap={1.5} sx={headerCss}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="body1" sx={mergeSx(sxTruncate, sx)}>
          <Stack direction="row" gap={1}>
            {title}
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <Box>
                  <InfoIcon />
                </Box>
              </Tooltip>
            )}
          </Stack>
        </Typography>
        <Stack direction="row">
          {onMinimize && (
            <IconButton onClick={onMinimize}>
              <MinimizeIcon color={theme.palette.secondary.main} />
            </IconButton>
          )}
          <IconButton onClick={onClose}>
            <CrossIcon color={theme.palette.secondary.main} />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const EVModalHeaderMobile = ({ children, sx }: { children: ReactNode; sx?: SxProps }) => {
  const childNodes = React.Children.toArray(children).filter(isValidElement);

  const title = childNodes.find((child) => child.type === EVModalTitle);
  const action = childNodes.find((child) => child.type === EVModalTitleAction);
  const otherChildren = childNodes.filter((child) => child.type !== EVModalTitle && child.type !== EVModalTitleAction);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        top: 0,
        position: 'sticky',
        padding: 2,
        alignItems: 'center',
        zIndex: 1,
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.backgroundExtension.white,
        ...sx,
      }}
    >
      <Box sx={{ justifySelf: 'start' }}>{action}</Box>
      {title}
      <Box sx={{ justifySelf: 'end' }}>{otherChildren}</Box>
    </Box>
  );
};

export const EVModalTitle = ({ children, sx }: { children: ReactNode; sx?: SxProps }) => (
  <Typography variant="body1" sx={mergeSx(sxTruncate, sx)}>
    {children}
  </Typography>
);

export const EVModalTitleAction = ({ children, ...iconButtonProps }: IconButtonProps) => (
  <IconButton {...iconButtonProps}>{children}</IconButton>
);
