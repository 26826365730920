import { SxProps } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { EVCheckbox } from 'components/general/EVCheckbox/EVCheckbox';
import { ReactNode } from 'react';
import { theme } from 'theme';
import { mergeSx } from 'util/styleUtils';

export const NESTED_OPTION_SEPARATOR = '.';

interface OptionItemProps extends MenuItemProps {
  value: string;
  label: ReactNode;
  selected?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  onItemClick?: (value: string) => void;
  multiple?: boolean;
  compact?: boolean;
  hasStopPropagation?: boolean;
  sx?: SxProps;
  iconSx?: SxProps;
}

export function OptionItem({
  selected,
  indeterminate,
  value,
  disabled,
  onItemClick,
  multiple,
  label,
  sx,
  compact,
  hasStopPropagation,
  iconSx,
  ...otherProps
}: OptionItemProps) {
  const level = (value || '').split(NESTED_OPTION_SEPARATOR).length - 1;

  return (
    <MenuItem
      value={value}
      disabled={disabled}
      selected={selected}
      sx={mergeSx(
        {
          minHeight: compact ? 'unset' : undefined,
          paddingY: compact ? 0 : undefined,
          backgroundColor: selected ? theme.palette.shade.grey4 : undefined,
        },
        sx,
      )}
      onClick={
        onItemClick
          ? (event) => {
              onItemClick(value);
              if (hasStopPropagation) event?.stopPropagation();
            }
          : undefined
      }
      {...otherProps}
    >
      {multiple && (
        <ListItemIcon
          sx={mergeSx(
            {
              marginLeft: level * 4 - 1,
            },
            iconSx,
          )}
        >
          <EVCheckbox checked={selected} indeterminate={indeterminate} aria-hidden={false} />
        </ListItemIcon>
      )}
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
}
