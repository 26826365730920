import { datadogRum } from '@datadog/browser-rum';
import { useSnackBars } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMediaQuery } from '@mui/material';
import { useSendBulkEmail } from 'api/rest/hooks/useBulkEmail';
import { PreviewBulkEmailStepDesktop } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailStepDesktop';
import { PreviewBulkEmailStepMobile } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailStepMobile';
import {
  ModalType,
  PreviewBulkSendingModals,
} from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkSendingModals';
import { NylasIntegratedEmails } from 'components/emails/utils';
import { ConfirmCloseDialog } from 'components/general/EVDialog/StandardDialogs/ConfirmCloseDialog';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { countUnresolvedPlaceholders, hasAlreadyProposedProperty } from 'util/email';
import { useTranslation } from 'util/i18next';
import { sendBulkEmailMapper } from 'util/mappers/sendBulkEmailMapper';
import {
  ComposeBulkEmailFormData,
  PreviewBulkEmailFormData,
  usePreviewBulkEmailSchema,
} from 'util/schemas/sendBulkEmailSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';

export interface PreviewBulkEmailStepProps {
  onClose: () => void;
  onBackToCompose: () => void;
  defaultValues: PreviewBulkEmailFormData;
  possibleFromEmails: NylasIntegratedEmails[];
  onCloseAfterSend: () => void;
}

export interface InternalPreviewBulkEmailStepProps extends PreviewBulkEmailStepProps {
  noEmailIntegration: boolean;
  isSending: boolean;
  hidePrice?: boolean;
  from: string;
  onSend: () => void;
  onChangeFrom: (from: string) => void;
  selectedRecipient: string | undefined;
  onSelectRecipient: (recipientId: string) => void;
  onRemoveRecipient: (recipientId: string) => void;
}

export function PreviewBulkEmailStep(props: PreviewBulkEmailStepProps) {
  const { t } = useTranslation(['communication']);
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const noEmailIntegration = !props.possibleFromEmails.length;
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState<ModalType>(null);
  const [alreadyShow, setAlreadyShow] = useState<ModalType[]>([]);

  const { sendBulkEmail, isLoading: isSending } = useSendBulkEmail();
  const { activeShopId } = useActiveShopId();

  const {
    watch: watchComposeForm,
    setValue: setComposeValue,
    getValues: getComposeValues,
  } = useFormContext<ComposeBulkEmailFormData>();

  const [selectedRecipient, setSelectedRecipient] = useState(getComposeValues('to')[0]?.id);

  const onChangeFrom = (from: string) => setComposeValue('from', from);

  const previewSchema = usePreviewBulkEmailSchema();

  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();

  const methods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(previewSchema),
    defaultValues: props.defaultValues,
  });
  const recipients = watchComposeForm('to');
  const hasServiceContact = recipients.find((recipient) => {
    return recipient.type === 'CONTACT' ? recipient.isServiceContact : recipient.contact.isServiceContact;
  });
  const onSend = methods.handleSubmit((data) => {
    const hasPropertiesAlreadyProposed = data.recipients.some((recipient) => hasAlreadyProposedProperty(recipient));
    const hasUnresolvedPlaceholders = data.recipients.some((recipient) => countUnresolvedPlaceholders(recipient.body));

    if (hasUnresolvedPlaceholders && !alreadyShow.includes('unresolvedPlaceholders')) {
      setAlreadyShow((prevAlreadyShow) => [...prevAlreadyShow, 'unresolvedPlaceholders']);
      setCurrentModal('unresolvedPlaceholders');
    } else if (hasServiceContact && !alreadyShow.includes('serviceContacts')) {
      setAlreadyShow((prevAlreadyShow) => [...prevAlreadyShow, 'serviceContacts']);
      setCurrentModal('serviceContacts');
    } else if (hasPropertiesAlreadyProposed && !data.isPriceReduction && !alreadyShow.includes('alreadyProposed')) {
      setAlreadyShow((prevAlreadyShow) => [...prevAlreadyShow, 'alreadyProposed']);
      setCurrentModal('alreadyProposed');
    } else doSend(data);
  });

  const blockSending = useRef(false);
  const doSend = (data: PreviewBulkEmailFormData) => {
    // There are constant issues with emails being sent multiple time.
    if (blockSending.current) {
      datadogRum.addAction('Duplicate bulk email sending blocked');
      return;
    }

    blockSending.current = true;
    const senderEmail = getComposeValues('from');
    const template = getComposeValues('template');
    const createFollowUpTask = getComposeValues('createFollowUpTask');
    const hidePrice = getComposeValues('hidePrice');
    sendBulkEmail(
      sendBulkEmailMapper(data, {
        shopId: activeShopId!,
        senderEmail,
        template,
        createFollowUpTask,
        hidePrice,
      }),

      {
        onSuccess: () => {
          openSnackBar(t('communication:newEmail.submit.success'), 'success');
          props.onCloseAfterSend();
        },
        onError: (error) => {
          blockSending.current = false;
          openErrorSnackBar(t('communication:newEmail.submit.error'), error);
        },
      },
    );
  };

  const onRemoveRecipient = (recipientId: string) => {
    const newRecipients = getComposeValues('to').filter((r) => r.id !== recipientId);
    setComposeValue('to', newRecipients);

    const recipients = methods.getValues('recipients').filter((r) => r.recipient.id !== recipientId);
    methods.setValue('recipients', recipients);

    if (!newRecipients.length) {
      props.onBackToCompose();
      return;
    }

    if (selectedRecipient === recipientId) {
      setSelectedRecipient(newRecipients[0]!.id);
    }
  };

  const handleBackToCompose = () => {
    // Checking if the form is dirty does not work here because the editor changes the body when it is mounted
    setIsConfirmationOpen(true);
  };

  const internalProps = {
    ...props,
    isSending,
    onBackToCompose: handleBackToCompose,
    onSend,
    noEmailIntegration,
    from: watchComposeForm('from'),
    hidePrice: watchComposeForm('hidePrice'),
    onChangeFrom,
    selectedRecipient,
    onSelectRecipient: setSelectedRecipient,
    onRemoveRecipient,
  };

  return (
    <FormProvider {...methods}>
      {isDesktop ? (
        <PreviewBulkEmailStepDesktop {...internalProps} />
      ) : (
        <PreviewBulkEmailStepMobile {...internalProps} />
      )}

      <ConfirmCloseDialog
        isOpen={isConfirmationOpen}
        title={t('communication:sendBulkEmailDialog.abortPreviewConfirmation')}
        onConfirm={props.onBackToCompose}
        onCancel={() => setIsConfirmationOpen(false)}
      />

      <PreviewBulkSendingModals
        isSending={isSending}
        currentModal={currentModal}
        onCancel={() => {
          setAlreadyShow([]);
          setCurrentModal(null);
        }}
        onSend={onSend}
      />
    </FormProvider>
  );
}
