import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import IconSearch from 'components/icons/search.svg?react';
import { forwardRef, memo, useRef } from 'react';
import { splitRef } from 'util/splitRef';

const SearchTextFieldComponent = forwardRef<
  HTMLElement,
  TextFieldProps & { forceFocus?: boolean; onDelete?: () => void }
>(function SearchTextFieldComponent({ forceFocus, onDelete, ...props }, forwardRef) {
  const focusForced = useRef(false);
  const refCallback = (field: HTMLElement) => {
    if (forceFocus && !focusForced.current) {
      setTimeout(() => {
        const input = field?.querySelector('input');
        if (input) {
          focusForced.current = true;
          input.focus();
        }
      }, 10);
    }
  };

  return (
    <TextField
      ref={splitRef(forwardRef, refCallback)}
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconSearch />
          </InputAdornment>
        ),
        endAdornment: onDelete ? (
          <InputAdornment position="end">
            <IconButton onClick={onDelete}>
              <CrossIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined,
        ...props.InputProps,
      }}
    />
  );
});

export const SearchTextField = memo(SearchTextFieldComponent);
