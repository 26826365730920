import { Button, CircularProgress, SxProps } from '@mui/material';
import { useTranslation } from 'util/i18next';

export type ConfirmButtonCustomization = {
  disabled?: boolean;
  color?: 'primary' | 'secondary';
  label?: string;
  showSpinner?: boolean;
  sx?: SxProps;
};

export function ConfirmButton({
  onConfirm,
  customization,
}: {
  onConfirm: () => void;
  customization?: ConfirmButtonCustomization;
}) {
  const { t } = useTranslation([]);

  const spinner = <CircularProgress size={14} sx={{ color: 'white' }} />;

  return (
    <Button
      variant="contained"
      onClick={onConfirm}
      disabled={customization?.disabled}
      color={customization?.color || 'primary'}
      sx={customization?.sx}
      startIcon={customization?.showSpinner ? spinner : undefined}
    >
      {customization?.label ?? t('button.confirm')}
    </Button>
  );
}
