import { SxProps, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { mergeSx } from 'util/styleUtils';

export function SectionHeaderText({ children, sx }: PropsWithChildren<{ sx?: SxProps }>) {
  return (
    <Typography
      variant="subtitle1"
      fontWeight="bold"
      sx={mergeSx({ textTransform: 'uppercase', letterSpacing: '0.04em' }, sx)}
    >
      {children}
    </Typography>
  );
}
