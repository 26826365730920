import { Agent, ContactAndLeads, EmailTemplate, LanguageEnum } from 'api/graphql/generated/graphql';
import { useMemo } from 'react';
import { ExposeProperty } from 'util/email';
import { useTranslation } from 'util/i18next';
import { hasExposeDownloadUrl } from 'util/propertyUtils';
import { useLeadAndContactSearchOptionSchema } from 'util/schemas/contactAndLeadSchema';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { useCcAndBccSchema } from 'util/schemas/useCcAndBccSchema';
import { z } from 'zod';

export function useComposeBulkEmailSchema() {
  const { t } = useTranslation(['communication']);
  const { requiredEmail, optionalString, requiredBoolean } = useSchemaPrimitives();
  const recipient = useLeadAndContactSearchOptionSchema();

  return useMemo(() => {
    const ccRecipient = z.union([z.custom<Agent & { type: 'AGENT' }>(), z.custom<{ type: 'EMAIL'; email: string }>()]);

    return z
      .object({
        from: requiredEmail,
        to: recipient.array().min(1, t('communication:formValidation.mail.atLeastOneRecipient')),
        cc: ccRecipient.array(),
        bcc: ccRecipient.array(),
        isExposeSending: requiredBoolean,
        template: z.custom<EmailTemplate>((value) => !!value, t('communication:formValidation.mail.templateRequired')),
        subjectOverride: optionalString,
        properties: z.custom<ExposeProperty>().array(),
        createFollowUpTask: z.boolean().default(false),
        hidePrice: z.boolean().default(false),
        signatureId: optionalString,
        attachments: z.custom<File>().array(),
        toContactsWithoutEmail: z.custom<ContactAndLeads>().array(),
        toContactsFlagged: z.custom<ContactAndLeads>().array(),
      })
      .refine(
        (data) => data.properties.length <= 1 || !data.isExposeSending || data.template?.type !== 'PREMIUM_EXPOSE_MAIL',
        {
          message: t('communication:formValidation.mail.onlyOneProperty'),
          path: ['properties'],
        },
      )
      .refine((data) => !!data.properties.length || !data.isExposeSending, {
        message: t('communication:formValidation.mail.atLeastOneProperty'),
        path: ['properties'],
      })
      .superRefine((val, ctx) => {
        const propertyIdsWithoutExposeUrl = val.properties
          ?.filter(({ property }) => !hasExposeDownloadUrl(property))
          .map((p) => p.property.utag);

        if (val.isExposeSending && propertyIdsWithoutExposeUrl.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['properties'],
            message: t('communication:formValidation.mail.propertyWithoutDownloadLink', {
              properties: propertyIdsWithoutExposeUrl.join(', '),
            }),
          });
        }
      });
  }, [optionalString, recipient, requiredEmail, requiredBoolean, t]);
}

export type ComposeBulkEmailFormData = z.infer<ReturnType<typeof useComposeBulkEmailSchema>>;
export type ComposeCcRecipient = ComposeBulkEmailFormData['cc'][number];

export function usePreviewBulkEmailSchema() {
  const { t } = useTranslation(['communication']);
  const { requiredString, requiredHtmlString, requiredBoolean, optionalBoolean } = useSchemaPrimitives();
  const recipient = useLeadAndContactSearchOptionSchema();
  const ccAndBccArray = useCcAndBccSchema();

  return useMemo(() => {
    return z
      .object({
        isExposeSending: requiredBoolean,
        isPriceReduction: requiredBoolean,
        recipients: z
          .object({
            recipient,
            alreadyProposedProperties: z.string().array(),
            properties: z.custom<ExposeProperty>().array(),
            subject: requiredString,
            showSubjectOverrideWarning: optionalBoolean,
            body: requiredHtmlString,
            language: z.custom<LanguageEnum>(),
            cc: ccAndBccArray,
            bcc: ccAndBccArray,
          })
          .array()
          .min(1, t('communication:formValidation.mail.atLeastOneRecipient')),
        attachments: z.custom<File>().array(),
      })
      .superRefine((data, context) => {
        if (data.isExposeSending) {
          data.recipients.forEach((recipient, index) => {
            if (!recipient.properties.length) {
              context.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['recipients', index, 'properties'],
                message: t('communication:formValidation.mail.atLeastOneProperty'),
              });
            }
          });
        }
      });
  }, [recipient, t, optionalBoolean, requiredString, requiredHtmlString, requiredBoolean, ccAndBccArray]);
}

export type PreviewBulkEmailFormData = z.infer<ReturnType<typeof usePreviewBulkEmailSchema>>;
export type PreviewCcRecipient = PreviewBulkEmailFormData['recipients'][number]['cc'][number];
