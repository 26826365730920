import { Stack, Typography } from '@mui/material';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { useFormContext } from 'react-hook-form';
import { UNDEFINED_OPTION_VALUE } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';
import { joinWithComma } from 'util/stringUtils';

const kycRequiredFields = ['firstName', 'lastName', 'birthDate', 'placeOfBirth', 'nationality', 'idNumber'] as const;

const addressRequiredFields = ['streetName', 'streetNumber', 'city', 'postalCode', 'countryCode'] as const;

export function KycMissingFieldsAlert() {
  const { t } = useTranslation(['contact']);

  const { getValues } = useFormContext<ContactDetailsFormData>();

  const preferredAddressNumber = getValues('_internals.contact.preferredAddress') ?? -1;
  const preferredAddress = getValues('addresses')[preferredAddressNumber];

  const addressIsPrivate = preferredAddress?.addressType === 'PRIVATE';

  const kycMissingFields = kycRequiredFields.filter((field) => {
    const fieldValue = getValues(field);
    return !fieldValue || fieldValue === UNDEFINED_OPTION_VALUE;
  });

  const addressMissingFields = addressRequiredFields.filter((field) => {
    const fieldValue = preferredAddress?.address?.[field];
    return !fieldValue || fieldValue === UNDEFINED_OPTION_VALUE;
  });

  const kycMissingFieldText = joinWithComma(
    kycMissingFields.map((field) => t(`contact:drawer.kycWarning.missingField.${field}`)),
  );

  const addressMissingFieldText = joinWithComma(
    addressMissingFields.map((field) => t(`contact:drawer.kycWarning.missingField.address.${field}`)),
  );

  if (!kycMissingFieldText && !addressMissingFieldText && addressIsPrivate) {
    return null;
  }

  return (
    <EVAlert severity="warning" sx={{ mb: 3 }} fullWidth>
      <Stack gap={1}>
        <Typography variant="body3" fontWeight="bold">
          {t('contact:drawer.kycWarning.title')}
        </Typography>
        {kycMissingFieldText && (
          <Typography variant="body3">
            {t('contact:drawer.kycWarning.message', {
              missingFields: kycMissingFieldText,
            })}
          </Typography>
        )}
        {addressMissingFieldText && (
          <Typography variant="body3">
            {t('contact:drawer.kycWarning.message.address', {
              missingFields: addressMissingFieldText,
            })}
          </Typography>
        )}
        {!addressIsPrivate && (
          <Typography variant="body3">
            {t('contact:drawer.kycWarning.missingField.address.addressTypeWarning')}
          </Typography>
        )}
      </Stack>
    </EVAlert>
  );
}
