import { DraggableAttributes, DraggableSyntheticListeners } from '@dnd-kit/core';
import { IconButton, SvgIcon } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';
import DragNDropIcon from 'components/icons/drag_n_drop.svg?react';

export function DragNDropHandle(props: {
  attributes: DraggableAttributes;
  disabled?: boolean;
  dragging: boolean;
  listeners: DraggableSyntheticListeners;
  sx?: IconButtonProps['sx'];
}) {
  return (
    <IconButton
      disableRipple
      disableFocusRipple
      disableTouchRipple
      className="drag-handle"
      sx={{
        '&:focus': {
          border: 0,
        },
        margin: 0,
        width: 24,
        height: 24,
        cursor: props.disabled ? 'not-allowed' : props.dragging ? 'grabbing' : 'grab',
        ...props.sx,
      }}
      {...(!props.disabled ? { ...props.listeners, ...props.attributes } : {})}
    >
      <SvgIcon component={DragNDropIcon} color={props.disabled ? 'disabled' : 'secondary'} />
    </IconButton>
  );
}
