import { Box, Paper, Popover, SxProps, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { PropertyEngineListingType } from 'api/graphql/generated/graphql';
import { useListingSearch } from 'api/graphql/hooks/useListingSearch';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { EvaImage } from 'components/general/EvaImage/EvaImage';
import { PropertyItem, usePropertyDetails } from 'components/property/PropertySearch/PropertyItem';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { GoEVIdLink } from 'page-components/contact/EVIdWithLink/GoEVIdLink';
import React, { ReactNode, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { ListingWithLegacyFields } from 'util/go3';
import { useTranslation } from 'util/i18next';
import { useShowError } from 'util/useShowError';

const CustomPaper = (props: { className?: string; children?: ReactNode }) => {
  return (
    <Paper
      sx={{
        '& .MuiAutocomplete-listbox': {
          '& .MuiAutocomplete-option': {
            display: 'block',
          },
        },
      }}
      {...props}
    />
  );
};

export const LinkPropertySearchField = ({
  name = 'properties',
  listingType,
  required,
  label,
  sx,
  isError,
  helperText,
  leadType,
}: {
  name?: string;
  label: string;
  sx?: SxProps;
  required?: boolean;
  isError?: boolean;
  listingType?: PropertyEngineListingType;
  helperText?: string;
  leadType?: string;
}) => {
  const { activeShopId } = useActiveShopId();

  const { t } = useTranslation(['enums', 'lead', 'errors']);

  const [searchString, setSearchString] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const [selectedOption, setOption] = useState<ListingWithLegacyFields>();
  const { control } = useFormContext();
  const updatedListingType = leadType == 'SELL' ? 'SALE' : leadType == 'RENT_OUT' ? 'RENTAL' : listingType;
  const { listings, isLoading, error } = useListingSearch(
    {
      shopId: activeShopId ?? '',
      query: searchString,
      ...(updatedListingType ? { listingTypes: [updatedListingType] } : {}),
    },
    { enabled: !!searchString },
  );

  useShowError(t('lead:leadOverview.linkPropertyToLead.propertySearch.error'), !!error);

  const handleClick = (option: ListingWithLegacyFields) => {
    return (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      setOption(option);
      setAnchorEl(event.currentTarget);
    };
  };

  const handleClose = () => {
    setOption(undefined);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getOptionsToSearch = (option: ListingWithLegacyFields) => {
    let matchString = '';
    if (option?.address?.city) {
      matchString += option?.address?.city;
    }
    if (option?.address?.streetName) {
      matchString += option?.address?.streetName;
    }
    if (option?.utag) {
      matchString += option?.utag;
    }
    return matchString;
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            open={!!searchString.length}
            noOptionsText={t('lead:leadOverview.linkPropertyToLead.propertySearch.noMatches')}
            loading={isLoading}
            loadingText={t('lead:leadOverview.linkPropertyToLead.propertySearch.loading')}
            disableClearable
            PaperComponent={CustomPaper}
            multiple
            limitTags={3}
            value={value}
            sx={sx}
            getOptionLabel={(option) => {
              return typeof option === 'string' ? option : getOptionsToSearch(option);
            }}
            onChange={(_, items) => {
              onChange(items);
            }}
            isOptionEqualToValue={(option: string | ListingWithLegacyFields, value: ListingWithLegacyFields) =>
              typeof option === 'string' ? option === value.utag : option.utag === value.utag
            }
            onInputChange={(event, newInputValue) => setSearchString(newInputValue)}
            id="properties"
            options={listings || []}
            renderOption={(props, property: ListingWithLegacyFields) => {
              return (
                <Box component="li" {...props} sx={{ p: 1 }}>
                  <PropertyItem property={property} />
                </Box>
              );
            }}
            freeSolo={false}
            getOptionDisabled={() => value?.length > 0}
            filterSelectedOptions
            renderTags={(value: ListingWithLegacyFields[], getTagProps) =>
              value.map((option: ListingWithLegacyFields, index: number) => (
                <EVChip
                  label={option.utag}
                  {...getTagProps({ index })}
                  key={index}
                  data-testid="propertyTag"
                  onClick={handleClick(option)}
                  selected={false}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                required={required}
                error={isError}
                helperText={helperText}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        )}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {selectedOption && <PropertyTooltip property={selectedOption} />}
      </Popover>
    </>
  );
};

function PropertyTooltip({ property }: { property: ListingWithLegacyFields }) {
  const propertyDetails = usePropertyDetails(property);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
      {property.previewImage ? (
        <EvaImage
          src={property.previewImage}
          alt="property_photo"
          width={32}
          height={32}
          style={{ borderRadius: '50%', objectFit: 'cover' }}
        />
      ) : (
        <EVAvatar size="m" />
      )}

      <Box sx={{ display: 'block', lineHeight: '15px' }}>
        <Typography variant="body3" sx={{ pl: 1, py: 0 }} component="div">
          {property.fileAs}
        </Typography>
        {propertyDetails && (
          <Typography variant="caption" sx={{ pl: 1, py: 0, color: theme.palette.text.secondary }}>
            {propertyDetails}
          </Typography>
        )}
      </Box>
      {property.type && (
        <Box sx={{ pl: 3, lineHeight: '15px' }}>
          <GoEVIdLink
            label={property.utag || ''}
            goPath={`${property.type === 'SALE' ? 'sales' : 'lettings'}/${property.utag}`}
          />
        </Box>
      )}
    </Box>
  );
}
