import { boundingBoxToLatLngBounds } from 'components/map/LeafletMap/LeafletMapUtils';
import LeafletPolygons from 'components/map/LeafletMap/LeafletPolygons';
import { LeafletRectangle } from 'components/map/LeafletMap/LeafletRectangle';
import { FeatureCollection, GeoJsonProperties, Polygon } from 'geojson';
import Leaflet from 'leaflet';
import { useEffect } from 'react';
import { GeoJSON, useMap } from 'react-leaflet';
import { theme } from 'theme';
import { BoundingBox, PlacesAddressSearchResult } from 'util/places/types';

interface LeafletRectanglesAndPolygonsProps {
  details: PlacesAddressSearchResult[] | undefined;
  overallCoordinates: BoundingBox | undefined;
  geojson?: FeatureCollection<Polygon, GeoJsonProperties>;
  setGeojson?: (featureCollection: FeatureCollection<Polygon, GeoJsonProperties>) => void;
}

export const LeafletRectanglesAndPolygons = ({
  details,
  overallCoordinates,
  geojson,
  setGeojson,
}: LeafletRectanglesAndPolygonsProps) => {
  const map = useMap();
  const geojsonBounds = Leaflet.geoJSON(geojson).getBounds();
  const overallBounds = overallCoordinates
    ? geojsonBounds.extend(boundingBoxToLatLngBounds(overallCoordinates))
    : geojsonBounds;

  useEffect(() => {
    if (map && overallBounds?.isValid()) {
      map.fitBounds(overallBounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, JSON.stringify(overallBounds)]);

  return (
    <>
      {details
        ?.filter((place) => !!place.boundingBox)
        .map((place) =>
          place.polygon && place.polygon?.coordinates?.length > 0 ? (
            <GeoJSON
              key={place.placeId}
              data={place.polygon}
              pathOptions={{
                fillColor: theme.palette.text.primary,
                color: theme.palette.text.primary,
              }}
            />
          ) : (
            <LeafletRectangle key={place.placeId} bounds={place.boundingBox!} />
          ),
        )}
      {geojson && <LeafletPolygons geojson={geojson} setGeojson={setGeojson} />}
    </>
  );
};
