import { AppointmentDrawerData } from '@ev/eva-container-api';
import { AppointmentTypeEnum } from 'api/graphql/generated/graphql';
import { mapLeadInfosToLeadAndContactOptions } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { useCallback } from 'react';
import { loadAppointment, loadContactsAndLeads, loadLeads } from 'util/defaultValues/defaultValueLoaders';
import { ListingWithLegacyFields } from 'util/go3';
import { CreateNewAppointmentFormData } from 'util/schemas/newAppointmentSchema';
import { getNextClosestQuarter, setTimeFromDifferentDate } from 'util/timeUtils';
import { usePermissions } from 'util/usePermissions';

export function useGetAppointmentDefaultValues() {
  const { canEditLead } = usePermissions();

  return useCallback(
    async ({
      currentAgentId,
      activeShopId,
      initialAppointmentData,
    }: {
      currentAgentId?: string;
      activeShopId?: string;
      initialAppointmentData: AppointmentDrawerData;
    }): Promise<CreateNewAppointmentFormData> => {
      const isEdit = !!initialAppointmentData.editId;
      const day = initialAppointmentData.day
        ? setTimeFromDifferentDate(initialAppointmentData.day, new Date())
        : new Date();

      if (isEdit) {
        const baseActivity = await loadAppointment(initialAppointmentData.editId);
        const appointment = baseActivity!.appointmentActivity!.appointment;
        const [contacts, leads] = await Promise.all([
          loadContactsAndLeads(appointment?.appointmentContacts?.map((ap) => ap.contactId)),
          loadLeads(appointment?.appointmentLeads?.map((ap) => ap.leadId)),
        ]);

        const contactsAndLeads = mapLeadInfosToLeadAndContactOptions(leads || [], contacts || []);

        const agents =
          appointment.appointmentAgents?.map(({ agent }) => ({
            type: 'AGENT' as const,
            ...agent,
          })) ?? [];

        return {
          actionType: appointment.type,
          agentId: appointment.agentId,
          teamId: appointment.teamId || '',
          shopId: baseActivity!.shopId!,
          comment: appointment.comment || '',
          contactsAndLeads,
          feedback: appointment.feedback || '',
          subject: appointment.subject || '',
          date: new Date(appointment.startTime),
          startTime: new Date(appointment.startTime),
          endTime: new Date(appointment.endTime),
          properties: appointment.propertyIds?.map((utag) => ({ utag } as ListingWithLegacyFields)),
          status: appointment.status || undefined,
          go3AppointmentKey: appointment.go3AppointmentKey || undefined,
          additionalAgents: agents,
          _editAppointmentActivity: baseActivity,
          _nonRemovableLeadIds: contactsAndLeads
            .filter((cl) => cl.type === 'LEAD' && !canEditLead(cl))
            .map((cl) => cl.id),
        };
      }

      const [contacts, leads] = await Promise.all([
        loadContactsAndLeads(initialAppointmentData.contactIds),
        loadLeads(initialAppointmentData.leadIds),
      ]);
      const contactsAndLeads = mapLeadInfosToLeadAndContactOptions(leads || [], contacts || []);

      return {
        agentId: currentAgentId!,
        teamId: '',
        shopId: activeShopId!,
        actionType: (initialAppointmentData?.type as AppointmentTypeEnum) || ('VIEWING' as const),
        status: 'OPEN' as const,
        date: day,
        subject: '',
        startTime: getNextClosestQuarter(day),
        endTime: getNextClosestQuarter(day, 1),
        properties: initialAppointmentData?.propertyUtags?.map((utag) => ({ utag } as ListingWithLegacyFields)),
        contactsAndLeads,
        go3AppointmentKey: undefined,
        _editAppointmentActivity: undefined,
      };
    },
    [canEditLead],
  );
}
