import { LanguageEnum } from 'api/graphql/generated/graphql';
import { ListingWithLegacyFields } from 'util/go3';
import { PROPERTY_ENGINE_LANGUAGE_MAP } from 'util/mappers/propertyEngineMapper';

export function getMarketingDetails(property: ListingWithLegacyFields, language: LanguageEnum) {
  for (const propertyEngineLanguage of PROPERTY_ENGINE_LANGUAGE_MAP[language]) {
    const details = property.marketingDetails.find((details) => details.language === propertyEngineLanguage);
    if (details) {
      return details;
    }
  }
}

export function getMarketingDetailsWithPremiumExpose(property: ListingWithLegacyFields, language: LanguageEnum) {
  for (const propertyEngineLanguage of PROPERTY_ENGINE_LANGUAGE_MAP[language]) {
    const details = property.marketingDetails.find((details) => details.language === propertyEngineLanguage);
    if (
      details?.premiumExposeEmail?.description ||
      details?.premiumExposeEmail?.subject ||
      details?.premiumExposeEmail?.bulletPoints?.length ||
      details?.premiumExposeEmail?.bulletPointsTitle
    ) {
      return details;
    }
  }
}

export function hasExposeDownloadUrl(property: ListingWithLegacyFields) {
  return property.exposes.some((expose) => !!expose.exposeDownloadUrl);
}
