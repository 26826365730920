import { Agent, ContactAndLeads, ContactInfo, ContactRelationTypeEnum } from 'api/graphql/generated/graphql';
import { LeadAndContactSearchOption } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { useMemo } from 'react';
import { ListingWithLegacyFields } from 'util/go3';
import { useTranslation } from 'util/i18next';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';
import { useCcAndBccSchema } from 'util/schemas/useCcAndBccSchema';
import { z } from 'zod';

export function useEmailSchema() {
  const { t } = useTranslation(['communication']);
  const { requiredEmail, optionalString, requiredString, requiredHtmlString } = useSchemaPrimitives();
  const ccAndBccArray = useCcAndBccSchema();

  return useMemo(() => {
    const recipientsArray = z
      .union([
        z.custom<Agent & { type: 'AGENT' }>(),
        z.custom<ContactInfo & { type: 'RELATED_CONTACT'; relationship: ContactRelationTypeEnum }>(),
        z.custom<LeadAndContactSearchOption>(),
        z.custom<{ type: 'EMAIL'; email: string }>(),
      ])
      .array()
      .min(1, t('communication:formValidation.mail.atLeastOneRecipient'))
      .refine((data) => !data.find((r) => r.type === 'EMAIL' && !requiredEmail.safeParse(r.email).success), {
        message: t('formValidation.invalidEmail'),
      });

    return z
      .object({
        from: requiredEmail,
        to: recipientsArray,
        cc: ccAndBccArray,
        bcc: ccAndBccArray,
        templateId: optionalString,
        properties: z.custom<ListingWithLegacyFields>().array(),
        signatureId: optionalString,
        attachments: z.custom<File>().array(),
        subject: requiredString,
        body: requiredHtmlString,
        sharedLeadId: optionalString,
        appointmentId: optionalString,
        toContactsWithoutEmail: z.custom<ContactAndLeads>().array(),
        toContactsFlagged: z.custom<ContactAndLeads>().array(),
        responseToType: optionalString,
        replyToMessageId: optionalString,
      })
      .refine((data) => !data.sharedLeadId || data.to.every((recipient) => recipient.type === 'AGENT'), {
        message: t('communication:formValidation.mail.allRecipientsMustBeAgents'),
        path: ['to'],
      });
  }, [optionalString, requiredEmail, requiredHtmlString, requiredString, ccAndBccArray, t]);
}

export type EmailFormData = z.infer<ReturnType<typeof useEmailSchema>>;
export type Recipient = EmailFormData['to'][number];
