import { Stack, Typography } from '@mui/material';
import { DuplicateContactInfo } from 'api/rest/models/ContactDuplicates';
import { DuplicateContactCard } from 'components/contact/ContactDetailsForm/CheckForDuplicates/DuplicateContactCard';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export interface DuplicateAlertProps {
  onClose: () => void;
  isOpen: boolean;
  duplicates: DuplicateContactInfo[];
  shopName?: string;
  onContinue: () => void;
  isLoading?: boolean;
  hideBackdrop?: boolean;
  showLastInteraction?: boolean;
}

export function DuplicateAlertDrawer({
  onClose,
  isOpen,
  duplicates,
  onContinue,
  shopName,
  isLoading = false,
  hideBackdrop,
  showLastInteraction,
}: DuplicateAlertProps) {
  const { t } = useTranslation(['contact']);

  const zIndexForDuplicateDrawer: number = theme.zIndex.drawer + 4;

  return (
    <EVDrawer
      sx={{ position: 'relative' }}
      zIndex={zIndexForDuplicateDrawer}
      isOpen={isOpen}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
    >
      <EVDrawerContent
        sx={{ overflowX: 'hidden' }}
        title={t('contact:duplicateAlertDrawer.title')}
        onClose={onClose}
        primaryAction={{
          primaryButtonLabel: t('contact:duplicateAlertDrawer.continueButton'),
          callback: onContinue,
          cancelButtonLabel: t('contact:duplicateAlertDrawer.backButton'),
        }}
        hasBackButton
        isLoading={isLoading}
      >
        <Stack
          sx={{
            gap: 1,
            margin: 3,
            marginTop: 2,
            fontSize: theme.typography.body3.fontSize,
            lineHeight: 1.75,
          }}
        >
          {shopName && (
            <Typography variant="body3" fontWeight="bold" component="div">
              {shopName}
            </Typography>
          )}
          <Typography variant="body3" color={theme.palette.text.secondary}>
            {t('contact:duplicateAlertDrawer.titleHint')}
          </Typography>
        </Stack>

        {duplicates?.map((duplicate, index) => (
          <DuplicateContactCard key={index} duplicateContact={duplicate} showLastInteraction={showLastInteraction} />
        ))}
      </EVDrawerContent>
    </EVDrawer>
  );
}
