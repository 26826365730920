import { TaskDrawerData, useSnackBars } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useCreateTask, useUpdateTask } from 'api/rest';
import { CreateOrEditTaskForm } from 'components/dashboard/CreateOrEditTaskForm';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { separateContactsAndLeads } from 'page-components/dashboard/separateContactsAndLeads';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetTaskDefaultValues } from 'util/defaultValues/taskDefaultValues';
import { UNASSIGNED } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { useCreateNewTaskSchema } from 'util/schemas/newTaskSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { z } from 'zod';

export interface CreateNewTaskProps {
  onClose: () => void;
  initialTaskData: TaskDrawerData;
}

export function CreateOrEditTask({ onClose, initialTaskData }: CreateNewTaskProps) {
  const { t } = useTranslation(['user']);
  const { createTask, isLoading: isCreateLoading } = useCreateTask();
  const { updateTask, isLoading: isUpdateLoading } = useUpdateTask();
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const currentAgentId = useCurrentAgentId();
  const { activeShopId } = useActiveShopId();

  const getTaskDefaultValues = useGetTaskDefaultValues();

  const createNewTaskSchema = useCreateNewTaskSchema();
  const methods = useForm<z.infer<typeof createNewTaskSchema>>({
    mode: 'onTouched',
    resolver: zodResolver(createNewTaskSchema),
    defaultValues: () =>
      getTaskDefaultValues({
        currentAgentId,
        activeShopId,
        initialTaskData,
      }),
  });
  const isCompletedOrCancelled = ['COMPLETED', 'CANCELLED'].includes(methods.formState.defaultValues?.status ?? '');

  const drawerTitle = (() => {
    if (initialTaskData.editId) {
      return t('user:dashboard.task.edit.drawerTitle');
    }
    if (isCompletedOrCancelled) {
      return t('user:dashboard.task.completed.drawerTitle');
    }
    return t('user:dashboard.task.add.drawerTitle');
  })();

  const onSave = (data: z.infer<typeof createNewTaskSchema>): void => {
    const payload = mapTaskData(data);

    if (initialTaskData.editId) {
      updateTask(
        { ...payload, taskId: initialTaskData.editId, shopId: activeShopId! },
        {
          onSuccess: () => {
            openSnackBar(t('user:dashboard.updateActivity.task.success'), 'success');
            onClose();
          },
          onError: (error) => openErrorSnackBar(t('user:dashboard.updateActivity.task.error'), error),
        },
      );
    } else {
      createTask(
        { ...payload, shopId: activeShopId! },
        {
          onSuccess: () => {
            openSnackBar(t('user:dashboard.addActivity.task.success'), 'success');
            onClose();
          },
          onError: (error) => openErrorSnackBar(t('user:dashboard.addActivity.task.error'), error),
        },
      );
    }
  };

  if (methods.formState.isLoading) {
    return null;
  }

  return (
    <EVDrawerContent
      title={drawerTitle}
      onClose={onClose}
      {...(!isCompletedOrCancelled
        ? {
            primaryAction: {
              primaryButtonLabel: t('user:dashboard.drawer.addActivity.saveButton'),
              callback: methods.handleSubmit(onSave),
            },
          }
        : {})}
      isLoading={isCreateLoading || isUpdateLoading}
    >
      <FormProvider {...methods}>
        <CreateOrEditTaskForm />
      </FormProvider>
    </EVDrawerContent>
  );
}

export const mapTaskData = (data: z.infer<ReturnType<typeof useCreateNewTaskSchema>>) => {
  const dueTime = new Date(data.date);
  dueTime.setHours(data.time.getHours());
  dueTime.setMinutes(data.time.getMinutes());

  const contactsAndLeads = separateContactsAndLeads(data.contactsAndLeads);

  return {
    agentId: data.agentId,
    teamId: data.teamId !== UNASSIGNED ? data.teamId : undefined,
    type: data.actionType,
    status: data.status,
    priority: data.priority,
    title: data.title,
    dueDateTime: dueTime.toISOString(),
    comment: data.comment,
    contactIds: contactsAndLeads.contactIds,
    leadIds: contactsAndLeads.leadIds,
    additionalAgentIds: data.additionalAgents?.map((agent) => agent.id),
    ...(data.properties?.length && { linkedPropertyIds: data.properties.map((p) => p.utag) }),
  };
};
