import { useSnackBars } from '@ev/eva-container-api';
import { Divider, ListItemIcon, ListItemText, Switch, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SessionStatusEnum } from 'api/graphql/generated/graphql';
import { DropdownMenu } from 'components/general/DropDown/DropdownMenu';
import PhoneIcon from 'components/icons/phone_naked.svg?react';
import { useTwilio } from 'components/state/Twilio';
import { StyledBadge } from 'eva-frame/EvaLayout/StyleBadge/style';
import React, { useCallback, useEffect, useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { Container, listItemHoverReset } from './style';

function isActive(sessionStatus: SessionStatusEnum | undefined) {
  return sessionStatus !== 'OFFLINE';
}

// We only want to check once per session
let globalMicEnabledChecked = false;

export function CallMenuButton() {
  const { t } = useTranslation(['user', 'communication', 'errors']);
  const { openSnackBar, closeSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { twilioSession, openEmptyCallPopover, updateTwilioSessionStatus, canCallInAnyShop } = useTwilio();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const onCloseMenu = () => setAnchorEl(null);
  const onOpenMenu = (event: React.MouseEvent) => setAnchorEl(event.currentTarget);

  const checked = isActive(twilioSession?.sessionStatus);

  useEffect(() => {
    if (!globalMicEnabledChecked && canCallInAnyShop) {
      navigator?.mediaDevices?.getUserMedia({ audio: true }).catch(() => {
        openSnackBar(t('globalNotification.micDisable'), 'warning', {
          asBanner: true,
        });
      });
      globalMicEnabledChecked = true;
    }
  }, [openSnackBar, t, canCallInAnyShop]);

  const onOnlineSwitchClicked = useCallback(() => {
    const enabled = !checked;
    updateTwilioSessionStatus(
      { status: enabled ? 'ACTIVE' : 'OFFLINE' },
      {
        onSuccess: (response) => {
          if (response) {
            setAnchorEl(null);
          } else {
            openSnackBar(t('user:profile.errorMessage.updateStatusFailed'), 'error');
          }
        },
        onError: (error) => {
          openErrorSnackBar(t('user:profile.errorMessage.updateStatusFailed'), error);
        },
      },
    );
  }, [checked, openErrorSnackBar, openSnackBar, t, updateTwilioSessionStatus]);

  const dotColor = checked ? theme.palette.success.main : theme.palette.primary.main;

  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (!checked) {
      let timeoutId: number;
      const id = openSnackBar(t('user:profile.warningMessage.youAreInactive'), 'warning', {
        action: {
          text: t('user:profile.warningMessage.setActive'),
          callback: onOnlineSwitchClicked,
        },
        asBanner: true,
        onCloseCallback: () => {
          timeoutId = window.setTimeout(() => setCounter((counter) => counter + 1), 10 * 60 * 1000);
        },
      });
      return () => {
        if (timeoutId) {
          window.clearTimeout(timeoutId);
        }
        closeSnackBar(id);
      };
    }
  }, [checked, closeSnackBar, onOnlineSwitchClicked, openSnackBar, t, counter]);

  return (
    <Container>
      <IconButton
        onClick={onOpenMenu}
        disableTouchRipple
        sx={{ marginRight: -1 }}
        aria-label={t('communication:callMenu.callMenuButton')}
      >
        <StyledBadge
          dotColor={dotColor}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
        >
          <PhoneIcon style={{ color: theme.palette.shade.grey2 }} />
        </StyledBadge>
      </IconButton>
      <DropdownMenu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        id="account-menu"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        sxPopover={{ width: '220px' }}
        showDrawerOnMobile={{ title: t('communication:callMenu.title') }}
      >
        <MenuList>
          <Stack sx={{ padding: 2 }}>
            {isDesktop && (
              <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                {t('communication:callMenu.title')}
              </Typography>
            )}
            <Typography sx={{ whiteSpace: 'initial' }} color="secondary" variant="body4">
              {checked
                ? t('communication:callMenu.activeDescription')
                : t('communication:callMenu.inactiveDescription')}
            </Typography>
          </Stack>

          <Divider variant="middle" />
          <MenuItem
            onClick={() => {
              onCloseMenu();
              openEmptyCallPopover();
            }}
          >
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText>{t('communication:callMenu.openCaller')}</ListItemText>
          </MenuItem>

          <Divider variant="middle" />
          <MenuItem disableRipple sx={{ ...listItemHoverReset }} onClick={onOnlineSwitchClicked}>
            <ListItemText>
              {checked ? t('communication:callMenu.setInactive') : t('communication:callMenu.setActive')}
            </ListItemText>
            <Switch checked={checked} tabIndex={-1} />
          </MenuItem>
        </MenuList>
      </DropdownMenu>
    </Container>
  );
}
