import { Contact, ContactRelationTypeEnum, CountryEnum, CurrencyEnum } from 'api/graphql/generated/graphql';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { IterableElement } from 'type-fest';
import { prepareForChangeDetection } from 'util/changeDetection';
import { isSupportedCountry } from 'util/phoneUtils';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';

type RelationshipRecordType = Record<string, IterableElement<ContactDetailsFormData['contactRelationships']>>;

function contactRelationshipDefaultValues(contact: Contact) {
  if (!contact.contactRelationships?.length) {
    return [
      {
        type: undefined,
        autoCc: false,
        visibleContacts: [],
        hiddenContacts: [],
      },
    ];
  }

  const relationshipsByType = (contact.contactRelationships || []).reduce(
    (relationships: RelationshipRecordType, relation) => {
      if (!relationships[relation.type]) {
        relationships[relation.type] = {
          autoCc: false,
          visibleContacts: [],
          hiddenContacts: [],
        };
      }

      if (relation.relatedContact) {
        relationships[relation.type]!.visibleContacts.push({
          firstName: relation.relatedContact.firstName || undefined,
          lastName: relation.relatedContact.lastName || undefined,
          id: relation.relatedContactId,
        });
      } else {
        // User is not allowed to see the related contact
        relationships[relation.type]!.hiddenContacts.push({
          id: relation.relatedContactId,
        });
      }

      if (relation.autoCc) {
        relationships[relation.type]!.autoCc = relation.autoCc;
      }

      return relationships;
    },
    {} as RelationshipRecordType,
  );

  const relationships = (Object.keys(relationshipsByType) as ContactRelationTypeEnum[]).map((relationshipName) => ({
    type: relationshipName,
    autoCc: relationshipsByType[relationshipName]!.autoCc,
    visibleContacts: relationshipsByType[relationshipName]!.visibleContacts,
    hiddenContacts: relationshipsByType[relationshipName]!.hiddenContacts,
  }));

  return relationships.every((r) => !r.visibleContacts.length)
    ? [
        ...relationships,
        {
          type: undefined,
          autoCc: false,
          visibleContacts: [],
          hiddenContacts: [],
        },
      ]
    : relationships;
}

export function editContactDefaultValues(
  contact: Contact,
  isContactQualified: boolean,
  shopCurrency: CurrencyEnum,
  shopCountry?: CountryEnum | null,
): ContactDetailsFormData {
  const formData: ContactDetailsFormData = {
    _internals: {
      isEditMode: true,
      contact: {
        contactQualified: isContactQualified,
        preferredAddress: contact.addresses.findIndex((address) => address.preferred),
        preferredEmail: contact.emailAddresses.findIndex((email) => email.preferred),
        preferredPhone: contact.phoneNumbers.findIndex((phone) => phone.preferred),
        isServiceContact: !!contact.type && contact.type !== 'UNDEFINED',
        migratedPartnerContact: {
          firstName: contact.partnerFirstName,
          lastName: contact.partnerLastName,
        },
        id: contact.id,
      },
    },
    additionalNote: contact.personalNote ?? undefined,
    addresses:
      contact.addresses?.map((address) => ({
        addressType: address.type,
        preferred: address.preferred,
        address: {
          city: address.address.city || undefined,
          countryCode: address.address.countryCode,
          postalCode: address.address.postalCode || undefined,
          streetName: address.address.streetName || undefined,
          streetNumber: address.address.streetNumber || undefined,
          placeId: address.address.placeId || undefined,
        },
      })) ?? [],
    birthDate: contact.birthdate ? new Date(contact.birthdate) : null,
    placeOfBirth: contact.placeOfBirth || '',
    nationality: contact.nationality || undefined,
    idNumber: contact.idNumber || '',
    company: contact.legalEntity?.name ?? '',
    division: contact.legalEntity?.department ?? undefined,
    emailAddresses:
      contact.emailAddresses?.map((email) => ({
        email: email.address,
        emailType: email.type,
        preferred: email.preferred,
      })) || [],
    isCompany: !!contact.legalEntity,
    formattedSalutation: contact.formattedSalutation ?? '',
    salutation: contact.salutation ?? undefined,
    title: contact.title ?? '',
    firstName: contact.firstName ?? '',
    lastName: contact.lastName ?? '',
    autoCc: contact.autoCc ?? false,
    tags: (contact.tags || []).map((tagItem) => ({
      value: tagItem?.tag?.id ?? '',
      displayValue: tagItem?.tag?.name ?? '',
    })),
    phoneNumbers:
      contact.phoneNumbers?.map((phone) => ({
        number: phone.internationalNumber || getInternationalNumber(phone.number, shopCountry),
        phoneType: phone.type,
        preferred: phone.preferred,
      })) ?? [],
    contactRelationships: contactRelationshipDefaultValues(contact),
    renterDetails: {
      householdType: contact.contactRenterInformations?.[0]?.householdType ?? undefined,
      professionType: contact.contactRenterInformations?.[0]?.professionType ?? undefined,
      petOwner: contact.contactRenterInformations?.[0]?.petOwner ?? undefined,
      incomeRange: {
        minimum: contact.contactRenterInformations?.[0]?.monthlyIncomeMin ?? undefined,
        maximum: contact.contactRenterInformations?.[0]?.monthlyIncomeMax ?? undefined,
        _currency: contact.contactRenterInformations?.[0]?.currency ?? shopCurrency,
      },
    },
    preferredLanguage: contact.preferredLanguage || undefined,
    preferredTimes:
      contact.preferredTimes
        .filter((time) => time.preferredContactTime !== 'UNDEFINED')
        .map((time) => time.preferredContactTime) ?? ([] as string[]),
    contactType: contact.type && contact.type !== 'UNDEFINED' ? contact.type : undefined,
  };
  prepareForChangeDetection(formData);
  return formData;
}

function getInternationalNumber(nationalNumber: string, shopCountry?: CountryEnum | null): string {
  const phoneUtil = new PhoneNumberUtil();
  if (isSupportedCountry(shopCountry)) {
    return nationalNumber;
  }
  try {
    return phoneUtil.format(phoneUtil.parse(nationalNumber, shopCountry || undefined), PhoneNumberFormat.E164);
  } catch {
    return nationalNumber;
  }
}
