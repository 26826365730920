import { useResolveEmailSignature } from 'api/rest/hooks/useEmail';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'util/i18next';
import { useErrorSnackBar } from 'util/useErrorSnackBar';

export function useDefaultSignature() {
  const [defaultSignature, setDefaultSignature] = useState<string | undefined>(undefined);
  const { activeShopId } = useActiveShopId();
  const { resolveEmailSignature } = useResolveEmailSignature();
  const { openErrorSnackBar } = useErrorSnackBar();
  const { t } = useTranslation(['communication']);

  useEffect(() => {
    resolveEmailSignature(
      { shopId: activeShopId! },
      {
        onSuccess: (data: { data?: string } | undefined) => setDefaultSignature(data!.data),
        onError: (error) => openErrorSnackBar(t('communication:newEmail.signatures.resolve.error'), error),
      },
    );
  }, [activeShopId, resolveEmailSignature, openErrorSnackBar, t]);

  return defaultSignature;
}
