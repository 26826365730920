import { getKeycloakToken } from '@ev/eva-container-api';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { env } from 'env';

export const apiClient = axios.create({
  baseURL: env.VITE_BACKEND_API_URL + '/api',
});

apiClient.interceptors.request.use(async function (config) {
  const accessToken = await getKeycloakToken();
  // Axios types prevent adding new headers: https://github.com/axios/axios/issues/5034
  // @ts-ignore
  config.headers = {
    ...config.headers,
    Authorization: accessToken ? `Bearer ${accessToken}` : '',
  };
  return config;
});

axiosRetry(apiClient, {
  retries: 3,
  retryDelay: () => Math.random() * 100,
  // Retry 409 (Conflict) because the BE can't prevent race conditions
  retryCondition: (error) => error.code !== 'ECONNABORTED' && error.response?.status === 409,
});
