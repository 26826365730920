import { Stack, Typography } from '@mui/material';
import { theme } from 'theme';

export const TwoRowChipLabel = ({ firstRow, secondRow }: { firstRow: string; secondRow: string }) => (
  <Stack>
    <Typography variant="body4">{firstRow}</Typography>
    <Typography variant="body4" sx={{ fontSize: '10px' }} color={theme.palette.text.secondary}>
      {secondRow}
    </Typography>
  </Stack>
);
