import { MODAL_Z_INDEX } from '@ev/eva-container-api';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAgentsInShopGroup } from 'api/graphql/hooks/useAgents';
import { SEARCH_FIELD_WIDTH } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogLayout';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import { PersonMenuItem } from 'components/general/person/PersonItem';
import DirectoryIcon from 'components/icons/contact.svg?react';
import MinusIcon from 'components/icons/minus_circle.svg?react';
import userIconUrl from 'components/icons/user_single.svg?url';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useRef, useState } from 'react';
import { theme } from 'theme';
import { agentSearchFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { ComposeCcRecipient } from 'util/schemas/sendBulkEmailSchema';
import { useDebouncedValue } from 'util/useDebounce';

function getRecipientId(recipient: ComposeCcRecipient) {
  return recipient.type === 'EMAIL' ? recipient.email : recipient.id;
}

export function CcBccPickerList({
  disabled,
  recipients,
  onChange,
}: {
  disabled?: boolean;
  recipients: ComposeCcRecipient[];
  onChange: (recipients: ComposeCcRecipient[]) => void;
}) {
  const { t } = useTranslation(['communication']);
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebouncedValue(searchString, 300);
  const searchFieldRef = useRef<HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  const { shopIdsInActiveGroup } = useActiveShop();
  const { agentsInShopGroup } = useAgentsInShopGroup(
    {
      shopIds: shopIdsInActiveGroup,
      where: {
        _or: [agentSearchFilter(debouncedSearchString), { email: { _like: `${debouncedSearchString}%` } }],
      },
    },
    { enabled: !!debouncedSearchString?.length },
  );

  function addRecipient(recipient: ComposeCcRecipient) {
    setSearchString('');
    if (!recipients.find((r) => getRecipientId(r) === getRecipientId(recipient))) {
      onChange([...recipients, recipient]);
    }
  }

  function onRemoveRecipient(recipient: ComposeCcRecipient) {
    onChange(recipients.filter((r) => getRecipientId(r) !== getRecipientId(recipient)));
  }

  const showAddEmailOption = !agentsInShopGroup?.length && searchString.includes('@');

  return (
    <Stack sx={{ height: '100%' }}>
      <Box>
        <SearchTextField
          fullWidth
          disabled={disabled}
          ref={searchFieldRef}
          value={searchString}
          placeholder={t('communication:sendBulkEmailDialog.selectCcBccPlaceholder')}
          onChange={(event) => setSearchString(event.target.value)}
        />
        <Popper
          anchorEl={() => searchFieldRef.current!}
          open={!!searchString.trim().length}
          sx={{ transformOrigin: 'left top', zIndex: MODAL_Z_INDEX, width: isMobile ? '100vw' : SEARCH_FIELD_WIDTH }}
        >
          <Paper elevation={3} sx={{ maxHeight: '300px', overflow: 'auto' }}>
            <MenuList>
              {showAddEmailOption && (
                <MenuItem onClick={() => addRecipient({ type: 'EMAIL', email: searchString })}>
                  <CcRecipient recipient={{ type: 'EMAIL', email: searchString }} />
                </MenuItem>
              )}
              {agentsInShopGroup?.map((agent) => (
                <MenuItem key={agent.id} onClick={() => addRecipient({ type: 'AGENT', ...agent })}>
                  <CcRecipient recipient={{ type: 'AGENT', ...agent }} />
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popper>
      </Box>
      {!recipients.length && <NoRecipientsPlaceholder />}
      <Stack gap={2} sx={{ paddingLeft: 2, paddingY: 3, overflow: 'auto' }}>
        {recipients.map((recipient) => (
          <Stack direction="row" justifyContent="space-between" alignItems="center" key={getRecipientId(recipient)}>
            <CcRecipient recipient={recipient} />
            <IconButton onClick={() => onRemoveRecipient(recipient)} disabled={disabled}>
              <MinusIcon />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

function CcRecipient({ recipient }: { recipient: ComposeCcRecipient }) {
  return recipient.type === 'AGENT' ? (
    <PersonMenuItem person={recipient} />
  ) : (
    <PersonMenuItem
      avatar={<EVAvatar size="s" pictureSrc={userIconUrl} backgroundColor={theme.palette.shade.grey4} />}
      person={{
        email: recipient.email,
        firstName: recipient.email,
      }}
    />
  );
}

function NoRecipientsPlaceholder() {
  const { t } = useTranslation(['communication']);

  return (
    <Stack alignItems="center" gap={2} sx={{ marginTop: '112px' }}>
      <DirectoryIcon style={{ color: theme.palette.shade.grey2 }} />
      <Typography variant="body3" sx={{ textAlign: 'center' }} color={theme.palette.shade.grey2}>
        {t('communication:sendBulkEmailDialog.noCcBccRecipients')}
      </Typography>
    </Stack>
  );
}
