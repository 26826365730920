import { TaskDrawerData } from '@ev/eva-container-api';
import { PriorityEnum, TaskTypeEnum } from 'api/graphql/generated/graphql';
import { mapLeadInfosToLeadAndContactOptions } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { useCallback } from 'react';
import { loadContactsAndLeads, loadLeads, loadTask } from 'util/defaultValues/defaultValueLoaders';
import { ListingWithLegacyFields } from 'util/go3';
import { CreateNewTaskFormData } from 'util/schemas/newTaskSchema';
import { getNextClosestQuarter, setTimeFromDifferentDate } from 'util/timeUtils';
import { usePermissions } from 'util/usePermissions';

export function useGetTaskDefaultValues() {
  const { canEditLead } = usePermissions();

  return useCallback(
    async ({
      currentAgentId,
      activeShopId,
      initialTaskData,
    }: {
      currentAgentId?: string;
      activeShopId?: string;
      initialTaskData: TaskDrawerData;
    }): Promise<CreateNewTaskFormData> => {
      const day = initialTaskData.day ? setTimeFromDifferentDate(initialTaskData.day, new Date()) : new Date();

      const isEdit = !!initialTaskData?.editId;

      if (isEdit) {
        const baseActivity = await loadTask(initialTaskData.editId);
        const task = baseActivity!.taskActivity!.task;

        const [contacts, leads] = await Promise.all([
          loadContactsAndLeads(task?.taskContacts?.map((ap) => ap.contactId)),
          loadLeads(task?.taskLeads?.map((ap) => ap.leadId)),
        ]);

        const contactsAndLeads = mapLeadInfosToLeadAndContactOptions(leads || [], contacts || []);

        const agents =
          task.taskAgents?.map(({ agent }) => ({
            type: 'AGENT' as const,
            ...agent,
          })) ?? [];

        return {
          agentId: task.agentId,
          teamId: task.teamId || '',
          shopId: baseActivity!.shopId!,
          status: task.status!,
          title: task.title || '',
          comment: task.comment || '',
          actionType: task.type || '',
          contactsAndLeads,
          date: new Date(task.dueDatetime),
          time: new Date(task.dueDatetime),
          priority: (task.priority || undefined) as PriorityEnum,
          properties: task.propertyIds?.map((utag) => ({ utag } as ListingWithLegacyFields)),
          additionalAgents: agents,
          go3TaskKey: task.go3TaskKey || undefined,
          _editTaskActivity: baseActivity,
          _nonRemovableLeadIds: contactsAndLeads
            .filter((cl) => cl.type === 'LEAD' && !canEditLead(cl))
            .map((cl) => cl.id),
        };
      }

      const [contacts, leads] = await Promise.all([
        loadContactsAndLeads(initialTaskData?.contactIds),
        loadLeads(initialTaskData.leadIds),
      ]);
      const contactsAndLeads = mapLeadInfosToLeadAndContactOptions(leads || [], contacts || []);

      return {
        agentId: currentAgentId!,
        teamId: '',
        shopId: activeShopId!,
        status: 'OPEN' as const,
        title: '',
        priority: 'MEDIUM',
        actionType: (initialTaskData?.type as TaskTypeEnum) || ('ACTIVE_BUYER_CONTACT' as const),
        date: day,
        time: getNextClosestQuarter(day),
        properties: initialTaskData?.propertyUtags?.map((utag) => ({ utag } as ListingWithLegacyFields)),
        comment: '',
        contactsAndLeads,
        go3TaskKey: undefined,
        _editTaskActivity: undefined,
      };
    },
    [canEditLead],
  );
}
