import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  BulkEmailRecipientTabPanels,
  BulkEmailRecipientTabs,
  RecipientTab,
} from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/BulkEmailRecipientTabs';
import { InternalComposeBulkEmailStepProps } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ComposeBulkEmailStep';
import { ComposeBulkEmailTemplateForm } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ComposeBulkEmailTemplateForm';
import { CreateFollowUpTaskToggle } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/CreateFollowUpTaskToggle';
import { ShowPricesToggle } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ShowPricesToggle';
import { SendBulkEmailDialogHeader } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogHeader';
import {
  HEADER_HEIGHT,
  SendBulkEmailDesktopLayout,
} from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogLayout';
import { EVModalFooter } from 'components/general/EVModal/ModalComponents/EVModalFooter';
import { EVModalHeader } from 'components/general/EVModal/ModalComponents/EVModalHeader';
import { usePopover } from 'components/state/UIStateProvider';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { ComposeBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function ComposeBulkEmailStepDesktop({
  possibleFromEmails,
  onClose,
  onPreview,
  noEmailIntegration,
}: InternalComposeBulkEmailStepProps) {
  const { t } = useTranslation(['communication']);
  const [activeTab, setActiveTab] = useState<RecipientTab>('to');
  const { control, watch } = useFormContext<ComposeBulkEmailFormData>();

  const title = watch('isExposeSending')
    ? t('communication:sendBulkEmailDialog.titleExpose')
    : t('communication:sendBulkEmailDialog.title');

  const { isMinimized, setIsMinimized } = usePopover('emailBulk');

  return (
    <>
      <EVModalHeader title={title} onClose={onClose} onMinimize={() => setIsMinimized(!isMinimized)} />
      <Controller
        name="from"
        control={control}
        render={({ field }) => (
          <SendBulkEmailDialogHeader
            from={field.value}
            onChangeFrom={field.onChange}
            noEmailIntegration={noEmailIntegration}
            possibleFromEmails={possibleFromEmails}
          />
        )}
      />
      <SendBulkEmailDesktopLayout
        noEmailIntegration={noEmailIntegration}
        left={
          <>
            <BulkEmailRecipientTabs activeTab={activeTab} setActiveTab={setActiveTab} disabled={noEmailIntegration} />
            <BulkEmailRecipientTabPanels activeTab={activeTab} disabled={noEmailIntegration} />
          </>
        }
        right={
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ alignSelf: 'center', height: HEADER_HEIGHT, marginBottom: 2 }}
              alignItems="center"
            >
              <Typography variant="h2" fontWeight="bold">
                {t('communication:sendBulkEmailDialog.templateTitle')}
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <CreateFollowUpTaskToggle disabled={noEmailIntegration} />
                <ShowPricesToggle disabled={noEmailIntegration} />
              </Stack>
            </Stack>
            <ComposeBulkEmailTemplateForm disabled={noEmailIntegration} />
          </>
        }
      />
      <EVModalFooter>
        <Button color="secondary" variant="contained" onClick={onPreview} disabled={noEmailIntegration}>
          {t('communication:sendBulkEmailDialog.previewButton', { number: watch('to').length })}
        </Button>
      </EVModalFooter>
    </>
  );
}
