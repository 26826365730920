import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const kycStatusInfoQuery = graphql(/* GraphQL */ `
  query KycStatus($contactIds: [String!]) {
    dbContact(where: { id: { _in: $contactIds } }) {
      ...KycStatusInfo
    }
  }
`);

export const useKycStatusInfo = (
  variables: VariablesOf<typeof kycStatusInfoQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: kycStatusData, ...rest } = useQuery({
    queryKey: ['contact', 'useKycStatus', variables],
    queryFn: async () => {
      const result = await graphqlClient.request(kycStatusInfoQuery, variables);
      return result.dbContact;
    },
    ...options,
    refetchInterval: (query) => {
      //fetch more frequently if we're in the process of doing a check
      const hasPendingKycStatus = query?.some((item) => item.kycStatus === 'PENDING');
      return hasPendingKycStatus ? 10_000 : 60_000;
    },
  });

  return { kycStatusData, ...rest };
};
