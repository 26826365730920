import { create } from 'jsondiffpatch';

export const hashProperty = '__diffId';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function prepareForChangeDetection(obj: any) {
  let diffId = 0;

  function provideEachObjectWithId(obj: any) {
    if (obj && typeof obj === 'object') {
      if (!Array.isArray(obj)) {
        obj[hashProperty] = (diffId++).toString();
      }
      for (const property in obj) {
        if (!property.startsWith('_')) {
          provideEachObjectWithId(obj[property]);
        }
      }
    }
  }

  provideEachObjectWithId(obj);
}

export const diffPatcher = create({
  objectHash: function (obj: any) {
    return obj[hashProperty];
  },
  arrays: {
    detectMove: true,
  },
  propertyFilter: function (name: string) {
    return name !== hashProperty && !name.startsWith('_');
  },
  textDiff: {
    minLength: 1000000, // effectively turn off text diff
  },
});
