import { Stack, TextField } from '@mui/material';
import { ContactsAndLeadsPicker } from 'components/contact/LeadAndContactPicker/ContactsAndLeadsPicker';
import { FormSection } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import ChatIcon from 'components/icons/chat_1_naked.svg?react';
import DirectoryIcon from 'components/icons/contact.svg?react';
import PropertiesIcon from 'components/icons/home.svg?react';
import { PropertySearchField } from 'components/property/PropertySearch/PropertySearchField';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { ShareExposeFormData } from 'util/schemas/shareExposeSchema';

export function ShareExposeForm() {
  const { t } = useTranslation(['user']);

  const {
    getValues,
    formState: { errors },
    register,
  } = useFormContext<ShareExposeFormData>();

  const [comment] = getValues(['comment']);

  return (
    <Stack gap={6} p={3}>
      <FormSection>
        <FormStack>
          <TextField
            required
            label={t('user:dashboard.shareExpose.title.label')}
            error={!!errors.title}
            helperText={errors.title?.message}
            {...register('title')}
          />
        </FormStack>
        <FormStack icon={<DirectoryIcon />}>
          <ContactsAndLeadsPicker
            required
            errorMessage={errors.contactsAndLeads?.message || errors.contactsAndLeads?.[0]?.message}
            limitTags={2}
            name="contactsAndLeads"
            label={t('user:dashboard.shareExpose.contacts.label')}
            allowLeads
            disableReadonlyLeads
            sx={{ width: '100%' }}
          />
        </FormStack>
        <FormStack icon={<PropertiesIcon />}>
          <PropertySearchField
            required
            sx={{ width: '100%' }}
            label={t('user:dashboard.shareExpose.property.label')}
            maxProperties={1}
          />
        </FormStack>
        <FormStack
          descriptionText={t('user:dashboard.task.internalComments.label')}
          isOpen={!!comment}
          icon={<ChatIcon />}
        >
          <TextField label={t('user:dashboard.task.internalComments.label')} multiline {...register('comment')} />
        </FormStack>
      </FormSection>
    </Stack>
  );
}
