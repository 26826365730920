import useLocalStorage, { readValue } from 'components/state/LocalStorage';

export enum FeatureToggle {
  debugTable = 'debugTable',
  debugMaps = 'debugMaps',
  versionInfo = 'versionInfo',
}

export type FeatureToggles = Record<FeatureToggle, boolean>;

export const LOCAL_STORAGE_FEATURE_TOGGLES = 'featureToggles';
export const DEFAULT_VALUE = {} as FeatureToggles;

export const toggleFeature = (feature: FeatureToggle) => {
  const allFeatures = Object.keys(FeatureToggle);
  if (!allFeatures.includes(feature)) {
    // eslint-disable-next-line no-console
    console.info(`Unknown featureToggle, known feature toggles are:`);
    // eslint-disable-next-line no-console
    allFeatures.forEach((f) => console.info(f));
    return;
  }

  const currentFeatures = readValue<FeatureToggles>(LOCAL_STORAGE_FEATURE_TOGGLES) || DEFAULT_VALUE;
  currentFeatures[feature] = !currentFeatures[feature];

  window.localStorage.setItem(LOCAL_STORAGE_FEATURE_TOGGLES, JSON.stringify(currentFeatures));
  window.location.reload();
};

export const getFeatureToggles = () => {
  const currentFeatures = readValue<FeatureToggles>(LOCAL_STORAGE_FEATURE_TOGGLES) || DEFAULT_VALUE;
  // eslint-disable-next-line no-console
  console.info('Currently configured features: ', currentFeatures);
  // eslint-disable-next-line no-console
  console.info('Available features: ', FeatureToggle);
};

export const useFeatureToggles = () => {
  return useLocalStorage<FeatureToggles>(LOCAL_STORAGE_FEATURE_TOGGLES, DEFAULT_VALUE);
};
