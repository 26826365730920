import { ActivityListItem, BaseActivity, DashboardActivity } from 'api/graphql/generated/graphql';

export function isActivityClosed(activity: DashboardActivity | BaseActivity) {
  const status = activity.appointmentActivity?.appointment?.status ?? activity.taskActivity?.task?.status;
  return status === 'CANCELLED' || status === 'COMPLETED';
}

export function isDeletedEmailActivity(activity: BaseActivity | ActivityListItem) {
  return !!activity.emailActivity?.email?.isHidden;
}

export function isManualSendEmailActivity(activity: BaseActivity | ActivityListItem) {
  const email = activity.emailActivity?.email;
  return (
    email &&
    activity.category === 'EMAIL' &&
    (activity.type === 'INCOMING_EMAIL' ||
      (activity.type === 'OUTGOING_EMAIL' && !email.from?.toLowerCase().startsWith('noreply@')))
  );
}
