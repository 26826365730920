import { Box, SxProps, useMediaQuery } from '@mui/material';
import { EVModalTabs } from 'components/general/EVModal/ModalComponents/EVModalTabs';
import { ReactNode } from 'react';
import { theme } from 'theme';
import { hasChildComponent } from 'util/compositionUtils';

export const EVModalContainer = ({ sx, children }: { sx?: SxProps; children: ReactNode }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const hasNavigation = hasChildComponent(children, EVModalTabs);

  const showSingleColumn = isMobile || !hasNavigation;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: showSingleColumn ? 'column' : 'row',
        paddingX: isMobile ? 2 : 3,
        paddingY: 2,
        gap: isMobile ? 1 : 3,
        flexGrow: 1,
        overflowY: isMobile ? undefined : 'auto',
        backgroundColor: theme.palette.backgroundExtension.grey1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
