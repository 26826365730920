import { EmailTemplate, EmailTemplateTypeEnum, LanguageEnum } from 'api/graphql/generated/graphql';
import {
  LeadAndContactSearchOption,
  getContact,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import uniqBy from 'lodash.uniqby';
import { nanoid } from 'nanoid';
import { getPreferredEmail } from 'util/contactUtils';
import { ListingWithLegacyFields } from 'util/go3';
import {
  PreviewCcRecipient as BulkPreviewCcRecipient,
  PreviewBulkEmailFormData,
} from 'util/schemas/sendBulkEmailSchema';
import { Recipient as NormalNodeRecipient } from 'util/schemas/sendEmailSchema';
import { CcRecipient } from 'util/schemas/useCcAndBccSchema';

export const EXPOSE_TEMPLATE_TYPES = [
  'EXPOSE',
  'PRICE_REDUCTION',
  'PREMIUM_EXPOSE_MAIL',
] as const satisfies readonly EmailTemplateTypeEnum[];
export const AUTOMATED_TEMPLATE_TYPES: EmailTemplateTypeEnum[] = [
  'AUTO',
  'EXPOSE',
  'VIEWING_REPORT',
  'PRICE_REDUCTION',
  'PREMIUM_EXPOSE_MAIL',
  'NEW_OWNER_ENERGY_ADVISORY',
];
export interface ExposeProperty {
  comment?: string;
  property: ListingWithLegacyFields;
}
export const SIGNATURE_MARKER = '<signature><br></signature>';
const UNRESOLVED_PLACEHOLDER_REGEX = /data-resolved=["']false["']/g;

// Removes any zero-width non-breaking space (U+FEFF) characters from the email body
export const sanitizeEmailBody = (body: string) => {
  return body.replace(/\uFEFF/g, '');
};

export function addSignatureMarker(signature?: string) {
  if (!signature) {
    return '';
  }
  return '<p><br></p>' + SIGNATURE_MARKER + signature;
}

export function emailBodyWithSignature(emailBody: string, signature?: string, thread?: string) {
  const signatureIndex = emailBody.indexOf('<signature>');

  let updatedEmailBody;
  if (signatureIndex >= 0) {
    updatedEmailBody = emailBody.slice(0, signatureIndex) + SIGNATURE_MARKER + signature;
  } else {
    updatedEmailBody = emailBody + addSignatureMarker(signature);
  }

  if (thread) {
    updatedEmailBody += thread;
  }

  return updatedEmailBody;
}

export function highlightUnresolvedPlaceholders(body: string, unresolvedPlaceholders: string[]) {
  let bodyWithHighlights = body.replaceAll('<!-- -->', '').replaceAll('\x3C!-- -->', '');

  for (const unresolvedPlaceholder of unresolvedPlaceholders) {
    const placeholderText = unresolvedPlaceholder.replaceAll(/[{}]/g, '');
    bodyWithHighlights = bodyWithHighlights.replaceAll(
      `{${placeholderText}}`,
      `{<span class='ql-placeholder' data-ql-id='${nanoid()}' data-placeholder='${placeholderText}' data-resolved='false'>${placeholderText}</span>}`,
    );
  }
  return bodyWithHighlights;
}

export function countUnresolvedPlaceholders(bodyText: string) {
  return (bodyText.match(UNRESOLVED_PLACEHOLDER_REGEX) || []).length;
}

export function hasAlreadyProposedProperty(recipientWithData: PreviewBulkEmailFormData['recipients'][number]) {
  const propertyUtags = new Set(recipientWithData.properties.map((p) => p.property.utag));
  return recipientWithData.alreadyProposedProperties.some((alreadyProposedPropertyUtag) =>
    propertyUtags.has(alreadyProposedPropertyUtag),
  );
}

export function replaceExposePlaceholders(content: string) {
  // The div is important here otherwise the content after the widget is cut off
  return content
    .replaceAll('{{{property_expose_list}}}', '<div><expose-list/></div>')
    .replaceAll('{{{premium_expose}}}', '<div><premium-expose/></div>');
}

export function getEmailForCcRecipient(ccRecipient: BulkPreviewCcRecipient) {
  return ccRecipient.type === 'AGENT' || ccRecipient.type === 'EMAIL'
    ? ccRecipient.email
    : getPreferredEmail(ccRecipient);
}

export function getTemplateContent(
  template: EmailTemplate | undefined,
  preferredShopLanguage: LanguageEnum | undefined,
) {
  if (!template) return undefined;

  let fallbackLanguages: LanguageEnum[] = ['EN'];

  if (preferredShopLanguage) {
    switch (preferredShopLanguage) {
      case 'DE_AT':
        fallbackLanguages = ['DE_AT', 'DE', 'DE_CH', 'EN'];
        break;

      case 'FR_BE':
        fallbackLanguages = ['FR_BE', 'FR', 'EN'];
        break;

      default:
        fallbackLanguages = [preferredShopLanguage, 'EN'];
    }
  }

  for (const lang of fallbackLanguages) {
    const match = template.contents.find((content) => content.language === lang);
    if (match) return match;
  }

  return undefined;
}

export function getPreferredLanguage(recipients: NormalNodeRecipient[] | undefined) {
  const firstRecipient = recipients?.[0];

  if (firstRecipient?.type === 'CONTACT' || firstRecipient?.type === 'RELATED_CONTACT') {
    return firstRecipient.preferredLanguage;
  }
  if (firstRecipient?.type === 'LEAD') {
    return firstRecipient.contact.preferredLanguage;
  }
}

export function getEmail(recipient: NormalNodeRecipient) {
  switch (recipient.type) {
    case 'AGENT':
    case 'CONTACT':
    case 'EMAIL':
      return recipient.email;
    case 'RELATED_CONTACT':
      return getPreferredEmail(recipient);
    case 'LEAD':
      return recipient.contact.email;
  }
}

export function getId(recipient?: NormalNodeRecipient | CcRecipient) {
  if (!recipient) {
    return;
  }
  return recipient.type === 'EMAIL' ? recipient.email : recipient.id;
}

export function getAutoCcRecipients(
  recipients: NormalNodeRecipient[],
  excludedRecipients: NormalNodeRecipient[],
): CcRecipient[] {
  const leadOrContactRecipients = recipients.filter(
    (r) => r.type === 'CONTACT' || r.type === 'LEAD',
  ) as LeadAndContactSearchOption[];
  if (!leadOrContactRecipients.length) {
    return [];
  }

  const excludedEmails = excludedRecipients?.map(getEmail) ?? [];

  return uniqBy(
    leadOrContactRecipients.flatMap((leadOrContact) => {
      const contact = getContact(leadOrContact);

      const ccOtherEmailsResult = contact.autoCc
        ? contact.emailAddresses
            .filter((email) => !excludedEmails.includes(email.address))
            .map((email) => ({
              type: 'EMAIL' as const,
              email: email.address,
            }))
        : [];

      const ccRelationshipsContactsResult = contact.contactRelationships
        .filter((r) => r.relatedContact && r.autoCc)
        .flatMap((contactRelationship) => ({ type: 'CONTACT' as const, ...contactRelationship.relatedContact }));

      return [...ccOtherEmailsResult, ...ccRelationshipsContactsResult];
    }),
    getId,
  );
}
