import { DialogActions, SxProps, useMediaQuery } from '@mui/material';
import { CancelButton, CancelButtonCustomization } from 'components/general/CancelButton/CancelButton';
import { ConfirmButton, ConfirmButtonCustomization } from 'components/general/ConfirmButton/ConfirmButton';
import { ReactNode } from 'react';
import { theme } from 'theme';

export const EVModalFooter = ({
  children,
  sx,
  onConfirm,
  onCancel,
  confirmButtonCustomization,
  cancelButtonCustomization,
}: {
  children?: ReactNode;
  sx?: SxProps;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonCustomization?: ConfirmButtonCustomization;
  cancelButtonCustomization?: CancelButtonCustomization;
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  if (isMobile && !children) {
    return null;
  }

  return (
    <DialogActions
      sx={{
        margin: 0,
        paddingX: 3,
        paddingY: 1.5,
        width: '100%',
        display: 'flex',
        borderTop: `1px solid ${theme.palette.tertiary.active}`,
        justifyContent: 'flex-end',
        [theme.breakpoints.down('tablet')]: {
          padding: 2,
          position: 'sticky',
          bottom: 0,
        },
        ...sx,
      }}
    >
      {children}
      {onCancel && <CancelButton onCancel={onCancel} customization={cancelButtonCustomization} />}
      {onConfirm && <ConfirmButton onConfirm={onConfirm} customization={confirmButtonCustomization} />}
    </DialogActions>
  );
};
