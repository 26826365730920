import { Box, Stack } from '@mui/material';
import { Appointment, BaseActivity, Task } from 'api/graphql/generated/graphql';
import DateIcon from 'components/icons/calendar_naked.svg?react';
import ClockIcon from 'components/icons/clock.svg?react';
import { appointmentTypeTranslation, taskTypeTranslation } from 'const/enumTranslations';
import { format, isPast, isThisYear, parseISO } from 'date-fns';
import { ActivityProperty } from 'page-components/activities/ActivityTable/ActivityProperty';
import { theme } from 'theme';
import { useDateFnsLocale } from 'util/i18n/useDateFnsLocale';
import { useTranslation } from 'util/i18next';

const toMDYDateFormat = (dateString: string, locale: Locale) => {
  const date = parseISO(dateString);
  return format(date, `dd MMM ${isThisYear(date) ? '' : 'yyyy'}`, { locale });
};

export function AppointmentTaskSummary({
  task,
  appointment,
  activity,
}: {
  task?: Task;
  appointment?: Appointment;
  activity?: BaseActivity;
}) {
  const { t } = useTranslation(['activities', 'enums']);
  const { locale } = useDateFnsLocale();
  const taskActivity = task ?? activity?.taskActivity?.task;
  const appointmentActivity = appointment ?? activity?.appointmentActivity?.appointment;
  const { startTime, endTime, type: appointmentType, status: appointmentStatus } = appointmentActivity || {};
  const { dueDatetime, type: taskType, status: taskStatus } = taskActivity || {};

  const overdue = isPast(new Date(taskActivity ? dueDatetime : endTime));
  const isOpenStatus = taskActivity ? taskStatus === 'OPEN' : appointmentStatus === 'OPEN';
  const hasAttachedProperties = taskActivity?.propertyIds.length || appointmentActivity?.propertyIds.length;

  return (
    <Stack direction={'row'} gap={1} flexWrap={'wrap'} fontSize={theme.typography.body3.fontSize}>
      <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5 }} color={theme.palette.text.secondary}>
        {appointmentType && t(appointmentTypeTranslation[appointmentType])}
        {taskType && t(taskTypeTranslation[taskType])}
      </Box>
      {(dueDatetime || endTime) && (
        <Stack gap={1} flexDirection="row">
          <Box
            color={theme.palette.text.secondary}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 0.5,
              color: overdue && isOpenStatus ? theme.palette.error.main : theme.palette.text.secondary,
            }}
          >
            <DateIcon color={theme.palette.text.secondary} />
            {toMDYDateFormat(dueDatetime || endTime, locale)}
          </Box>
          <Box
            color={theme.palette.text.secondary}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 0.5,
              color: overdue && isOpenStatus ? theme.palette.error.main : theme.palette.text.secondary,
            }}
          >
            <ClockIcon color={theme.palette.text.secondary} />
            {dueDatetime && format(parseISO(dueDatetime), 'HH:mm', { locale })}
            {startTime &&
              `${format(parseISO(startTime), 'HH:mm', { locale })} - ${format(parseISO(endTime), 'HH:mm', {
                locale,
              })}`}
          </Box>
        </Stack>
      )}
      {hasAttachedProperties && <ActivityProperty activity={activity} maxChips={1} />}
    </Stack>
  );
}
