import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { InternalPreviewBulkEmailStepProps } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailStep';
import { PreviewBulkEmailTemplateForm } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailTemplateForm';
import { PreviewRecipients } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewRecipents';
import { SendBulkEmailDialogHeader } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogHeader';
import { SendBulkEmailDesktopLayout } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogLayout';
import { EVModalFooter } from 'components/general/EVModal/ModalComponents/EVModalFooter';
import { EVModalHeader } from 'components/general/EVModal/ModalComponents/EVModalHeader';
import ArrowLeftIcon from 'components/icons/arrow_2_left.svg?react';
import { usePopover } from 'components/state/UIStateProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PreviewBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function PreviewBulkEmailStepDesktop({
  possibleFromEmails,
  onClose,
  onSend,
  onBackToCompose,
  noEmailIntegration,
  selectedRecipient,
  onSelectRecipient,
  onRemoveRecipient,
  isSending,
  from,
  hidePrice,
  onChangeFrom,
}: InternalPreviewBulkEmailStepProps) {
  const { t } = useTranslation(['communication']);
  const { watch } = useFormContext<PreviewBulkEmailFormData>();

  const title = watch('isExposeSending')
    ? t('communication:sendBulkEmailDialog.titleExpose')
    : t('communication:sendBulkEmailDialog.title');

  const { isMinimized, setIsMinimized } = usePopover('emailBulk');
  return (
    <>
      <EVModalHeader title={title} onClose={onClose} onMinimize={() => setIsMinimized(!isMinimized)} />
      <SendBulkEmailDialogHeader
        from={from}
        onChangeFrom={onChangeFrom}
        noEmailIntegration={noEmailIntegration}
        possibleFromEmails={possibleFromEmails}
      />

      <SendBulkEmailDesktopLayout
        noEmailIntegration={noEmailIntegration}
        left={
          <PreviewRecipients
            disabled={noEmailIntegration}
            selectedRecipient={selectedRecipient}
            onSelectRecipient={onSelectRecipient}
            onRemoveRecipient={onRemoveRecipient}
          />
        }
        right={
          <PreviewBulkEmailTemplateForm
            selectedRecipient={selectedRecipient}
            disabled={noEmailIntegration}
            hidePrice={hidePrice}
          />
        }
      />
      <EVModalFooter>
        <Button
          sx={{ marginRight: 'auto' }}
          startIcon={<ArrowLeftIcon />}
          onClick={onBackToCompose}
          color="secondary"
          disabled={isSending}
        >
          {t('communication:sendBulkEmailDialog.backToComposeButton')}
        </Button>
        <Button variant="contained" onClick={onSend} disabled={noEmailIntegration || isSending} color="primary">
          {t('communication:sendBulkEmailDialog.sendButton', { number: watch('recipients').length })}
          {isSending && <CircularProgress size={14} sx={{ marginLeft: 1, color: 'inherit' }} />}
        </Button>
      </EVModalFooter>
    </>
  );
}
