import { Divider, Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { theme } from 'theme';

type PopperEntityHeaderProps = {
  label: ReactNode;
  subLabel?: ReactNode;
  avatar?: ReactNode;
} & PropsWithChildren;

export function PopperEntityHeader({ label, subLabel, avatar, children }: PopperEntityHeaderProps) {
  return (
    <Stack gap={2} direction="row" alignItems="center" sx={{ maxWidth: '100%', overflow: 'hidden' }}>
      {avatar}
      <Stack>
        <Typography variant="body3" color={theme.palette.text.primary} fontWeight={theme.typography.fontWeightBold}>
          {label}
        </Typography>
        {subLabel && (
          <Typography variant="body3" color={theme.palette.text.secondary}>
            {subLabel}
          </Typography>
        )}
        {children}
      </Stack>
    </Stack>
  );
}

type PopperEntityProps = {
  header: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
};

export function PopperEntity({ header, body, footer }: PopperEntityProps) {
  return (
    <Stack sx={{ width: '100%', overflow: 'hidden', padding: 2, gap: 1 }}>
      <Stack sx={{ gap: 1 }}>
        {header}
        <Typography variant="body4" color={theme.palette.text.secondary}>
          {body}
        </Typography>
      </Stack>
      {footer && (
        <>
          <Divider />
          <Stack>{footer}</Stack>
        </>
      )}
    </Stack>
  );
}
