import { nanoid } from 'nanoid';

const LEADHUB_HOSTNAME = window.location.origin;

async function sha1(str: string) {
  const enc = new TextEncoder();
  const hash = await crypto.subtle.digest('SHA-1', enc.encode(str));
  return Array.from(new Uint8Array(hash))
    .map((v) => v.toString(16).padStart(2, '0'))
    .join('');
}

export function wait(ms: number) {
  return new Promise<void>((resolve) => window.setTimeout(() => resolve(), ms));
}

async function logResponse({
  moduleUrl,
  response,
  message,
  debugId,
}: {
  moduleUrl: string;
  response: Response;
  message: string;
  debugId: string;
}) {
  let text;
  let responseHash;
  try {
    text = await response.text();
    responseHash = await sha1(text);
  } catch {
    text = 'Failed to parse content!';
  }

  // eslint-disable-next-line no-console
  console.error(`${message} debug`, {
    requestUrl: moduleUrl,
    responseUrl: response.url,
    status: response.status,
    redirected: response.redirected,
    debugId,
    response: text.length > 200 ? text.substring(0, 200) + '…' : text,
    responseHash,
    responseHeaders: Object.fromEntries(response.headers.entries()),
  });
}

export async function fetchModule(moduleUrl: string, message: string) {
  const debugId = nanoid();
  try {
    const response = await fetch(moduleUrl, {
      headers: { 'eva-debug': debugId },
    });
    logResponse({ moduleUrl, response: response.clone(), message, debugId });
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`${message} error`, { moduleUrl, error, debugId });
  }
}

export async function tryToFetchAllAssets(moduleUrl: string) {
  const allAssetResponse = await fetchModule(`${LEADHUB_HOSTNAME}/assets/assetList.json`, 'all assets');

  try {
    const allAssets: string[] = (await allAssetResponse?.json()) || [];
    const otherAssets = allAssets.filter((asset) => !moduleUrl.endsWith(asset));
    for (const asset of otherAssets) {
      await wait(200);
      await fetchModule(`${LEADHUB_HOSTNAME}/${asset}`, 'all assets');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error while fetching all assets', error);
  }
}

let triedToFetchAllAssets = false;
export async function debugModuleLoading(moduleUrl: string) {
  const response = await fetchModule(moduleUrl, 'module loading');

  if (response?.ok && moduleUrl.includes(LEADHUB_HOSTNAME) && !triedToFetchAllAssets) {
    triedToFetchAllAssets = true;
    await tryToFetchAllAssets(moduleUrl);
  }
}
