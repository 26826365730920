import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { NumberFieldWithController } from 'components/forms/NumberField/NumberField';
import { DropDown } from 'components/general/DropDown/DropDown';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { EVDrawerCollapsible } from 'components/general/EVDrawer/EVDrawerCollapsible';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormSeparatorIcon } from 'components/general/Form/FormSeparatorIcon';
import { FormStack } from 'components/general/Form/FormStack';
import CommentIcon from 'components/icons/chat_1_naked.svg?react';
import FloorPlanIcon from 'components/icons/floor_plan.svg?react';
import InfoIcon from 'components/icons/info.svg?react';
import PriceIcon from 'components/icons/money_notes.svg?react';
import SurfaceAreaIcon from 'components/icons/surface_area.svg?react';
import { FeaturesSection } from 'components/leads/forms/LeadForm/SpecificAddressAndOptionalDetails/FeaturesSection';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { assetConditionTranslation } from 'const/enumTranslations';
import { rentRangeTranslationsWithCurrency } from 'const/rentTranslations';
import { SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions } from 'util/mappers/enumToOption';
import { NewLeadWithContactFormData } from 'util/schemas/newLeadSchema';
import { useCurrencySymbol, useFormatNumber } from 'util/useFormatNumber';
import { useLeadDetailUtils } from 'util/useLeadDetailUtils';

const PRICE_RANGE_FROM = 'priceRangeFrom';
const RENT_RANGE_FROM = 'rentRangeFrom';
const PRICE_RANGE_TO = 'priceRangeTo';
const RENT_RANGE_TO = 'rentRangeTo';

export const OptionalSections = () => {
  const { t } = useTranslation(['lead', 'enums']);
  const { formatPriceRange } = useFormatNumber();

  const { collapseAreaValues, collapseRoomNumbers, collapseFurtherValues } = useLeadDetailUtils();
  const { control, watch, register, formState, setValue, trigger } = useFormContext<NewLeadWithContactFormData>();
  const { errors } = formState;

  const watchIntentType = watch('intentType');
  const noteCollapsedValue = watch('property.additionalNoteProperty');
  const isSellerIntent = watchIntentType === 'SELL' || watchIntentType === 'VALUATION';

  const { shopCurrency, shopRentUnit } = useActiveShop();
  const shopCurrencySymbol = useCurrencySymbol(shopCurrency);

  const currency = isSellerIntent ? watch('property.priceRangeUnit') : watch('property.rentRangeUnit');
  const currencySymbol = useCurrencySymbol(currency);
  const hasNonShopCurrency = currency && currency !== shopCurrency;

  const priceCollapsedValues = formatPriceRange(
    ...(isSellerIntent
      ? watch(['property.priceRangeFrom', 'property.priceRangeTo'])
      : watch(['property.rentRangeFrom', 'property.rentRangeTo'])),
    {
      emptyDataString: '',
      currency,
    },
  );

  const areaCollapsedValues = collapseAreaValues({
    livingArea: watch('property.livingArea'),
    plotSurface: watch('property.plotSurface'),
  });

  const roomCollapsedValues = collapseRoomNumbers({
    rooms: watch('property.rooms'),
    bathrooms: watch('property.bathrooms'),
  });

  const furtherCollapsedValues = collapseFurtherValues({
    floor: watch('property.floor'),
    totalFloors: watch('property.totalFloors'),
    constructionYear: watch('property.constructionDate'),
    condition: watch('property.condition'),
  });

  const { allEnums } = useGetAllEnums();

  const handleChangeToShopCurrency = (event: SyntheticEvent) => {
    event.stopPropagation();
    if (isSellerIntent) {
      setValue('property.priceRangeUnit', shopCurrency);
      trigger(['property.priceRangeFrom', 'property.priceRangeTo']);
    } else {
      setValue('property.rentRangeUnit', shopCurrency);
      trigger(['property.rentRangeFrom', 'property.rentRangeTo']);
    }
  };

  return (
    <FormSection>
      <FormSectionHeader>{t('lead:form.optionalSection.header')}</FormSectionHeader>
      <Box>
        {hasNonShopCurrency && <EVAlert severity="warning">{t('lead:currency.nonShopCurrency')}</EVAlert>}
        <EVDrawerCollapsible
          icon={<PriceIcon />}
          title={t('lead:form.optionalSection.priceHeader', { currency: currencySymbol })}
          collapsedValues={priceCollapsedValues}
        >
          {hasNonShopCurrency && (
            <Button onClick={handleChangeToShopCurrency} variant="outlined" color="secondary" sx={{ marginLeft: 5 }}>
              {t('lead:currency.changeToShopCurrency', { currency: shopCurrencySymbol })}
            </Button>
          )}
          <FormStack gap={0.25}>
            <NumberFieldWithController
              label={
                isSellerIntent
                  ? t('lead:propertyEvaluateForm.details.priceRangeMin', { currency: currencySymbol })
                  : t(rentRangeTranslationsWithCurrency[shopRentUnit].genericFrom, { currency: currencySymbol })
              }
              error={'property' in errors && !!errors.property?.[isSellerIntent ? PRICE_RANGE_FROM : RENT_RANGE_FROM]}
              helperText={
                'property' in errors && errors.property?.[isSellerIntent ? PRICE_RANGE_FROM : RENT_RANGE_FROM]?.message
              }
              control={control}
              name={`property.${isSellerIntent ? PRICE_RANGE_FROM : RENT_RANGE_FROM}`}
              allowNegative={false}
            />
            <FormSeparatorIcon />
            <NumberFieldWithController
              label={
                isSellerIntent
                  ? t('lead:propertyEvaluateForm.details.priceRangeMax', { currency: currencySymbol })
                  : t(rentRangeTranslationsWithCurrency[shopRentUnit].genericTo, { currency: currencySymbol })
              }
              error={'property' in errors && !!errors.property?.[isSellerIntent ? PRICE_RANGE_TO : RENT_RANGE_TO]}
              helperText={
                'property' in errors && errors.property?.[isSellerIntent ? PRICE_RANGE_TO : RENT_RANGE_TO]?.message
              }
              control={control}
              name={`property.${isSellerIntent ? PRICE_RANGE_TO : RENT_RANGE_TO}`}
              allowNegative={false}
            />
          </FormStack>
        </EVDrawerCollapsible>
      </Box>
      <EVDrawerCollapsible
        icon={<SurfaceAreaIcon />}
        title={t('lead:form.optionalSection.area.header')}
        collapsedValues={areaCollapsedValues}
      >
        <FormStack gap={3}>
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.livingArea')}
            error={'property' in errors && !!errors.property?.livingArea}
            helperText={'property' in errors && errors.property?.livingArea?.message}
            control={control}
            name="property.livingArea"
            allowNegative={false}
          />
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.plotSurface')}
            error={'property' in errors && !!errors.property?.plotSurface}
            helperText={'property' in errors && errors.property?.plotSurface?.message}
            control={control}
            name="property.plotSurface"
            allowNegative={false}
          />
        </FormStack>
      </EVDrawerCollapsible>
      <EVDrawerCollapsible
        icon={<FloorPlanIcon />}
        title={t('lead:form.optionalSection.rooms.header')}
        collapsedValues={roomCollapsedValues}
      >
        <FormStack gap={3}>
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.rooms')}
            error={'property' in errors && !!errors.property?.rooms}
            helperText={'property' in errors && errors.property?.rooms?.message}
            control={control}
            name="property.rooms"
            allowNegative={false}
          />
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.bathrooms')}
            error={'property' in errors && !!errors.property?.bathrooms}
            helperText={'property' in errors && errors.property?.bathrooms?.message}
            control={control}
            name="property.bathrooms"
            allowNegative={false}
          />
        </FormStack>
      </EVDrawerCollapsible>
      <EVDrawerCollapsible
        icon={<InfoIcon />}
        title={t('lead:form.optionalSection.furtherDetails.header')}
        collapsedValues={furtherCollapsedValues}
      >
        <FormStack gap={3}>
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.floor')}
            error={'property' in errors && !!errors.property?.floor}
            helperText={'property' in errors && errors.property?.floor?.message}
            control={control}
            name="property.floor"
            allowNegative={false}
          />
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.totalFloors')}
            error={'property' in errors && !!errors.property?.totalFloors}
            helperText={'property' in errors && errors.property?.totalFloors?.message}
            control={control}
            name="property.totalFloors"
            allowNegative={false}
          />
        </FormStack>
        <FormStack gap={3}>
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.yearOfConstruction')}
            error={'property' in errors && !!errors.property?.constructionDate}
            helperText={'property' in errors && errors.property?.constructionDate?.message}
            control={control}
            name="property.constructionDate"
            allowNegative={false}
            decimalScale={0}
          />
          <Controller
            name="property.condition"
            control={control}
            render={({ field }) => (
              <DropDown
                {...field}
                label={t('lead:propertyEvaluateForm.details.condition')}
                options={allEnums?.enumsAssetCondition?.map(enumToLokalisedOptions(t, assetConditionTranslation)) ?? []}
              />
            )}
          />
        </FormStack>
      </EVDrawerCollapsible>
      <EVDrawerCollapsible
        icon={<CommentIcon />}
        title={t('lead:form.optionalSection.comment.header')}
        collapsedValues={noteCollapsedValue}
      >
        <FormStack>
          <TextField
            label={t('lead:personalNote')}
            multiline
            error={'property' in errors && !!errors.property?.additionalNoteProperty}
            helperText={'property' in errors && errors.property?.additionalNoteProperty?.message}
            {...register('property.additionalNoteProperty')}
          />
        </FormStack>
      </EVDrawerCollapsible>
      <FeaturesSection />
    </FormSection>
  );
};
