import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Stack from '@mui/material/Stack';
import { useGlobalEditorState } from 'components/forms/RichTextEditor/blots/GlobalEditorState';
import ExposeListItem from 'components/forms/RichTextEditor/blots/expose/ExposeListItem';
import { NoPropertyPlaceholder } from 'components/forms/RichTextEditor/blots/expose/NoPropertyPlaceholder';

export function ExposeListWidget() {
  const editorState = useGlobalEditorState();
  const { properties, alreadyProposedProperties, enableRemove, setProperties, language, hidePrice } =
    editorState.props.expose?.['global'] || {};

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = properties?.findIndex((property) => property.property.utag === active.id) ?? 0;
      const newIndex = properties?.findIndex((property) => property.property.utag === over.id) ?? 0;
      setProperties?.(arrayMove(properties ?? [], oldIndex, newIndex));
    }
  }

  return (
    <Stack gap={1} sx={{ maxWidth: 424, marginY: 2 }}>
      {!properties?.length && <NoPropertyPlaceholder />}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext items={properties?.map((p) => p.property.utag) || []} strategy={verticalListSortingStrategy}>
          {properties?.map((property) => (
            <ExposeListItem
              key={property.property.utag}
              exposeProperty={property}
              onRemove={
                enableRemove
                  ? () => setProperties?.(properties.filter(({ property: u }) => u.utag !== property.property.utag))
                  : undefined
              }
              hidePrice={hidePrice}
              language={language}
              isAlreadyProposed={alreadyProposedProperties?.includes(property.property.utag)}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Stack>
  );
}
