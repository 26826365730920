import { ActionDialog } from 'components/general/EVDialog/StandardDialogs/ActionDialog';
import { useTranslation } from 'util/i18next';

export const ConfirmCloseDialog = ({
  onConfirm,
  title,
  description,
  onCancel,
  isOpen,
}: {
  onConfirm: () => void;
  title?: string;
  description?: string;
  onCancel: () => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation([]);

  return (
    <ActionDialog
      open={isOpen}
      handleClose={onCancel}
      handleConfirm={onConfirm}
      title={title ?? t('confirmCloseModal.confirmationTitle')}
      description={description}
      confirmButtonCustomization={{ label: t('ok') }}
    />
  );
};
