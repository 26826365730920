import { useSnackBars } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { UseMutationOptions } from '@tanstack/react-query';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useCreateBuyerRentalLead } from 'api/rest';
import {
  CreateSellerRenterLeadRequest,
  CreateSellerRenterLeadResponse,
  useCreateNewSellerLead,
} from 'api/rest/hooks/useCreateLead';
import { LeadResponse } from 'api/rest/models/LeadResponse';
import { CreateBuyerRentalLeadRequest } from 'api/rest/models/rest/Command';
import { ContactDetailsForm } from 'components/contact/ContactDetailsForm/ContactDetailsForm';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { useUpdatePropertyAddress } from 'components/leads/forms/AddNewLead/updatePropertyAddress';
import { LeadForm } from 'components/leads/forms/LeadForm';
import { NewLeadAssignmentForm } from 'components/leads/forms/NewLeadAssignmentForm';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useConfirmation } from 'components/state/ConfirmationContext';
import { useNavigateToLead } from 'components/state/SelectedLead';
import { ContactEditSection } from 'page-components/contact/LayoutContact/EditContact/EditContact';
import { CreateNewContact } from 'page-components/contacts/ContactListPage/CreateNewContact';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, useForm, useWatch } from 'react-hook-form';
import { theme } from 'theme';
import { newLeadDefaultValues } from 'util/defaultValues/newLeadDefaultValues';
import { logSentryEntryIfAddressIsTruncated } from 'util/errorUtils';
import { useTranslation } from 'util/i18next';
import { isIntentTypeWithProperty } from 'util/intentType';
import { createBuyerRentalLeadMapper, createSellerRenterLeadMapper } from 'util/mappers/createLeadMapper';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';
import { useNewLeadTeamAssignmentSchema } from 'util/schemas/newLeadAssignmentSchema';
import { useNewLeadWithContactSchema } from 'util/schemas/newLeadSchema';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { usePermissions } from 'util/usePermissions';
import { z } from 'zod';

export type CreateLeadFormData = ContactDetailsFormData & PropertyDetailsFormData & { teamId?: string };

export interface CreateNewLeadProps {
  onClose: () => void;
  defaultContactValues?: Partial<ContactDetailsFormData>;
  onSuccess?: (data: { contactId: string; leadId: string }) => void;
  redirectToLeadOnSuccess?: boolean;
}

export const CreateNewLead = ({
  onClose,
  defaultContactValues,
  onSuccess,
  redirectToLeadOnSuccess = true,
}: CreateNewLeadProps) => {
  const { t } = useTranslation(['lead']);
  const { activeShop: shop, activeShopSettings, teamsWhereUserIsMember, shopCurrency } = useActiveShop();
  const currentAgentId = useCurrentAgentId();
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const [activeSection, setActiveSection] = useState<ContactEditSection | undefined>('contact-form');
  const navigateToLead = useNavigateToLead();
  const { createNewSellerLead, isLoading: isLoadingNewSellerLead } = useCreateNewSellerLead();
  const { createBuyerRentalLead, isLoading: isLoadingNewBuyerRentalLead } = useCreateBuyerRentalLead();
  const updatePropertyAddress = useUpdatePropertyAddress();

  const { canCreateLeadWithoutTeamAssignment, canCreateLeadWithoutAgentAssignment } = usePermissions();
  const createWithoutAssignment = !!shop && canCreateLeadWithoutTeamAssignment(shop);

  const isLoading = isLoadingNewBuyerRentalLead || isLoadingNewSellerLead;

  const newLeadWithContactSchema = useNewLeadWithContactSchema();
  const newLeadTeamAssignmentSchema = useNewLeadTeamAssignmentSchema();
  const formSchema =
    !createWithoutAssignment && teamsWhereUserIsMember.length
      ? newLeadWithContactSchema.and(newLeadTeamAssignmentSchema)
      : newLeadWithContactSchema;

  const defaultValues = newLeadDefaultValues(shopCurrency);

  const methods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...defaultValues,
      ...((defaultContactValues || {}) as Partial<CreateLeadFormData>),
      _internals: {
        ...defaultValues._internals,
        ...(defaultContactValues || {})._internals,
      },
      teamId: '' as string | undefined,
    },
  });
  const { formState, setValue, control, handleSubmit } = methods;

  useConfirmation({
    title: t('lead:newLead.add'),
    active: formState.isDirty,
  });

  useEffect(() => {
    if (activeShopSettings?.applyAutomatedContactSettings) {
      setValue('addresses', [
        {
          preferred: true,
          address: {
            city: activeShopSettings?.defaultCity ?? undefined,
            countryCode: activeShopSettings?.defaultCountryCode ?? undefined,
          },
        },
      ]);
      setValue('preferredLanguage', activeShopSettings?.defaultPreferredLanguage ?? undefined);
    }
  }, [setValue, activeShopSettings]);

  useEffect(() => {
    if (!createWithoutAssignment && teamsWhereUserIsMember?.length) {
      const teamId = teamsWhereUserIsMember[0]!.id;
      setValue('teamId', teamId);
    } else {
      setValue('teamId', '');
    }
  }, [setValue, createWithoutAssignment, teamsWhereUserIsMember]);

  const watchedContactQualified = useWatch({
    control: control,
    name: '_internals.contact.contactQualified',
  });

  const watchedIsOnlyContact = useWatch({
    control,
    name: '_internals.contact.isOnlyContact',
  });

  if (watchedIsOnlyContact) {
    return <CreateNewContact onClose={onClose} defaultValues={methods.getValues()} />;
  }

  async function handleCreateNewLead(submittedData: CreateLeadFormData) {
    if (!shop) {
      return;
    }
    const formData: CreateLeadFormData = await updatePropertyAddress(submittedData);

    const data = {
      ...formData,
      shopId: shop.id,
      ...(canCreateLeadWithoutAgentAssignment(shop, formData.teamId) ? {} : { agentId: currentAgentId }),
    };

    logSentryEntryIfAddressIsTruncated('addressSelect', data.addresses);
    logSentryEntryIfAddressIsTruncated('propertySelect', [{ address: data.property?.propertyAddress ?? undefined }]);

    const mutationOptions: UseMutationOptions<
      LeadResponse | CreateSellerRenterLeadResponse,
      unknown,
      CreateSellerRenterLeadRequest | CreateBuyerRentalLeadRequest
    > = {
      onSuccess: (leadResponse) => {
        openSnackBar(t('lead:message.addNewLead.success'), 'success');
        const leadId = leadResponse?.id;
        const contactId = leadResponse?.contact?.id;

        if (leadId && contactId) {
          onSuccess?.({ contactId, leadId });
          if (redirectToLeadOnSuccess) {
            navigateToLead(leadId, contactId, submittedData?.property?.isAddProperty);
          }
        }

        onClose();
      },
      onError: (error) => openErrorSnackBar(t('lead:message.addNewLead.error'), error),
    };

    if (isIntentTypeWithProperty(submittedData.intentType)) {
      createNewSellerLead(createSellerRenterLeadMapper(data), mutationOptions);
    } else {
      createBuyerRentalLead(createBuyerRentalLeadMapper(data), mutationOptions);
    }
  }

  const handleValidationError: SubmitErrorHandler<z.infer<typeof formSchema>> = (errors) => {
    const keys = Object.entries(errors)
      .filter(([, error]) => error.type !== 'invalid_union')
      .map(([key]) => key);

    const internalKeys = errors._internals ? Object.keys(errors._internals) : [];
    const hasNonePropertyDetailsError =
      keys.some(
        (key) => !['property', 'knownProperty', 'search', 'intentType', '_internals', 'leadSource'].includes(key),
      ) || internalKeys.some((key) => !['contactIntentType', 'isKnownProperty'].includes(key));

    setActiveSection(hasNonePropertyDetailsError ? 'contact-form' : 'intent-type-form');
  };

  const isLeadFormActive = activeSection === 'intent-type-form';

  const drawerProps = isLeadFormActive
    ? {
        title: (
          <Box gap={2} display="flex">
            <strong>2/2</strong>
            {t('lead:newLead.header.addLead')}
          </Box>
        ),
        primaryAction: {
          primaryButtonLabel: t('common:save'),
          disabled: !watchedContactQualified,
          callback: handleSubmit(handleCreateNewLead, handleValidationError),
        },
        sxHeader: {
          borderBottom: '2px solid',
          borderImage: `linear-gradient(to right, ${theme.palette.brand.red} 90%, transparent 50%) 100% 1`,
        },
        extraFooterItems: (
          <Button variant="outlined" color="secondary" onClick={() => setActiveSection('contact-form')}>
            {t('lead:newLead.header.backCTA')}
          </Button>
        ),
      }
    : {
        title: (
          <Box gap={2} display="flex">
            <strong>1/2</strong>
            {t('lead:newLead.header.addContact')}
          </Box>
        ),
        primaryAction: {
          primaryButtonLabel: t('lead:newLead.header.nextCTA'),
          disabled: !watchedContactQualified,
          callback: () => setActiveSection('intent-type-form'),
        },
        sxHeader: {
          borderBottom: '2px solid',
          borderImage: `linear-gradient(to right, ${theme.palette.brand.red} 50%, transparent 50%) 100% 1`,
        },
      };

  if (!shop) {
    return <LinearProgress />;
  }

  return (
    <EVDrawerContent {...drawerProps} isLoading={isLoading} onClose={onClose}>
      <FormProvider {...methods}>
        <form>
          {isLeadFormActive ? (
            <Box sx={{ padding: 3 }}>
              {teamsWhereUserIsMember.length > 1 && !createWithoutAssignment && (
                <NewLeadAssignmentForm teams={teamsWhereUserIsMember} />
              )}
              {watchedContactQualified && <LeadForm isCreateNewLead />}
            </Box>
          ) : (
            <ContactDetailsForm activeSection={activeSection} />
          )}
        </form>
      </FormProvider>
    </EVDrawerContent>
  );
};
