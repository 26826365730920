import { Box, Stack, useMediaQuery } from '@mui/material';
import { CopyButton } from 'components/general/CopyButton/CopyButton';
import { PropsWithChildren } from 'react';

export function CopyButtonWrapper({ value, children }: PropsWithChildren & { value: string }) {
  const isTouchDevice = useMediaQuery('(hover: none)');

  if (isTouchDevice) {
    return children;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      sx={{
        '.copy-button': { visibility: 'hidden' },
        ':hover .child-box': { maxWidth: 'calc(100% - 40px)' },
        ':hover .copy-button': { visibility: 'visible' },
      }}
    >
      <Box className="child-box" sx={{ maxWidth: '100%' }}>
        {children}
      </Box>
      <CopyButton value={value} />
    </Stack>
  );
}
