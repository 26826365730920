import { ErrorDetails } from '@ev/eva-container-api';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { useUploadImages } from 'api/rest/hooks/useImages';
import { toErrorDetails, useErrorMessage } from 'components/general/ErrorInfoModal/ErrorInfoModal';
import CloseIcon from 'components/icons/cross_naked.svg?react';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useErrorSnackBar } from 'util/useErrorSnackBar';

export const DragAndDropZone = ({
  setShowDragArea,
  handleImageInsert,
}: {
  setShowDragArea: (value: React.SetStateAction<boolean>) => void;
  handleImageInsert: (urls: string[]) => void;
}) => {
  const { t } = useTranslation(['communication']);
  const { uploadImages, isLoading } = useUploadImages();
  const { openErrorSnackBar } = useErrorSnackBar();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragOverCount, setDragOverCount] = useState(0);
  const [errorDetails, setErrorDetails] = useState<ErrorDetails>();

  const customErrorMessage = useErrorMessage(errorDetails);

  const handleUpload = (event: React.DragEvent | React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOverCount(0);
    setErrorDetails(undefined);

    let files: File[] = [];

    if ('dataTransfer' in event) {
      files = Array.from(event.dataTransfer.files);
    } else if ('target' in event && event.target.files) {
      files = Array.from(event.target.files);
    }

    if (files.length > 0) {
      uploadImages(files, {
        onSuccess: (urls) => {
          if (urls) {
            handleImageInsert(urls);
            setShowDragArea(false);
          }
        },
        onError: (error) => {
          const errorDetails = toErrorDetails(error);
          if (!errorDetails?.errorCode) {
            openErrorSnackBar(t('communication:uploadImage.error'), error);
          } else {
            setErrorDetails(errorDetails);
          }
        },
      });
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const hasCustomError = !!customErrorMessage;

  function getContent() {
    if (isLoading) {
      return <CircularProgress color="inherit" />;
    }

    return (
      <>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            setShowDragArea(false);
          }}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="body1" sx={{ marginY: 1 }}>
          {t('communication:uploadImage.label')}
        </Typography>
        <input type="file" ref={fileInputRef} multiple style={{ display: 'none' }} onChange={handleUpload} />
        {hasCustomError ? (
          <Typography variant="body3" color={theme.palette.error.main}>
            {customErrorMessage}
          </Typography>
        ) : (
          <Typography variant="body3">
            <Trans t={t} i18nKey="communication:uploadImage.limits" />
          </Typography>
        )}
      </>
    );
  }

  return (
    <Stack
      onDrop={handleUpload}
      onDragOver={(event) => event.preventDefault()}
      onDragEnter={() => setDragOverCount((c) => c + 1)}
      onDragLeaveCapture={() => setDragOverCount((c) => c - 1)}
      onClick={handleClick}
      sx={{
        zIndex: 1,
        position: 'absolute',
        margin: 2,
        inset: 0,
        justifyContent: 'center',
        alignItems: 'center',
        border: `2px dashed ${hasCustomError ? theme.palette.error.main : theme.palette.shade.grey3}`,
        borderRadius: '4px',
        backgroundColor: dragOverCount ? theme.palette.backgroundExtension.grey : theme.palette.shade.grey4,
        textAlign: 'center',
        color: hasCustomError ? theme.palette.error.main : theme.palette.shade.grey2,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.backgroundExtension.grey,
        },
      }}
    >
      {getContent()}
    </Stack>
  );
};
