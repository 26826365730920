import { styled } from '@mui/material/styles';
import { memo, ReactNode } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export function getColor(status: string | undefined) {
  switch (status) {
    case 'ACTIVE':
      return {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.special.green4,
      };
    case 'INACTIVE':
      return {
        backgroundColor: theme.palette.transparent.yellow24,
        color: theme.palette.warning.main,
      };
    case 'CLOSED':
    case 'INVALID':
      return {
        backgroundColor: theme.palette.transparent.gray10,
        color: theme.palette.text.primary,
      };
    default:
      return {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.primary.main,
      };
  }
}

export const Label = styled('span', {
  shouldForwardProp: (prop) => prop !== 'status',
})<{
  status: string | undefined;
}>((props) => ({
  ...getColor(props.status),
  alignItems: 'center',
  borderRadius: '0.3em',
  display: 'inline-flex',
  fontSize: theme.typography.body4.fontSize,
  height: '2em',
  justifyContent: 'center',
  padding: '0.5em',
}));

function StatusLabelComponent({ status, label }: { status?: string; label?: ReactNode }) {
  const { t } = useTranslation(['user']);
  return (
    <Label status={status} data-testid="statusType">
      {label
        ? label
        : status === 'ACTIVE'
        ? t('user:myLiPa.page.myLiPaProfile.statusActive')
        : t('user:myLiPa.page.myLiPaProfile.statusInActive')}
    </Label>
  );
}

export const StatusLabel = memo(StatusLabelComponent);
