import { IconButton, InputAdornment, SxProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CustomPickerActionBar } from 'components/general/EVDatePicker/CustomPickerActionBar';
import ClearIcon from 'components/icons/cross_naked.svg?react';
import { forwardRef, useState } from 'react';
import { theme } from 'theme';
import { useDateFnsLocale } from 'util/i18n/useDateFnsLocale';

export interface DatePickerProps {
  onChange: (event: Date | null) => void;
  label: string;
  helperText?: string;
  error?: boolean;
  value?: Date | null;
  disabled?: boolean;
  required?: boolean;
  displayFormat?: string;
  sx?: SxProps;
  showWeekDay?: boolean;
  minDate?: Date | null;
  maxDate?: Date;
  formatted?: boolean;
  clearable?: boolean;
}

export const EVDatePicker = forwardRef<HTMLInputElement, DatePickerProps>(function DatePicker(
  {
    error,
    helperText,
    onChange,
    label,
    value,
    disabled,
    required,
    displayFormat,
    showWeekDay,
    sx,
    formatted,
    minDate,
    maxDate,
    clearable,
  },
  ref,
) {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { locale } = useDateFnsLocale();
  const isCurrentYear = value && new Date(value).getFullYear() === new Date().getFullYear();
  const defaultFormat = `${showWeekDay ? 'EEE, ' : ''}d MMM${isCurrentYear ? '' : ' yyyy'}`;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <MUIDatePicker
        slots={{
          actionBar: CustomPickerActionBar,
          openPickerIcon: () => null,
        }}
        slotProps={{
          textField: {
            variant: 'outlined',
            helperText,
            error,
            required,
            sx: sx,
            onClick: () => setIsOpen(true),
            onFocus: () => setIsFocused(true),
            onBlur: () => {
              if (!isOpen) {
                setIsFocused(false);
              }
            },
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  {value && clearable && (
                    <IconButton
                      onClick={() => {
                        onChange(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            },
          },
          dialog: {
            sx: {
              '.MuiDialogContent-root': { marginBottom: 2, marginTop: 0 },
              '.MuiDatePickerToolbar-title': { fontSize: theme.typography.h2, marginTop: 0 },
            },
          },
          //&& will duplicate the class name to add more specificity and override dialog base styling
          mobilePaper: {
            sx: { '&&': { background: theme.palette.backgroundExtension.white, padding: 0, maxWidth: 500 } },
          },
        }}
        ref={ref}
        label={label}
        value={value}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        maxDate={maxDate}
        onChange={onChange}
        format={!isFocused && !formatted ? displayFormat ?? defaultFormat : 'dd.MM.yyyy'}
        disabled={disabled}
        minDate={minDate || undefined}
      />
    </LocalizationProvider>
  );
});
