import { Call } from '@twilio/voice-sdk';
import { Shop } from 'api/graphql/generated/graphql';
import { useDeclineTwilioCall } from 'api/rest';
import { ActiveCallPopover } from 'components/calls/ActiveCallPopover/ActiveCallPopover';
import { EmptyCallPopover } from 'components/calls/EmptyCallPopover';
import { IncomingCallPopover } from 'components/calls/IncomingCallPopover';
import { OutgoingCallPopover } from 'components/calls/OutgoingCallPopover';
import { ActionDialog } from 'components/general/EVDialog/StandardDialogs/ActionDialog';
import { CallerInfo } from 'components/state/Twilio/Twilio';
import { useEffect, useState } from 'react';
import { useTranslation } from 'util/i18next';

export type CallUiState = 'incoming' | 'outgoing' | 'active' | 'empty';

export function CallerScreen({
  callerInfo,
  callInstance,
  uiState,
  setUiState,
  toggleMuteCall,
  hasCallingEnabled,
  onClose,
}: {
  callerInfo: CallerInfo | undefined;
  callInstance: Call | undefined;
  uiState: CallUiState | undefined;
  toggleMuteCall: (call: Call) => void;
  setUiState: (state: CallUiState) => void;
  hasCallingEnabled: (shop: Shop) => boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(['communication']);
  const [discardCallModalOpen, setDiscardCallModalOpen] = useState(false);
  const { declineTwilioCall } = useDeclineTwilioCall();

  const callConnected = callerInfo?.call?.status === Call.State.Open;
  useEffect(() => {
    const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string }) => {
      if (callConnected) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    if (callConnected) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [callConnected]);

  function renderCallState() {
    switch (uiState) {
      case 'incoming':
        return callerInfo ? (
          <IncomingCallPopover
            callerInfo={callerInfo}
            onAcceptCall={() => {
              callInstance?.accept();
            }}
            onDeclineCall={() => {
              const callSid = callerInfo?.call.callSid;

              const declineCallTimeoutMs = 150;
              if (callSid) {
                callInstance?.accept();

                setTimeout(() => {
                  declineTwilioCall({ callSid });
                }, declineCallTimeoutMs);
              } else {
                callInstance?.reject();
              }
            }}
            onClose={() => {
              callInstance?.reject();
            }}
          />
        ) : null;
      case 'outgoing':
        return callerInfo ? (
          <OutgoingCallPopover
            callerInfo={callerInfo}
            onClose={() => {
              setDiscardCallModalOpen(true);
            }}
            onEndCall={() => {
              callInstance?.disconnect();
              setUiState('active'); // Will show call ended
            }}
          />
        ) : null;
      case 'active':
        return callerInfo ? (
          <ActiveCallPopover
            callerInfo={callerInfo}
            onClose={() => {
              if (callerInfo.call?.status !== Call.State.Closed) {
                setDiscardCallModalOpen(true);
              } else {
                onClose();
              }
            }}
            onEndCall={() => {
              callInstance?.disconnect();
            }}
            onMuteCall={() => {
              if (callInstance) {
                toggleMuteCall(callInstance);
              }
            }}
          />
        ) : null;
      case 'empty':
        return <EmptyCallPopover hasCallingEnabled={hasCallingEnabled} onClose={onClose} />;
      default:
        return null;
    }
  }

  const onConfirm = () => {
    callInstance?.disconnect();
    onClose();
    setDiscardCallModalOpen(false);
  };

  return (
    <>
      {renderCallState()}
      {discardCallModalOpen && (
        <ActionDialog
          open={discardCallModalOpen}
          handleClose={() => setDiscardCallModalOpen(false)}
          handleConfirm={onConfirm}
          title={t('communication:discardCallTitle')}
          description={t('communication:discardCallDescription')}
          confirmButtonCustomization={{
            label: t('drawer.discardModal'),
          }}
        />
      )}
    </>
  );
}
