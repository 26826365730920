import { ContactRelationTypeEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { DropDown } from 'components/general/DropDown/DropDown';
import { contactRelationshipTypeTranslation } from 'const/enumTranslations';
import { forwardRef } from 'react';
import { useTranslation } from 'util/i18next';

export type RelationshipTypeSelectProps = {
  value?: ContactRelationTypeEnum;
  disabled?: boolean;
  onChange: (relationshipType: string | string[]) => void;
  error?: boolean;
  helperText?: string;
};

export const RelationshipTypeSelect = forwardRef<unknown, RelationshipTypeSelectProps>(
  ({ value, onChange, disabled, error, helperText }, ref) => {
    const { allEnums } = useGetAllEnums();
    const { t } = useTranslation(['contact', 'enums']);
    const relationshipTypes = allEnums?.enumsContactRelationType ?? [];

    const relationshipTypeOptions = relationshipTypes.map(({ name }) => ({
      label: t(contactRelationshipTypeTranslation[name as ContactRelationTypeEnum]),
      value: name as ContactRelationTypeEnum,
    }));

    return (
      <DropDown
        ref={ref}
        label={t('contact:addRelationship.relationshipType.label')}
        options={relationshipTypeOptions}
        value={value}
        disabled={disabled}
        onChange={onChange}
        hasError={error}
        errorMessage={helperText}
      />
    );
  },
);
