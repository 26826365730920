import { TOOLTIP_Z_INDEX } from '@ev/eva-container-api';
import { Box, Paper } from '@mui/material';
import MuiPopper, { PopperProps as MuiPopperProps } from '@mui/material/Popper';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

const MAX_WIDTH = 350;

type PopperProps = PropsWithChildren<{
  placement?: MuiPopperProps['placement'];
  isLoading?: boolean;
  disabled?: boolean;
  anchor: React.ReactNode;
  onChange?: (popperState: boolean) => void;
  isPopoverOpen?: boolean;
}>;

export function Popper({
  anchor,
  children,
  isLoading,
  placement = 'top',
  disabled,
  onChange,
  isPopoverOpen = false,
}: PopperProps) {
  const id = String('mouse-over-popover').concat('-', crypto.randomUUID());

  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(isPopoverOpen);

  useEffect(() => {
    setIsOpen(isPopoverOpen);
  }, [isPopoverOpen]);

  const handlePopoverOpen = () => {
    setIsOpen(true);
    onChange?.(true);
  };

  const handlePopoverClose = () => {
    setIsOpen(false);
    onChange?.(false);
  };

  const disablePropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <Box
        aria-owns={id}
        aria-haspopup="true"
        ref={anchorEl}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {anchor}
      </Box>

      {(!disabled || !isLoading) && (
        <MuiPopper
          anchorEl={anchorEl.current}
          placement={placement}
          open={isOpen}
          id={id}
          onClick={disablePropagation}
          sx={{ pointerEvents: 'none', zIndex: TOOLTIP_Z_INDEX, maxWidth: MAX_WIDTH }}
        >
          <Paper
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            style={{
              pointerEvents: 'auto',
            }}
          >
            {children}
          </Paper>
        </MuiPopper>
      )}
    </>
  );
}
