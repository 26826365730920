import { Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

const ImageMenu = ({
  handleImageOption,
  handleClose,
}: {
  handleImageOption: (e: 'drag' | 'insert') => void;
  handleClose: () => void;
}) => {
  const { t } = useTranslation(['communication']);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  useEffect(() => {
    const toolbarButton = document.querySelector('button.ql-image');
    setAnchorEl(toolbarButton);
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      slotProps={{
        paper: {
          sx: {
            boxShadow: '0 0 5px rgba(0,0,0,0.2)',
            border: '1px solid #ccc',
            borderRadius: '4px',
            maxWidth: '150px',
          },
        },
        root: {
          sx: {
            '& .MuiMenu-list': {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
      }}
    >
      <MenuItem
        sx={{
          alignContent: 'center',
          display: 'block',
          fontSize: '14px',
          padding: '8px',
          transition: 'background-color 0.3s',
          '&:hover': { backgroundColor: theme.palette.shade.grey4 },
        }}
        onClick={() => {
          handleImageOption('drag');
          handleClose();
        }}
      >
        {t('communication:drawer.insertImagePhoto.label')}
      </MenuItem>
      <MenuItem
        sx={{
          display: 'block',
          alignContent: 'center',
          fontSize: '14px',
          padding: '8px',
          transition: 'background-color 0.3s',
          '&:hover': { backgroundColor: theme.palette.shade.grey4 },
        }}
        onClick={() => {
          handleImageOption('insert');
          handleClose();
        }}
      >
        {t('communication:drawer.insertURL.label')}
      </MenuItem>
    </Menu>
  );
};

export default ImageMenu;
