import { SnackbarAction } from '@ev/eva-container-api';
import { AlertColor, Alert as MuiAlert, Stack, SxProps, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { alertSx, getAlertColor, getAlertIcon } from 'components/general/EVAlert/EVAlert';
import React, { ReactNode } from 'react';
import { theme } from 'theme';

export interface SnackbarAlertProps {
  type: AlertColor;
  message: string | ReactNode;
  action?: SnackbarAction;
  onClose: () => void;
  sx?: SxProps;
  sxAction?: SxProps;
}

export const SnackbarAlert = React.forwardRef<HTMLDivElement, SnackbarAlertProps>(function Alert(
  { type, message, action, onClose, sx, sxAction }: SnackbarAlertProps,
  ref,
) {
  return (
    <MuiAlert
      ref={ref}
      onClose={onClose}
      severity={type}
      elevation={4}
      icon={getAlertIcon(type)}
      sx={{ ...sx, ...alertSx, pointerEvents: 'all', backgroundColor: getAlertColor(type), border: 'none' }}
    >
      <Stack direction="row">
        {message}
        {action &&
          ('callback' in action ? (
            <Typography
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                marginLeft: theme.spacing(2),
                alignSelf: 'center',
                ...sxAction,
              }}
              variant="body2"
              onClick={action.callback}
            >
              {action.text}
            </Typography>
          ) : (
            <MuiLink
              sx={{ marginLeft: theme.spacing(2), alignSelf: 'center', ...sxAction }}
              color="inherit"
              variant="body2"
              // Notifications are render outside of the router therefore we have to use hard links
              href={action.link}
            >
              {action.text}
            </MuiLink>
          ))}
      </Stack>
    </MuiAlert>
  );
});
