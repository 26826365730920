import { ActionDialog } from 'components/general/EVDialog/StandardDialogs/ActionDialog';
import { useTranslation } from 'util/i18next';

export type ModalType = 'alreadyProposed' | 'serviceContacts' | 'unresolvedPlaceholders' | null;

interface ModalRendererProps {
  currentModal: ModalType;
  isSending: boolean;
  onCancel: () => void;
  onSend: () => void;
}

export const PreviewBulkSendingModals = ({ currentModal, isSending, onCancel, onSend }: ModalRendererProps) => {
  const { t } = useTranslation(['communication']);

  if (!currentModal) return null;

  const modalConfig = {
    alreadyProposed: {
      title: t('communication:emailForm.alreadyProposedProperties.title'),
      description: t('communication:sendBulkEmailDialog.alreadyProposedProperties.confirmation'),
    },
    serviceContacts: {
      title: t('communication:emailForm.hasServiceContact.title'),
      description: t('communication:emailForm.hasServiceContact.description'),
    },
    unresolvedPlaceholders: {
      title: t('communication:emailForm.unresolved.error.title'),
      description: t('communication:emailForm.unresolved.error.description'),
    },
  };

  const { title, description } = modalConfig[currentModal];

  return (
    <ActionDialog
      open
      handleClose={onCancel}
      handleConfirm={onSend}
      title={title}
      description={description}
      confirmButtonCustomization={{ label: t('communication:newEmail.drawer.send'), disabled: isSending }}
    />
  );
};
