import { createEnv } from '@t3-oss/env-core';
import { clientSchema, serverSchema } from 'env-schema';

export const env = createEnv({
  clientPrefix: 'VITE_',
  server: serverSchema,
  client: clientSchema,

  runtimeEnv: import.meta.env,
});
