import { Box, Stack, SxProps, Typography } from '@mui/material';
import { useAgentsWithAllMemberships } from 'api/graphql/hooks/useAgentsWithAllMemberships';
import { EVChip, EVChipProps } from 'components/general/Chips/EVChip/EVChip';
import { TooltipButton } from 'components/general/Chips/LeadChip/TooltipButton';
import { EmailTooltipButton } from 'components/general/Chips/TooltipButton/EmailTooltipButton';
import { TwoRowChipLabel } from 'components/general/Chips/TwoRowChipLabel';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { LinkButton } from 'components/general/LinkButton';
import { PopperEntity, PopperEntityHeader } from 'components/general/Popper/Entity';
import { Popper } from 'components/general/Popper/Popper';
import ExternalLinkIcon from 'components/icons/link_external.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { ROLE_TRANSLATION } from 'const/enumTranslations';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { MouseEventHandler, ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { fullName } from 'util/stringUtils';
import { sxTruncate } from 'util/styleUtils';
import { usePermissions } from 'util/usePermissions';

type AgentTooltipChipProps = {
  agentId?: Maybe<string>;
  children?: ReactElement;
  chipProps?: EVChipProps;
  compact?: boolean;
  emptyDataString?: string;
  handleDelete?: () => void;
  isDefaultSystemUser?: boolean;
  subLabel?: string;
  sx?: SxProps;
};

export const AgentTooltipChip: React.FC<AgentTooltipChipProps> = ({
  agentId,
  children,
  chipProps,
  compact,
  emptyDataString = EMPTY_DATA_STRING,
  handleDelete,
  isDefaultSystemUser,
  subLabel,
  sx,
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const { activeShop } = useActiveShop();
  const { t } = useTranslation(['enums']);
  const { canViewAgentProfile } = usePermissions();
  const profileEnabled = useIsFeatureEnabled('PROFILE_INTEGRATION');

  const { agentsWithMembership: agents } = useAgentsWithAllMemberships(
    { where: { id: { _eq: agentId } } },
    { enabled: !!agentId && inView },
  );

  const { toAgentProfile } = useDestination();

  function getContent() {
    const agent = agents?.[0];
    if (!(agentId && agent) && !isDefaultSystemUser) {
      return (
        <Typography variant="body3" sx={{ color: theme.palette.text.secondary }}>
          {emptyDataString}
        </Typography>
      );
    }

    const agentPerson = isDefaultSystemUser
      ? { firstName: 'System User' }
      : { firstName: agent?.firstName, lastName: agent?.lastName };
    const label = fullName(agentPerson);

    return (
      <Box sx={{ display: 'inline-flex' }}>
        <Popper
          anchor={
            children ??
            (!!agentId && (
              <Link to={toAgentProfile({ agentId })}>
                {compact ? (
                  <EVAvatar person={agent} secondRowText={subLabel ?? undefined} />
                ) : (
                  <EVChip
                    avatar={<EVAvatar size="s" person={agent} />}
                    onDelete={handleDelete}
                    label={subLabel ? <TwoRowChipLabel firstRow={label} secondRow={subLabel} /> : label}
                    {...chipProps}
                  />
                )}
              </Link>
            ))
          }
        >
          <PopperEntity
            header={
              <PopperEntityHeader
                label={
                  canViewAgentProfile(activeShop) && profileEnabled && agentId ? (
                    <LinkButton to={toAgentProfile({ agentId })}>{label}</LinkButton>
                  ) : (
                    label
                  )
                }
                avatar={<EVAvatar size="l" person={agent} />}
                subLabel={
                  <>
                    <Box sx={sxTruncate}>{agent?.email}</Box>
                    <Box sx={sxTruncate}>{agent?.mobilePhoneNumber}</Box>
                    <Box sx={sxTruncate}>{agent?.officePhoneNumber}</Box>
                  </>
                }
              />
            }
            body={
              <>
                {agent?.userShopAssignments.map((assignment) => (
                  <Box key={assignment.shop.id}>{assignment.shop.shopName}</Box>
                ))}
                {agent?.userTeamAssignments.map((assignment) => (
                  <Box key={assignment.team.id}>
                    {assignment.team.name} | {t(ROLE_TRANSLATION[assignment.role])}
                  </Box>
                ))}
              </>
            }
            footer={
              !!agentId && (
                <Stack>
                  <EmailTooltipButton to={[{ agentId }]} />
                  <Link to={toAgentProfile({ agentId })} target="_blank">
                    <TooltipButton fullWidth icon={<ExternalLinkIcon />}>
                      {t('menu.openInNewTab')}
                    </TooltipButton>
                  </Link>
                </Stack>
              )
            }
          />
        </Popper>
      </Box>
    );
  }

  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  return (
    <Box ref={ref} sx={sx} onClick={handleClick}>
      {getContent()}
    </Box>
  );
};
