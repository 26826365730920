import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CancelButton, CancelButtonCustomization } from 'components/general/CancelButton/CancelButton';
import { ConfirmButton, ConfirmButtonCustomization } from 'components/general/ConfirmButton/ConfirmButton';
import { EVDialog } from 'components/general/EVDialog/EVDialog';
import { FC, ReactNode } from 'react';

interface Props {
  open: boolean;
  title: string;
  description?: string | ReactNode;
  children?: ReactNode;
  handleConfirm: () => void;
  handleClose: () => void;
  handleCancel?: () => void;
  confirmButtonCustomization?: ConfirmButtonCustomization;
  cancelButtonCustomization?: CancelButtonCustomization;
}

export const ActionDialog: FC<Props> = ({
  open,
  handleConfirm,
  handleClose,
  handleCancel,
  confirmButtonCustomization,
  cancelButtonCustomization,
  title,
  description,
  children,
}) => {
  return (
    <EVDialog open={open} onClose={handleClose} data-testid="action-dialog">
      <DialogTitle sx={{ mr: 4 }}>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body3">{description}</Typography>
        {children}
      </DialogContent>
      <DialogActions>
        <CancelButton onCancel={handleCancel ?? handleClose} customization={cancelButtonCustomization} />
        <ConfirmButton onConfirm={handleConfirm} customization={confirmButtonCustomization} />
      </DialogActions>
    </EVDialog>
  );
};
