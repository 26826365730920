import { useDrawer } from '@ev/eva-container-api';
import { Box, CircularProgress, Stack, SxProps } from '@mui/material';
import { KycStatusEnum, Maybe } from 'api/graphql/generated/graphql';
import { PublicContact, usePublicContacts } from 'api/graphql/hooks/useContact';
import { EVChip, EVChipProps } from 'components/general/Chips/EVChip/EVChip';
import { TooltipButton } from 'components/general/Chips/LeadChip/TooltipButton';
import { EmailTooltipButton } from 'components/general/Chips/TooltipButton/EmailTooltipButton';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { LinkButton } from 'components/general/LinkButton';
import { PopperEntity, PopperEntityHeader } from 'components/general/Popper/Entity';
import { Popper } from 'components/general/Popper/Popper';
import ExternalLinkIcon from 'components/icons/link_external.svg?react';
import WarningIcon from 'components/icons/warning.svg?react';
import EditContact from 'page-components/contact/LayoutContact/EditContact/EditContact';
import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { EMPTY_DATA_STRING, renderEmptyableString } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { fullName } from 'util/stringUtils';
import { sxTruncate } from 'util/styleUtils';

type ContactTooltipChipProps = {
  chipProps?: EVChipProps;
  contactId?: string | null;
  handleDelete?: () => void;
  currentEmail?: string;
  linkTarget?: string;
  asLink?: boolean;
  kycStatus?: Maybe<KycStatusEnum>;
  sx?: SxProps;
  children?: ReactNode;
};

export function ContactTooltip({ contactId, sx, children }: PropsWithChildren<{ contactId: string; sx?: SxProps }>) {
  const { publicContact, isLoading } = usePublicContacts(
    {
      where: { id: { _eq: contactId } },
    },
    { staleTime: 300_000 },
  );

  return (
    <InnerContactTooltip publicContact={publicContact} isLoading={isLoading} sx={sx}>
      {children}
    </InnerContactTooltip>
  );
}

export const ContactTooltipChip: React.FC<ContactTooltipChipProps> = ({
  chipProps,
  contactId,
  handleDelete,
  currentEmail,
  linkTarget,
  asLink = true,
  kycStatus,
  sx,
  children,
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const { t } = useTranslation(['user']);

  const { publicContact, isLoading } = usePublicContacts(
    { where: { id: { _eq: contactId } } },
    { enabled: !!contactId && inView, staleTime: 300_000 },
  );

  const label = fullName({ firstName: publicContact?.firstName, lastName: publicContact?.lastName });

  const { toContactDetails } = useDestination();

  const isKYCIncomplete = kycStatus && kycStatus === 'INCOMPLETE';
  const kycWarningMessage = isKYCIncomplete ? t('user:activity.kyc.contactIncomplete') : undefined;

  const linkTo =
    publicContact?.contact?.id && !publicContact.contact.blocked
      ? toContactDetails({ contactId: publicContact?.contact?.id })
      : undefined;
  const withoutTooltip = !publicContact?.contact || publicContact.contact.blocked;
  const avatar = isKYCIncomplete ? (
    <WarningIcon color={theme.palette.warning.main} />
  ) : (
    <EVAvatar person={publicContact} size="s" />
  );
  function getContent() {
    if (!contactId) {
      return EMPTY_DATA_STRING;
    }

    if (withoutTooltip) {
      return (
        <EVChip
          avatar={isLoading ? <CircularProgress variant="indeterminate" size={24} /> : avatar}
          {...chipProps}
          label={label}
          interactive={false}
        />
      );
    }

    return (
      <InnerContactTooltip
        linkTo={linkTo}
        publicContact={publicContact}
        isLoading={isLoading}
        sx={sx}
        currentEmail={currentEmail}
        kycWarningMessage={kycWarningMessage}
      >
        {children ?? (
          <EVChip
            avatar={
              isKYCIncomplete ? (
                <WarningIcon color={theme.palette.warning.main} />
              ) : isLoading ? (
                <CircularProgress variant="indeterminate" size={24} />
              ) : (
                avatar
              )
            }
            {...chipProps}
            onDelete={handleDelete}
            label={label}
          />
        )}
      </InnerContactTooltip>
    );
  }

  if (contactId && asLink && linkTo) {
    return (
      <Box ref={ref}>
        <Link to={linkTo} target={linkTarget}>
          {getContent()}
        </Link>
      </Box>
    );
  }

  return <Box ref={ref}>{getContent()}</Box>;
};

function InnerContactTooltip({
  publicContact,
  children,
  linkTo,
  kycWarningMessage,
}: PropsWithChildren<{
  publicContact: PublicContact | undefined;
  isLoading: boolean;
  sx?: SxProps;
  currentEmail?: string;
  linkTo?: string;
  kycWarningMessage?: string;
}>) {
  const label = fullName({ firstName: publicContact?.firstName, lastName: publicContact?.lastName });
  const { t } = useTranslation(['contact', 'user']);
  const [isEditContactKycInfoDrawerOpen, setEditContactKycInfoDrawerOpen] = useDrawer('editKycInfo');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { emailAddresses, phoneNumbers } = publicContact?.contact || {};

  return (
    <Box sx={{ display: 'inline-flex' }}>
      <Popper anchor={children} isPopoverOpen={isPopoverOpen} onChange={setIsPopoverOpen}>
        <PopperEntity
          header={
            <PopperEntityHeader
              label={linkTo ? <LinkButton to={linkTo}>{label}</LinkButton> : label}
              avatar={<EVAvatar size="l" person={publicContact} />}
              subLabel={
                <>
                  {emailAddresses?.map((email) => (
                    <Box key={email.address} sx={sxTruncate}>
                      {email.address}
                    </Box>
                  ))}
                  {phoneNumbers?.map((phone) => (
                    <Box key={phone.number}>{phone.number}</Box>
                  ))}
                </>
              }
            >
              {kycWarningMessage && (
                <EVAlert
                  severity={'warning'}
                  fullWidth
                  onButtonClick={() => {
                    setIsPopoverOpen(false);
                    setEditContactKycInfoDrawerOpen(true);
                  }}
                  buttonText={t('user:kyc.complete')}
                  sx={{ fontSize: '12px', mt: 2 }}
                >
                  {kycWarningMessage}
                </EVAlert>
              )}
            </PopperEntityHeader>
          }
          body={t('contact:contactId') + ': ' + renderEmptyableString(publicContact?.contact?.go3Utag)}
          footer={
            !!publicContact?.contact?.id && (
              <Stack>
                <EmailTooltipButton to={[{ contactId: publicContact?.contact?.id }]} />
                {linkTo && (
                  <Link to={linkTo} target="_blank">
                    <TooltipButton fullWidth icon={<ExternalLinkIcon />}>
                      {t('menu.openInNewTab')}
                    </TooltipButton>
                  </Link>
                )}
              </Stack>
            )
          }
        />
      </Popper>
      {publicContact?.contact && (
        <EVDrawer isOpen={isEditContactKycInfoDrawerOpen} onClose={() => setEditContactKycInfoDrawerOpen(false)}>
          <EditContact
            contact={publicContact?.contact}
            onClose={() => {
              setEditContactKycInfoDrawerOpen(false);
            }}
            initialOpenSection="kyc-information-and-personal-data-form"
          />
        </EVDrawer>
      )}
    </Box>
  );
}
