export const userEvent = {
  topbar: {
    support: {
      open: 'news-and-help-open',
      close: 'news-and-help-close',
    },
  },
  chatbot: {
    close: 'chatbot-close',
    open: 'chatbot-open',
    minimize: 'chatbot-minimize',
    maximize: 'chatbot-maximize',
  },
} as const;

type ValueOf<T> = T extends string ? T : ValueOf<T[keyof T]>;
export type USER_EVENT = ValueOf<typeof userEvent>;
