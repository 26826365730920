import { DRAWER_Z_INDEX, useDrawer, useSnackBars } from '@ev/eva-container-api';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Contact } from 'api/graphql/generated/graphql';
import { useContacts } from 'api/graphql/hooks/useContact';
import { useShops } from 'api/graphql/hooks/useShops';
import { useLinkCallToContact } from 'api/rest/hooks/useContacts';
import { ActiveCallPage } from 'components/calls/ActiveCallPopover/ActiveCallPopover';
import { CallSubpageHeader } from 'components/calls/ActiveCallPopover/CallSubpageHeader';
import { SearchResultCard } from 'components/calls/ActiveCallPopover/SearchResultCard';
import { CallFrame } from 'components/calls/CallFrame';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import LinkIcon from 'components/icons/link_external.svg?react';
import PlusIcon from 'components/icons/plus_naked.svg?react';
import { CreateNewLead } from 'components/leads/forms/CreateNewLead';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { CallerInfo } from 'components/state/Twilio';
import { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { getPreferredEmail, getPreferredPhoneNumber } from 'util/contactUtils';
import { contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { fullName } from 'util/stringUtils';
import { useSearchText } from 'util/useDebounce';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { usePermissions } from 'util/usePermissions';

function emailAndPhoneNumberSummary(contact: Contact) {
  const preferredEmailAddress = getPreferredEmail(contact);
  const preferredPhoneNumber = getPreferredPhoneNumber(contact);

  return [preferredEmailAddress, preferredPhoneNumber].filter(Boolean).join(', ');
}

export function LinkContactToCallPage({
  onClose,
  headerTitle,
  headerColor,
  onPageChange,
  callDuration,
  callerInfo,
}: {
  headerTitle: string;
  headerColor: string;
  callerInfo: CallerInfo;
  onClose: () => void;
  onPageChange: (page: ActiveCallPage) => void;
  callDuration: number;
}) {
  const { t } = useTranslation(['communication']);
  const [isCreateContactDrawerOpen, setCreateContactDrawerOpen] = useDrawer('createContactForCall');
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const { setActiveShopId } = useActiveShopId();

  const { searchText, rawSearchText, setSearchText } = useSearchText();
  const { canCreateContact } = usePermissions();

  const { shops } = useShops({ where: { id: { _eq: callerInfo.call.shopId } } }, { enabled: !!callerInfo.call.shopId });

  const shop = shops?.[0];
  const shopGroupIds = shop ? shop.shopGroup?.shops.map(({ id }) => id) || [shop.id] : [];

  const { contacts, isInitialLoading } = useContacts(
    {
      where: {
        shopId: { _in: shopGroupIds },
        _and: [
          {
            deleted: { _neq: true },
          },
          { blocked: { _neq: true } },
          contactSearchNameFilter(searchText),
        ],
      },
      limit: 100,
    },
    { enabled: !!searchText.length && !!shopGroupIds.length },
  );

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
  };

  const { linkCallToContact, isLoading: isLinkingContact } = useLinkCallToContact();

  const handleLinkContactAndLead = ({ contactId, leadId }: { contactId: string; leadId?: string }) => {
    if (isLinkingContact || !callerInfo.call.callSid) {
      return;
    }

    linkCallToContact(
      {
        requestBody: {
          callSid: callerInfo.call.callSid,
          direction: callerInfo.call.direction,
          callNumber: callerInfo.call.callerNumber,
          leadId,
        },
        contactId,
      },
      {
        onSuccess: () => {
          openSnackBar(t('communication:call.activeCall.linkContactSuccess'), 'success');
          onPageChange('overview');
        },
        onError: (error) => openErrorSnackBar(t('communication:call.activeCall.linkContactError'), error),
      },
    );
  };

  const handleCreateNewContact = () => {
    if (isLinkingContact && !callerInfo.call.shopId) {
      return;
    }
    setActiveShopId(callerInfo.call.shopId!);
    setCreateContactDrawerOpen(true);
  };

  return (
    <>
      <CallFrame
        onClose={onClose}
        title={headerTitle}
        headerColor={headerColor}
        variant="light"
        isLoading={isInitialLoading}
        sx={isCreateContactDrawerOpen ? { backgroundColor: theme.palette.backgroundExtension.grey1 } : undefined}
      >
        {isCreateContactDrawerOpen ? (
          <CompleteContactCreationPlaceholder />
        ) : (
          <>
            <CallSubpageHeader onPageChange={onPageChange} callDuration={callDuration} />
            <Typography variant="h2" sx={{ marginTop: 4, marginBottom: 2, textAlign: 'center' }}>
              {t('communication:call.activeCall.linkContactTitle')}
            </Typography>

            <SearchTextField
              fullWidth={true}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                onSearchTextChanged(event.target.value);
              }}
              size="medium"
              value={rawSearchText}
              placeholder={t('communication:call.activeCall.searchForAContact')}
            />
            <Box sx={{ overflow: 'auto', flex: 'auto', backgroundColor: theme.palette.backgroundExtension.grey1 }}>
              {rawSearchText.length > 0 && (
                <CreateContactCard onClick={handleCreateNewContact} disabled={!shop || !canCreateContact(shop)} />
              )}
              {contacts?.map((contact) => (
                <LinkingContactCard key={contact.id} contact={contact} onLink={handleLinkContactAndLead} />
              ))}
            </Box>
          </>
        )}
      </CallFrame>
      <EVDrawer
        zIndex={DRAWER_Z_INDEX + 1} // Make sure this drawer is always on top of other drawers
        isOpen={isCreateContactDrawerOpen}
        onClose={() => setCreateContactDrawerOpen(false)}
      >
        <CreateNewLead
          onSuccess={handleLinkContactAndLead}
          onClose={() => setCreateContactDrawerOpen(false)}
          redirectToLeadOnSuccess={false}
          defaultContactValues={{ phoneNumbers: [{ number: callerInfo.call.callerNumber, preferred: true }] }}
        />
      </EVDrawer>
    </>
  );
}

export function CompleteContactCreationPlaceholder() {
  const { t } = useTranslation(['communication']);
  return (
    <Typography variant="h2" color={theme.palette.text.disabled} sx={{ margin: 'auto', textAlign: 'center' }}>
      {t('communication:call.activeCall.completeContactCreationPlaceholder')}
    </Typography>
  );
}

export function CreateContactCard({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
  const { t } = useTranslation(['communication']);

  return (
    <SearchResultCard
      onClick={onClick}
      disabled={disabled}
      avatar={<PlusIcon color={theme.palette.shade.grey3} />}
      title={<Typography variant="body3">{t('communication:call.activeCall.createNewContact')}</Typography>}
    />
  );
}

export function LinkingContactCard({
  contact,
  onLink,
}: {
  contact: Contact;
  onLink: (data: { contactId: string; leadId?: string }) => void;
}) {
  const { toContactDetails } = useDestination();
  return (
    <SearchResultCard
      onClick={() => onLink({ contactId: contact.id })}
      avatar={<EVAvatar size="s" person={contact} />}
      title={
        <Stack direction="row" alignItems="center">
          <Typography variant="body3" component="span" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {fullName(contact)}
          </Typography>
          <Link
            to={toContactDetails({ shopId: contact.shopId, contactId: contact.id })}
            onClick={(event) => event.stopPropagation()}
            target="_blank"
          >
            <IconButton color="secondary">
              <LinkIcon />
            </IconButton>
          </Link>
        </Stack>
      }
    >
      <Typography
        sx={{ gridColumn: 2, textOverflow: 'ellipsis', overflow: 'hidden' }}
        variant="body4"
        color="text.secondary"
      >
        {emailAndPhoneNumberSummary(contact)}
      </Typography>
    </SearchResultCard>
  );
}
