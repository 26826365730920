import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

const contactTagsQuery = graphql(/* GraphQL */ `
  query ContactTags($contactId: String!) {
    dbContactTag(where: { contactId: { _eq: $contactId } }) {
      tag {
        name
      }
    }
  }
`);

export const useContactTags = (variables: VariablesOf<typeof contactTagsQuery>) => {
  const { data: contactTags, ...rest } = useQuery({
    queryKey: ['contact', 'useContactTags', variables],
    queryFn: async () => (await graphqlClient.request(contactTagsQuery, variables)).dbContactTag,
  });
  return { contactTags, ...rest };
};

export const contactAllTagsQuery = graphql(/* GraphQL */ `
  query ContactAllTags($limit: Int, $offset: Int, $order_by: [DbContactTagOrderBy!], $where: DbContactTagBoolExp) {
    dbContactTag(limit: $limit, offset: $offset, orderBy: $order_by, where: $where, distinctOn: tagId) {
      ...ContactTag
    }
  }
`);

export const useContactAllTags = (
  variables: VariablesOf<typeof contactAllTagsQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'staleTime' | 'keepPreviousData'>,
) => {
  const { data: contactAllTags, ...rest } = useQuery({
    queryKey: ['contact', 'useContactAllTags', variables],
    queryFn: async () => {
      const tags = (await graphqlClient.request(contactAllTagsQuery, variables)).dbContactTag.map((tag) => tag.tag);
      return tags;
    },
    ...options,
  });
  return { contactAllTags, ...rest };
};
