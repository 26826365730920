import IconButton from '@mui/material/IconButton';
import { InternalPreviewBulkEmailStepProps } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailStep';
import { PreviewBulkEmailTemplateForm } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailTemplateForm';
import { RecipientPagination } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/RecipientPagination';
import { SendBulkEmailDialogHeader } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogHeader';
import { EVModalContainer } from 'components/general/EVModal/ModalComponents/EVModalContainer';
import { EVModalFooter } from 'components/general/EVModal/ModalComponents/EVModalFooter';
import { EVModalHeader } from 'components/general/EVModal/ModalComponents/EVModalHeader';
import ArrowLeftIcon from 'components/icons/arrow_2_left.svg?react';
import TrashIcon from 'components/icons/trash.svg?react';
import { useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { PreviewBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function PreviewBulkEmailStepMobile({
  possibleFromEmails,
  onSend,
  onBackToCompose,
  noEmailIntegration,
  selectedRecipient,
  onSelectRecipient,
  onRemoveRecipient,
  from,
  onChangeFrom,
  hidePrice,
  isSending,
}: InternalPreviewBulkEmailStepProps) {
  const { t } = useTranslation(['communication']);
  const { watch } = useFormContext<PreviewBulkEmailFormData>();

  return (
    <>
      <EVModalHeader
        title={t('communication:sendBulkEmailDialog.titlePreviewMobile')}
        onClose={onBackToCompose}
        onConfirm={onSend}
        mobileBreakPoint={theme.breakpoints.down('desktop')}
        confirmButtonCustomization={{
          label: t('communication:sendBulkEmailDialog.sendButtonShort', { number: watch('recipients').length }),
          disabled: noEmailIntegration || isSending,
          sx: { whitespace: 'nowrap' },
        }}
        closeButtonCustomization={{
          icon: <ArrowLeftIcon />,
          disabled: isSending,
        }}
      />

      <SendBulkEmailDialogHeader
        from={from}
        onChangeFrom={onChangeFrom}
        noEmailIntegration={noEmailIntegration}
        possibleFromEmails={possibleFromEmails}
      />

      <EVModalContainer sx={{ opacity: noEmailIntegration ? 0.2 : undefined }}>
        <PreviewBulkEmailTemplateForm
          selectedRecipient={selectedRecipient}
          disabled={noEmailIntegration}
          showFullRecipient
          hidePrice={hidePrice}
        />
      </EVModalContainer>
      <EVModalFooter>
        {selectedRecipient && (
          <IconButton
            sx={{ marginRight: 'auto' }}
            disabled={noEmailIntegration || isSending}
            onClick={() => onRemoveRecipient(selectedRecipient)}
          >
            <TrashIcon />
          </IconButton>
        )}
        <RecipientPagination
          disabled={noEmailIntegration}
          recipients={watch('recipients').map((r) => r.recipient)}
          selectedRecipient={selectedRecipient}
          onSelectRecipient={onSelectRecipient}
        />
      </EVModalFooter>
    </>
  );
}
