import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  ListSubheader,
  Select,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import { SxProps } from '@mui/material/styles';
import { OptionItem } from 'components/general/OptionItem/OptionItem';
import { Option } from 'components/general/OptionsList/OptionsList';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import ClearIcon from 'components/icons/cross_naked.svg?react';
import { nanoid } from 'nanoid';
import React, { ChangeEvent, ReactNode, useRef } from 'react';

export type DropDownProps = {
  value: string | string[] | undefined;
  onChange: (value: string | string[]) => void;
  onBlur?: () => void;
  onClickOption?: (value: string) => void;
  label?: ReactNode;
  options: Option[];
  required?: boolean;
  id?: string;
  disabled?: boolean;
  multiple?: boolean;
  clearable?: boolean;
  hasError?: boolean;
  showUndefinedOption?: boolean;
  errorMessage?: string;
  renderValue?: (value: string | string[]) => ReactNode;
  disablePortal?: boolean;
  sx?: SxProps;
  autoFocus?: boolean;
  /** Using this should be avoided, this might break accessibility **/
  search?: {
    onSearchStringChange: (newSearchString: string) => void;
    placeholder?: string;
    searchString: string;
    loading?: boolean;
  };
  'data-testid'?: string;
};

function getInputValue(value: string | string[], options: Option[]) {
  const optionsByValue = options.reduce((agg, options) => {
    agg[options.value] = options.label;
    return agg;
  }, {} as Record<string, ReactNode>);
  if (!Array.isArray(value)) {
    return value ? optionsByValue[value] : '';
  }
  if (!value.length) {
    return '';
  }
  const labels = value.map((v) => optionsByValue[v]);
  return labels.every((label) => typeof label === 'string') ? labels.join(', ') : labels;
}

export const DropDown = React.forwardRef<unknown, DropDownProps>(function DropDown(
  {
    id,
    hasError,
    disabled,
    clearable,
    required,
    label,
    errorMessage,
    search,
    value,
    multiple,
    showUndefinedOption,
    onChange,
    onBlur,
    onClickOption,
    options,
    sx,
    renderValue,
    disablePortal,
    autoFocus,
    'data-testid': dataTestId,
  },
  ref,
) {
  const labelId = useRef(nanoid());
  const selectedOptionList = Array.isArray(value) ? value : value !== undefined ? [value] : [];
  const onSearchStringChange = (eventArgs: ChangeEvent<HTMLInputElement>) => {
    search?.onSearchStringChange?.(eventArgs.currentTarget.value);
  };

  // This makes sure we display an empty select field instead of ``.
  // MUI renders everything as it is except the value ``.
  if (value === 'UNDEFINED' && !showUndefinedOption) {
    value = '';
  }

  return (
    <FormControl fullWidth sx={sx} disabled={disabled} error={hasError}>
      {label && (
        <InputLabel id={labelId.current} required={required}>
          {label}
        </InputLabel>
      )}
      <Select
        inputRef={ref}
        labelId={labelId.current}
        id={id}
        value={multiple ? selectedOptionList : value || ''}
        multiple={multiple}
        label={label}
        required={required}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        onClose={() => search?.onSearchStringChange('')}
        renderValue={(v) => (renderValue ? renderValue(v) : getInputValue(v, options))}
        MenuProps={{
          autoFocus,
          disablePortal: disablePortal,
          PaperProps: {
            sx: { maxHeight: '50vh' },
          },
        }}
        endAdornment={
          value && clearable ? (
            <InputAdornment position="end" sx={{ marginRight: 3 }}>
              <IconButton
                onClick={() => {
                  onChange('');
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        data-testid={dataTestId}
      >
        {search ? (
          <ListSubheader sx={{ padding: 0 }} tabIndex={-1}>
            <Box sx={{ padding: 2, paddingBottom: 1 }}>
              <SearchTextField
                role="combox"
                forceFocus={true}
                aria-autocomplete="list"
                onClick={(event) => event.stopPropagation()}
                fullWidth={true}
                onChange={onSearchStringChange}
                size="small"
                value={search.searchString}
                placeholder={search.placeholder}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </Box>
            <Divider />
            {search.loading && <LinearProgress />}
          </ListSubheader>
        ) : null}

        {options.map(({ label: optionLabel, disabled, value: optionValue }, index) => {
          const selected = !!selectedOptionList?.includes(optionValue);

          return (
            <OptionItem
              tabIndex={!search ? undefined : index === 0 ? 0 : -1}
              key={optionValue}
              selected={selected}
              value={optionValue}
              disabled={disabled}
              multiple={multiple}
              label={optionLabel}
              onClick={() => onClickOption?.(optionValue)}
            />
          );
        })}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
});
