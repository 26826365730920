import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const teamsWithMembersQuery = graphql(/* GraphQL */ `
  query teamsWithMembers($where: DbTeamBoolExp!, $limit: Int, $offset: Int, $orderBy: [DbTeamOrderBy!]) {
    dbTeam(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
      ...TeamWithMembersAndShop
    }
    dbTeamAggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
  }
`);

export const useTeamsWithMembers = (
  variables: VariablesOf<typeof teamsWithMembersQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['team', 'useTeamsWithMembersQuery', variables],
    queryFn: () => graphqlClient.request(teamsWithMembersQuery, variables),
    ...options,
  });
  return {
    teams: data?.dbTeam,
    totalTeamsCount: data?.dbTeamAggregate.aggregate?.totalCount,
    ...rest,
  };
};

export const teamInfoQuery = graphql(/* GraphQL */ `
  query teamInfo($where: DbTeamBoolExp!, $orderBy: [DbTeamOrderBy!]) {
    dbTeam(where: $where, orderBy: $orderBy) {
      ...TeamInfo
    }
  }
`);

export const useTeamInfo = (
  variables: VariablesOf<typeof teamInfoQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError' | 'keepPreviousData' | 'staleTime'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['team', 'useTeamInfo', variables],
    queryFn: () => graphqlClient.request(teamInfoQuery, variables),
    ...options,
  });
  return {
    teams: data?.dbTeam,
    ...rest,
  };
};
