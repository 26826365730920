import { PopoverFrame } from 'components/general/PopoverFrame/PopoverFrame';
import { usePopover } from 'components/state/UIStateProvider';
import { HTMLAttributes, useRef } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useTrackUserInteractionWithoutPageContext } from 'util/snowplow/useSnowplowTracking';
import { userEvent } from 'util/snowplow/userEvent';

export const CHATBOT_POPOVER_ID = 'chatbot';
const EV_CHATBOT_ID = 'cm6f19wsk002odiemie1ji2pp';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['zapier-interfaces-chatbot-embed']: HTMLAttributes<{ 'is-popup': boolean; 'chatbot-id': string }>;
    }
  }
}

export function Chatbot() {
  const { t } = useTranslation(['user']);
  const trackUserEvent = useTrackUserInteractionWithoutPageContext();
  const { isOpen, setOpen } = usePopover(CHATBOT_POPOVER_ID);
  const hasBeenOpened = useRef(false);

  // If the chatbot has been opened once it is always rendered and hidden if it is closed
  // This makes sure that conversation is not lost and makes opening the chatbot faster.
  if (!isOpen && !hasBeenOpened.current) {
    return null;
  }
  hasBeenOpened.current = true;

  const onClose = () => {
    setOpen(false);
    trackUserEvent(userEvent.chatbot.close);
  };

  return (
    <PopoverFrame
      sxContainer={isOpen ? undefined : { display: 'none' }}
      sx={{
        padding: 0,
        backgroundColor: theme.palette.background.paper,
      }}
      height={600}
      popoverId={CHATBOT_POPOVER_ID}
      title={t('user:chatbotTitle')}
      onMinimize={() => trackUserEvent(userEvent.chatbot.minimize)}
      onMaximize={() => trackUserEvent(userEvent.chatbot.maximize)}
      onClose={onClose}
    >
      <zapier-interfaces-chatbot-embed
        chatbot-id={EV_CHATBOT_ID}
        is-popup={false}
        style={{ flex: 'auto', overflow: 'hidden', height: 0 }}
      />
    </PopoverFrame>
  );
}
