import { Box, Dialog, SxProps, useMediaQuery } from '@mui/material';
import { ModalContextProvider } from 'components/general/EVModal/ModalComponents/EVModalContext';
import { CALL_SCREEN_WIDTH, MAIL_SCREEN_WIDTH } from 'components/general/PopoverFrame/PopoverFrame';
import { HEADER_HEIGHT, PopoverHeader } from 'components/general/PopoverFrame/PopoverHeader';
import { usePopover } from 'components/state/UIStateProvider';
import { CSSProperties, ReactNode } from 'react';
import { theme } from 'theme';

export type ModalSize = 'standard' | 'oversized';

export const EVModalBase = ({
  onClose,
  canBeMinimizedWithId,
  minimizedTitle,
  children,
  isInvalid,
  size = 'standard',
  sx,
  sxPaper,
  sxPaperFullWidth,
}: {
  onClose: () => void;
  canBeMinimizedWithId?: string;
  minimizedTitle?: string;
  isInvalid?: boolean;
  children: ReactNode;
  //If you need a smaller size, use EVDialog
  size?: 'standard' | 'oversized';
  sx?: SxProps;
  sxPaper?: CSSProperties;
  sxPaperFullWidth?: CSSProperties;
}) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('desktop'));

  const desktopStandardWidth = '672px';
  const desktopOversizedWidth = '916px';

  const desktopWidth = size === 'oversized' ? desktopOversizedWidth : desktopStandardWidth;
  const desktopHeight = 'min(770px, 90vh)';

  const hasMinimized = !isMobileOrTablet && !!canBeMinimizedWithId;

  const { isMinimized, setIsMinimized, minimizedOrder, isEmailPopoverMaximized } = usePopover(
    canBeMinimizedWithId ?? '',
  );
  const resultWidth = isEmailPopoverMaximized ? MAIL_SCREEN_WIDTH : CALL_SCREEN_WIDTH;
  const displayModal = hasMinimized ? !isMinimized : true;

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        fullScreen={true}
        transitionDuration={{ exit: 0 }}
        sx={{
          display: displayModal ? 'block' : 'none',
          '.MuiDialog-paper': {
            px: 0,
            py: 0,
            flexDirection: 'column',
            ...sxPaper,
          },
          '.MuiDialog-paperFullWidth': {
            width: isMobileOrTablet ? '100%' : desktopWidth,
            maxWidth: '1400px',
            maxHeight: isMobileOrTablet ? '100%' : desktopHeight,
            ...sxPaperFullWidth,
          },
          zIndex: theme.zIndex.modal,
          ...sx,
        }}
      >
        <ModalContextProvider isInvalid={isInvalid}>{children}</ModalContextProvider>
      </Dialog>
      {isMinimized && (
        <Box
          sx={{
            width: resultWidth,
            position: 'fixed',
            bottom: minimizedOrder * HEADER_HEIGHT,
            right: 0,
          }}
        >
          <PopoverHeader
            title={minimizedTitle ?? ''}
            isMinimized={isMinimized}
            setIsMinimized={setIsMinimized}
            onClose={onClose}
          />
        </Box>
      )}
    </>
  );
};
