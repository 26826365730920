import { Stack, Typography } from '@mui/material';
import { KycStatusInfo } from 'api/graphql/generated/graphql';
import { KycIncompleteCard } from 'components/contact/LeadAndContactPicker/KycStatusCheck/KycIncompleteCard';
import { LeadAndContactSearchOption } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { useMemo } from 'react';
import { useTranslation } from 'util/i18next';

export function KycIncompleteDrawer({
  onClose,
  kycStatusData,
  selectedContactsAndLeads,
}: {
  onClose: () => void;
  kycStatusData: KycStatusInfo[];
  selectedContactsAndLeads: LeadAndContactSearchOption[];
}) {
  const { t } = useTranslation(['user']);

  const kycIncompleteContacts = useMemo(() => {
    function isKycStatusIncomplete(contactId: string) {
      return kycStatusData.some((item) => item.id === contactId && item.kycStatus === 'INCOMPLETE');
    }

    return selectedContactsAndLeads
      .map((item) => {
        if (item.type === 'LEAD') {
          const contactId = item.contact.id;
          if (isKycStatusIncomplete(contactId)) {
            return item.contact;
          }
        } else {
          const contactId = item.id;
          if (isKycStatusIncomplete(contactId)) {
            return item;
          }
        }
      })
      .filter(Boolean);
  }, [kycStatusData, selectedContactsAndLeads]);

  return (
    <EVDrawer isOpen={true} onClose={onClose}>
      <EVDrawerContent onClose={onClose} isLoading={false} title={t('user:activity.kyc.drawer.title')}>
        <Stack gap={2} padding={2}>
          <Typography variant={'body3'}>{t('user:activity.kyc.drawer.text')}</Typography>
          {kycIncompleteContacts.map((contact) => (
            <KycIncompleteCard key={contact?.id} contact={contact!} onClose={onClose} />
          ))}
        </Stack>
      </EVDrawerContent>
    </EVDrawer>
  );
}
