import { Option } from 'components/general/OptionsList/OptionsList';
import { TFunction } from 'util/i18next';
import { stringFormatToUpperCaseStart } from 'util/stringUtils';

export function enumToOptionsWithUndefinedAs(undefinedLabel: string) {
  // TODO i18n: description is not translated (and even CANNOT be translated)
  return function ({ name, description }: { name: string; description?: string | null }): Option {
    return {
      value: name,
      label: name === 'UNDEFINED' ? undefinedLabel : description || stringFormatToUpperCaseStart(name),
    };
  };
}

/**
 * @deprecated use enumToLokalisedOptions instead
 */
export function enumToOptions(enumEntry: { name: string; description?: string | null }): Option {
  return enumToOptionsWithUndefinedAs('')(enumEntry);
}

export const enumToLokalisedOptions = <TKeys>(
  t: TFunction<['enums']>,
  translationKeyMapping: Record<string, TKeys>,
): (({ name }: { name: string }) => Option) => {
  return ({ name: enumValue }: { name: string }) => {
    return enumValueToLokalisedOptions(t, translationKeyMapping)(enumValue);
  };
};

export const enumToLokalisedFilterOptions = <TKeys>(
  t: TFunction<['enums']>,
  translationKeyMapping: Record<string, TKeys>,
): (({ name }: { name: string }) => Option) => {
  return ({ name: enumValue }: { name: string }) => {
    const option = enumValueToLokalisedOptions(t, translationKeyMapping)(enumValue);
    return {
      ...option,
      labelText: option.label as string,
    };
  };
};

export const enumValueToLokalisedOptions = <TKeys, T extends (k: TKeys) => string>(
  t: T,
  translationKeyMapping: Record<string, TKeys>,
) => {
  return (enumValue: string): Option => {
    const labelValue = translationKeyMapping[enumValue];

    return {
      value: enumValue,
      label: enumValue === 'UNDEFINED' ? '' : labelValue ? t(labelValue) || enumValue : enumValue,
    };
  };
};

export const convertToLokalizedOption = <T extends string>(key: T, translateFunction: (label: T) => string) => {
  const label = translateFunction(key);
  return { label, labelText: label, value: key };
};
