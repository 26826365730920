import { getKeycloakToken } from '@ev/eva-container-api';
import { env } from 'env';
import { GraphQLClient } from 'graphql-request';
import sentry from 'util/sentry';

type RequestInit = ConstructorParameters<typeof GraphQLClient>[1];

const graphqlClientConfig: RequestInit = {
  requestMiddleware: async (request) => {
    const accessToken = await getKeycloakToken();

    return {
      ...request,
      headers: { ...request.headers, Authorization: accessToken ? `Bearer ${accessToken}` : '' },
    };
  },
  responseMiddleware: (response) => {
    if (response instanceof Error) {
      sentry.logError(response);
    }
  },
};

export const graphqlClient = new GraphQLClient(env.VITE_HASURA_URL, graphqlClientConfig);

if (typeof window !== 'undefined' && ['local', 'feature', 'dev', 'stage'].includes(env.VITE_LEADHUB_ENV)) {
  // expose graphqlClient for E2E tests
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).graphqlClient = graphqlClient;
}

export const secondaryGraphqlClient = new GraphQLClient(
  env.VITE_SECONDARY_HASURA_URL || env.VITE_HASURA_URL,
  graphqlClientConfig,
);
