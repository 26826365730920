import { useSnackBars } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { Contact } from 'api/graphql/generated/graphql';
import { useUpdateContact } from 'api/rest/hooks/useContacts';
import { ContactDetailsForm } from 'components/contact/ContactDetailsForm/ContactDetailsForm';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useConfirmation } from 'components/state/ConfirmationContext';
import { useState } from 'react';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useIsContactQualified } from 'util/contactUtils';
import { editContactDefaultValues } from 'util/defaultValues/editContactDefaultValues';
import { logSentryEntryIfAddressIsTruncated } from 'util/errorUtils';
import { useTranslation } from 'util/i18next';
import { toUpdateContactRequest } from 'util/mappers/updateContactRequestMapper';
import { useContactDetailsSchema } from 'util/schemas/contactDetailsSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { z } from 'zod';

export type ContactEditSection =
  | 'comment-tag-form'
  | 'contact-form'
  | 'relationship-form'
  | 'intent-type-form'
  | 'household-information-form'
  | 'kyc-information-and-personal-data-form';

const EditContact = ({
  contact,
  onClose,
  initialOpenSection,
  showKycAlert,
}: {
  contact: Contact;
  onClose(): void;
  initialOpenSection?: ContactEditSection;
  showKycAlert?: boolean;
}) => {
  const { t } = useTranslation(['contact', 'errors']);
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const { updateContact, isLoading } = useUpdateContact();
  const { isContactQualified } = useIsContactQualified();
  const { activeShop, shopCurrency } = useActiveShop();
  const [activeSection, setActiveSection] = useState<ContactEditSection | undefined>(
    initialOpenSection || 'contact-form',
  );

  const initialContactFormData = editContactDefaultValues(
    contact,
    isContactQualified({
      firstName: contact.firstName,
      lastName: contact.lastName,
      emailAddresses: contact.emailAddresses,
      phoneNumbers: contact.phoneNumbers,
    }),
    shopCurrency,
    activeShop.countryCode,
  );
  const contactDetailsSchema = useContactDetailsSchema();
  const methods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(contactDetailsSchema),
    defaultValues: initialContactFormData,
  });

  const watchedContactQualified = methods.watch('_internals.contact.contactQualified');

  useConfirmation({
    title: t('contact:editContact'),
    active: methods.formState.isDirty,
  });

  const handleUpdateContact = (editedContactFormData: z.infer<typeof contactDetailsSchema>): void => {
    const updateContactRequest = toUpdateContactRequest(
      initialContactFormData,
      editedContactFormData,
      contact.id,
      activeShop.id,
    );
    logSentryEntryIfAddressIsTruncated('addressSelect', editedContactFormData.addresses);
    updateContact(
      { requestBody: updateContactRequest, contactId: contact.id },
      {
        onSuccess: () => {
          onClose();
          openSnackBar(t('contact:updateContact.updated.success'), 'success');
        },
        onError: (error) => openErrorSnackBar(t('contact:updateContact.updated.error'), error),
      },
    );
  };

  const handleValidationError: SubmitErrorHandler<z.infer<typeof contactDetailsSchema>> = (errors) => {
    setActiveSection(errors ? 'contact-form' : 'comment-tag-form');
  };

  return (
    <EVDrawerContent
      title={t('contact:editContact')}
      primaryAction={{
        primaryButtonLabel: t('save'),
        disabled: !watchedContactQualified,
        callback: methods.handleSubmit(handleUpdateContact, handleValidationError),
      }}
      isLoading={isLoading}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <form>
          <ContactDetailsForm activeSection={activeSection} showKycAlert={showKycAlert} />
        </form>
      </FormProvider>
    </EVDrawerContent>
  );
};

export default EditContact;
