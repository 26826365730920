import { Box, Stack } from '@mui/material';
import { EVModalContainer } from 'components/general/EVModal/ModalComponents/EVModalContainer';
import { ReactNode } from 'react';
import { theme } from 'theme';

export const HEADER_HEIGHT = 48;
export const LEFT_COLUMN_WIDTH = 320;
export const SEARCH_FIELD_WIDTH = LEFT_COLUMN_WIDTH - 24;

export function SendBulkEmailDesktopLayout({
  left,
  right,
  noEmailIntegration,
}: {
  noEmailIntegration: boolean;
  left: ReactNode;
  right: ReactNode;
}) {
  return (
    <EVModalContainer sx={{ opacity: noEmailIntegration ? 0.2 : undefined, padding: 0 }}>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 3,
          width: '100%',
          height: '100%',
        }}
      >
        <Stack sx={{ width: LEFT_COLUMN_WIDTH, padding: theme.spacing(3, 0, 0, 3), height: '100%', flex: 'none' }}>
          {left}
        </Stack>
        <Box sx={{ overflow: 'auto', padding: theme.spacing(3, 3, 3, 0), flex: 1 }}>{right}</Box>
      </Stack>
    </EVModalContainer>
  );
}
