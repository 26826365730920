import { Alert, AlertColor, AlertTitle, Box, Link, SxProps, Typography } from '@mui/material';
import IconSuccess from 'components/icons/check_circle.svg?react';
import IconError from 'components/icons/error.svg?react';
import IconInfo from 'components/icons/info.svg?react';
import IconWarning from 'components/icons/warning.svg?react';
import { ReactNode } from 'react';
import { theme } from 'theme';

type EVAlertProps = {
  severity: AlertColor;
  children: ReactNode;
  onClose?: () => void;
  onButtonClick?: () => void;
  buttonText?: string;
  customButton?: ReactNode;
  title?: string;
  fullWidth?: boolean;
  sx?: SxProps;
  testId?: string;
  icon?: ReactNode;
};

export const alertSx = {
  border: '1px solid',
  borderColor: `rgb(from ${theme.palette.shade.grey6} r g b / 10%)`,
  fontSize: theme.typography.body3.fontSize,
  color: theme.palette.text.primary,
};

export const getAlertIcon = (severity: AlertColor) => {
  switch (severity) {
    case 'warning':
      return <IconWarning />;
    case 'error':
      return <IconError />;
    case 'success':
      return <IconSuccess />;
    case 'info':
      return <IconInfo />;
  }
};

export const getAlertColor = (severity: AlertColor) => {
  switch (severity) {
    case 'warning':
      return theme.palette.warning.light;
    case 'error':
      return theme.palette.error.light;
    case 'success':
      return theme.palette.success.light;
    case 'info':
      return theme.palette.information.light;
  }
};

export function EVAlert({
  severity,
  children,
  onClose,
  onButtonClick,
  buttonText,
  customButton,
  title,
  fullWidth,
  sx,
  testId,
  icon,
}: EVAlertProps) {
  return (
    <Alert
      data-testid={testId}
      icon={icon ?? getAlertIcon(severity)}
      severity={severity}
      sx={{
        backgroundColor: getAlertColor(severity),
        width: fullWidth ? '100%' : 'initial',
        ...alertSx,
        ...sx,
      }}
      onClose={onClose}
      action={
        onButtonClick ? (
          <Link
            onClick={onButtonClick}
            type="button"
            component={'button'}
            variant="body3"
            sx={{ mr: 1, mt: 1.25 }}
            color={theme.palette.text.primary}
          >
            {buttonText}
          </Link>
        ) : customButton ? (
          <Box sx={{ mt: 1.25 }}>{customButton}</Box>
        ) : null
      }
    >
      {title && (
        <Typography variant="body3" fontWeight="bold" component={AlertTitle}>
          {title}
        </Typography>
      )}

      {children}
    </Alert>
  );
}
