import { useMediaQuery } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { PropsWithChildren } from 'react';
import { theme } from 'theme';

export function EVDialog({
  children,
  onClose,
  maxWidth = 'tablet',
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }) {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <Dialog
      onClose={onClose}
      {...props}
      maxWidth={maxWidth}
      fullWidth={true}
      PaperProps={{
        sx: {
          paddingY: 1.5,
          paddingX: isMobile ? 2 : 3,
        },
      }}
    >
      {children}
    </Dialog>
  );
}

export function DialogSubtitle({ children }: PropsWithChildren) {
  return (
    <Typography variant="body3" component="div" color={theme.palette.text.secondary} sx={{ marginTop: 4 }}>
      {children}
    </Typography>
  );
}
