import { SnackbarOptions } from '@ev/eva-container-api';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FetchError } from 'api/FetchError';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { AxiosError } from 'axios';
import { EVModalBase } from 'components/general/EVModal/ModalComponents/EVModalBase';
import CopyIcon from 'components/icons/document_duplicate.svg?react';
import { TFuncKey } from 'i18next';
import { ReactNode } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export type ErrorDetails = SnackbarOptions['errorDetailsInfo'];
type ErrorKey = `errors:${TFuncKey<'errors'>}`;

interface ErrorData {
  message?: string;
  errorCode?: string;
  errorDataMap: { [key: string]: string };
  detailMessage?: string;
}

type ErrorResponse = AxiosError<ErrorData>;

export function toErrorDetails(error?: unknown): ErrorDetails {
  const errorResponseData =
    error instanceof FetchError
      ? (error.response.body as ErrorData | undefined)
      : (error as { response?: ErrorResponse })?.response?.response?.data;
  const errorRequestData =
    error instanceof FetchError
      ? error.request
      : (error as { response?: ErrorResponse; request: { [key: string]: string } })?.request;
  const errorCode = errorResponseData?.errorCode;

  return {
    errorCode,
    responseData: errorResponseData as Record<string, unknown> | undefined,
    requestData: errorRequestData,
  };
}

export function useErrorMessage(errorInfo: ErrorDetails | undefined) {
  const errorCode = errorInfo?.errorCode;
  const { t, i18n } = useTranslation(['user', 'errors']);
  return errorCode && i18n.exists(`errors:${errorCode}`)
    ? t(`errors:${errorCode}` as ErrorKey, errorInfo?.responseData?.errorDataMap ?? {})
    : undefined;
}

export const ErrorInfoModal = ({
  errorInfo,
  message,
  onClose,
}: {
  errorInfo: ErrorDetails;
  message: string | ReactNode;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['user', 'errors']);
  const currentAgentId = useCurrentAgentId();

  const resultErrorInfo = {
    ...errorInfo,
    agentId: currentAgentId,
  };
  const specificErrorMessage = useErrorMessage(errorInfo);
  const errorMessage = message + (specificErrorMessage ? ` ${t('reason')}: ${specificErrorMessage}` : '');

  if (!errorInfo) {
    return null;
  }

  return (
    <EVModalBase onClose={onClose}>
      <Stack sx={{ paddingX: 4, paddingY: 2, height: '100%' }}>
        <Typography variant="body2" fontWeight="bold" sx={{ marginBottom: 2 }}>
          {errorMessage}
        </Typography>

        <Typography variant="body3" color={theme.palette.text.secondary}>
          {t('user:errorInfoDetails.info.serviceDesk')}
        </Typography>
        <Box
          sx={{
            marginY: 2,
            flex: 'auto',
            backgroundColor: theme.palette.shade.grey5,
            border: `1px solid ${theme.palette.shade.grey3}`,
            overflow: 'auto',
          }}
        >
          <Typography variant="body4" color={theme.palette.text.secondary} sx={{ margin: 1 }} component="pre">
            {JSON.stringify(resultErrorInfo, undefined, 2)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button sx={{ width: '96px', marginRight: 1 }} variant="outlined" color="secondary" onClick={onClose}>
            {t('user:errorInfoDetails.button.cancel')}
          </Button>
          <Button
            startIcon={<CopyIcon />}
            variant="contained"
            type="submit"
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(resultErrorInfo));
            }}
          >
            {t('user:errorInfoDetails.button.copy')}
          </Button>
        </Box>
      </Stack>
    </EVModalBase>
  );
};
