import { Button } from '@mui/material';
import { useTranslation } from 'util/i18next';

export type CancelButtonCustomization = {
  label?: string;
  disabled?: boolean;
};

export function CancelButton({
  onCancel,
  customization,
}: {
  onCancel: () => void;
  customization?: CancelButtonCustomization;
}) {
  const { t } = useTranslation([]);

  return (
    <Button onClick={onCancel} variant="outlined" color="secondary" disabled={customization?.disabled}>
      {customization?.label ?? t('cancel')}
    </Button>
  );
}
