import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

const getAllEnumsQuery = graphql(/* GraphQL */ `
  query GetAllEnums {
    enumsBaseActivityCategory {
      name
    }
    enumsActivityType {
      name
    }
    enumsAddressType {
      name
    }
    enumsContactTime {
      name
    }
    enumsCountry {
      name
    }
    enumsLanguage {
      name
    }
    enumsLeadSource {
      name
    }
    enumsLeadStatus {
      name
    }
    enumsPhoneNumberType {
      name
    }
    enumsEmailAddressType {
      name
    }
    enumsSalutation {
      name
    }
    enumsGender {
      name
    }
    enumsAssetType {
      name
    }
    enumsAssetSubType {
      name
    }
    enumsPriority {
      name
    }
    enumsIntentType {
      name
    }
    enumsAssetCondition {
      name
    }
    enumsReferralNotPossibleReason {
      name
    }
    enumsZeroCommissionReason {
      name
    }
    enumsEmailSignatureScope {
      name
    }
    enumsEmailTemplateScope {
      name
    }
    enumsUserRole {
      name
    }
    enumsTimeFrame {
      name
    }
    enumsLeadStatusReason {
      name
    }
    enumsShopExpertise {
      name
    }
    enumsIntentCategory {
      name
    }
    enumsContactRelationType {
      name
    }
    enumsCallStatus {
      name
    }
    enumsCallOutcome {
      name
    }
    enumsTaskAppointmentStatus {
      name
    }
    enumsTaskType {
      name
    }
    enumsAppointmentType {
      name
    }
    enumsSharedType {
      name
    }
    enumsPropertyOwnershipStatus {
      name
    }
    enumsPropertyOccupationStatus {
      name
    }
    enumsEntityType {
      name
    }
    enumsPrivacyLevel {
      name
    }
    enumsMandatoryShopProfileField {
      name
    }
    enumsConsentSource {
      name
    }
    enumsConsentType {
      name
    }
    enumsHouseholdType {
      name
    }
    enumsProfessionType {
      name
    }
    enumsSpokenLanguage {
      name
    }
  }
`);

export const useGetAllEnums = (
  variables: VariablesOf<typeof getAllEnumsQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) => {
  const { data: allEnums, ...rest } = useQuery({
    queryKey: ['enums', 'useGetAllEnums', variables],
    queryFn: async () => graphqlClient.request(getAllEnumsQuery, variables),
    refetchInterval: false,
    staleTime: 3_600_000,
    cacheTime: 3_600_000,
    ...options,
  });
  return { allEnums, ...rest };
};
