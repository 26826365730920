import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CountryEnum } from 'api/graphql/generated/graphql';
import { components, operations } from 'api/searchBff/generated-rest';
import { createSearchBFFToken, searchBffFetchClient } from 'api/searchBff/searchBffFetchClient';
import { MultiPolygon } from 'geojson';
import { useCallback } from 'react';
import { isSet } from 'util/filters';
import { useISO2LanguageCode } from 'util/i18next';
import { PlacesAddressSearchResult } from 'util/places/types';

function responseToDetails(
  response: components['schemas']['PlaceDetails'] | undefined,
): PlacesAddressSearchResult | null {
  if (!response) {
    return null;
  }
  const { addressComponents, location, boundingBox, types } = response;

  if (!addressComponents) {
    return null;
  }

  function findAddrComponent(type: components['schemas']['AddressTypeDto']) {
    return addressComponents?.find((addressComponent) => addressComponent.types.includes(type));
  }

  return {
    placeId: response.placeId,
    boundingBox,
    streetName: findAddrComponent('route')?.longName || '',
    streetNumber: findAddrComponent('street_number')?.longName,
    postalCode: findAddrComponent('postal_code')?.longName || '',
    locality: findAddrComponent('locality')?.longName || '',
    sublocality: findAddrComponent('sublocality')?.longName || '',
    city:
      findAddrComponent('locality')?.longName ||
      findAddrComponent('postal_town')?.longName ||
      findAddrComponent('sublocality')?.longName ||
      findAddrComponent('administrative_area_level_4')?.longName ||
      '',
    types,
    countryCode: findAddrComponent('country')?.shortName as CountryEnum,
    formattedAddress: response.formattedAddress,
    location:
      location ||
      (boundingBox && {
        lat: (boundingBox.northEast.lat + boundingBox.southWest.lat) / 2,
        lng: (boundingBox.northEast.lng + boundingBox.southWest.lng) / 2,
      }),
    polygon: response.polygon as MultiPolygon,
  };
}

export type PlaceDetailsOptions = Pick<operations['getPlaceDetailsV2']['parameters']['query'], 'placeId'> &
  Partial<operations['getPlaceDetailsV2']['parameters']['query']>;

export const usePlaceDetails = () => {
  const language = (useISO2LanguageCode() || 'en') as components['schemas']['Language'];

  return useCallback(
    async (input: PlaceDetailsOptions) => {
      if (!input.placeId) {
        return null;
      }

      return searchBffFetchClient
        .GET('/v1/autosuggestion/details', {
          params: {
            query: {
              language: language,
              sessionToken: createSearchBFFToken(),
              ...input,
            },
          },
        })
        .then(({ data }) => responseToDetails(data));
    },
    [language],
  );
};

export function useBFFPlaceDetails({
  onSuccess,
}: {
  onSuccess?: (address: PlacesAddressSearchResult | null) => void;
} = {}) {
  const placeDetails = usePlaceDetails();
  const {
    mutate: getDetails,
    mutateAsync: getDetailsAsync,
    data: details,
    ...rest
  } = useMutation(placeDetails, {
    onSuccess,
  });
  return { getDetails, getDetailsAsync, details, ...rest };
}

export function useBFFPlaceDetailsQuery(placeId?: string | null, options?: Pick<UseQueryOptions, 'enabled'>) {
  const placeDetails = usePlaceDetails();
  const { data: details, ...rest } = useQuery(
    ['searchBFF', 'useBFFPlaceDetailsQuery', { placeId }],
    async () => placeDetails({ placeId: placeId! }),
    {
      enabled: options?.enabled ?? !!placeId,
      staleTime: Infinity,
    },
  );
  return { details, ...rest };
}

export function useBFFPlacesDetailsQuery(placeIds?: string[], options?: Pick<UseQueryOptions, 'enabled'>) {
  const placeDetails = usePlaceDetails();
  const { data: details, ...rest } = useQuery(
    ['searchBFF', 'useBFFPlacesDetailsQuery', { placeIds }],
    async () => {
      const details = await Promise.all(placeIds!.map((placeId) => placeDetails({ placeId })));
      return details.filter(isSet);
    },
    {
      enabled: options?.enabled ?? !!placeIds?.length,
    },
  );
  return { details, ...rest };
}
