import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient, secondaryGraphqlClient } from 'api/graphql/graphql-client';
import { lastInteractionWhereFilter } from 'util/hasura/filters';

export const autoExposeShopSettingsQuery = graphql(/* GraphQL */ `
  query AutoExposeShopSettings($where: DbShopSettingBoolExp) {
    dbShopSetting(where: $where) {
      shop {
        qualiformMandatoryFields {
          fieldName
        }
        qualiformLeadIntents {
          intentType
        }
        qualiformLeadSources {
          leadSource
        }
        qualiformShopSettings {
          secondaryLanguageEnglishEnabled
        }
      }
    }
  }
`);

export const useAutoExposeShopSettings = (variables: VariablesOf<typeof autoExposeShopSettingsQuery>) => {
  const { data: shopSettings, ...rest } = useQuery({
    queryKey: ['shop', 'useAutoExposeShopSettings', variables],
    queryFn: async () =>
      (await graphqlClient.request(autoExposeShopSettingsQuery, variables)).dbShopSetting[0]?.shop ?? null,
  });
  return { shopSettings, ...rest };
};

export const autoLeadAssignmentShopSettingsQuery = graphql(/* GraphQL */ `
  query AutoLeadAssignmentShopSettings($where: DbShopSettingBoolExp) {
    dbShopSetting(where: $where) {
      shop {
        leadAutoAssignmentIntents {
          intentType
        }
        leadAutoAssignmentRules {
          leadAssignmentRule
        }
      }
    }
  }
`);

export const useAutoLeadAssignmentShopSettings = (
  variables: VariablesOf<typeof autoLeadAssignmentShopSettingsQuery>,
) => {
  const { data: shopSettings, ...rest } = useQuery({
    queryKey: ['shop', 'useAutoLeadAssignmentShopSettings', variables],
    queryFn: async () =>
      (await graphqlClient.request(autoLeadAssignmentShopSettingsQuery, variables)).dbShopSetting[0]?.shop,
  });
  return { shopSettings, ...rest };
};

export const consentManagementShopSettingsQuery = graphql(/* GraphQL */ `
  query ConsentManagementShopSettings($shopId: String!) {
    dbShopManagedConsent(where: { shopId: { _eq: $shopId } }) {
      consentType
    }
  }
`);

export const useConsentManagementShopSettings = (variables: VariablesOf<typeof consentManagementShopSettingsQuery>) => {
  const { data: consentManagementShopSettings, ...rest } = useQuery({
    queryKey: ['shop', 'useConsentManagementShopSettings', variables],
    queryFn: async () =>
      (await graphqlClient.request(consentManagementShopSettingsQuery, variables)).dbShopManagedConsent,
  });
  return { consentManagementShopSettings, ...rest };
};

export const newsletterListShopSettingsQuery = graphql(/* GraphQL */ `
  query NewsletterListShopSettings($shopId: String!) {
    dbNewsletterShopSetting(where: { shopId: { _eq: $shopId } }) {
      newsletterMailingLists {
        id
        listName
      }
    }
  }
`);

export const useNewsletterListShopSettings = (variables: VariablesOf<typeof consentManagementShopSettingsQuery>) => {
  const { data: shopSettings, ...rest } = useQuery({
    queryKey: ['shop', 'useNewsletterListShopSettings', variables],
    queryFn: async () =>
      (await graphqlClient.request(newsletterListShopSettingsQuery, variables)).dbNewsletterShopSetting,
  });
  return { shopSettings, ...rest };
};

const lastInteractionSettingsQuery = graphql(/* GraphQL */ `
  query LastInteractionSettings($shopId: String!) {
    dbShopSetting(where: { shopId: { _eq: $shopId } }) {
      leadExtendingInteractionsEnabled
    }
  }
`);

export const useLastInteractionSettings = (shopId?: string) => {
  const { data: isLastInteractionSettingsEnabled, ...rest } = useQuery({
    queryKey: ['shop', 'useLastInteractionSettings', shopId],
    queryFn: async () =>
      (await graphqlClient.request(lastInteractionSettingsQuery, { shopId: shopId || '' })).dbShopSetting?.[0]
        ?.leadExtendingInteractionsEnabled,
    enabled: !!shopId,
  });
  return { isLastInteractionSettingsEnabled, ...rest };
};

export const newsletterShopSettingsQuery = graphql(/* GraphQL */ `
  query NewsletterShopSettings($shopId: String!) {
    dbNewsletterShopSetting(where: { shopId: { _eq: $shopId } }) {
      clientId
      newsletterMailingLists {
        id
        listId
        listName
      }
      preferredNewsletterMailingList {
        id
        listId
        listName
      }
    }
  }
`);

export const useNewsletterShopSettings = (variables: VariablesOf<typeof newsletterShopSettingsQuery>) => {
  const { data: newsletterShopSettings, ...rest } = useQuery({
    queryKey: ['shop', 'useNewsletterShopSettings', variables],
    queryFn: async () => (await graphqlClient.request(newsletterShopSettingsQuery, variables)).dbNewsletterShopSetting,
  });
  return { newsletterShopSettings, ...rest };
};

const shopLastInteractionActivitiesQuery = graphql(/* GraphQL */ `
  query ShopLastInteractionActivities($shopId: String!) {
    dbLeadExtendingActivities(where: { shopId: { _eq: $shopId } }) {
      activityType
    }
    dbLeadExtendingTaskTypes(where: { shopId: { _eq: $shopId } }) {
      taskType
    }
    dbLeadExtendingAppointmentTypes(where: { shopId: { _eq: $shopId } }) {
      appointmentType
    }
  }
`);

export const useShopLastInteractionActivities = (shopId?: string) => {
  const { isLastInteractionSettingsEnabled, isLoading } = useLastInteractionSettings(shopId);

  const { data: activities, ...rest } = useQuery({
    queryKey: ['shop', 'useShopLastInteractionActivities', shopId],
    queryFn: async () =>
      await secondaryGraphqlClient.request(shopLastInteractionActivitiesQuery, { shopId: shopId || '' }),
    enabled: !isLoading && isLastInteractionSettingsEnabled && !!shopId,
  });
  const leadExtendingActivities = activities?.dbLeadExtendingActivities?.map(({ activityType }) => activityType);
  const leadExtendingTaskTypes = activities?.dbLeadExtendingTaskTypes?.map(({ taskType }) => taskType);
  const leadExtendingAppointmentTypes = activities?.dbLeadExtendingAppointmentTypes?.map(
    ({ appointmentType }) => appointmentType,
  );
  return {
    leadExtendingActivities,
    leadExtendingTaskTypes,
    leadExtendingAppointmentTypes,
    isLastInteractionSettingsEnabled,
    isLastInteractionSettingsFetched: !isLastInteractionSettingsEnabled || rest.isSuccess,
    lastInteractionWhere: lastInteractionWhereFilter({
      isLastInteractionSettingsEnabled,
      leadExtendingActivities,
      leadExtendingTaskTypes,
      leadExtendingAppointmentTypes,
    }),
    ...rest,
  };
};
