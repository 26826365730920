import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ContactAndLeads } from 'api/graphql/generated/graphql';
import { ContactItem, LeadItem } from 'components/contact/LeadAndContactPicker/LeadAndContactOptionItems';
import { LeadAndContactSearchOption } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { ContactsRemovedAlert } from 'components/emails/ContactsRemovedAlert/ContactsRemovedAlert';
import AlreadyProposedIcon from 'components/icons/already_proposed.svg?react';
import DirectoryIcon from 'components/icons/contact.svg?react';
import MinusIcon from 'components/icons/minus_circle.svg?react';
import WarningIcon from 'components/icons/warning.svg?react';
import React, { ReactNode } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export type Recipient = LeadAndContactSearchOption & {
  hasUnresolvedPlaceholder?: boolean;
  hasAlreadyProposedProperty?: boolean;
  showSubjectOverrideWarning?: boolean;
  errorMessage?: string;
};

export function RecipientsList({
  recipients,
  onRemoveRecipient,
  selectedRecipient,
  onSelectedRecipientChange,
  disabled,
  toContactsFlagged,
  toContactsWithoutEmail,
}: {
  recipients: Recipient[];
  selectedRecipient?: string;
  onSelectedRecipientChange?: (recipientId: string) => void;
  onRemoveRecipient: (recipientId: string) => void;
  disabled?: boolean;
  toContactsWithoutEmail?: ContactAndLeads[];
  toContactsFlagged?: ContactAndLeads[];
}) {
  const { t } = useTranslation(['communication']);

  if (!recipients.length) {
    return (
      <Stack alignItems="center" gap={2} sx={{ marginTop: '96px' }}>
        <DirectoryIcon style={{ color: theme.palette.shade.grey2 }} />
        <Typography variant="body3" sx={{ textAlign: 'center' }} color={theme.palette.shade.grey2}>
          {t('communication:sendBulkEmailDialog.noRecipients')}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={1} sx={{ overflow: 'auto', paddingY: 2 }}>
      <ContactsRemovedAlert toContactsFlagged={toContactsFlagged} toContactsWithoutEmail={toContactsWithoutEmail} />
      {recipients.map((recipient) => (
        <RecipientsListItem
          selected={recipient.id === selectedRecipient}
          key={recipient.id}
          recipient={recipient}
          disabled={disabled}
          onClick={onSelectedRecipientChange ? () => onSelectedRecipientChange(recipient.id) : undefined}
          onRemove={() => onRemoveRecipient(recipient.id)}
        />
      ))}
    </Stack>
  );
}

function RecipientsListItem({
  recipient,
  disabled,
  onRemove,
  onClick,
  selected,
}: {
  recipient: Recipient;
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => void;
  onRemove: () => void;
}) {
  const { t } = useTranslation(['communication']);

  const onSelect = !disabled ? onClick : undefined;
  const onRemoveClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRemove();
  };

  const warnings = (
    <>
      {recipient.hasAlreadyProposedProperty && (
        <Warning
          icon={<AlreadyProposedIcon />}
          color={theme.palette.information.main}
          message={t('communication:sendBulkEmailDialog.hasAlreadyProposedPropertiesShort')}
        />
      )}
      {recipient.hasUnresolvedPlaceholder && (
        <Warning
          icon={<WarningIcon />}
          color={theme.palette.error.main}
          message={t('communication:sendBulkEmailDialog.hasUnresolvedPlaceholders')}
        />
      )}
      {recipient.showSubjectOverrideWarning && (
        <Warning
          icon={<WarningIcon />}
          color={theme.palette.error.main}
          message={t('communication:sendBulkEmailDialog.subjectOverrideWarningShort')}
        />
      )}
      {recipient.errorMessage && <FormHelperText>{recipient.errorMessage}</FormHelperText>}
    </>
  );

  return (
    <Stack
      key={recipient.id}
      direction="row"
      alignItems="center"
      onClick={onSelect}
      sx={{
        paddingLeft: 2,
        paddingBottom: 1,
        backgroundColor: selected ? theme.palette.shade.grey4 : undefined,
        borderRight: selected ? `1px solid ${theme.palette.primary.main}` : undefined,
        cursor: onSelect ? 'pointer' : 'default',
      }}
    >
      {recipient.type === 'LEAD' ? (
        <>
          <ContactItem option={recipient.contact} sx={{ minWidth: 0, flex: 1 }} lead={recipient} type={recipient.type}>
            <LeadItem lead={recipient} />
            {warnings}
          </ContactItem>
        </>
      ) : (
        <ContactItem option={recipient} sx={{ minWidth: 0, flex: 1 }} type={recipient.type}>
          {warnings}
        </ContactItem>
      )}
      <IconButton onClick={onRemoveClick} disabled={disabled} color="secondary">
        <MinusIcon />
      </IconButton>
    </Stack>
  );
}

function Warning({ icon, message, color }: { icon: ReactNode; message: string; color: string }) {
  return (
    <Stack direction="row" gap={1} sx={{ color }} alignItems="center">
      <Box sx={{ flex: 0 }}>{icon}</Box>
      <Typography variant="body4">{message}</Typography>
    </Stack>
  );
}
