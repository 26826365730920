export const rentRangeTranslationsWithCurrency = {
  MONTHLY: {
    netFrom: 'lead:rentRangeWithCurrency.monthlyNetRentFrom',
    netTo: 'lead:rentRangeWithCurrency.monthlyNetRentTo',
    totalFrom: 'lead:rentRangeWithCurrency.monthlyTotalRentFrom',
    totalTo: 'lead:rentRangeWithCurrency.monthlyTotalRentTo',
    genericFrom: 'lead:rentRangeWithCurrency.monthlyRentFrom',
    genericTo: 'lead:rentRangeWithCurrency.monthlyRentTo',
  },
  YEARLY: {
    netFrom: 'lead:rentRangeWithCurrency.yearlyNetRentFrom',
    netTo: 'lead:rentRangeWithCurrency.yearlyNetRentTo',
    totalFrom: 'lead:rentRangeWithCurrency.yearlyTotalRentFrom',
    totalTo: 'lead:rentRangeWithCurrency.yearlyTotalRentTo',
    genericFrom: 'lead:rentRangeWithCurrency.yearlyRentFrom',
    genericTo: 'lead:rentRangeWithCurrency.yearlyRentTo',
  },
} as const;

export const rentRangeTranslations = {
  MONTHLY: {
    netFrom: 'lead:rentRange.monthlyNetRentFrom',
    netTo: 'lead:rentRange.monthlyNetRentTo',
    totalFrom: 'lead:rentRange.monthlyTotalRentFrom',
    totalTo: 'lead:rentRange.monthlyTotalRentTo',
    genericFrom: 'lead:rentRange.monthlyRentFrom',
    genericTo: 'lead:rentRange.monthlyRentTo',
  },
  YEARLY: {
    netFrom: 'lead:rentRange.yearlyNetRentFrom',
    netTo: 'lead:rentRange.yearlyNetRentTo',
    totalFrom: 'lead:rentRange.yearlyTotalRentFrom',
    totalTo: 'lead:rentRange.yearlyTotalRentTo',
    genericFrom: 'lead:rentRange.yearlyRentFrom',
    genericTo: 'lead:rentRange.yearlyRentTo',
  },
} as const;

export const rentTranslations = {
  MONTHLY: {
    net: 'lead:rent.monthlyNetRent',
    total: 'lead:rent.monthlyTotalRent',
    generic: 'lead:rent.monthlyRent',
  },
  YEARLY: {
    net: 'lead:rent.yearlyNetRent',
    total: 'lead:rent.yearlyTotalRent',
    generic: 'lead:rent.yearlyRent',
  },
} as const;
