import { Contact, ContactAddress, ContactAndLeads, ContactInfo } from 'api/graphql/generated/graphql';
import { useCallback } from 'react';
import { EmailDetailsFormData, useEmailSchema } from 'util/schemas/emailSchema';
import { PhoneDetailsFormData, usePhoneSchema } from 'util/schemas/phoneSchema';
import { useSchemaPrimitives } from 'util/schemas/schemaPrimitives';

export interface IsContactQualifiedArgs {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  emailAddresses: EmailDetailsFormData[] | undefined;
  phoneNumbers: PhoneDetailsFormData[] | undefined;
}

export function useIsContactQualified() {
  const { validEmail } = useEmailSchema();
  const { phoneNumber } = usePhoneSchema();
  const { requiredString } = useSchemaPrimitives();

  const isEmailValid = useCallback(
    (email: string | null | undefined) => {
      return validEmail.safeParse(email).success;
    },
    [validEmail],
  );

  const isPhoneNumberValid = useCallback(
    (number: string | null | undefined) => {
      return phoneNumber.safeParse(number).success;
    },
    [phoneNumber],
  );

  const isContactQualified = useCallback(
    ({ firstName, lastName, emailAddresses, phoneNumbers }: IsContactQualifiedArgs) => {
      return (
        (requiredString.safeParse(firstName).success || requiredString.safeParse(lastName).success) &&
        (isEmailValid(emailAddresses?.[0]?.email) || isPhoneNumberValid(phoneNumbers?.[0]?.number))
      );
    },
    [isEmailValid, isPhoneNumberValid, requiredString],
  );

  return { isEmailValid, isPhoneNumberValid, isContactQualified };
}

export const getContactLastActivity = (contact: ContactAndLeads) => {
  const newDate: Date | null = contact.leads.reduce((lastDate, lead) => {
    const compDate = lead.lastActivity?.[0]?.createdAt;
    return !lastDate || new Date(lastDate) < new Date(compDate) ? compDate : lastDate;
  }, null);
  return newDate;
};

export function getPreferredEmail(contact?: ContactInfo) {
  if (!contact) {
    return undefined;
  }
  return contact.emailAddresses.find((e) => e.preferred)?.address || contact.emailAddresses[0]?.address;
}
export function getPreferredPhoneNumber(contact?: ContactInfo) {
  if (!contact) {
    return undefined;
  }
  return contact.phoneNumbers.find((p) => p.preferred)?.number || contact.phoneNumbers[0]?.number;
}
export function getContactAddressValue(address?: ContactAddress) {
  if (!address) {
    return undefined;
  }
  const { fullAddress } = address.address;
  return fullAddress;
}

export function isServiceContact(contact: Contact) {
  return !!contact.type && contact.type !== 'UNDEFINED';
}
