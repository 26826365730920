import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useGoogleIntegrations } from 'api/graphql/hooks/useGoogleIntegrations';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import useLocalStorage from 'components/state/LocalStorage';
import { useEffect } from 'react';
import { usePermissions } from 'util/usePermissions';

export function useSelectedGoogleIntegration() {
  const currentAgentId = useCurrentAgentId();
  const { activeShop } = useActiveShop();
  const { canCreateOrDeleteShopEmailIntegration, canCreateOrDeletePersonalEmailIntegration } = usePermissions();
  const [selectedGoogleIntegration, setSelectedGoogleIntegration] = useLocalStorage<string>('selectedIntegration', '');
  const { googleIntegrations: allGoogleIntegrations, isLoading } = useGoogleIntegrations(
    {
      where: {
        _or: [{ agentId: { _eq: currentAgentId ?? '' } }, { shopId: { _eq: activeShop.id ?? '' } }],
      },
      agent_id: currentAgentId ?? '',
      shop_id: activeShop.id ?? '',
      selectedIntegration: selectedGoogleIntegration || undefined,
    },
    {
      enabled: !!activeShop.id && !!currentAgentId,
    },
  );

  const defaultIntegration =
    allGoogleIntegrations?.find((integration) => integration.isDefault) ?? allGoogleIntegrations?.[0] ?? undefined;

  const currentlySelectedGoogleIntegration = allGoogleIntegrations?.find(
    (integration) => integration.email === selectedGoogleIntegration,
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!currentlySelectedGoogleIntegration) {
      if (defaultIntegration) {
        setSelectedGoogleIntegration(defaultIntegration.email);
      } else if (selectedGoogleIntegration) {
        setSelectedGoogleIntegration('');
      }
    }
  }, [
    isLoading,
    defaultIntegration,
    currentlySelectedGoogleIntegration,
    selectedGoogleIntegration,
    setSelectedGoogleIntegration,
  ]);

  const canCreateOrDeleteShopIntegration = canCreateOrDeleteShopEmailIntegration();
  const canCreateOrDeletePersonalIntegration = canCreateOrDeletePersonalEmailIntegration(activeShop);
  const hasPersonalIntegration = !!allGoogleIntegrations?.find((item) => !!item.agentId);
  const hasShopIntegration = !!allGoogleIntegrations?.find((item) => !!item.shopId);
  const cannotAddIntegration =
    (hasShopIntegration && hasPersonalIntegration) ||
    (hasPersonalIntegration && !canCreateOrDeleteShopIntegration) ||
    (hasShopIntegration && !canCreateOrDeletePersonalIntegration) ||
    (!canCreateOrDeleteShopIntegration && !canCreateOrDeletePersonalIntegration);

  return {
    isLoading,
    allGoogleIntegrations,
    hasActiveGoogleAccountIntegration:
      (allGoogleIntegrations || []).filter((integration) => integration.active).length > 0,
    selectedGoogleIntegration: currentlySelectedGoogleIntegration,
    setSelectedGoogleIntegration,
    cannotAddIntegration,
  };
}
