import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { graphqlClient } from 'api/graphql/graphql-client';

export const kycShopSettingQuery = graphql(/* GraphQL */ `
  query KycShopSetting($where: DbKycShopSettingBoolExp) {
    dbKycShopSetting(where: $where) {
      ...KycShopSetting
    }
  }
`);

//todo: maybe move this into shop settings cards
export const useKycShopSetting = (
  variables: VariablesOf<typeof kycShopSettingQuery> = {},
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['shop', 'useKycShopSettings', variables],
    queryFn: async () => (await graphqlClient.request(kycShopSettingQuery, variables)).dbKycShopSetting,
    ...options,
  });

  return { kycShopSettings: data?.[0], ...rest };
};
