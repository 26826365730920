import { EmailData, EmailRecipient } from '@ev/eva-container-api';
import { useMutation } from '@tanstack/react-query';
import { LanguageEnum } from 'api/graphql/generated/graphql';
import {
  ContactSearchOption,
  LeadAndContactSearchOption,
  getContact,
  getContactId,
  mapLeadInfosToLeadAndContactOptions,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import {
  loadAgents,
  loadContactsAndLeads,
  loadLeads,
  loadListingsFromPe,
  loadResolvedSignature,
  loadResolvedTemplate,
  loadTemplate,
} from 'util/defaultValues/defaultValueLoaders';
import { emailBodyWithSignature, getAutoCcRecipients, getPreferredLanguage } from 'util/email';
import { recipientMapper } from 'util/mappers/sendEmailMapper';

import { getFormattedSalutation } from 'components/contact/useFormattedSalutation';
import { t } from 'i18next';

export function isContact(recipient: EmailRecipient): recipient is { contactId: string; email?: string } {
  return 'contactId' in recipient && !!recipient.contactId;
}

export function isLead(recipient: EmailRecipient): recipient is { leadId: string } {
  return 'leadId' in recipient && !!recipient.leadId;
}

export function isAgent(recipient: EmailRecipient): recipient is { agentId: string } {
  return 'agentId' in recipient && !!recipient.agentId;
}

export function isEmail(recipient: EmailRecipient): recipient is { email: string } {
  return 'email' in recipient && !!recipient.email && !isContact(recipient);
}

function replaceContactEmail(leadsAndContacts: LeadAndContactSearchOption[], toData: EmailRecipient[]) {
  const contactsWithEmail = toData.filter(isContact).filter((contact) => contact.email);
  const emailOverwriteMap = Object.fromEntries(contactsWithEmail.map((contact) => [contact.contactId, contact.email]));

  return leadsAndContacts.map((leadOrContact) => {
    const contactId = getContactId(leadOrContact);
    if (leadOrContact.type === 'CONTACT' && contactId in emailOverwriteMap) {
      return {
        ...leadOrContact,
        email: emailOverwriteMap[contactId]!,
      };
    }
    if (leadOrContact.type === 'LEAD' && contactId in emailOverwriteMap) {
      return {
        ...leadOrContact,
        contact: {
          ...leadOrContact.contact,
          email: emailOverwriteMap[contactId]!,
        },
      };
    }
    return leadOrContact;
  });
}

async function createEmailDefaultValues({
  from,
  data,
  activeShopId,
  preferredShopLanguage,
}: {
  from: string;
  data: EmailData;
  activeShopId: string;
  preferredShopLanguage: LanguageEnum | undefined | null;
}) {
  const contactIds = data.to.filter(isContact).map((recipient) => recipient.contactId);
  const leadIds = data.to.filter(isLead).map((recipient) => recipient.leadId);
  const agentIds = data.to.filter(isAgent).map((recipient) => recipient.agentId);
  const emails = data.to.filter(isEmail).map((recipient) => recipient.email);

  const ccEmails = data.cc?.filter(isEmail).map((recipient) => recipient.email) ?? [];
  const bccEmails = data.bcc?.filter(isEmail).map((recipient) => recipient.email) ?? [];

  const [leads, agents, defaultSignature, template, properties] = await Promise.all([
    loadLeads(leadIds),
    loadAgents(agentIds),
    loadResolvedSignature(activeShopId),
    loadTemplate(activeShopId, data.template),
    loadListingsFromPe(data.propertyUtags),
  ]);
  const contacts = await loadContactsAndLeads([...contactIds, ...(leads || []).map((l) => l.contactId)]);

  const contactsFlagged = contacts?.filter((contact) => contact.contactStatus === 'FLAGGED' || contact.blocked);
  const contactsNotFlagged =
    contacts?.filter((contact) => contact.contactStatus !== 'FLAGGED' && !contact.blocked) ?? [];
  const contactsWithEmailAndNotFlagged = contactsNotFlagged.filter((contact) => contact?.preferredEmail[0]?.address);
  const contactsWithoutEmail = contactsNotFlagged.filter((contact) => !contact?.preferredEmail[0]?.address);

  let recipientsLeadsAndContacts = mapLeadInfosToLeadAndContactOptions(
    leads || [],
    contactsWithEmailAndNotFlagged || [],
  );
  recipientsLeadsAndContacts = replaceContactEmail(recipientsLeadsAndContacts, data.to);

  const recipientsAgents =
    agents?.map((agent) => {
      return { type: 'AGENT' as const, ...agent };
    }) ?? [];
  const recipientsEmails = emails?.map((email) => {
    return { type: 'EMAIL' as const, email };
  });

  const recipientsCcEmails = ccEmails?.map((email) => {
    return { type: 'EMAIL' as const, email };
  });

  const recipientsBccEmails = bccEmails?.map((email) => {
    return { type: 'EMAIL' as const, email };
  });

  const toRecipients = [...recipientsLeadsAndContacts, ...recipientsAgents, ...recipientsEmails];
  const ccRecipients = [...getAutoCcRecipients(toRecipients, toRecipients), ...recipientsCcEmails];
  const bccRecipients = recipientsBccEmails || [];

  const preferredContactLanguage = getPreferredLanguage(toRecipients);

  const templateContent = template
    ? await loadResolvedTemplate({
        emailTemplateId: template?.id,
        shopId: activeShopId,
        recipients: toRecipients.map(recipientMapper),
        language: preferredContactLanguage || preferredShopLanguage || 'EN',
        propertyIds: data.propertyUtags,
        appointmentId: data.appointmentId,
        sharedLeadId: data.sharedLeadId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    : undefined;

  const toRecipientsForSalutations =
    toRecipients
      ?.filter((recipient) => recipient.type === 'CONTACT' || recipient.type === 'LEAD')
      .map((recipient) => getContact(recipient as LeadAndContactSearchOption)) || [];

  const ccRecipientsForSalutations =
    ccRecipients
      ?.filter((recipient) => recipient.type === 'CONTACT')
      .map((recipient) => getContact(recipient as ContactSearchOption)) || [];

  const contactsForSalutations = [...toRecipientsForSalutations, ...ccRecipientsForSalutations];

  const formattedSalutation =
    !templateContent && contactsForSalutations.length
      ? (
          await Promise.all(
            contactsForSalutations.map((contact) => getFormattedSalutation(contact, t, preferredShopLanguage)),
          )
        ).join(', ') + ','
      : '';

  const subject = data.responseToMessage?.subject || templateContent?.subject || '';

  return {
    from,
    to: toRecipients,
    cc: ccRecipients,
    bcc: bccRecipients,
    body: emailBodyWithSignature(
      templateContent?.body || formattedSalutation || '',
      defaultSignature,
      data?.responseToMessage?.body,
    ),
    subject: subject,
    templateId: template?.id,
    sharedLeadId: data.sharedLeadId,
    appointmentId: data.appointmentId,
    properties: properties,
    createFollowUpTask: false,
    hidePrice: false,
    signatureId: undefined,
    attachments: [],
    toContactsWithoutEmail: contactsWithoutEmail ?? [],
    toContactsFlagged: contactsFlagged ?? [],
    responseToType: data.responseToType,
    replyToMessageId:
      data.responseToType === 'reply' || data.responseToType === 'replyAll' ? data.responseToMessage?.id : undefined,
  };
}

export const useCreateEmailDefaultValues = () => {
  const { mutateAsync, ...rest } = useMutation(createEmailDefaultValues);
  return { createEmailDefaultValues: mutateAsync, ...rest };
};
