import { useLocale, useSnackBars } from '@ev/eva-container-api';
import { env } from 'env';
import { useEffect, useRef } from 'react';
import { TFunction, useTranslation } from 'util/i18next';

const messageFormat =
  (t: TFunction<['user']>, locale: string) =>
  (text: string = '', type: number, end: Date) => {
    const unescapedText = new DOMParser().parseFromString(text, 'text/html')?.documentElement?.textContent;
    const endTime: string = end
      ? t('user:freshDesk.title.until', {
          endTime: new Date(end).toLocaleTimeString(locale, {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
          }),
        })
      : '';
    switch (type) {
      case 1:
        return t('user:freshDesk.title.incident', { details: unescapedText });
      case 2:
        return t('user:freshDesk.title.maintenance', {
          details: `${unescapedText}${endTime}`,
        });
      default:
        return unescapedText;
    }
  };

const useFreshServiceWidget = (domain?: string) => {
  const locale = useLocale();
  const { t } = useTranslation(['user']);
  const { openSnackBar, closeSnackBar } = useSnackBars();
  const snackBarIdRef = useRef<string[] | undefined>();
  useEffect(() => {
    if (env.VITE_LEADHUB_ENV !== 'prod') {
      return;
    }
    const fetchData = async () => {
      const response = await fetch(`https://${domain}/api/public/status/widgets/disruptions`);
      const data = await response.json();
      const disruptions = data?.disruptions;
      if (!data.disruptions) {
        return;
      }
      for (const disruption of disruptions) {
        openSnackBar(
          messageFormat(t, locale)(disruption.title, disruption.type, disruption.ended_at),
          disruption.type === 1 ? 'error' : 'warning',
          {
            action: {
              text: t('user:freshDesk.viewIncident'),
              callback: () => {
                window.location.href = `https://${domain}/incidents/${disruption.id}`;
              },
            },
            asBanner: true,
          },
        );
      }
    };

    fetchData();
  }, [domain, openSnackBar, locale, t]);
  return () => {
    if (snackBarIdRef.current) {
      snackBarIdRef.current?.map((notification) => closeSnackBar(notification));
      snackBarIdRef.current = undefined;
    }
  };
};

export default useFreshServiceWidget;
