import { Button, FormHelperText } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ContactItem, LeadItem } from 'components/contact/LeadAndContactPicker/LeadAndContactOptionItems';
import { LeadAndContactSearchOption } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { Attachment } from 'components/emails/Attachment';
import { CcBccAutocomplete } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/CccBccAutocomplete';
import { HEADER_HEIGHT } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogLayout';
import { RichTextEditor } from 'components/forms/RichTextEditor/RichTextEditor';
import { useSyncExposeFormStateToEditorState } from 'components/forms/RichTextEditor/blots/expose/useSyncExposeFormStateToEditorState';
import { RichTextContainer } from 'components/forms/RichTextEditor/styles';
import AlreadyProposedIcon from 'components/icons/already_proposed.svg?react';
import WarningIcon from 'components/icons/warning.svg?react';
import React, { ReactNode, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { ExposeProperty, countUnresolvedPlaceholders, hasAlreadyProposedProperty } from 'util/email';
import { useTranslation } from 'util/i18next';
import { PreviewBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';
import { fullName } from 'util/stringUtils';

export function PreviewBulkEmailTemplateForm({
  selectedRecipient,
  disabled,
  showFullRecipient,
  hidePrice,
}: {
  selectedRecipient: string | undefined;
  disabled?: boolean;
  hidePrice?: boolean;
  showFullRecipient?: boolean;
}) {
  const { t } = useTranslation(['communication', 'enums']);
  const { control, watch, setValue } = useFormContext<PreviewBulkEmailFormData>();

  const [showEmptyCcBcc, setShowEmptyCcBcc] = useState(false);

  const index = watch('recipients').findIndex((r) => r.recipient.id === selectedRecipient);

  const noRecipient = index < 0;

  const setProperties = useCallback(
    (data: ExposeProperty[]) => setValue(`recipients.${index}.properties`, data),
    [setValue, index],
  );

  const getExposeListProps = useCallback(
    (data: PreviewBulkEmailFormData) => ({
      properties: data.recipients[index]?.properties || [],
      alreadyProposedProperties: data.recipients[index]?.alreadyProposedProperties,
      setProperties,
      enableRemove: !disabled,
      hidePrice,
      language: data.recipients[index]?.language || 'EN',
    }),
    [setProperties, disabled, hidePrice, index],
  );

  useSyncExposeFormStateToEditorState<PreviewBulkEmailFormData>(getExposeListProps);

  if (noRecipient) {
    return null;
  }

  const recipient = watch(`recipients.${index}.recipient`);
  const showSubjectOverrideWarning = watch(`recipients.${index}.showSubjectOverrideWarning`);
  const hasAlreadyProposed = hasAlreadyProposedProperty(watch(`recipients.${index}`));
  const hasUnresolved = !!countUnresolvedPlaceholders(watch(`recipients.${index}.body`));
  const hasCcBcc = !!watch(`recipients.${index}.cc`).length || !!watch(`recipients.${index}.bcc`).length;
  const showCcBcc = hasCcBcc || showEmptyCcBcc;

  return (
    <React.Fragment key={index}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: HEADER_HEIGHT, gap: 2, marginBottom: 2 }}
      >
        <Recipient recipient={recipient} showFull={showFullRecipient} />
        <Button color="secondary" disabled={disabled || hasCcBcc} onClick={() => setShowEmptyCcBcc((shown) => !shown)}>
          {t('communication:sendBulkEmailDialog.ccBccButton')}
        </Button>
      </Stack>

      <Stack gap={2}>
        {showCcBcc && (
          <>
            <Controller
              name={`recipients.${index}.cc`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CcBccAutocomplete
                  label={t('communication:emailForm.cc')}
                  disabled={disabled}
                  recipient={recipient}
                  errorMessage={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name={`recipients.${index}.bcc`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CcBccAutocomplete
                  label={t('communication:emailForm.bcc')}
                  disabled={disabled}
                  recipient={recipient}
                  errorMessage={error?.message}
                  {...field}
                />
              )}
            />
          </>
        )}
        <Box>
          <Controller
            name={`recipients.${index}.subject`}
            control={control}
            render={({ field }) => (
              <TextField {...field} disabled={disabled} label={t('communication:emailForm.subject')} />
            )}
          />
          {showSubjectOverrideWarning && (
            <FormHelperText>{t('communication:sendBulkEmailDialog.subjectOverrideWarning')}</FormHelperText>
          )}
        </Box>
        <Controller
          name={`recipients.${index}.body`}
          control={control}
          render={({ field }) => (
            <Box>
              {hasAlreadyProposed && (
                <Banner
                  backgroundColor={theme.palette.information.light}
                  icon={<AlreadyProposedIcon style={{ color: theme.palette.information.main }} />}
                  message={t('communication:sendBulkEmailDialog.hasAlreadyProposedProperties')}
                />
              )}
              {hasUnresolved && (
                <Banner
                  backgroundColor={theme.palette.error.light}
                  icon={<WarningIcon style={{ color: theme.palette.error.main }} />}
                  message={t('communication:sendBulkEmailDialog.hasUnresolvedPlaceholders')}
                />
              )}
              <RichTextContainer data-testid="richTextContainer">
                <RichTextEditor
                  showToolbarOnTop
                  theme="snow"
                  readOnly={disabled}
                  value={field.value}
                  onChange={field.onChange}
                />
                <Box>
                  {watch('attachments').map((file, index) => (
                    <Attachment key={`attachment-${index}`} name={file.name} size={file.size} />
                  ))}
                </Box>
              </RichTextContainer>
            </Box>
          )}
        />
      </Stack>
    </React.Fragment>
  );
}

function Recipient({ recipient, showFull }: { recipient: LeadAndContactSearchOption; showFull?: boolean }) {
  const { t } = useTranslation(['communication']);

  if (!showFull) {
    const contact = recipient.type === 'LEAD' ? recipient.contact : recipient;
    return (
      <Typography variant="body2" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {`${t('communication:sendBulkEmailDialog.previewToPrefix')} ${fullName(contact)}`}
      </Typography>
    );
  }

  return (
    <Box sx={{ marginBottom: 3, minWidth: 0 }}>
      {recipient.type === 'LEAD' ? (
        <>
          <ContactItem option={recipient.contact}>
            <LeadItem lead={recipient} />
          </ContactItem>
        </>
      ) : (
        <ContactItem option={recipient} />
      )}
    </Box>
  );
}

function Banner({ icon, backgroundColor, message }: { backgroundColor: string; icon: ReactNode; message: string }) {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{
        backgroundColor,
        paddingX: 3,
        paddingY: 1,
        marginBottom: '-1px',
        border: '1px solid #ccc',
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px',
      }}
    >
      {icon}
      <Typography variant="body3">{message}</Typography>
    </Stack>
  );
}
