import { AppModule } from '@ev/eva-container-api';
import { HomePageRedirect } from 'page-components/Redirects';
import RedirectToAgentProfilePage from 'page-components/profiles/RedirectToAgentProfilePage';
import { RouteObject } from 'react-router-dom';

export function leadHubRoutes(appModules: AppModule[]) {
  return [
    {
      index: true,
      element: <HomePageRedirect />,
    },
    {
      path: 'dashboard',
      lazy: () => import('page-components/dashboard'),
    },
    {
      path: 'statistics',
      lazy: () => import('page-components/statistics'),
    },
    {
      path: 'leads',
      lazy: () => import('page-components/LeadListPage'),
    },
    {
      path: 'contact/:contactId',
      lazy: () => import('page-components/contact/LayoutContact'),
      children: [
        {
          index: true,
          lazy: () => import('page-components/contact/ContactOverviewPage'),
        },
        {
          path: 'contactdetails',
          lazy: () => import('page-components/contact/ContactDetailsPage'),
        },
        {
          path: 'activities',
          lazy: () => import('page-components/contact/ContactActivitiesPage/ContactActivitiesPage'),
        },
        {
          path: 'lead/:leadId/matching',
          lazy: () => import('page-components/contact/lead/MatchingPage/MatchingPage'),
        },
        {
          path: 'lead/:leadId',
          lazy: () => import('page-components/contact/lead/LeadPage/LeadPage'),
        },
        {
          path: 'proposedlistings',
          lazy: () => import('page-components/contact/ContactProposedListingsPage'),
        },
        {
          path: 'documents',
          lazy: () => import('page-components/contact/ContactDocumentsPage'),
        },
      ],
    },
    {
      path: 'contacts',
      lazy: () => import('page-components/contacts/ContactListPage'),
    },
    {
      path: 'mails',
      lazy: () => import('page-components/mails/MailsLayout'),
      children: [
        {
          path: 'inbox',
          lazy: () => import('page-components/mails/mail/MailPage'),
        },
        {
          path: 'sent',
          lazy: () => import('page-components/mails/mail/MailPage'),
        },
        {
          path: 'sentbulk',
          lazy: () => import('page-components/mails/mail/BulkMailPage'),
        },
        {
          path: ':threadIndex',
          lazy: () => import('page-components/mails/ThreadPage'),
        },
        {
          path: 'setting/integrations',
          lazy: () => import('page-components/mails/settings/integrations/MyIntegrationsPage'),
        },
        {
          path: 'setting/templates',
          lazy: () => import('page-components/mails/settings/templates/MyTemplatesPage'),
        },
        {
          path: 'setting/signatures',
          lazy: () => import('page-components/mails/settings/signatures/MySignaturesPage'),
        },
      ],
    },
    {
      path: 'calls',
      lazy: () => import('page-components/calls/CallPage'),
    },
    {
      path: 'calls/:callId',
      lazy: () => import('page-components/calls/CallDetailsPage'),
    },
    {
      path: 'user/shop',
      lazy: () => import('page-components/profiles/shop/ShopProfileLayout'),
      children: [
        {
          path: 'details',
          lazy: () => import('page-components/profiles/shop/ShopDetailsPage'),
        },
        {
          path: 'settings',
          lazy: () => import('page-components/profiles/shop/ShopSettingsPage'),
        },
        {
          path: 'export',
          lazy: () => import('page-components/profiles/shop/ShopExportPage'),
        },
        ...appModules.filter((module) => module.scope === 'SHOP_SETTINGS').map((module) => module.route),
        { index: true, lazy: () => import('page-components/profiles/shop/ShopMembersPage') },
      ],
    },
    {
      path: 'profile',
      children: [
        {
          path: 'my-profile',
          element: <RedirectToAgentProfilePage />,
        },
        {
          path: 'lipa',
          lazy: () => import('page-components/profiles/lipa/LiPaProfileLayout'),
          children: [
            {
              path: 'details',
              lazy: () => import('page-components/profiles/lipa/LiPaProfileDetailsPage/LiPaProfileDetailsPage'),
            },
            ...appModules.filter((module) => module.scope === 'LIPA_SETTINGS').map((module) => module.route),
          ],
        },
        {
          path: 'agent/:agentId',
          lazy: () => import('page-components/profiles/agent/AgentProfileLayout'),
          children: [
            {
              path: 'details',
              lazy: () => import('page-components/profiles/agent/AgentProfileDetails/AgentProfileDetailsPage'),
            },
            ...appModules.filter((module) => module.scope === 'AGENT').map((module) => module.route),
          ],
        },
      ],
    },
    {
      path: 'network',
      lazy: () => import('page-components/network/Network'),
    },
    {
      path: 'admin',
      lazy: () => import('page-components/admin/AdminPage'),
    },
    {
      path: 'referrals',
      lazy: () => import('page-components/referrals/ReferralsPage'),
    },
    {
      path: 'activities',
      lazy: () => import('page-components/activities/ActivityPage'),
    },
  ] satisfies RouteObject[];
}
