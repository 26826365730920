import { Paper } from '@mui/material';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { ActivityListItem, BaseActivity } from 'api/graphql/generated/graphql';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { PropertyTooltipChip } from 'components/general/Chips/TooltipChip/PropertyTooltipChip';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';

export function ActivityProperty({
  activity,
  maxChips,
  showPropertiesOnToolTip,
}: {
  activity?: ActivityListItem | BaseActivity;
  maxChips?: number;
  showPropertiesOnToolTip?: boolean;
}) {
  const propertyIds = getActivityPropertyIds(activity);

  if (!propertyIds?.length) {
    return <>{EMPTY_DATA_STRING}</>;
  }

  const displayedPropertyIds = maxChips !== undefined ? propertyIds.slice(0, maxChips) : propertyIds;
  const isMultiLine = displayedPropertyIds.length > 1;
  const tooltipPropertyIds = propertyIds.slice(displayedPropertyIds.length);

  return (
    <Stack direction={isMultiLine ? 'column' : 'row'} gap={1} alignItems="flex-start">
      {displayedPropertyIds.map((propertyId) => (
        <PropertyTooltipChip key={propertyId} propertyId={propertyId} />
      ))}
      {propertyIds.length > displayedPropertyIds.length && (
        <Tooltip
          title={
            !showPropertiesOnToolTip ? (
              <Paper
                style={{
                  pointerEvents: 'auto',
                }}
              >
                <Stack direction={'column'} gap={1} padding={1.5} alignItems="flex-start">
                  {tooltipPropertyIds.map((propertyId) => (
                    <PropertyTooltipChip key={propertyId} propertyId={propertyId} />
                  ))}
                </Stack>
              </Paper>
            ) : (
              tooltipPropertyIds.join(', ')
            )
          }
          placement="bottom-end"
          slotProps={{
            tooltip: {
              style: {
                backgroundColor: 'transparent',
              },
            },
          }}
        >
          <EVChip label={`+${propertyIds.length - displayedPropertyIds.length}`} />
        </Tooltip>
      )}
    </Stack>
  );
}

export const getActivityPropertyIds = (activity: ActivityListItem | BaseActivity | undefined) => {
  let propertyIds: string[] | undefined = [];

  switch (activity?.category) {
    case 'APPOINTMENT': {
      propertyIds = activity.appointmentActivity?.appointment.propertyIds;
      break;
    }
    case 'EMAIL': {
      propertyIds = activity.emailActivity?.email?.propertyIds || undefined;
      break;
    }
    case 'TASK': {
      propertyIds = activity.taskActivity?.task.propertyIds;
      break;
    }
    case 'DOCUMENT': {
      propertyIds = activity.exposeSharedActivity?.propertyIds;
      break;
    }
    case 'SHARED': {
      propertyIds = activity.exposeSharedActivity?.propertyIds;
      break;
    }
    default:
      break;
  }

  return propertyIds?.length ? propertyIds : undefined;
};
