import { OutlinedInputProps, Stack } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { AsyncAutocomplete } from 'components/general/AsyncAutocomplete';
import { DialogAlertMessage } from 'components/general/DialogAlertMessage/DialogAlertMessage';

import City from 'components/icons/company.svg?react';
import House from 'components/icons/house.svg?react';
import Area from 'components/icons/location_2.svg?react';
import District from 'components/icons/properties.svg?react';

import { PlaceSuggestionsOptions, useBFFPlaceSuggestions } from 'api/searchBff/hooks/usePlaceSuggestions';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'util/i18next';
import { AutocompletePrediction, PlacesAddressSearchResult } from 'util/places/types';
import { AddressLike, addressToSingleLineString } from 'util/summaries/addressSummary';

import { useBFFPlaceDetails } from 'api/searchBff/hooks/usePlaceDetails';
import { createSearchBFFToken } from 'api/searchBff/searchBffFetchClient';
import { theme } from 'theme';
import { useDebounce } from 'util/useDebounce';

export enum PlaceType {
  COUNTRY = 'country',
  NATURAL_FEATURE = 'natural_feature',
  NEIGHBORHOOD = 'neighborhood',
  LOCALITY = 'locality',
  ADMINISTRATIVE_AREA_1 = 'administrative_area_level_1',
  ADMINISTRATIVE_AREA_2 = 'administrative_area_level_2',
  ADMINISTRATIVE_AREA_3 = 'administrative_area_level_3',
  ADMINISTRATIVE_AREA_4 = 'administrative_area_level_4',
  POSTCODE = 'postal_code',
  SUBLOCALITY = 'sublocality',
  DISPLAYID = 'displayId',
  SUBLOCALITY_LEVEL_1 = 'sublocality_level_1',
  SUBLOCALITY_LEVEL_2 = 'sublocality_level_2',
  SUBLOCALITY_LEVEL_3 = 'sublocality_level_3',
  SUBLOCALITY_LEVEL_4 = 'sublocality_level_4',
  SUBLOCALITY_LEVEL_5 = 'sublocality_level_5',
  SUBLOCALITY_LEVEL_6 = 'sublocality_level_6',
  SUBLOCALITY_LEVEL_7 = 'sublocality_level_7',
  ROUTE = 'route',
}

export const getFirstSupportedType = (types: PlaceType[]): PlaceType | undefined => {
  return types?.find((type) => Object.values(PlaceType).includes(type)) || undefined;
};
export interface AddressSearchFieldProps {
  label: ReactNode;
  sx?: SxProps;
  defaultAddress?: AddressLike | null;
  disabled?: boolean;
  suggestionOptions?: Omit<PlaceSuggestionsOptions, 'input'>;
  onChange: (address: PlacesAddressSearchResult | null) => void;
  errorMessage?: string;
  hasError?: boolean;
  showWarningOnBlur?: boolean;
  textFieldRef?: React.MutableRefObject<unknown> | React.RefCallback<unknown> | null;
  textFieldProps?: Partial<OutlinedInputProps> & { 'data-loggingid'?: string };
  onBlur?: () => void;
}

// This splits the suggestion into words and highlights the matching words from the input query
function highlightMatchingText({ query, suggestion }: { query: string; suggestion: string }) {
  const queryWords = query.toLocaleLowerCase().split(' ');
  let suggestionText = suggestion;
  queryWords.forEach((word) => {
    const wordIndex = suggestionText.toLocaleLowerCase().replace(/<b>/g, '---').replace(/<\/b>/g, '----').indexOf(word);
    if (wordIndex >= 0) {
      suggestionText =
        suggestionText.substring(0, wordIndex) +
        '<b>' +
        suggestionText.substring(wordIndex, wordIndex + word.length) +
        '</b>' +
        suggestionText.substring(wordIndex + word.length);
    }
  });
  return suggestionText;
}

export function AddressSearch({
  label,
  defaultAddress,
  disabled,
  onChange,
  suggestionOptions,
  errorMessage,
  hasError,
  textFieldRef,
  onBlur,
  textFieldProps,
  sx,
  showWarningOnBlur = true,
}: AddressSearchFieldProps) {
  const { t } = useTranslation(['lead', 'enums']);
  const [query, setQuery] = useState(defaultAddress ? addressToSingleLineString(t)(defaultAddress, '') : '');
  const [selectedSuggestion, setSelectedSuggestion] = useState<AutocompletePrediction>();
  const [showAlert, setShowAlert] = useState(false);
  const { suggestions, isLoading, getPlaceSuggestions } = useBFFPlaceSuggestions();
  const requestToken = useRef<string>(createSearchBFFToken());
  const debounce = useDebounce();

  const { getDetails } = useBFFPlaceDetails({ onSuccess: onChange });
  const { getDetails: getInitialDetails } = useBFFPlaceDetails({
    onSuccess: (address) => {
      if (address) {
        setQuery(address.formattedAddress || '');
      }
    },
  });

  useEffect(() => {
    /** Here we set a new token right after an address is selected */
    requestToken.current = createSearchBFFToken();
  }, [selectedSuggestion]);

  const onSearchStringChanged = (value: string) => {
    setQuery(value);
    debounce(() => {
      getPlaceSuggestions({ input: value, sessionToken: requestToken.current, ...suggestionOptions });
    });
  };

  useEffect(() => {
    if (defaultAddress?.placeId) {
      getInitialDetails({ placeId: defaultAddress?.placeId || '' });
    }
  }, [getInitialDetails, defaultAddress?.placeId, setQuery]);

  const onSelectedOptionChanged = (option: AutocompletePrediction | undefined) => {
    setSelectedSuggestion(option);
    if (option) {
      getDetails({ placeId: option.placeId, sessionToken: requestToken.current });
      setShowAlert(false);
    } else {
      onChange(null);
    }
  };

  const onFocus = () => {
    if (query && !suggestions.length && !isLoading) {
      getPlaceSuggestions({ input: query, ...suggestionOptions });
    }
  };

  const handleMissmatchedSuggestion = () => {
    setShowAlert(selectedSuggestion === undefined);
  };

  const iconsMapping = (type?: PlaceType) => {
    if (!type) {
      return undefined;
    }

    switch (type) {
      case PlaceType.ADMINISTRATIVE_AREA_1:
      case PlaceType.ADMINISTRATIVE_AREA_2:
      case PlaceType.ADMINISTRATIVE_AREA_3:
        return {
          icon: <Area />,
          name: t('lead:addressSearch.suggestionLabels.administrative_area_level_1'),
        };
      case PlaceType.COUNTRY:
        return {
          icon: <Area />,
          name: t('lead:addressSearch.suggestionLabels.country'),
        };
      case PlaceType.LOCALITY:
        return {
          icon: <City />,
          name: t('lead:addressSearch.suggestionLabels.locality'),
        };
      case PlaceType.NATURAL_FEATURE:
        return {
          icon: <Area />,
          name: t('lead:addressSearch.suggestionLabels.natural_feature'),
        };
      case PlaceType.NEIGHBORHOOD:
        return {
          icon: <District />,
          name: t('lead:addressSearch.suggestionLabels.neighborhood'),
        };
      case PlaceType.SUBLOCALITY:
      case PlaceType.SUBLOCALITY_LEVEL_1:
      case PlaceType.SUBLOCALITY_LEVEL_2:
      case PlaceType.SUBLOCALITY_LEVEL_3:
      case PlaceType.SUBLOCALITY_LEVEL_4:
      case PlaceType.SUBLOCALITY_LEVEL_5:
      case PlaceType.SUBLOCALITY_LEVEL_6:
      case PlaceType.SUBLOCALITY_LEVEL_7:
      case PlaceType.ROUTE:
        return {
          icon: <Area />,
          name: t('lead:addressSearch.suggestionLabels.sublocality'),
        };
      case PlaceType.POSTCODE:
        return {
          icon: <District />,
          name: t('lead:addressSearch.suggestionLabels.postal_code'),
        };
      case PlaceType.DISPLAYID:
        return {
          icon: <House />,
          name: t('lead:addressSearch.suggestionLabels.displayId'),
        };
      case PlaceType.ADMINISTRATIVE_AREA_4:
        return {
          icon: <Area />,
          name: t('lead:addressSearch.suggestionLabels.administrative_area_level_4'),
        };

      default:
        return {
          icon: <Area />,
          name: t('lead:addressSearch.suggestionLabels.natural_feature'),
        };
    }
  };

  return (
    <Stack direction="column" sx={sx} gap={2}>
      <AsyncAutocomplete
        label={label}
        loading={isLoading}
        searchString={query}
        onSearchStringChanged={onSearchStringChanged}
        noOptionsPlaceholder={t('lead:addressSearch.noMatches')}
        selectedOption={selectedSuggestion}
        onSelectedOptionChanged={onSelectedOptionChanged}
        options={(suggestions || []) as AutocompletePrediction[]}
        getSearchTextForOption={(suggestion) => suggestion.description}
        disabled={disabled}
        onFocus={onFocus}
        errorMessage={errorMessage}
        hasError={hasError}
        textFieldProps={textFieldProps}
        textFieldRef={textFieldRef}
        onBlur={() => {
          onBlur?.();
          handleMissmatchedSuggestion();
        }}
        popupIcon={query ? undefined : ''}
        renderOption={(props, suggestion, { index }) => {
          const type = getFirstSupportedType(suggestion.types as PlaceType[]);
          const suggestionResolved = iconsMapping(type);

          return (
            <MenuItem {...props} key={suggestion.placeId} data-testid={`address-search-suggestion-${index}`}>
              {suggestionResolved && <ListItemIcon>{suggestionResolved.icon}</ListItemIcon>}
              <Stack sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Typography
                  variant="body4"
                  dangerouslySetInnerHTML={{
                    __html: highlightMatchingText({ query, suggestion: suggestion.description }),
                  }}
                />
                {suggestionResolved && (
                  <Typography variant="body4" color={theme.palette.text.secondary}>
                    {suggestionResolved.name}
                  </Typography>
                )}
              </Stack>
            </MenuItem>
          );
        }}
      />
      {showWarningOnBlur && showAlert && (
        <DialogAlertMessage warningMessage={t('lead:addressSearch.missmatchValueWarning')} warningMessageHead={''} />
      )}
    </Stack>
  );
}
