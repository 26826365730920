import { AlertColor, Stack, Typography } from '@mui/material';
import { KycStatusInfo } from 'api/graphql/generated/graphql';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export function KycStatusCheck({
  kycStatusData,
  onAdjustContacts,
}: {
  kycStatusData?: KycStatusInfo[];
  onAdjustContacts: () => void;
}) {
  const { t } = useTranslation(['user']);

  const hasIncompleteContacts = kycStatusData?.some((item) => item.kycStatus === 'INCOMPLETE');
  const hasPendingContacts = kycStatusData?.some((item) => item.kycStatus === 'PENDING');

  let alertSeverity: AlertColor = 'success';
  let alertText = t('user:activity.kyc.checkCompleted');

  if (hasPendingContacts) {
    alertSeverity = 'info';
    alertText = t('user:activity.kyc.checkInProgress');
  }

  if (hasIncompleteContacts) {
    alertSeverity = 'warning';
    alertText = t('user:activity.kyc.missing');
  }

  if (!kycStatusData?.length) {
    return null;
  }

  return (
    <Stack>
      {hasIncompleteContacts && (
        <Typography variant="body4" color={theme.palette.warning.main}>
          {t('user:activity.kyc.checkIncomplete')}
        </Typography>
      )}
      <EVAlert
        severity={alertSeverity}
        buttonText={t('user:activity.kyc.reviewIssues')}
        onButtonClick={hasIncompleteContacts ? onAdjustContacts : undefined}
        sx={{ mt: 2 }}
      >
        {alertText}
      </EVAlert>
    </Stack>
  );
}
