import { VariablesOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { graphql } from 'api/graphql/generated';
import { PropertyEngineListingStatus, PropertyEngineListingType } from 'api/graphql/generated/graphql';
import { graphqlClient } from 'api/graphql/graphql-client';
import { httpService } from 'api/rest';
import { SearchPropertyRequest } from 'api/rest/models/rest/Command';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { Go3PropertySearchResult, ListingWithLegacyFields, mapGo3PropertyToListing } from 'util/go3';

export const listingsQuery = graphql(/* GraphQL */ `
  query ListingsQuery(
    $page: Int
    $perPage: Int
    $where: PropertyEngineSearchListingsFilterInput!
    $shopId: PropertyEngineUUID!
  ) {
    propertyEngine {
      searchListings(page: $page, perPage: $perPage, where: $where, shopId: $shopId) {
        listings {
          ...Listing
        }
      }
    }
  }
`);

function useListingsFromPropertyEngine(
  variables: VariablesOf<typeof listingsQuery>,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) {
  const { data: listings, ...rest } = useQuery({
    queryKey: ['listing', 'useListings', variables],
    queryFn: async () =>
      (await graphqlClient.request(listingsQuery, variables)).propertyEngine?.searchListings?.listings,
    staleTime: 30_000,
    retry: false,
    refetchInterval: false,
    ...options,
  });
  return { listings: listings as ListingWithLegacyFields[] | undefined, ...rest };
}

function useGo3PropertySearch(
  requestBody: SearchPropertyRequest,
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) {
  const { data: listings, ...rest } = useQuery({
    queryKey: ['listing', 'useGo3PropertySearch', requestBody],
    queryFn: async () => {
      const resp = await httpService.executeLeadEngineCommand({
        requestBody,
        className: 'SearchPropertyCommand',
      });
      const properties = resp.data.data as Go3PropertySearchResult[];
      return properties
        .sort((prev, next) => {
          if (prev.propertyStatus === 'active' && next.propertyStatus !== 'active') {
            return -1;
          } else if (prev.propertyStatus !== 'active' && next.propertyStatus === 'active') {
            return 1;
          } else {
            return 0;
          }
        })
        .map(mapGo3PropertyToListing);
    },
    ...options,
  });
  return { listings, ...rest };
}

export function useListingSearch(
  variables: {
    query: string;
    listingTypes?: PropertyEngineListingType[];
    shopId: string;
    listingStatus?: PropertyEngineListingStatus[];
  },
  options?: Pick<UseQueryOptions, 'enabled' | 'onError'>,
) {
  const { isFullyOnboardedShop } = useActiveShop();

  const go3Response = useGo3PropertySearch(
    { query: variables.query, shopId: variables.shopId },
    {
      ...options,
      enabled: !isFullyOnboardedShop && options?.enabled,
    },
  );

  const propertyEngineResponse = useListingsFromPropertyEngine(
    {
      where: { query: variables.query, listingTypes: variables.listingTypes, listingStatus: variables.listingStatus },
      shopId: variables.shopId,
    },
    {
      ...options,
      enabled: isFullyOnboardedShop && options?.enabled,
    },
  );

  return isFullyOnboardedShop ? propertyEngineResponse : go3Response;
}
