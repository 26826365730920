import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import LinkIcon from 'components/icons/link_external.svg?react';
import Plus from 'components/icons/plus_naked.svg?react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { MouseEventHandler } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { fullName } from 'util/stringUtils';

export interface TContactOption {
  type?: 'CONTACT' | 'ADD_CONTACT';
  id?: string;
  go3Utag?: Maybe<string>;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  email?: Maybe<string>;
  name?: Maybe<string>;
  phoneNumber?: string;
  disabled?: boolean;
  lastActivity?: Date | null;
  displayValue?: Maybe<string>;
}

export const ContactOption = ({
  option,
  props,
}: {
  option: TContactOption;
  props: React.HTMLAttributes<HTMLLIElement>;
}) => {
  const { toContactDetails } = useDestination();

  return (
    <Box component="li" {...props} sx={{ p: 1 }} key={option.id}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <EVAvatar person={option} size="s" />
        <Box sx={{ display: 'block', lineHeight: '15px' }}>
          <Typography variant="body3" sx={{ pl: 1, py: 0 }} component="div">
            {fullName(option)}
          </Typography>
          <Typography variant="caption" sx={{ pl: 1, py: 0, color: theme.palette.text.secondary }}>
            {[option.email, option.phoneNumber].filter(Boolean).join(', ')}
          </Typography>
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <IconButton target="_blank" href={toContactDetails({ contactId: option?.id || '' })}>
            <LinkIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export const AddNewContactButton = ({ onClick }: { onClick: MouseEventHandler }) => {
  const { t } = useTranslation(['contact']);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 2,
        py: 1,
        mb: 0.5,
        ':hover': {
          backgroundColor: theme.palette.shade.grey4,
          cursor: 'pointer',
        },
      }}
      onClick={onClick}
    >
      <Plus />
      <Typography variant="body4" sx={{ display: 'block', px: 1 }}>
        {t('contact:form.contactPicker.addNewContact')}
      </Typography>
    </Box>
  );
};
