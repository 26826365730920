import { ActionDialog } from 'components/general/EVDialog/StandardDialogs/ActionDialog';
import { useTranslation } from 'util/i18next';

export function EmailUnresolvedPlaceholdersModal({
  onConfirm,
  onCancel,
  sendingDisabled,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  sendingDisabled: boolean;
}) {
  const { t } = useTranslation(['communication']);

  return (
    <ActionDialog
      open={true}
      handleClose={onCancel}
      handleConfirm={onConfirm}
      confirmButtonCustomization={{
        label: t('communication:newEmail.drawer.send'),
        disabled: sendingDisabled,
      }}
      title={t('communication:emailForm.unresolved.error.title')}
      description={t('communication:emailForm.unresolved.error.description')}
    />
  );
}
