import { useEffect, useRef } from 'react';

export function useInterval(callback: () => void, { leading, delay }: { leading?: boolean; delay: number | null }) {
  const savedCallback = useRef<() => void>();
  savedCallback.current = callback;

  useEffect(() => {
    if (delay !== null) {
      if (leading) {
        savedCallback.current?.();
      }
      const id = setInterval(() => savedCallback.current?.(), delay);
      return () => clearInterval(id);
    }
  }, [delay, leading]);
}
