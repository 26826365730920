import { useActiveShopId, useJwtToken } from '@ev/eva-container-api';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { env } from 'env';
import { useMemo, useState } from 'react';
import { theme } from 'theme';
import { useFeatureToggles } from 'util/useFeatureToggles';

const branchColor = {
  dev: theme.palette.information.light1,
  /** Not adding to palette since we want a distinct color but this won't be used across the platform */
  feature: '#d477b3',
  local: theme.palette.special.navy2,
  'migration-prod': theme.palette.warning.main,
  prod: theme.palette.backgroundExtension.white,
  stage: theme.palette.text.primary,
};

const VersionInfo = () => {
  const [features] = useFeatureToggles();
  const currentEnv = env.VITE_LEADHUB_ENV;
  const [hideVersionInfo, setHideVersionInfo] = useState(
    (currentEnv === 'stage' || currentEnv === 'prod') && !features.versionInfo,
  );
  const currentVersion = `${env.VITE_VERSION ? `${env.VITE_VERSION} - ` : ''}`;
  const backendFeatureBranchID = env.VITE_BACKEND_API_URL.match(/(?<=le-)\d{4,}/)?.[0] || '';
  const frontendFeatureBranchID = window.location.origin.match(/(?<=le-)\d{4,}/)?.[0] || '';
  const featureBranchID = useMemo(() => {
    if (frontendFeatureBranchID) {
      if (!backendFeatureBranchID) {
        return `FE ${frontendFeatureBranchID} ▸ `;
      }
      if (frontendFeatureBranchID === backendFeatureBranchID) {
        return `FE/BE ${frontendFeatureBranchID} ▸ `;
      }
      return `FE ${frontendFeatureBranchID} ▸ BE ${backendFeatureBranchID} ▸ `;
    } else if (backendFeatureBranchID) {
      return `BE ${backendFeatureBranchID} ▸ `;
    }
    return '';
  }, [frontendFeatureBranchID, backendFeatureBranchID]);

  const { jwtToken } = useJwtToken();
  const activeShopId = useActiveShopId();
  const globalRoles = jwtToken['lead-engine']?.global_roles;
  const shopRoles = jwtToken['lead-engine']?.shop_roles;
  const teamRoles = jwtToken['lead-engine']?.team_roles;
  const currentRole =
    globalRoles?.[0] ||
    shopRoles?.find((role) => role.includes(activeShopId || ''))?.match('[A-Z_]+$')?.[0] ||
    teamRoles?.find((role) => role.includes(activeShopId || ''))?.match('[A-Z_]+$')?.[0];

  return useMemo(
    () =>
      !hideVersionInfo ? (
        <Box
          className="version-info"
          sx={{
            borderTop: `2px solid ${branchColor[currentEnv]}`,
            width: '100vw',
            position: 'absolute',
            top: 0,
          }}
        >
          <Tooltip
            title={
              <VersionTooltipInfo
                currentEnv={currentEnv}
                frontendFeatureBranchID={frontendFeatureBranchID}
                backendFeatureBranchID={backendFeatureBranchID}
                currentVersion={currentVersion}
                currentRole={currentRole}
              />
            }
            placement="top"
          >
            <Typography
              sx={{
                backgroundColor: branchColor[currentEnv],
                color: theme.palette.common.white,
                fontWeight: 'bold',
                pr: theme.spacing(2),
                pl: theme.spacing(1),
                width: 'max-content',
                fontSize: '11px',
                borderRadius: '0 0 2px 0',
              }}
              onClick={() => {
                setHideVersionInfo(true);
              }}
            >
              {currentVersion ? currentVersion.replace(/-/g, ' ▸ ') : `${currentEnv?.replace(/_/g, ' ')} ▸ `}
              {featureBranchID}
              {currentRole && currentRole.replace(/_/g, ' ')}
            </Typography>
          </Tooltip>
        </Box>
      ) : null,
    [
      currentEnv,
      featureBranchID,
      currentVersion,
      currentRole,
      frontendFeatureBranchID,
      backendFeatureBranchID,
      hideVersionInfo,
    ],
  );
};

const VersionTooltipInfo = ({
  currentEnv,
  frontendFeatureBranchID,
  backendFeatureBranchID,
  currentVersion,
  currentRole,
}: Record<
  'currentEnv' | 'frontendFeatureBranchID' | 'backendFeatureBranchID' | 'currentVersion' | 'currentRole',
  string | undefined
>) =>
  useMemo(
    () => (
      <Stack>
        <Box>Environment: {currentEnv?.replace(/_/g, ' ')}</Box>
        <Box>Frontend feature branch ticket id: {frontendFeatureBranchID}</Box>
        <Box>Backend feature branch ticket id: {backendFeatureBranchID}</Box>
        <Box>Current version hash: {currentVersion}</Box>
        <Box>Current role: {currentRole?.replace(/_/g, ' ')}</Box>
      </Stack>
    ),
    [backendFeatureBranchID, currentEnv, currentRole, currentVersion, frontendFeatureBranchID],
  );

export default VersionInfo;
