import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material';
import { LanguageEnum } from 'api/graphql/generated/graphql';
import { useGlobalEditorState } from 'components/forms/RichTextEditor/blots/GlobalEditorState';
import { CommentForm } from 'components/forms/RichTextEditor/blots/expose/CommentForm';
import { DragNDropHandle } from 'components/general/DndKitUtils/DragNDropHandle';
import AddCommentIcon from 'components/icons/add_comment.svg?react';
import AlreadyProposedIcon from 'components/icons/already_proposed.svg?react';
import MinusCircleIcon from 'components/icons/minus_circle.svg?react';
import PencilIcon from 'components/icons/pencil.svg?react';
import { PropertyItem } from 'components/property/PropertySearch/PropertyItem';
import { useState } from 'react';
import { theme } from 'theme';
import { ExposeProperty } from 'util/email';
import { useTranslation } from 'util/i18next';

export default function ExposeListItem({
  exposeProperty,
  isAlreadyProposed,
  language,
  hidePrice,
  onRemove,
}: {
  exposeProperty: ExposeProperty;
  onRemove?: () => void;
  isAlreadyProposed?: boolean;
  hidePrice?: boolean;
  language?: LanguageEnum;
}) {
  const { t } = useTranslation(['communication']);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: exposeProperty.property.utag,
    transition: null,
  });
  const editorState = useGlobalEditorState();
  const { properties, setProperties } = editorState.props.expose?.['global'] || {};
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditComment = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSaveComment = ({ propertyUtag, newComment }: { propertyUtag: string; newComment: string }) => {
    setProperties?.(
      properties!.map((property) =>
        property.property.utag === propertyUtag
          ? {
              ...property,
              comment: newComment,
            }
          : property,
      ),
    );
  };
  return (
    <Stack
      ref={setNodeRef}
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        border: `1px dashed ${theme.palette.transparent.black32}`,
        padding: 2,
        gap: 1,
      }}
      style={style}
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.shade.grey4}`,
          backgroundColor: theme.palette.backgroundExtension.white,
          minWidth: 0,
          flex: 1,
        }}
      >
        <Stack
          sx={{
            borderBottom: `1px solid ${theme.palette.shade.grey4}`,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <PropertyItem
            property={exposeProperty.property}
            hideStatus
            sx={{ minWidth: 0, flex: 1 }}
            size={64}
            hidePrice={hidePrice ? 'ALWAYS' : 'PE_SETTINGS'}
            showMarketingTitleFor={language}
          />
          {!exposeProperty.comment && (
            <IconButton onClick={(event) => handleEditComment(event)}>
              <AddCommentIcon />
            </IconButton>
          )}

          {onRemove && isAlreadyProposed && (
            <>
              <AlreadyProposedIcon style={{ color: theme.palette.information.main }} />
              <IconButton aria-label={t('delete')} onClick={onRemove}>
                <MinusCircleIcon />
              </IconButton>
            </>
          )}
        </Stack>

        <Typography variant="body4" color="text.secondary" sx={{ paddingY: 0.5, paddingX: 1 }}>
          {t('communication:sendBulkEmailDialog.property.linkPlaceholder')}
        </Typography>

        {exposeProperty.comment ? (
          <Stack
            sx={{
              borderTop: `1px solid ${theme.palette.shade.grey4}`,
              width: '100%',
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
          >
            <Typography
              variant="body4"
              color="text.secondary"
              sx={{
                wordBreak: 'break-word',
              }}
            >
              "{exposeProperty.comment}"
            </Typography>

            <IconButton onClick={(event) => handleEditComment(event)} sx={{ ml: 1 }}>
              <PencilIcon />
            </IconButton>
          </Stack>
        ) : (
          ''
        )}
      </Box>

      <DragNDropHandle dragging={isDragging} listeners={listeners} attributes={attributes} />
      <Popover
        id={exposeProperty.property.utag}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {exposeProperty.comment ? (
          <CommentForm
            onClose={handleClose}
            onSave={handleSaveComment}
            utag={exposeProperty.property.utag}
            initialComment={exposeProperty.comment ?? ''}
          />
        ) : (
          <CommentForm onClose={handleClose} onSave={handleSaveComment} utag={exposeProperty.property.utag} />
        )}
      </Popover>
    </Stack>
  );
}
