import { Box, FormHelperText, Stack, Tooltip } from '@mui/material';
import { DragAndDropZone } from 'components/forms/RichTextEditor/DragAndDropZone';
import ImageMenu from 'components/forms/RichTextEditor/ImageMenu';
import { useCustomBlots } from 'components/forms/RichTextEditor/blots/useCustomBlots';
import {
  bulletListIcon,
  imageIcon,
  italicIcon,
  linkIcon,
  orderedListIcon,
} from 'components/forms/RichTextEditor/icons';
import { Label } from 'components/general/Label/Label';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { nanoid } from 'nanoid';
import Quill from 'quill';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { splitRef } from 'util/splitRef';
import { QuillEditorStyles } from './styles';

const MemoizedReactQuill = React.memo(ReactQuill);

export function RichTextEditor({
  readOnly,
  readOnlyTooltipLabel,
  value,
  onChange,
  editorRef,
  label,
  errorMessage,
  onBlur,
  focusOnMount,
  scrollingContainer,
  extraToolBarItems,
  disabled,
}: {
  readOnly?: boolean;
  readOnlyTooltipLabel?: string;
  theme: string;
  value: string;
  showToolbarOnTop?: boolean;
  onChange?: ReactQuillProps['onChange'];
  editorRef?: React.MutableRefObject<ReactQuill | null>;
  label?: ReactNode;
  errorMessage?: string;
  onBlur?: () => void;
  focusOnMount?: boolean;
  scrollingContainer?: HTMLElement;
  extraToolBarItems?: React.ReactNode;
  disabled?: boolean;
}) {
  useCustomBlots();
  const [showDragArea, setShowDragArea] = useState(false);
  const [showImageMenuOptions, setShowImageMenuOptions] = useState(false);
  const isEmailImageUploadEnabled = useIsFeatureEnabled('EMAIL_IMAGE_UPLOAD');
  const cursorIndex = useRef(0);

  const { t } = useTranslation(['communication']);
  const ownEditorRef = useRef<ReactQuill>();

  useEffect(() => {
    if (focusOnMount) {
      ownEditorRef.current?.focus();
    }
  }, [focusOnMount]);

  const toolbarId = useRef(`toolbar-${nanoid()}`).current;

  const handleImageInsert = useCallback((urls: string[]) => {
    if (!ownEditorRef.current) return;

    const editor = ownEditorRef.current.getEditor();

    urls.forEach((url, index) => {
      editor.insertEmbed(cursorIndex.current + index, 'image', url, 'user');
    });
  }, []);

  // Moving the t function into the useCallback will break the editor in strict mode
  const promptLabel = t('drawer.insertImageURL.label');

  const handleImageOption = useCallback(
    (option: 'insert' | 'drag') => {
      if (option === 'insert') {
        const value = prompt(promptLabel);
        if (value) handleImageInsert([value]);
      } else if (option === 'drag') {
        setShowDragArea(true);
      }
    },
    [promptLabel, handleImageInsert],
  );

  const modules = useMemo(() => {
    return {
      clipboard: { matchVisual: false },
      toolbar: {
        container: `#${toolbarId}`,
        handlers: {
          image: () => {
            const editor = ownEditorRef.current?.getEditor();
            const range = editor?.getSelection();
            if (range) {
              cursorIndex.current = range.index;
            }
            if (isEmailImageUploadEnabled) {
              setShowImageMenuOptions(true);
            } else {
              handleImageOption('insert');
            }
          },
        },
      },
    };
  }, [toolbarId, isEmailImageUploadEnabled, handleImageOption]);

  const showToolbar = !readOnly || !!extraToolBarItems;

  const borderColor = errorMessage ? theme.palette.error.main : '#ccc';

  const quillRef = useMemo(() => splitRef(editorRef, ownEditorRef), [editorRef]);

  return (
    <>
      {label && <Label sx={{ marginBottom: 1 }}>{label}</Label>}

      <Box
        sx={{
          border: `1px solid ${borderColor}`,
          borderRadius: '2px',
          backgroundColor: theme.palette.backgroundExtension.white,
          position: 'relative',
        }}
        onBlur={onBlur}
        gap={1}
      >
        <Tooltip title={readOnly && readOnlyTooltipLabel} placement={'top'} arrow PopperProps={{ disablePortal: true }}>
          <QuillEditorStyles hideNativeToolbar={readOnly}>
            {showToolbar && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={readOnly ? 'flex-end' : 'flex-start'}
                sx={{ borderBottom: `1px solid ${borderColor}`, paddingLeft: 1 }}
              >
                <NativeEditorToolbar id={toolbarId} />
                {extraToolBarItems}
              </Stack>
            )}
            {showImageMenuOptions && (
              <ImageMenu handleImageOption={handleImageOption} handleClose={() => setShowImageMenuOptions(false)} />
            )}
            <Box sx={{ position: 'relative', zIndex: showDragArea ? 0 : undefined }}>
              {showDragArea && (
                <DragAndDropZone setShowDragArea={setShowDragArea} handleImageInsert={handleImageInsert} />
              )}

              <MemoizedReactQuill
                readOnly={readOnly || !!disabled}
                bounds=".quill"
                value={value}
                modules={modules}
                onChange={onChange}
                scrollingContainer={scrollingContainer}
                ref={quillRef}
              />
            </Box>
          </QuillEditorStyles>
        </Tooltip>
      </Box>
      <FormHelperText>{errorMessage}</FormHelperText>
    </>
  );
}

const NativeEditorToolbar = React.memo(({ id }: { id: string }) => {
  const icons = Quill.import('ui/icons');

  icons['image'] = imageIcon;
  icons['link'] = linkIcon;
  icons['italic'] = italicIcon;
  icons['list']['ordered'] = orderedListIcon;
  icons['list']['bullet'] = bulletListIcon;

  return (
    <div
      id={id}
      dangerouslySetInnerHTML={{
        __html: `
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <select class="ql-header">
            <option value="2"></option>
            <option value="3"></option>
            <option value="4"></option>
            <option value="5"></option>
            <option value="" selected></option>
          </select>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-link"></button>
          <button class="ql-image"></button>
        `,
      }}
    />
  );
});
