import { ExposeTemplateType, PreSelectableTemplateType } from '@ev/eva-container-api';
import { graphqlClient } from 'api/graphql';
import { agentQuery } from 'api/graphql/hooks/useAgents';
import { contactsAndLeadsQuery, contactsQuery } from 'api/graphql/hooks/useContact';
import { contactAllTagsQuery } from 'api/graphql/hooks/useContactTags';
import { CustomDemandField, customDemandFieldQuery } from 'api/graphql/hooks/useCustomDemandFields';
import { dashboardActivitiesQuery } from 'api/graphql/hooks/useDashboardActivities';
import { emailTemplatesQuery } from 'api/graphql/hooks/useEmailTemplates';
import { kycShopSettingQuery } from 'api/graphql/hooks/useKycShopSettings';
import { leadInfoListQuery } from 'api/graphql/hooks/useLeadList';
import { listingQuery } from 'api/graphql/hooks/useListing';
import { consentManagementShopSettingsQuery } from 'api/graphql/hooks/useShopSettingsCards';
import { teamInfoQuery } from 'api/graphql/hooks/useTeams';
import { httpService } from 'api/rest';
import { fetchClient } from 'api/rest/fetch-client';
import { ResolveEmailTemplateRequest } from 'api/rest/hooks/useEmail';
import { ResolveBulkEmailTemplateResponse, ResolveEmailTemplateResponse } from 'api/rest/models/EmailDataTypes';
import { ResolveBulkEmailTemplateRequest } from 'api/rest/models/rest/Command';
import { ListingWithLegacyFields } from 'util/go3';

export async function loadContacts(contactIds?: string[]) {
  if (!contactIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(contactsQuery, { where: { id: { _in: contactIds } } });
  return data?.dbContact;
}

export async function loadContactsAndLeads(contactIds?: string[]) {
  if (!contactIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(contactsAndLeadsQuery, { where: { id: { _in: contactIds } } });
  return data?.dbContact;
}

export async function loadLeads(leadIds?: string[]) {
  if (!leadIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(leadInfoListQuery, { where: { id: { _in: leadIds } } });
  return data?.dbLead;
}

export async function loadAgents(agentsIds?: string[]) {
  if (!agentsIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(agentQuery, { where: { id: { _in: agentsIds } } });
  return data?.dbAgent;
}

export async function loadAppointment(appointmentId?: string) {
  if (!appointmentId) {
    return undefined;
  }

  const data = await graphqlClient.request(dashboardActivitiesQuery, {
    where: { appointmentActivity: { appointment: { id: { _eq: appointmentId } } } },
  });
  return data.dbBaseActivity[0];
}

export async function loadTask(taskId?: string) {
  if (!taskId) {
    return undefined;
  }

  const data = await graphqlClient.request(dashboardActivitiesQuery, {
    where: { taskActivity: { task: { id: { _eq: taskId } } } },
  });
  return data.dbBaseActivity[0];
}

export async function loadResolvedSignature(activeShopId: string, emailSignatureId?: string) {
  return (
    await fetchClient.GET('/api/v1/email-signatures/email-signature-resolution', {
      params: {
        query: {
          shopId: activeShopId,
          emailSignatureId,
        },
      },
    })
  ).data?.data;
}

export async function loadTemplate(
  activeShopId: string,
  templateType: PreSelectableTemplateType | ExposeTemplateType | undefined,
) {
  if (!templateType) {
    return undefined;
  }
  return (
    await graphqlClient.request(emailTemplatesQuery, {
      where: { type: { _eq: templateType }, shopId: { _eq: activeShopId } },
    })
  ).dbEmailTemplate?.[0];
}

export async function loadConsentManagementShopSettings(activeShopId: string) {
  return (await graphqlClient.request(consentManagementShopSettingsQuery, { shopId: activeShopId }))
    .dbShopManagedConsent;
}

export async function loadMandatoryKycShopSettings(activeShopId: string) {
  return (await graphqlClient.request(kycShopSettingQuery, { where: { shopId: { _eq: activeShopId } } }))
    .dbKycShopSetting;
}

export async function loadResolvedTemplate(requestBody: ResolveEmailTemplateRequest) {
  return (await httpService.executeLeadEngineCommand({ requestBody, className: 'ResolveEmailTemplateCommand' })).data
    .data as ResolveEmailTemplateResponse;
}

export async function loadResolvedBulkTemplates(requestBody: ResolveBulkEmailTemplateRequest) {
  return (await httpService.executeLeadEngineCommand({ requestBody, className: 'ResolveBulkTemplateCommand' })).data
    .data as ResolveBulkEmailTemplateResponse[];
}

export function loadListingsFromPe(utags: string[] | undefined) {
  if (!utags?.length) {
    return [];
  }

  return Promise.all(
    utags.map(
      async (utag) =>
        (await graphqlClient.request(listingQuery, { utag })).propertyEngine
          ?.getListingByUtag as ListingWithLegacyFields,
    ),
  );
}

export async function loadTeams(teamIds?: string[]) {
  if (!teamIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(teamInfoQuery, { where: { id: { _in: teamIds } } });
  return data?.dbTeam;
}

export async function loadContactTags(tagIds?: string[]) {
  if (!tagIds?.length) {
    return undefined;
  }
  const data = await graphqlClient.request(contactAllTagsQuery, { where: { id: { _in: tagIds } } });
  return data?.dbContactTag?.map((tag) => tag.tag);
}

export async function loadCustomDemandFields(sellerDemandFieldsOptions: CustomDemandField[], cdfIds?: string[]) {
  if (!cdfIds?.length) {
    return undefined;
  }
  const customCdfs = cdfIds.filter((id) => !sellerDemandFieldsOptions?.some((option) => option.id === id));
  const data = await graphqlClient.request(customDemandFieldQuery, { where: { id: { _in: customCdfs } } });
  return [...sellerDemandFieldsOptions.filter((option) => cdfIds.includes(option.id)), ...(data?.dbCdf || [])];
}
