import { useDrawer } from '@ev/eva-container-api';
import { Box, Stack, Typography } from '@mui/material';
import { useContact } from 'api/graphql/hooks/useContact';
import { ContactSearchOption } from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { LinkButton } from 'components/general/LinkButton';
import EditContact from 'page-components/contact/LayoutContact/EditContact/EditContact';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { useDestination } from 'util/navigation/useDestination';
import { fullNameFromContact } from 'util/stringUtils';

export type KycIncompleteCardProps = {
  contact: Omit<ContactSearchOption, 'type'>;
  onClose: () => void;
};

export const KycIncompleteCard = ({ contact, onClose }: KycIncompleteCardProps) => {
  const { t } = useTranslation(['user']);
  const { toContactDetails } = useDestination();
  const linkTo = toContactDetails({ contactId: contact.id });
  const [isEditContactKycInfoDrawerOpen, setEditContactKycInfoDrawerOpen] = useDrawer('editKycInfo');
  const { contact: fullContact } = useContact({ id: contact.id }, { enabled: !!contact.id });

  return (
    <Stack
      padding={2}
      gap={2}
      bgcolor={theme.palette.background.default}
      border={'1px solid'}
      borderColor={theme.palette.tertiary.active}
      borderRadius={0.25}
    >
      <Stack gap={2} direction="row" alignItems="center" sx={{ maxWidth: '100%', overflow: 'hidden' }}>
        <EVAvatar size="l" person={contact} />
        <Stack>
          <Typography variant="body3" color={theme.palette.text.primary} fontWeight={theme.typography.fontWeightBold}>
            {linkTo ? (
              <LinkButton to={linkTo}>{fullNameFromContact(contact)}</LinkButton>
            ) : (
              <Typography variant="body3">{fullNameFromContact(contact)}</Typography>
            )}
          </Typography>

          <Typography variant="body3" color={theme.palette.text.secondary}>
            {contact.emailAddresses?.map((email) => (
              <Box key={email.address}>{email.address}</Box>
            ))}
            {contact.phoneNumbers?.map((phone) => (
              <Box key={phone.number}>{phone.number}</Box>
            ))}
          </Typography>
        </Stack>
      </Stack>
      <EVAlert
        severity={'warning'}
        fullWidth
        onButtonClick={() => setEditContactKycInfoDrawerOpen(true)}
        buttonText={t('user:kyc.complete')}
        sx={{ fontSize: '12px' }}
      >
        {t('user:activity.kyc.missing')}
      </EVAlert>
      {fullContact && (
        <EVDrawer isOpen={isEditContactKycInfoDrawerOpen} onClose={() => setEditContactKycInfoDrawerOpen(false)}>
          <EditContact
            contact={fullContact}
            onClose={() => {
              setEditContactKycInfoDrawerOpen(false);
              onClose();
            }}
            showKycAlert
          />
        </EVDrawer>
      )}
    </Stack>
  );
};
