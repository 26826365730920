import { Listing } from 'api/graphql/generated/graphql';
import { Go3RetrievePropertyInfoResponse } from 'api/rest/models/go3/Go3RetrievePropertyInfoResponse';
import { SearchPropertyResponse } from 'api/rest/models/rest/Command';

export type Go3PropertySearchResult = SearchPropertyResponse['content']['application/json'];

export type ListingWithLegacyFields = Omit<Listing, 'previewImage' | 'asset'> & {
  previewImage?: string | Listing['previewImage'];
  listingAgent?: Go3PropertySearchResult['listingAgent'];
  asset?: Listing['asset'];
};

function mapGo3PropertyToAsset(
  property: Go3PropertySearchResult | Go3RetrievePropertyInfoResponse,
): ListingWithLegacyFields['asset'] {
  // we can't map the subtype/variation because GO3 uses different types
  switch (property.propertyType) {
    case 'apartment':
      return {
        type: 'APARTMENT',
        surfaceLivingArea: 'livingArea' in property ? property.livingArea : undefined,
        numberOfBedrooms: 'numberBedrooms' in property ? property.numberBedrooms : undefined,
      };
    case 'house':
      return {
        type: 'HOUSE',
        surfaceLivingArea: 'livingArea' in property ? property.livingArea : undefined,
        numberOfBedrooms: 'numberBedrooms' in property ? property.numberBedrooms : undefined,
      };
    case 'land':
      return {
        type: 'LAND',
      };
    default:
      return undefined;
  }
}

function mapGo3PropertyStatusToListingStatus(status: string | undefined) {
  switch (status) {
    case 'active':
      return 'ACTIVE';
    case 'pending':
      return 'PENDING';
    case 'offershow':
      return 'OFFER_SHOW';
    case 'offernoshow':
      return 'OFFER_NO_SHOW';
    case 'soldbyev':
      return 'CLOSED_BY_EV';
    case 'soldbyothers':
      return 'CLOSED_BY_OTHERS';
    case 'withdrawn':
      return 'WITHDRAWN';
    case 'expired':
      return 'EXPIRED';
    case 'cancelled':
      return 'CANCELLED';
    case 'compprospect':
      return 'STREET_INDEX';
    case 'inactive':
      return 'INACTIVE';
    default:
      return 'UNDEFINED';
  }
}

export function mapGo3PropertyToListing(
  property: Go3PropertySearchResult | Go3RetrievePropertyInfoResponse,
): ListingWithLegacyFields {
  return {
    status: mapGo3PropertyStatusToListingStatus(property.propertyStatus),
    utag: property.utag!,
    type: property.marketingType === 'sales' ? 'SALE' : 'RENTAL',
    askingPrice: property.askingPrice,
    monthlyNetRent: property.monthlyNetRent,
    monthlyTotalRent: property.totalRent,
    monthlyUtilities: property.monthlyUtilities,
    asset: mapGo3PropertyToAsset(property),
    previewImage: property.mainPictureUrl,
    address: {
      streetName: property.propertyAddress?.streetName,
      city: property.propertyAddress?.city,
      streetNumber: property.propertyAddress?.streetNumber,
      postalCode: property.propertyAddress?.postalCode,
      countryCode: property.propertyAddress?.countryCode,
    },
    internetProfile: {
      priceOnRequest: undefined,
      portals: undefined,
    },
    exposes: [],
    marketingDetails: [],
    fileAs: property.fileAs,
    listingAgent: 'listingAgent' in property ? property.listingAgent : undefined,
    currency: property.go3Currency as ListingWithLegacyFields['currency'],
  };
}
