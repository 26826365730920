import { createFetchError } from 'api/FetchError';
import { paths } from 'api/searchBff/generated-rest';
import { env } from 'env';
import createClient from 'openapi-fetch';
import sentry from 'util/sentry';

export const createSearchBFFToken = () => 'lh_' + Math.random().toString(36).substring(2);

export const searchBffFetchClient = createClient<paths>({ baseUrl: env.VITE_SEARCH_BFF_API_URL });

searchBffFetchClient.use({
  onRequest({ request }) {
    request.headers.set('x-application-key', env.VITE_SEARCH_BFF_APPLICATION_KEY);
    return request;
  },
  async onResponse({ response, request }) {
    if (!response.ok) {
      const fetchError = await createFetchError(`SearchBff call to ${request.url} failed`, response, request);
      sentry.logError(fetchError);
      throw fetchError;
    }
  },
});
