import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TaskTypeEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { useTeamsAssignedToAgentInShop } from 'api/graphql/hooks/useTeamsAssignedToAgentInShop';
import { ContactsAndLeadsPicker } from 'components/contact/LeadAndContactPicker/ContactsAndLeadsPicker';
import { AgentPicker } from 'components/dashboard/AgentPicker';
import { MAX_PROPERTIES } from 'components/dashboard/CreateOrEditAppointmentForm';
import { AgentsPicker } from 'components/forms/AgentsPicker/AgentsPicker';
import { DropDown } from 'components/general/DropDown/DropDown';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { EVDatePicker } from 'components/general/EVDatePicker/EVDatePicker';
import { EVTimePicker } from 'components/general/EVTimePicker/EVTimePicker';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import CalendarIcon from 'components/icons/calendar_today.svg?react';
import ChatIcon from 'components/icons/chat_1_naked.svg?react';
import DirectoryIcon from 'components/icons/contact.svg?react';
import PropertiesIcon from 'components/icons/home.svg?react';
import StopWatchIcon from 'components/icons/stopwatch.svg?react';
import UserDoubleIcon from 'components/icons/user_double.svg?react';
import AgentIcon from 'components/icons/user_single_2.svg?react';
import { PropertySearchField } from 'components/property/PropertySearch/PropertySearchField';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import {
  activityPriorityTranslation,
  taskAppointmentStatusTranslation,
  taskTitleTranslation,
  taskTypeTranslation,
} from 'const/enumTranslations';
import { useEffect, useMemo } from 'react';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { UNASSIGNED } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions } from 'util/mappers/enumToOption';
import { CreateNewTaskFormData } from 'util/schemas/newTaskSchema';
import { useCanEditActivity, usePermissions } from 'util/usePermissions';

type FilteredTaskTypeEnum = Exclude<
  TaskTypeEnum,
  'MAKE_AN_APPOINTMENT' | 'INCOMING_CALL' | 'OUTGOING_CALL' | 'NOTICE' | 'MARKET_REPORT' | 'OFFER'
>;

export const CreateOrEditTaskForm = () => {
  const { t } = useTranslation(['user', 'enums']);
  const { allEnums } = useGetAllEnums();
  const { activeShop } = useActiveShop();
  const { canChangeResponsibleAgentAndTeam } = usePermissions();
  const disabledTaskTypes: TaskTypeEnum[] = [
    'MAKE_AN_APPOINTMENT',
    'INCOMING_CALL',
    'OUTGOING_CALL',
    'NOTICE',
    'MARKET_REPORT',
    'OFFER',
  ];

  const {
    control,
    watch,
    getValues,
    formState: { errors, defaultValues },
    register,
    setValue,
  } = useFormContext<CreateNewTaskFormData>();
  const [watchedActionType, watchedTeamId] = watch(['actionType', 'teamId']);

  const frequentlyUsedTypeSortOrder = [
    'ACTIVE_BUYER_CONTACT',
    'ACTIVE_SELLER_CONTACT',
    'EXPOSE_MANUAL_SHARE',
    'FOLLOW_UP',
    'INCOMING_BUYER_SELLER_CONTACT',
    'TODO',
    'PRICE_REDUCTION',
    'ACQUISITION',
    'NEWSLETTER',
    'ACTIVITY_REPORT',
    'PLACE_ADVERTISEMENT',
    'OWNERSHIP_ANNIVERSARY',
    'MISCELLANEOUS',
  ] as const satisfies readonly FilteredTaskTypeEnum[];

  const {
    field: { value: agentId, onChange: onAgentIdChange },
    fieldState: { error: agentError },
  } = useController({ name: 'agentId', control });
  const { agentTeamAssignments } = useTeamsAssignedToAgentInShop({
    where: { id: { _eq: agentId } },
    shopId: watch('shopId'),
  });
  const teams = useMemo(
    () =>
      agentTeamAssignments.length
        ? agentTeamAssignments.map((team) => ({ label: team.name, value: team.id }))
        : [{ label: t('user:dashboard.task.team.notAssigned'), value: UNASSIGNED }],
    [agentTeamAssignments, t],
  );

  useEffect(() => {
    if (!teams.find(({ value }) => value === watchedTeamId)) {
      setValue('teamId', (agentId && teams?.[0]?.value) || '');
    }
  }, [agentId, teams, setValue, watchedTeamId]);

  useEffect(() => {
    const currentTitleValue = getValues('title');

    /** Don't reset title if it's edited by the agent */
    if (
      !currentTitleValue ||
      frequentlyUsedTypeSortOrder.find((type) => currentTitleValue === t(taskTitleTranslation[type]))
    ) {
      const title = t(taskTitleTranslation[watchedActionType as FilteredTaskTypeEnum]);
      setValue('title', title);
    }
  }, [setValue, watchedActionType, getValues, frequentlyUsedTypeSortOrder, t]);

  const [comment, properties, additionalAgents] = getValues(['comment', 'properties', 'additionalAgents']);

  const isEdit = !!watch('_editTaskActivity');
  const hasEditPermissions = useCanEditActivity(watch('_editTaskActivity'));
  const hasReadOnlyStatus = defaultValues?.status === 'CANCELLED' || defaultValues?.status === 'COMPLETED';
  const readOnly = isEdit && (hasReadOnlyStatus || !hasEditPermissions);
  const taskCreatedBeforeMigration = !!defaultValues?.go3TaskKey;
  const canViewResponsibilitySection = canChangeResponsibleAgentAndTeam(activeShop);

  function getReadOnlyAlert() {
    if (!hasEditPermissions) {
      return (
        <FormSection>
          <EVAlert severity="info">{t(`user:activity.taskOrAppointment.noEditPermissions`)}</EVAlert>
        </FormSection>
      );
    }
    if (hasReadOnlyStatus) {
      return (
        <FormSection>
          <EVAlert severity="info">{t(`user:dashboard.task.statusAlert.${defaultValues?.status}`)}</EVAlert>
        </FormSection>
      );
    }
  }

  return (
    <>
      <Stack p={3}>
        {readOnly && getReadOnlyAlert()}
        {taskCreatedBeforeMigration && (
          <Stack>
            <EVAlert severity="warning">{t(`user:dashboard.activityDrawer.migrationAlert`)}</EVAlert>
          </Stack>
        )}
        <FormSection hidden={!canViewResponsibilitySection}>
          <FormSectionHeader>{t('user:dashboard.appointment.section.responsibility')}</FormSectionHeader>
          <FormStack icon={<AgentIcon />}>
            <Controller
              name="agentId"
              control={control}
              render={() => (
                <AgentPicker
                  required
                  shopId={watch('shopId')}
                  sx={{ width: '100%' }}
                  disabled={readOnly}
                  agentError={agentError?.message}
                  agentId={agentId ?? ''}
                  defaultAgentId={defaultValues?.agentId}
                  onChangeAgentId={onAgentIdChange}
                />
              )}
            />
          </FormStack>
          <FormStack>
            <Controller
              name="teamId"
              control={control}
              render={({ field }) => (
                <DropDown
                  {...field}
                  required
                  disabled={readOnly}
                  hasError={!!errors?.teamId}
                  errorMessage={errors?.teamId?.message}
                  label={t('user:dashboard.task.team')}
                  options={teams}
                />
              )}
            />
          </FormStack>
        </FormSection>
        <FormSection>
          <FormSectionHeader>{t('user:dashboard.appointment.section.dueDate')}</FormSectionHeader>
          <FormStack icon={<CalendarIcon />}>
            <Stack direction={'row'} gap={2}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <EVDatePicker
                    required
                    disabled={readOnly}
                    label={t('user:dashboard.task.dueDate')}
                    error={!!errors.date}
                    helperText={errors.date?.message}
                    showWeekDay
                    {...field}
                  />
                )}
              />
              <Controller
                name="time"
                control={control}
                render={({ field }) => (
                  <EVTimePicker
                    required
                    disabled={readOnly}
                    label={t('user:dashboard.task.dueTime')}
                    error={!!errors.time}
                    helperText={errors.time?.message}
                    hideIcon
                    {...field}
                  />
                )}
              />
            </Stack>
          </FormStack>
        </FormSection>
        <FormSection>
          <FormSectionHeader>{t('user:dashboard.appointment.section.typeAndProperties')}</FormSectionHeader>
          <FormStack icon={<StopWatchIcon />}>
            <Controller
              name="actionType"
              control={control}
              render={({ field }) => (
                <DropDown
                  {...field}
                  required
                  disabled={readOnly}
                  hasError={!!errors?.actionType}
                  errorMessage={errors?.actionType?.message}
                  label={t('user:dashboard.task.actionType')}
                  options={
                    /** Filter on frontend for compatibility reasons */
                    allEnums?.enumsTaskType
                      ?.filter((taskType) => !disabledTaskTypes.includes(taskType?.name as TaskTypeEnum))
                      .sort(
                        ({ name }, { name: name2 }) =>
                          frequentlyUsedTypeSortOrder.indexOf(name as FilteredTaskTypeEnum) -
                          frequentlyUsedTypeSortOrder.indexOf(name2 as FilteredTaskTypeEnum),
                      )
                      .map(enumToLokalisedOptions(t, taskTypeTranslation)) ?? []
                  }
                />
              )}
            />
          </FormStack>
          <FormStack>
            <Stack direction="row" width={'100%'} gap={2}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <DropDown
                    {...field}
                    disabled={readOnly}
                    label={t('user:dashboard.task.status')}
                    required
                    options={
                      allEnums?.enumsTaskAppointmentStatus?.map(
                        enumToLokalisedOptions(t, taskAppointmentStatusTranslation),
                      ) ?? []
                    }
                  />
                )}
              />
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <DropDown
                    {...field}
                    disabled={readOnly}
                    required
                    label={t('user:dashboard.task.priority')}
                    options={allEnums?.enumsPriority?.map(enumToLokalisedOptions(t, activityPriorityTranslation)) ?? []}
                  />
                )}
              />
            </Stack>
          </FormStack>
          <FormStack>
            <TextField
              required
              label={t('user:dashboard.task.title')}
              disabled={readOnly}
              error={!!errors.title}
              helperText={errors.title?.message}
              {...register('title')}
            />
          </FormStack>
          <FormStack icon={<DirectoryIcon />}>
            <ContactsAndLeadsPicker
              required
              disabled={readOnly}
              errorMessage={errors.contactsAndLeads?.message || errors.contactsAndLeads?.[0]?.message}
              limitTags={2}
              name="contactsAndLeads"
              label={t('user:dashboard.task.contacts')}
              allowLeads
              disableReadonlyLeads
              sx={{ width: '100%' }}
            />
          </FormStack>
          <FormStack
            descriptionText={t('user:dashboard.task.properties')}
            isOpen={!!properties?.length}
            icon={<PropertiesIcon />}
          >
            <PropertySearchField
              maxProperties={MAX_PROPERTIES}
              disabled={readOnly}
              sx={{ width: '100%' }}
              label={t('user:dashboard.task.properties.label')}
            />
          </FormStack>
          <FormStack descriptionText={t('user:dashboard.task.internalComments')} isOpen={!!comment} icon={<ChatIcon />}>
            <TextField
              label={t('user:dashboard.task.internalComments.label')}
              disabled={readOnly}
              multiline
              {...register('comment')}
            />
          </FormStack>
          <FormStack
            descriptionText={t('user:dashboard.appointment.additionalAgents')}
            isOpen={!!additionalAgents}
            icon={<UserDoubleIcon />}
          >
            <Controller
              name="additionalAgents"
              control={control}
              render={({ field, fieldState }) => (
                <AgentsPicker
                  errorMessage={errors.additionalAgents?.message || errors.additionalAgents?.[0]?.message}
                  label={t('user:dashboard.appointment.additionalAgents.label')}
                  fieldState={fieldState}
                  disabled={readOnly}
                  sx={{ width: '100%' }}
                  {...field}
                />
              )}
            />
          </FormStack>
        </FormSection>
      </Stack>
    </>
  );
};
