import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PostRequestBody, PostResponseData, PutRequestBody, fetchClient } from 'api/rest/fetch-client';

export type CreateAppointmentRequest = PostRequestBody<'/api/v1/appointments'>;
export type CreateAppointmentResponse = PostResponseData<'/api/v1/appointments'>;

export const useCreateAppointment = () => {
  const queryClient = useQueryClient();
  const { mutate: createAppointment, ...rest } = useMutation(
    ({ requestBody }: { requestBody: CreateAppointmentRequest }) =>
      fetchClient
        .POST('/api/v1/appointments', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { createAppointment, ...rest };
};
export type UpdateAppointmentRequest = PutRequestBody<'/api/v1/appointments/{appointment-id}'>;
export type UpdateAppointmentResponse = PostResponseData<'/api/v1/appointments/{appointment-id}'>;

export const useUpdateAppointment = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateAppointment,
    mutateAsync: updateAppointmentAsync,
    ...rest
  } = useMutation(
    ({ requestBody, appointmentId }: { requestBody: UpdateAppointmentRequest; appointmentId: string }) =>
      fetchClient
        .PUT('/api/v1/appointments/{appointment-id}', {
          body: requestBody,
          params: {
            path: {
              'appointment-id': appointmentId,
            },
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { updateAppointment, updateAppointmentAsync, ...rest };
};
