import MUIAvatar, { AvatarProps as MUIAvatarProps } from '@mui/material/Avatar';
import { SxProps } from '@mui/material/styles';
import { LeadInfo, LicensePartner, ShopInfo } from 'api/graphql/generated/graphql';
import { PersonProfile } from 'api/rest/models/Common';
import { TwoRowChipLabel } from 'components/general/Chips/TwoRowChipLabel';
import { EvaImage, EvaImageProps } from 'components/general/EvaImage/EvaImage';
import ApartmentIcon from 'components/icons/apartment.svg?react';
import HouseIcon from 'components/icons/house.svg?react';
import { env } from 'env';
import { theme } from 'theme';
import { EMPTY_DATA_STRING } from 'util/emptyDataUtils';

export const DEFAULT_SHOP_IMAGE_SRC = '/img/default_shop_avatar.png';

const LAST_DIGIT = 'Z'.charCodeAt(0);
const DIGIT_COUNT = LAST_DIGIT - 'A'.charCodeAt(0) + 1;

export type AvatarSize = 's' | 'm' | 'l' | 'xl' | 'xxl';

export interface EVAvatarProps extends Omit<MUIAvatarProps, 'src'> {
  backgroundColor?: string;
  floating?: boolean;
  isSquare?: boolean;
  lead?: LeadInfo | null;
  licensePartner?: LicensePartner | null;
  person?: PersonProfile;
  pictureSrc?: EvaImageProps['src'] | null;
  secondRowText?: string;
  shop?: ShopInfo | null;
  size?: AvatarSize;
}

const AVATAR_PIXEL_SIZES = { s: 24, m: 32, l: 56, xl: 80, xxl: 130 };

function getSize(size: AvatarSize) {
  switch (size) {
    case 's':
      return {
        fontSize: theme.typography.body4.fontSize,
        height: 24,
        width: 24,
      };
    case 'm':
      return {
        fontSize: theme.typography.body4.fontSize,
        height: 32,
        width: 32,
      };
    case 'l':
      return {
        height: 56,
        width: 56,
      };
    case 'xl':
      return {
        fontSize: theme.typography.h1.fontSize,
        height: 80,
        width: 80,
      };
    case 'xxl':
      return {
        fontSize: 50,
        height: 130,
        width: 130,
      };
  }
}

export function EVAvatar({
  backgroundColor,
  floating,
  isSquare,
  lead,
  licensePartner,
  person,
  pictureSrc,
  shop,
  secondRowText,
  size = 'm',
  sx,
  ...restProps
}: EVAvatarProps) {
  let evaImageSrc = pictureSrc;
  let icon;

  if (shop) {
    const shopAvatar = shop.profiles?.[0]?.images[0]?.gcpFileName;
    evaImageSrc = shopAvatar ? env.VITE_GCP_SHOP_PROFILE_IMAGE_URL + shopAvatar : DEFAULT_SHOP_IMAGE_SRC;
  }

  if (licensePartner) {
    const lipaAvatar = licensePartner.profiles?.[0]?.licensePartnerProfileImages[0]?.gcpFileName;
    evaImageSrc = lipaAvatar ? env.VITE_GCP_LIPA_PROFILE_IMAGE_URL + lipaAvatar : DEFAULT_SHOP_IMAGE_SRC;
  }

  if (lead) {
    const propertyType = lead.sellerRentOutSpecification?.type || lead?.buyerToRentSpecification?.type;
    icon = propertyType === 'HOUSE' ? <HouseIcon /> : propertyType === 'APARTMENT' ? <ApartmentIcon /> : undefined;
  }

  if (person) {
    const agentAvatar = person.profiles?.[0]?.images[0]?.gcpFileName;
    if (agentAvatar) {
      evaImageSrc = env.VITE_GCP_AGENT_PROFILE_IMAGE_URL + agentAvatar;
    }
  }

  const avatarSx = {
    ...getSize(size),
    ...(floating
      ? {
          border: '0.11em solid white',
          boxShadow: '0px 0.04em 0.1em 0px rgba(0, 0, 0, 0.4)',
        }
      : {}),
    boxSizing: 'border-box',
    '&.MuiAvatar-colorDefault.MuiAvatar-colorDefault': {
      color: theme.palette.textExtension.white,
    },
    borderRadius: isSquare ? 0 : 50,
    ...sx,
  };

  const iconSx = {
    ...getSize(size),
    ...(floating
      ? {
          border: '0.11em solid white',
          boxShadow: '0px 0.04em 0.1em 0px rgba(0, 0, 0, 0.4)',
        }
      : {}),
    boxSizing: 'border-box',
    backgroundColor: '#f0f0f0',
    color: theme.palette.text.primary,
    ...sx,
  };

  if (icon) {
    return (
      <MUIAvatar {...restProps} sx={{ ...iconSx } as SxProps}>
        {icon}
      </MUIAvatar>
    );
  }

  if (evaImageSrc) {
    return (
      <MUIAvatar {...restProps} sx={{ ...avatarSx, backgroundColor } as SxProps}>
        <EvaImage
          src={evaImageSrc}
          alt=""
          fill
          style={{ objectFit: 'cover' }}
          sizes={`${AVATAR_PIXEL_SIZES[size]}px`}
        />
      </MUIAvatar>
    );
  }

  const initials = `${person?.firstName?.[0] ?? ''}${person?.lastName?.[0] ?? ''}`.toUpperCase() ?? EMPTY_DATA_STRING;
  const hue =
    (((LAST_DIGIT - initials.charCodeAt(0)) / DIGIT_COUNT + (LAST_DIGIT - initials.charCodeAt(1) / DIGIT_COUNT)) *
      360) %
    360;

  return (
    <MUIAvatar
      {...restProps}
      sx={
        {
          ...avatarSx,
          backgroundColor: backgroundColor || (initials.length && `hsl(${hue}, 50%, 60%)`),
        } as SxProps
      }
    >
      {secondRowText ? <TwoRowChipLabel firstRow={initials} secondRow={secondRowText} /> : initials}
    </MUIAvatar>
  );
}
