import Typography from '@mui/material/Typography';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { ErrorPage } from 'eva-frame/ErrorPage';
import { useRouteError } from 'react-router-dom';
import { theme } from 'theme';
import { isModuleLoadingError } from 'util/errorUtils';
import { useTranslation } from 'util/i18next';

export function RouterErrorBoundary() {
  const error = useRouteError();
  const { t } = useTranslation(['user']);
  const currentAgentId = useCurrentAgentId();

  // eslint-disable-next-line no-console
  console.error(error);

  const gotoRoot = () => (window.location.href = '/');

  if (isModuleLoadingError(error)) {
    const debugData = {
      error: error.name,
      message: error.message,
      stack: error.stack,
      agentId: currentAgentId,
    };

    return (
      <ErrorPage
        title={t('user:error.newVersion.title')}
        buttonText={t('user:error.newVersion.button')}
        message={t('user:error.newVersion.message')}
        onClick={gotoRoot}
      >
        <Typography
          variant="body4"
          color={theme.palette.text.secondary}
          sx={{
            border: `1px solid ${theme.palette.shade.grey2}`,
            textAlign: 'left',
            padding: 2,
            marginTop: 4,
            backgroundColor: theme.palette.backgroundExtension.grey1,
            whiteSpace: 'pre-line',
            alignSelf: 'center',
            maxWidth: '700px',
          }}
          component="div"
        >
          {JSON.stringify(debugData, undefined, 2)}
        </Typography>
      </ErrorPage>
    );
  } else {
    return (
      <ErrorPage
        title={t('user:error.unknownError.title')}
        message={t('user:error.unknownError.message')}
        buttonText={t('user:error.unknownError.button')}
        onClick={gotoRoot}
      />
    );
  }
}
