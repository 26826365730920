import { useMutation } from '@tanstack/react-query';
import { fetchClient, toMultiPartFormData } from 'api/rest/fetch-client';

export const useUploadImages = () => {
  const { mutate: uploadImages, ...rest } = useMutation((files: File[]) => {
    return fetchClient
      .POST('/api/v1/email/upload/images', {
        body: toMultiPartFormData({ files }),
      })
      .then((resp) => resp.data);
  });
  return { uploadImages, ...rest };
};
