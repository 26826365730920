import { NumberFieldWithController } from 'components/forms/NumberField/NumberField';
import { EVDrawerCollapsible } from 'components/general/EVDrawer/EVDrawerCollapsible';
import { FormSeparatorIcon } from 'components/general/Form/FormSeparatorIcon';
import { FormStack } from 'components/general/Form/FormStack';
import FloorPlanIcon from 'components/icons/floor_plan.svg?react';
import SurfaceAreaIcon from 'components/icons/surface_area.svg?react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';
import { titleWithValue } from 'util/stringUtils';
import { useFormatNumber } from 'util/useFormatNumber';

const SizeSection = () => {
  const { t } = useTranslation(['lead', 'enums']);
  const { formatAreaRange, formatRange } = useFormatNumber();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<PropertyDetailsFormData>();

  const areaValues = [
    titleWithValue(
      t('lead:form.searchDemand.size.totalSurface.title'),
      formatAreaRange(...watch(['search.totalSurfaceMin', 'search.totalSurfaceMax']), { emptyDataString: '' }),
    ),
    titleWithValue(
      t('lead:form.searchDemand.size.livingArea.title'),
      formatAreaRange(...watch(['search.livingAreaMin', 'search.livingAreaMax']), { emptyDataString: '' }),
    ),
    titleWithValue(
      t('lead:form.searchDemand.size.plotSurface.title'),
      formatAreaRange(...watch(['search.plotSurfaceMin', 'search.plotSurfaceMax']), { emptyDataString: '' }),
    ),
  ]
    .filter(Boolean)
    .join(', ');
  const roomsValues = [
    titleWithValue(
      t('lead:form.searchDemand.size.rooms.title'),
      formatRange(...watch(['search.roomsMin', 'search.roomsMax']), { emptyDataString: '' }),
      { isTitleAtEnd: true },
    ),
    titleWithValue(
      t('lead:form.searchDemand.size.bedrooms.title'),
      formatRange(...watch(['search.bedroomsMin', 'search.bedroomsMax']), { emptyDataString: '' }),
      { isTitleAtEnd: true },
    ),
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <EVDrawerCollapsible
        title={t('lead:form.searchDemand.size.area.header')}
        icon={<SurfaceAreaIcon />}
        collapsedValues={areaValues}
      >
        <FormStack direction={'row'} gap={0.25}>
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.totalSurfaceFrom')}
            error={!!errors.search?.totalSurfaceMin}
            helperText={errors.search?.totalSurfaceMin?.message}
            name="search.totalSurfaceMin"
            allowNegative={false}
            control={control}
          />
          <FormSeparatorIcon />
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.totalSurfaceTo')}
            error={!!errors.search?.totalSurfaceMax}
            helperText={errors.search?.totalSurfaceMax?.message}
            name="search.totalSurfaceMax"
            allowNegative={false}
            control={control}
          />
        </FormStack>
        <FormStack direction={'row'} gap={0.25}>
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.livingAreaFrom')}
            error={!!errors.search?.livingAreaMin}
            helperText={errors.search?.livingAreaMin?.message}
            name="search.livingAreaMin"
            allowNegative={false}
            control={control}
          />
          <FormSeparatorIcon />
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.livingAreaTo')}
            error={!!errors.search?.livingAreaMax}
            helperText={errors.search?.livingAreaMax?.message}
            name="search.livingAreaMax"
            allowNegative={false}
            control={control}
          />
        </FormStack>
        <FormStack direction={'row'} gap={0.25}>
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.plotSurfaceFrom')}
            error={!!errors.search?.plotSurfaceMin}
            helperText={errors.search?.plotSurfaceMin?.message}
            name="search.plotSurfaceMin"
            allowNegative={false}
            control={control}
          />
          <FormSeparatorIcon />
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.plotSurfaceTo')}
            error={!!errors.search?.plotSurfaceMax}
            helperText={errors.search?.plotSurfaceMax?.message}
            name="search.plotSurfaceMax"
            allowNegative={false}
            control={control}
          />
        </FormStack>
      </EVDrawerCollapsible>
      <EVDrawerCollapsible
        title={t('lead:form.searchDemand.size.rooms.header')}
        icon={<FloorPlanIcon />}
        collapsedValues={roomsValues}
      >
        <FormStack direction={'row'} gap={0.25}>
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.numberRoomsFrom')}
            error={!!errors.search?.roomsMin}
            helperText={errors.search?.roomsMin?.message}
            name="search.roomsMin"
            allowNegative={false}
            control={control}
          />
          <FormSeparatorIcon />
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.numberRoomsTo')}
            error={!!errors.search?.roomsMax}
            helperText={errors.search?.roomsMax?.message}
            name="search.roomsMax"
            allowNegative={false}
            control={control}
          />
        </FormStack>
        <FormStack direction={'row'} gap={0.25}>
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.numberBedRoomsFrom')}
            error={!!errors.search?.bedroomsMin}
            helperText={errors.search?.bedroomsMin?.message}
            name="search.bedroomsMin"
            allowNegative={false}
            control={control}
            decimalScale={0}
          />
          <FormSeparatorIcon />
          <NumberFieldWithController
            label={t('lead:propertyEvaluateForm.details.numberBedRoomsTo')}
            error={!!errors.search?.bedroomsMax}
            helperText={errors.search?.bedroomsMax?.message}
            name="search.bedroomsMax"
            allowNegative={false}
            control={control}
            decimalScale={0}
          />
        </FormStack>
      </EVDrawerCollapsible>
    </>
  );
};

export default SizeSection;
