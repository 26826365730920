import Link from '@mui/material/Link';
import Typography, { TypographyProps } from '@mui/material/Typography';
import LinkIcon from 'components/icons/link_external.svg?react';
import { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';
import { theme } from 'theme';

export function VisitWebsite({
  url,
  label,
  target = '_blank',
  typographyProps,
  children,
  onClick,
}: {
  url: string;
  label?: string;
  target?: HTMLAttributeAnchorTarget;
  typographyProps?: TypographyProps;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
} & PropsWithChildren) {
  const href = url.startsWith('http') ? url : `https://${url.replace(/^\//, '')}`;

  return (
    <Link
      href={href}
      target={target}
      onClick={onClick}
      rel="noopener noreferrer"
      sx={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: 0.5,
        color: theme.palette.text.primary,
        textDecoration: 'none',
      }}
    >
      {children ?? (
        <>
          <Typography data-testid="go3-evId-link-label" variant="body3" {...typographyProps}>
            {label ?? url}
          </Typography>
          <LinkIcon />
        </>
      )}
    </Link>
  );
}
