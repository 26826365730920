import { MODAL_Z_INDEX } from '@ev/eva-container-api';
import { Box, Popper, Stack, Typography, useMediaQuery } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { ContactAndLeads, IntentTypeEnum } from 'api/graphql/generated/graphql';
import { useContactsAndLeads } from 'api/graphql/hooks/useContact';
import { ContactItem, LeadItem } from 'components/contact/LeadAndContactPicker/LeadAndContactOptionItems';
import {
  LeadAndContactSearchOption,
  getContactId,
  mapContactToLeadAndContactOptions,
} from 'components/contact/LeadAndContactPicker/leadAndContactSearchOptions';
import { RecipientPickerLeadsFilter } from 'components/emails/RecipientPickerLeadsFilter';
import { SEARCH_FIELD_WIDTH } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogLayout';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import { StatusLabel } from 'components/general/StatusLabel/StatusLabel';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { leadStatusTranslation } from 'const/enumTranslations';
import { useMemo, useRef, useState } from 'react';
import { theme } from 'theme';
import { contactSearchNameFilter } from 'util/hasura/filters';
import { useTranslation } from 'util/i18next';
import { useSearchText } from 'util/useDebounce';

export const SEND_EXPOSE_INTENT_TYPES: IntentTypeEnum[] = ['BUY', 'TO_RENT'];

function filterLeadsByIntentType(contacts: ContactAndLeads[]) {
  return contacts?.map((contact) => ({
    ...contact,
    leads: contact.leads.filter((lead) => SEND_EXPOSE_INTENT_TYPES.includes(lead.intentType)),
  }));
}

function hasEmail(recipient: LeadAndContactSearchOption) {
  return recipient.type === 'CONTACT' ? !!recipient.email : !!recipient.contact.email;
}

export function RecipientPicker({
  recipients,
  isForExposeSending,
  onRecipientsChange,
  disabled,
  errorMessage,
}: {
  recipients: LeadAndContactSearchOption[];
  isForExposeSending: boolean;
  onRecipientsChange: (recipients: LeadAndContactSearchOption[]) => void;
  disabled?: boolean;
  errorMessage?: string;
}) {
  const [isFocused, setIsFocused] = useState(false);
  const { shopIdsInActiveGroup } = useActiveShop();
  const { t } = useTranslation(['communication', 'enums']);
  const { searchText, rawSearchText, setSearchText } = useSearchText();
  const [activeLeads, setActiveLeads] = useState(false);
  const searchFieldRef = useRef<HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  const { contacts = [] } = useContactsAndLeads(
    {
      where: activeLeads
        ? {
            _and: [
              {
                shopId: { _in: shopIdsInActiveGroup },
                contactStatus: { _neq: 'FLAGGED' },
                blocked: { _neq: true },
                deleted: { _neq: true },
                ...contactSearchNameFilter(searchText),
              },
              { leads: { status: { _eq: 'ACTIVE' } } },
            ],
          }
        : {
            shopId: { _in: shopIdsInActiveGroup },
            contactStatus: { _neq: 'FLAGGED' },
            blocked: { _neq: true },
            deleted: { _neq: true },
            ...contactSearchNameFilter(searchText),
          },

      limit: 10,
      offset: 0,
      leads_where: activeLeads ? { status: { _eq: 'ACTIVE' } } : undefined,
    },
    { enabled: !!searchText && searchText.length > 2 },
  );

  const contactsWithLeads = useMemo(() => {
    const contactsWithFilteredLeads = isForExposeSending ? filterLeadsByIntentType(contacts) : contacts;
    return mapContactToLeadAndContactOptions(contactsWithFilteredLeads);
  }, [contacts, isForExposeSending]);

  function addRecipient(recipient: LeadAndContactSearchOption) {
    setSearchText('');
    onRecipientsChange([
      ...recipients.filter((oldRecipient) => getContactId(oldRecipient) !== getContactId(recipient)),
      recipient,
    ]);
  }

  return (
    <Box>
      <SearchTextField
        fullWidth
        disabled={disabled}
        ref={searchFieldRef}
        value={rawSearchText}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={t('communication:sendBulkEmailDialog.selectRecipientsPlaceholder')}
        onChange={(event) => setSearchText(event.target.value)}
        error={!!errorMessage}
        helperText={errorMessage}
        onDelete={rawSearchText.length > 0 ? () => setSearchText('') : undefined}
      />
      <Popper
        anchorEl={() => searchFieldRef.current!}
        open={!!searchText.length || isFocused}
        sx={{
          zIndex: MODAL_Z_INDEX,
          transformOrigin: 'left top',
          width: isMobile ? '100vw' : SEARCH_FIELD_WIDTH,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            border: 1,
            borderColor: theme.palette.shade.grey4,
            color: theme.palette.shade.grey1,
            borderRadius: 1,
          }}
        >
          {isFocused && searchText.length < 3 && (
            <Box>
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{
                  margin: '16px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'noWrap',
                }}
              >
                {t('communication:sendBulkEmailDialog.searchContactAndRecipientsPlaceholder')}
              </Typography>
            </Box>
          )}
          {!!searchText.length && searchText.length > 2 && (
            <>
              <ClickAwayListener onClickAway={() => setSearchText('')}>
                <>
                  <RecipientPickerLeadsFilter activeLeads={activeLeads} setActiveLeads={setActiveLeads} />

                  <MenuList
                    sx={{
                      overflow: 'auto',
                      maxHeight: '320px',
                      paddingTop: 0,
                      '& .MuiDivider-root': { margin: '0px !important' },
                    }}
                  >
                    {contactsWithLeads.map((contactOrLead, index) => {
                      const showDivider = contactOrLead.type === 'CONTACT' && index !== 0;
                      return (
                        <MenuItem
                          key={contactOrLead.id}
                          onClick={() => addRecipient(contactOrLead)}
                          sx={{
                            whiteSpace: 'wrap',
                            paddingX: '12px',
                            borderTop: showDivider ? `1px solid ${theme.palette.shade.grey4}` : 'none',
                            backgroundColor: recipients.find((recipient) => recipient.id === contactOrLead.id)
                              ? theme.palette.special.noon
                              : 'auto',
                            '&:hover': { backgroundColor: theme.palette.special.noon },
                            paddingTop: contactOrLead.type === 'CONTACT' ? 2 : 1,
                            paddingBottom:
                              index === contactsWithLeads.length - 1 || contactsWithLeads[index + 1]?.type === 'CONTACT'
                                ? 3
                                : 1,
                          }}
                          disabled={!hasEmail(contactOrLead)}
                        >
                          {contactOrLead.type === 'LEAD' ? (
                            <Stack direction="row" spacing={1} flex={1} alignItems="center">
                              <LeadItem lead={contactOrLead} sx={{ flexGrow: 2 }} />
                              <StatusLabel
                                status={contactOrLead.status}
                                label={t(leadStatusTranslation[contactOrLead.status])}
                              />
                            </Stack>
                          ) : (
                            <ContactItem option={contactOrLead} sx={{ minWidth: 0, padding: 0 }} />
                          )}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </>
              </ClickAwayListener>
            </>
          )}
        </Paper>
      </Popper>
    </Box>
  );
}
