import {
  AssetSubTypeEnum,
  AssetTypeEnum,
  LanguageEnum,
  PropertyEngineAddress,
  PropertyEngineAssetSubType,
  PropertyEngineAssetType,
  PropertyEngineLanguage,
} from 'api/graphql/generated/graphql';
import { components } from 'api/rest';
import { assetSubTypeTranslation, assetTypeTranslation } from 'const/enumTranslations';
import { ListingWithLegacyFields } from 'util/go3';
import { TFunction } from 'util/i18next';

const ANY_ENGLISH = ['EN_GB', 'EN_AU', 'EN_HK', 'EN_ME', 'EN_TH'] as const;
export const PROPERTY_ENGINE_LANGUAGE_MAP: Record<LanguageEnum, PropertyEngineLanguage[]> = {
  BG: ['BG', ...ANY_ENGLISH],
  CA: ['CA', 'ES_ES', 'ES_SA', ...ANY_ENGLISH],
  CS: ['CS', ...ANY_ENGLISH],
  DA: ['DA', ...ANY_ENGLISH],
  DE: ['DE_DE', 'DE_CH', 'DE_AT', ...ANY_ENGLISH],
  DE_AT: ['DE_AT', 'DE_DE', 'DE_CH', ...ANY_ENGLISH],
  DE_CH: ['DE_CH', 'DE_DE', 'DE_AT', ...ANY_ENGLISH],
  EL: ['EL', ...ANY_ENGLISH],
  EN: ['EN_GB', ...ANY_ENGLISH],
  EN_AU: ['EN_AU', ...ANY_ENGLISH],
  EN_HK: ['EN_HK', ...ANY_ENGLISH],
  EN_ME: ['EN_ME', ...ANY_ENGLISH],
  EN_TH: ['EN_TH', ...ANY_ENGLISH],
  ES: ['ES_ES', 'ES_SA', ...ANY_ENGLISH],
  ES_AR: ['ES_SA', 'ES_ES', ...ANY_ENGLISH],
  ET: ['ET', ...ANY_ENGLISH],
  FI: ['FI', ...ANY_ENGLISH],
  FR: ['FR_FR', 'FR_BE', ...ANY_ENGLISH],
  FR_BE: ['FR_BE', 'FR_FR', ...ANY_ENGLISH],
  HR: ['HR', ...ANY_ENGLISH],
  HU: ['HU', ...ANY_ENGLISH],
  IT: ['IT', ...ANY_ENGLISH],
  LT: ['LT', ...ANY_ENGLISH],
  LV: ['LV', ...ANY_ENGLISH],
  NL: ['NL', 'NL_BE', ...ANY_ENGLISH],
  NL_BE: ['NL_BE', ...ANY_ENGLISH],
  NO: ['NO', ...ANY_ENGLISH],
  PL: ['PL', ...ANY_ENGLISH],
  PT: ['PT', ...ANY_ENGLISH],
  RO: ['RO', ...ANY_ENGLISH],
  RU: ['RU', ...ANY_ENGLISH],
  SR: ['SR', ...ANY_ENGLISH],
  SR_ME: ['SR_ME', ...ANY_ENGLISH],
  SV: ['SV', ...ANY_ENGLISH],
  TH: ['TH', ...ANY_ENGLISH],
  TR: ['TR', ...ANY_ENGLISH],
  UK: ['UK', ...ANY_ENGLISH],
  YUE: ['YUE', ...ANY_ENGLISH],
  ZH_CN: ['ZH_CN', 'ZH_TW', ...ANY_ENGLISH],
  ZH_TW: ['ZH_TW', 'ZH_CN', ...ANY_ENGLISH],
  // Missing in PE
  AF: ['EN_GB', ...ANY_ENGLISH],
  AR: ['EN_GB', ...ANY_ENGLISH],
  AZ: ['EN_GB', ...ANY_ENGLISH],
  FA: ['EN_GB', ...ANY_ENGLISH],
  HE: ['EN_GB', ...ANY_ENGLISH],
  HI: ['EN_GB', ...ANY_ENGLISH],
  JA: ['EN_GB', ...ANY_ENGLISH],
  KO: ['EN_GB', ...ANY_ENGLISH],
  ME: ['EN_GB', ...ANY_ENGLISH],
  TL: ['EN_GB', ...ANY_ENGLISH],
  UR: ['EN_GB', ...ANY_ENGLISH],
  XH: ['EN_GB', ...ANY_ENGLISH],
  ZH_HANS: ['ZH_CN', 'ZH_TW', ...ANY_ENGLISH],
  ZH_HANT: ['ZH_CN', 'ZH_TW', ...ANY_ENGLISH],
  ZU: ['EN_GB', ...ANY_ENGLISH],
};

export function mapPropertyEngineAssetType(propertyEngineType: PropertyEngineAssetType | AssetTypeEnum): AssetTypeEnum {
  if (propertyEngineType === 'LAND') return 'PLOT';
  return propertyEngineType;
}

export function mapPropertyEngineAssetSubType(
  propertyEngineType: PropertyEngineAssetType,
  propertyEngineSubtype: PropertyEngineAssetSubType,
): AssetSubTypeEnum {
  switch (propertyEngineSubtype) {
    case 'INVESTMENT': {
      return 'INVESTMENT_HOUSE';
    }
    case 'OTHER': {
      if (propertyEngineType === 'HOUSE') return 'OTHER_HOUSE';
      if (propertyEngineType === 'APARTMENT') return 'OTHER_APARTMENT';
      if (propertyEngineType === 'LAND') return 'OTHER_LAND';
      return 'OTHER_HOUSE';
    }
    default: {
      return propertyEngineSubtype;
    }
  }
}

export function mapPropertyEngineAddress(
  propertyEngineAddress: PropertyEngineAddress,
): components['schemas']['Address'] {
  const { longitude, latitude } = propertyEngineAddress;
  return {
    city: propertyEngineAddress.city || undefined,
    countryCode: propertyEngineAddress.countryCode || undefined,
    longitude: longitude || undefined,
    latitude: latitude || undefined,
    postalCode: propertyEngineAddress.postalCode || undefined,
    secondAddressLine: propertyEngineAddress.secondAddressLine || undefined,
    streetName: propertyEngineAddress.streetName || undefined,
    streetNumber: propertyEngineAddress.streetNumber || undefined,
    ...(longitude || latitude
      ? { coordinate: { longitude: longitude || undefined, latitude: latitude || undefined } }
      : undefined),
  };
}

export function translateAssetTypeAndSubType(t: TFunction<['enums']>, listing: ListingWithLegacyFields | undefined) {
  const { type: assetType, subType: assetSubType } = listing?.asset || {};
  const translatedAssetType = assetType ? t(assetTypeTranslation[mapPropertyEngineAssetType(assetType)]) : undefined;
  const translatedAssetSubType =
    assetSubType && assetType
      ? t(assetSubTypeTranslation[mapPropertyEngineAssetSubType(assetType, assetSubType)])
      : undefined;

  return { translatedAssetType, translatedAssetSubType };
}
