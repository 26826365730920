import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DeleteRequestBody,
  PostRequestBody,
  PostResponseData,
  PutRequestBody,
  fetchClient,
} from 'api/rest/fetch-client';
import { useDestination } from 'util/navigation/useDestination';
import { useNavigation } from 'util/navigation/useNavigation';

export type AssignAgentOnContactRequest = PostRequestBody<'/api/v1/contacts/{contact-id}/agent-assignments'>;
export type AssignAgentOnContactResponse = PostResponseData<'/api/v1/contacts/{contact-id}/agent-assignments'>;
type assignAgentOnContactRequest = {
  go3AgentId: number;
  intentCategory: 'BUYER' | 'SELLER' | 'RENTAL';
  teamId: string;
};
export const useAssignAgentOnContact = () => {
  const queryClient = useQueryClient();
  const { mutate: addResponsibleAgent, ...rest } = useMutation(
    ({ requestBody, contactId }: { requestBody: assignAgentOnContactRequest; contactId: string }) =>
      fetchClient
        .POST('/api/v1/contacts/{contact-id}/agent-assignments', {
          params: {
            path: {
              'contact-id': contactId,
            },
            query: {
              assignAgentOnContactRequest: requestBody,
            },
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { addResponsibleAgent, ...rest };
};

export type ReassignAgentsOnContactRequest = PutRequestBody<'/api/v1/contacts/{contact-id}/agent-assignments'>;
export type ReassignAgentsOnContactResponse = PostResponseData<'/api/v1/contacts/{contact-id}/agent-assignments'>;
type ReassignAgent = {
  firstGo3AgentId?: number;
  firstTeamId?: string;
  secondGo3AgentId?: number;
  secondTeamId?: string;
  shopId: string;
  thirdGo3AgentId?: number;
  thirdTeamId?: string;
};
export const useReassignAgentsOnContact = () => {
  const queryClient = useQueryClient();
  const { mutate: reassignAgents, ...rest } = useMutation(
    ({ requestBody, contactId }: { requestBody: ReassignAgent; contactId: string }) =>
      fetchClient
        .PUT('/api/v1/contacts/{contact-id}/agent-assignments', {
          params: {
            path: {
              'contact-id': contactId,
            },
            query: {
              reassignAgentsOnContactRequest: requestBody,
            },
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { reassignAgents, ...rest };
};

type ContactDuplicateQuery = {
  phoneNumbers?: string[];
  emailAddresses?: string[];
  firstName?: string;
  lastName?: string;
  shopId: string;
  contactId?: string;
};
export const useCheckContactDuplicates = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: checkContactDuplicatesAsync, ...rest } = useMutation(
    ({ requestBody }: { requestBody: ContactDuplicateQuery }) =>
      fetchClient
        .GET('/api/v1/contacts/duplicates', {
          params: {
            query: requestBody,
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { checkContactDuplicatesAsync, ...rest };
};

export type UpdateContactRequest = PostRequestBody<'/api/v1/contacts/{contact-id}'>;
export type UpdateContactResponse = PostResponseData<'/api/v1/contacts/{contact-id}'>;
export const useUpdateContact = () => {
  const queryClient = useQueryClient();
  const { mutate: updateContact, ...rest } = useMutation(
    ({ requestBody, contactId }: { requestBody: UpdateContactRequest; contactId: string }) =>
      fetchClient
        .POST('/api/v1/contacts/{contact-id}', {
          params: {
            path: {
              'contact-id': contactId,
            },
          },
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { updateContact, ...rest };
};

export type CreateContactRequest = PostRequestBody<'/api/v1/contacts'>;
export type CreateContactResponse = PostResponseData<'/api/v1/contacts'>;
export const useCreateContact = () => {
  const queryClient = useQueryClient();
  const { mutate: createContact, ...rest } = useMutation(
    ({ requestBody }: { requestBody: CreateContactRequest }) =>
      fetchClient
        .POST('/api/v1/contacts', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { createContact, ...rest };
};

export type DeleteContactsRequest = DeleteRequestBody<'/api/v1/contacts'>;

export const useDeleteContacts = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteContacts, ...rest } = useMutation(
    ({ requestBody }: { requestBody: DeleteContactsRequest }) =>
      fetchClient
        .DELETE('/api/v1/contacts', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { deleteContacts, ...rest };
};

export type LinkCallToContactRequest = PostRequestBody<'/api/v1/contacts/{contact-id}/call-link'>;
export type LinkCallToContactResponse = PostResponseData<'/api/v1/contacts/{contact-id}/call-link'>;
export const useLinkCallToContact = () => {
  const queryClient = useQueryClient();
  const { mutate: linkCallToContact, ...rest } = useMutation(
    ({ requestBody, contactId }: { requestBody: LinkCallToContactRequest; contactId: string }) =>
      fetchClient
        .POST('/api/v1/contacts/{contact-id}/call-link', {
          body: requestBody,
          params: {
            path: {
              'contact-id': contactId,
            },
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { linkCallToContact, ...rest };
};

export type ActivateContactsRequest = PostRequestBody<'/api/v1/contacts/activation'>;
export type ActivateContactsResponse = PostResponseData<'/api/v1/contacts/activation'>;
export const useActivateContacts = () => {
  const queryClient = useQueryClient();
  const { mutate: activateContacts, ...rest } = useMutation(
    ({ requestBody }: { requestBody: ActivateContactsRequest }) =>
      fetchClient
        .POST('/api/v1/contacts/activation', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { activateContacts, ...rest };
};

export type MergeContactsRequest = PostRequestBody<'/api/v1/contacts/contact-merge'>;

export const useMergeContacts = () => {
  const { replace } = useNavigation();
  const { toContactDetails } = useDestination();
  const queryClient = useQueryClient();
  const { mutate: mergeContacts, ...rest } = useMutation(
    (requestBody: MergeContactsRequest) =>
      fetchClient
        .POST('/api/v1/contacts/contact-merge', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: (_, { targetContactId }) => {
        // The navigation needs to happen before the invalidation to prevent issues with the
        // redirect on the contact details page
        replace(toContactDetails({ contactId: targetContactId }));
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { mergeContacts, ...rest };
};

export type MergeDuplicateContactsRequest = PostRequestBody<'/api/v1/contacts/contact-merge/duplicates'>;
export const useMergeDuplicateContacts = () => {
  const queryClient = useQueryClient();
  const { mutate: mergeDuplicateContacts, ...rest } = useMutation(
    (requestBody: MergeDuplicateContactsRequest) =>
      fetchClient
        .POST('/api/v1/contacts/contact-merge/duplicates', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { mergeDuplicateContacts, ...rest };
};
