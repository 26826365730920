import { Tooltip } from '@mui/material';
import { formulateStaticMapProps } from 'components/map/MapUtils';
import { FeatureCollection, Polygon } from 'geojson';
import { StaticMap } from 'page-components/contact/StaticMap';
import { useTranslation } from 'util/i18next';
import { PlacesAddressSearchResult } from 'util/places/types';

export function StaticLocationMap({
  location: { lat, lng },
  onClick,
  details,
  showMarker = true,
  zoom = 9,
  geojson,
}: {
  location: { lat: number; lng: number };
  details?: PlacesAddressSearchResult[];
  onClick: () => void;
  showMarker?: boolean;
  zoom?: number;
  geojson?: FeatureCollection<Polygon>;
}) {
  const { t } = useTranslation(['lead']);
  const boundingBoxes = details?.filter((place) => !!place.boundingBox);
  const { center, zoomLevel, paths } = formulateStaticMapProps(boundingBoxes, geojson);

  return (
    <Tooltip title={t('lead:showInteractiveMap')} arrow>
      {/*  An empty div is needed for the tooltip to show*/}
      <div>
        <StaticMap
          sizes="613px, 200px"
          zoom={zoomLevel || zoom}
          latitude={center?.lat || lat}
          longitude={center?.lng || lng}
          cover
          mapId={null}
          mapStyle="feature:poi%7Cvisibility:off"
          extraParams={paths}
          alt=""
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
          showMarker={showMarker}
        />
      </div>
    </Tooltip>
  );
}
