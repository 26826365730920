import { useSnackBars } from '@ev/eva-container-api';
import { env } from 'env';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'util/i18next';
import { useInterval } from 'util/useInterval';

const POLL_INTERVAL = 60_000;
const LEADHUB_UI_URL = window.location.origin;

const APPS = {
  LeadHub: LEADHUB_UI_URL,
  KYC: env.VITE_KYC_URL,
  GOme: env.VITE_GO_AGENT_BASE_URL,
  ProfileEngine: env.VITE_PROFILE_ENGINE_URL,
};

type VersionMap = Record<string, string | null>;

async function fetchVersion(baseUrl: string) {
  const versionJsonUrl = `${baseUrl}/assets/version.json`;

  try {
    const response = await fetch(versionJsonUrl);
    if (response.ok) {
      const data = await response.json();
      return data.version || null;
    }
  } catch {
    // ignore problems with app
  }
  return null;
}

async function fetchAppVersions(): Promise<VersionMap> {
  return Object.fromEntries(
    await Promise.all(
      Object.entries(APPS).map(([name, url]) => {
        return fetchVersion(url).then((version) => [name, version]);
      }),
    ),
  );
}

function getInitialVersions(oldVersionMap: VersionMap | undefined, newVersionMap: VersionMap) {
  return Object.fromEntries(Object.keys(APPS).map((name) => [name, oldVersionMap?.[name] || newVersionMap[name]!]));
}

function checkIsOutdated(oldVersionMap: VersionMap | undefined, newVersionMap: VersionMap) {
  return function (appName: string) {
    const oldVersion = oldVersionMap?.[appName];
    const newVersion = newVersionMap[appName];

    // if the version is missing because it can't be fetched we assume noting changed
    return oldVersion && newVersion && oldVersion !== newVersion;
  };
}

export default function OutOfDateDetection() {
  const { t } = useTranslation();
  const { openSnackBar } = useSnackBars();
  const isEnabled = useIsFeatureEnabled('OUTDATED_DETECTION');

  const [pollInterval, setPollInterval] = useState<number | null>(POLL_INTERVAL);
  const initialVersionsRef = useRef<VersionMap>();

  useInterval(
    async () => {
      const currentVersions = await fetchAppVersions();
      initialVersionsRef.current = getInitialVersions(initialVersionsRef.current, currentVersions);

      const isOutdated = Object.keys(APPS).some(checkIsOutdated(initialVersionsRef.current, currentVersions));

      if (isOutdated) {
        // eslint-disable-next-line no-console
        console.info('FE is out of date!');

        setPollInterval(null); // stop polling

        openSnackBar(<Trans t={t} i18nKey="common:evaOutdatedNotification.message" />, 'warning', {
          isSticky: true,
          action: {
            text: t('evaOutdatedNotification.action'),
            callback: () => window.location.reload(),
          },
        });
      }
    },
    { delay: isEnabled ? pollInterval : null, leading: true },
  );

  return null;
}
