import { MODAL_Z_INDEX } from '@ev/eva-container-api';
import { Box, Popper } from '@mui/material';
import { DigitalClock, TimeField } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CustomMenuItem } from 'components/general/EVTimePicker/CustomMenuItem';
import { forwardRef, useRef, useState } from 'react';
import { theme } from 'theme';
import { useOnClickOutside } from 'usehooks-ts';
import { useDateFnsLocale } from 'util/i18n/useDateFnsLocale';

export interface TimePickerProps {
  onChange: (event: Date | null) => void;
  label: string;
  helperText?: string;
  error?: boolean;
  value?: Date | null;
  disabled?: boolean;
  required?: boolean;
  hideIcon?: boolean;
  showInterval?: boolean;
  startTime?: Date;
}

const MENU_DEFAULT_WIDTH = 300;

export const EVTimePicker = forwardRef<HTMLInputElement, TimePickerProps>(function TimePicker(
  { error, helperText, onChange, label, value, disabled, required, startTime, showInterval },
  ref,
) {
  const { locale } = useDateFnsLocale();

  const initialValue = value?.setSeconds(0, 0) ?? new Date();

  const [isDigitalClockOpen, setIsDigitalClockOpen] = useState(false);
  const [digitalClockMenuWidth, setDigitalClockMenuWidth] = useState(MENU_DEFAULT_WIDTH);
  const clockRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => {
    setIsDigitalClockOpen(false);
  };

  const openDigitalClockMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    const timePickerWidth = e.currentTarget.getBoundingClientRect().width;

    setDigitalClockMenuWidth(timePickerWidth);
    setIsDigitalClockOpen(true);
  };

  useOnClickOutside(clockRef, handleClickOutside);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <Box position={'relative'} ref={containerRef}>
        <TimeField
          onSelect={openDigitalClockMenu}
          ref={ref}
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
          slotProps={{
            htmlInput: {
              variant: 'outlined',
              helperText: helperText,
              error: error,
              required: required,
            },
          }}
        />
      </Box>
      <Popper open={isDigitalClockOpen} anchorEl={containerRef.current} sx={{ zIndex: MODAL_Z_INDEX }}>
        <DigitalClock
          ref={clockRef}
          timeStep={30}
          value={new Date(initialValue)}
          onChange={(newValue) => {
            if (newValue) {
              const selectedDate = new Date(newValue);
              onChange(selectedDate);
              setIsDigitalClockOpen(false);
            }
          }}
          slots={{
            digitalClockItem: ({ children, onClick, selected, ...props }) => {
              return (
                <CustomMenuItem
                  value={children}
                  selected={selected}
                  onClick={onClick}
                  startDate={startTime}
                  showInterval={showInterval}
                  {...props}
                />
              );
            },
          }}
          minTime={startTime}
          skipDisabled
          sx={{
            backgroundColor: theme.palette.backgroundExtension.white,
            width: `${digitalClockMenuWidth}px`,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
          }}
        />
      </Popper>
    </LocalizationProvider>
  );
});
