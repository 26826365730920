import { IconButton, Tooltip } from '@mui/material';
import CheckIcon from 'components/icons/check_naked.svg?react';
import CopyIcon from 'components/icons/document_duplicate.svg?react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'util/i18next';

export function CopyButton({ value }: { value: string }) {
  const { t } = useTranslation();
  const [isCopied, setCopied] = useState(false);

  const handleCopyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    setCopied(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }, [isCopied]);

  return (
    <Tooltip title={isCopied ? t('common:copy.success') : t('common:copy.tooltip')}>
      <IconButton
        onClick={handleCopyClick}
        className="copy-button"
        sx={{ p: 0.5, color: 'inherit' }}
        color="primary"
        size="small"
      >
        {isCopied ? <CheckIcon fontSize="inherit" /> : <CopyIcon fontSize="inherit" />}
      </IconButton>
    </Tooltip>
  );
}
