/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { ContactTypeEnum, CountryEnum, HouseholdTypeEnum, LanguageEnum } from 'api/graphql/generated/graphql';
import { useGetAllEnums } from 'api/graphql/hooks/useGetAllEnums';
import { CommentForm } from 'components/contact/CommentForm/CommentForm';
import AddButton from 'components/contact/ContactDetailsForm/AddButton';
import { AddressDetailsFormFields } from 'components/contact/ContactDetailsForm/AddressDetailsFormFields';
import { CheckForDuplicates } from 'components/contact/ContactDetailsForm/CheckForDuplicates/CheckForDuplicates';
import { KycMissingFieldsAlert } from 'components/contact/ContactDetailsForm/KycMissingFieldsAlert';
import { HouseholdForm } from 'components/contact/HouseholdForm/HouseholdForm';
import { RelationshipForm } from 'components/contact/RelationshipForm/RelationshipForm';
import { TagsForm } from 'components/contact/TagsForm';
import { useFormattedSalutation } from 'components/contact/useFormattedSalutation';
import { DropDown } from 'components/general/DropDown/DropDown';
import { EVDatePicker } from 'components/general/EVDatePicker/EVDatePicker';
import { EVDrawerCollapsible } from 'components/general/EVDrawer/EVDrawerCollapsible';
import { FormSection, FormSectionHeader } from 'components/general/Form/FormSection';
import { FormStack } from 'components/general/Form/FormStack';
import { RequiredLabel } from 'components/general/RequiredLabel/RequiredLabel';
import { SingleAutocomplete } from 'components/general/SingleAutocomplete/SingleAutocomplete';
import ChatIcon from 'components/icons/chat_1_naked.svg?react';
import ClockIcon from 'components/icons/clock.svg?react';
import CompanyIcon from 'components/icons/company.svg?react';
import ContactCardIcon from 'components/icons/contact_card.svg?react';
import HouseHoldIcon from 'components/icons/household.svg?react';
import LocationIcon from 'components/icons/location_2.svg?react';
import EmailIcon from 'components/icons/mail.svg?react';
import MinusIcon from 'components/icons/minus_circle.svg?react';
import PhoneIcon from 'components/icons/phone_naked.svg?react';
import UserDoubleIcon from 'components/icons/user_double.svg?react';
import UserIcon from 'components/icons/user_single_2.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import {
  contactRelationshipTypeTranslation,
  contactTimeTranslation,
  contactTypeTranslation,
  emailAddressTypeTranslation,
  householdTypeTranslation,
  phoneNumberTypeTranslation,
  professionTypeTranslation,
  salutationTranslation,
} from 'const/enumTranslations';
import { MuiTelInput, MuiTelInputCountry } from 'mui-tel-input';
import { ContactEditSection } from 'page-components/contact/LayoutContact/EditContact/EditContact';
import { Fragment, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIsContactQualified } from 'util/contactUtils';
import { includeEmptyOption } from 'util/emptyDataUtils';
import { filterUndefined } from 'util/filterUndefined';
import { translateCountryEnum } from 'util/i18n/translateCountryEnum';
import { translateLanguageEnum, translateLanguageOption } from 'util/i18n/translateLanguageEnum';
import { useTranslation } from 'util/i18next';
import { enumToLokalisedOptions, enumValueToLokalisedOptions } from 'util/mappers/enumToOption';
import { isSupportedCountry } from 'util/phoneUtils';
import { ContactDetailsFormData } from 'util/schemas/contactDetailsSchema';
import { sortByDescriptionUndefinedFirst } from 'util/sorting';
import { fullName } from 'util/stringUtils';
import { addressToSingleLineString } from 'util/summaries/addressSummary';
import { useFormatNumber } from 'util/useFormatNumber';
import { useFormatTime } from 'util/useFormatTime';
import { usePermissions } from 'util/usePermissions';
import { useCountryOptions } from '../useCountryOptions';

export const ContactDetailsFormFields = ({
  activeSection,
  withoutRelationships,
  showKycAlert,
}: {
  activeSection?: ContactEditSection;
  withoutRelationships?: boolean;
  showKycAlert?: boolean;
}) => {
  const { t } = useTranslation(['contact', 'enums']);
  const { activeShop } = useActiveShop();
  const { isEmailValid, isPhoneNumberValid } = useIsContactQualified();
  const ContactType = ['CONTACT_PERSON', 'SERVICE_CONTACT', 'TENANT'] as const satisfies readonly ContactTypeEnum[];
  const { formatDateTime } = useFormatTime();
  const { canCreateNonServiceContact } = usePermissions();
  const { formatPriceRange } = useFormatNumber();

  const {
    control,
    setValue,
    trigger,
    formState: { errors },
    register,
    watch,
    getValues,
  } = useFormContext<ContactDetailsFormData>();

  const watchedContactQualified = watch('_internals.contact.contactQualified');

  const [
    watchedFormattedSalutation,
    watchedSalutation,
    watchedTitle,
    watchedFirstName,
    watchedLastName,
    watchedPreferredLanguage,
    watchedEmails,
    watchedPreferredEmail,
    watchedPhoneNumbers,
    watchedPreferredPhone,
    watchedAddress,
    watchedRelationships,
    watchedIsServiceContact,
  ] = watch([
    'formattedSalutation',
    'salutation',
    'title',
    'firstName',
    'lastName',
    'preferredLanguage',
    'emailAddresses',
    '_internals.contact.preferredEmail',
    'phoneNumbers',
    '_internals.contact.preferredPhone',
    'addresses',
    'contactRelationships',
    '_internals.contact.isServiceContact',
  ]);

  const placeholderFormattedSalutation = useFormattedSalutation({
    formattedSalutation: watchedFormattedSalutation,
    salutation: watchedSalutation,
    title: watchedTitle,
    firstName: watchedFirstName,
    lastName: watchedLastName,
    preferredLanguage: watchedPreferredLanguage as LanguageEnum,
  });

  const {
    fields: emailFields,
    append: emailAppend,
    remove: emailRemove,
  } = useFieldArray({
    name: 'emailAddresses',
    control,
  });

  useEffect(() => {
    emailFields.forEach((field, index) => {
      if (watchedPreferredEmail !== undefined && +watchedPreferredEmail === index) {
        setValue(`emailAddresses.${index}.preferred`, true);
      } else {
        setValue(`emailAddresses.${index}.preferred`, false);
      }
    });
  }, [setValue, emailFields, watchedPreferredEmail]);

  const {
    fields: phoneFields,
    append: phoneAppend,
    remove: phoneRemove,
  } = useFieldArray({
    name: 'phoneNumbers',
    control,
  });

  useEffect(() => {
    phoneFields.forEach((field, index) => {
      if (watchedPreferredPhone !== undefined && +watchedPreferredPhone === index) {
        setValue(`phoneNumbers.${index}.preferred`, true);
      } else {
        setValue(`phoneNumbers.${index}.preferred`, false);
      }
    });
  }, [setValue, phoneFields, watchedPreferredPhone]);

  useEffect(() => {
    if (errors.emailAddresses || errors.phoneNumbers) {
      trigger(['emailAddresses', 'phoneNumbers']);
    }
  }, [trigger, watchedEmails, watchedPhoneNumbers, errors]);

  const watchIsCompany = useWatch({
    control,
    name: 'isCompany',
  });

  const { allEnums } = useGetAllEnums();
  const { countries } = useCountryOptions();

  const countryOptions = countries.filter((c) => c.value !== 'UNDEFINED');

  const showAddEmailButton = !watchedEmails || watchedEmails.length === 0 || isEmailValid(watchedEmails[0]?.email);
  const showAddPhoneNumberButton =
    !watchedPhoneNumbers || watchedPhoneNumbers.length === 0 || isPhoneNumberValid(watchedPhoneNumbers?.[0]?.number);

  const watchPersonalData = watch(['preferredLanguage', 'birthDate', 'placeOfBirth', 'nationality', 'idNumber']);
  const watchCommentAndTags = watch(['tags', 'additionalNote']);
  const watchHouseholdInformation = watch([
    'renterDetails.householdType',
    'renterDetails.professionType',
    'renterDetails.petOwner',
    'renterDetails.incomeRange',
  ]);
  const watchCompany = watch(['company', 'isCompany']);

  const addressCollapsedValues = useMemo(() => {
    const values = getValues('addresses');
    const collapsedValues = values
      ?.map((address) => address?.address && addressToSingleLineString(t)(address?.address, ''))
      .join(', ');
    return collapsedValues;
    //JSON.stringify allows for a deep comparison of objects with useMemo
  }, [JSON.stringify(watchedAddress)]);

  const personalDataCollapsedValues = useMemo(() => {
    const preferredLanguage = translateLanguageEnum(t, getValues('preferredLanguage') as LanguageEnum);
    const birthDate = getValues('birthDate')
      ? formatDateTime(getValues('birthDate'), {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      : undefined;

    const placeOfBirth = getValues('placeOfBirth');
    const nationality = translateCountryEnum(t, getValues('nationality') as CountryEnum);
    const idNumber = getValues('idNumber');

    const sanitizedNationality = nationality === 'Undefined' ? '' : nationality;

    return [preferredLanguage, birthDate, placeOfBirth, sanitizedNationality, idNumber].filter(Boolean).join(', ');
  }, [watchPersonalData]);

  const commentAndTagsCollapsedValues = useMemo(() => {
    const additionalNote = getValues('additionalNote');
    const tags = getValues('tags')
      ?.map((tag) => tag.displayValue)
      .filter(Boolean)
      .join(', ');

    return [additionalNote, tags].filter(Boolean).join(', ');
  }, [watchCommentAndTags]);

  const householdInformationCollapsedValues = useMemo(() => {
    const size = getValues('renterDetails.householdType') as HouseholdTypeEnum;
    const householdType = t(householdTypeTranslation[size]);

    const incomeRange = getValues('renterDetails.incomeRange');
    const incomeRangeString = formatPriceRange(incomeRange?.minimum, incomeRange?.maximum, {
      emptyDataString: '',
      currency: incomeRange?._currency,
    });

    const profession = getValues('renterDetails.professionType');
    const professionType = profession && t(professionTypeTranslation[profession]);

    const pets = getValues('renterDetails.petOwner');
    const petsTranslation = pets ? t('contact:renterDetails.pets.yes') : t('contact:renterDetails.pets.no');
    const petOwner = pets === undefined ? undefined : petsTranslation;

    return [householdType, incomeRangeString, professionType, petOwner].filter(Boolean).join(', ');
  }, [watchHouseholdInformation]);

  const relationshipCollapsedValues = watchedRelationships?.reduce((relationshipValuesText, relationship) => {
    if (!relationship?.type || !relationship.visibleContacts.length) {
      return relationshipValuesText;
    }
    const singleRelationshipText = `${t(
      contactRelationshipTypeTranslation[relationship.type],
    )}: ${relationship.visibleContacts.map((contact) => fullName(contact)).join(', ')}`;
    if (!relationshipValuesText) {
      return singleRelationshipText;
    }
    return `${relationshipValuesText}, ${singleRelationshipText}`;
  }, '');

  const companyCollapsedValues = useMemo(() => {
    const company = getValues('company');
    const division = getValues('division');

    return [company, division].filter(Boolean).join(', ');
  }, [watchCompany]);

  const isEditMode = getValues('_internals.isEditMode');
  const isContactLeadToggleDisabled = getValues('_internals.isContactLeadToggleDisabled');
  const canCreateContactWithoutLead =
    !watchedIsServiceContact && canCreateNonServiceContact(activeShop) && !isEditMode && !isContactLeadToggleDisabled;
  const canEditContactWithoutLead = isEditMode && canCreateNonServiceContact(activeShop);

  return (
    <Stack>
      {showKycAlert && <KycMissingFieldsAlert />}
      <FormSection>
        <FormSectionHeader>{t('contact:drawer.basicData')}</FormSectionHeader>
        <FormStack icon={<UserIcon />}>
          <Controller
            name={'salutation'}
            control={control}
            render={({ field }) => (
              <DropDown
                {...field}
                label={t('contact:salutation')}
                options={includeEmptyOption(
                  allEnums?.enumsSalutation?.map(enumToLokalisedOptions(t, salutationTranslation)),
                )}
              />
            )}
          />
          <TextField
            label={t('contact:title')}
            error={!!errors.title}
            helperText={errors.title?.message}
            {...register('title')}
          />
        </FormStack>
        <FormStack>
          <TextField
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            label={<RequiredLabel text={t('contact:firstName')} />}
            {...register('firstName', {
              deps: ['lastName'],
            })}
          />
          <TextField
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            label={<RequiredLabel text={t('contact:lastName')} />}
            {...register('lastName', {
              deps: ['firstName'],
            })}
          />
        </FormStack>
        <FormStack>
          <TextField
            InputLabelProps={{ shrink: true }}
            error={!!errors.formattedSalutation}
            helperText={errors.formattedSalutation?.message}
            label={t('contact:formattedSalutation')}
            placeholder={placeholderFormattedSalutation}
            {...register('formattedSalutation', {
              deps: ['salutation', 'title', 'firstName', 'lastName'],
            })}
          />
        </FormStack>
        {canCreateContactWithoutLead && (
          <FormStack>
            <Controller
              name="_internals.contact.isOnlyContact"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  value={value}
                  control={
                    <Switch data-testid="isOnlyContact" color="secondary" onChange={onChange} defaultChecked={value} />
                  }
                  label={t('contact:isOnlyContact')}
                />
              )}
            />
          </FormStack>
        )}
        {canEditContactWithoutLead && (
          <FormStack>
            <Controller
              name="_internals.contact.isServiceContact"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  value={value}
                  control={<Switch color="secondary" onChange={onChange} defaultChecked={value} />}
                  label={t('contact:isServiceContact')}
                />
              )}
            />
          </FormStack>
        )}
        {watchedIsServiceContact && (
          <FormStack>
            <Controller
              name="contactType"
              control={control}
              render={({ field }) => (
                <DropDown
                  {...field}
                  hasError={!!errors.contactType}
                  label={t('contact:contactType')}
                  errorMessage={errors.contactType?.message}
                  required
                  options={ContactType.map((contactType) =>
                    enumValueToLokalisedOptions(t, contactTypeTranslation)(contactType),
                  )}
                />
              )}
            />
          </FormStack>
        )}
      </FormSection>
      <FormSection>
        <FormSectionHeader>{t('contact:drawer.contactOptions')}</FormSectionHeader>
        <Controller
          name="_internals.contact.preferredEmail"
          control={control}
          render={({ field: controllerField }) => (
            <RadioGroup
              name="email-group"
              value={controllerField.value}
              onChange={controllerField.onChange}
              sx={{ flex: 1, gap: 3 }}
            >
              {emailFields.map((field, index) => (
                <Fragment key={field.id}>
                  <Stack gap={3}>
                    <FormStack
                      icon={
                        <IconButton
                          sx={{ m: -1 }}
                          onClick={() => {
                            if (emailFields.length === 1 || controllerField.value === undefined) return;
                            if (controllerField.value >= index && controllerField.value > 0) {
                              controllerField.onChange(controllerField.value - 1);
                            }
                            emailRemove(index);
                          }}
                        >
                          {index > 0 ? <MinusIcon /> : <EmailIcon />}
                        </IconButton>
                      }
                    >
                      <TextField
                        sx={{ marginBottom: { mobile: 1, tablet: 0 } }}
                        label={<RequiredLabel text={t('contact:email')} />}
                        helperText={errors.emailAddresses?.[index]?.email?.message}
                        error={!!errors.emailAddresses?.[index]?.email}
                        {...register(`emailAddresses.${index}.email`)}
                      />
                    </FormStack>
                    <FormStack>
                      <Controller
                        name={`emailAddresses.${index}.emailType`}
                        control={control}
                        render={({ field }) => (
                          <DropDown
                            sx={{ maxWidth: '220px' }}
                            {...field}
                            hasError={!!errors?.emailAddresses?.[index]?.emailType}
                            errorMessage={errors.emailAddresses?.[index]?.emailType?.message}
                            label={t('contact:email.type')}
                            options={
                              allEnums?.enumsEmailAddressType?.map(
                                enumToLokalisedOptions(t, emailAddressTypeTranslation),
                              ) ?? []
                            }
                          />
                        )}
                      />
                      <FormControlLabel value={index} control={<Radio />} label={t('preferred.abbreviation')} />
                    </FormStack>
                  </Stack>
                  {index === 0 && (
                    <FormStack>
                      <Controller
                        name="autoCc"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            value={value}
                            control={<Switch color="secondary" onChange={onChange} defaultChecked={value} />}
                            label={t('contact:email.ccAutomaticallyAllEmails')}
                          />
                        )}
                      />
                    </FormStack>
                  )}
                </Fragment>
              ))}

              {showAddEmailButton && (
                <AddButton
                  onClick={() => {
                    if (emailFields.length === 0) {
                      setValue('_internals.contact.preferredEmail', 0);
                    }
                    emailAppend({ email: '', emailType: '', preferred: false });
                  }}
                  disabled={emailFields.length === 3}
                  text={t('contact:addEmail')}
                />
              )}
            </RadioGroup>
          )}
        />

        <Controller
          name="_internals.contact.preferredPhone"
          control={control}
          render={({ field: controllerField }) => (
            <RadioGroup
              name="phone-group"
              value={controllerField.value}
              onChange={controllerField.onChange}
              sx={{ flex: 1, gap: 3 }}
            >
              {phoneFields.map((field, index) => (
                <Stack key={field.id} gap={3}>
                  <FormStack
                    icon={
                      <IconButton
                        sx={{ m: -1 }}
                        onClick={() => {
                          if (phoneFields.length === 1 || controllerField.value === undefined) return;
                          if (controllerField.value >= index && controllerField.value > 0) {
                            controllerField.onChange(controllerField.value - 1);
                          }
                          phoneRemove(index);
                        }}
                      >
                        {index > 0 ? <MinusIcon /> : <PhoneIcon />}
                      </IconButton>
                    }
                  >
                    <Controller
                      name={`phoneNumbers.${index}.number`}
                      control={control}
                      render={({ field }) => (
                        <MuiTelInput
                          sx={{
                            root: { maxWidth: 200 },
                            marginBottom: { mobile: 1, tablet: 0 },
                          }}
                          {...field}
                          label={<RequiredLabel text={t('contact:phone.number')} />}
                          helperText={errors.phoneNumbers?.[index]?.number?.message}
                          error={!!errors.phoneNumbers?.[index]?.number}
                          defaultCountry={
                            isSupportedCountry(activeShop.countryCode)
                              ? (activeShop.countryCode as MuiTelInputCountry)
                              : undefined
                          }
                          focusOnSelectCountry
                          disableFormatting
                        />
                      )}
                    />
                  </FormStack>

                  <FormStack>
                    <Controller
                      name={`phoneNumbers.${index}.phoneType`}
                      control={control}
                      render={({ field }) => (
                        <DropDown
                          sx={{ maxWidth: '220px' }}
                          {...field}
                          hasError={!!errors?.phoneNumbers?.[index]?.phoneType}
                          errorMessage={errors.phoneNumbers?.[index]?.phoneType?.message}
                          label={t('contact:phone.type')}
                          options={
                            allEnums?.enumsPhoneNumberType?.map(
                              enumToLokalisedOptions(t, phoneNumberTypeTranslation),
                            ) ?? []
                          }
                        />
                      )}
                    />
                    <FormControlLabel value={index} control={<Radio />} label={t('preferred.abbreviation')} />
                  </FormStack>
                </Stack>
              ))}

              {showAddPhoneNumberButton && (
                <AddButton
                  onClick={() => {
                    if (phoneFields.length === 0) {
                      setValue('_internals.contact.preferredPhone', 0);
                    }
                    phoneAppend({ number: '', phoneType: '', preferred: false });
                  }}
                  disabled={phoneFields.length === 5}
                  text={t('contact:addPhoneNumber')}
                />
              )}
            </RadioGroup>
          )}
        />
        <FormStack icon={<ClockIcon />}>
          <Controller
            name="preferredTimes"
            control={control}
            render={({ field }) => (
              <DropDown
                {...field}
                multiple
                label={t('contact:preferredTime')}
                options={
                  allEnums?.enumsContactTime
                    ?.filter(filterUndefined)
                    .map(enumToLokalisedOptions(t, contactTimeTranslation)) ?? []
                }
              />
            )}
          />
        </FormStack>
      </FormSection>
      <CheckForDuplicates />
      <FormSection sx={!watchedContactQualified ? { pointerEvents: 'none', opacity: 0.6 } : null}>
        <FormSectionHeader>{t('contact:drawer.optional')}</FormSectionHeader>

        <EVDrawerCollapsible
          title={t('contact:drawer.address')}
          icon={<LocationIcon />}
          collapsedValues={addressCollapsedValues}
          data-testid="collapsible-address-details"
        >
          <AddressDetailsFormFields />
        </EVDrawerCollapsible>
        <EVDrawerCollapsible
          title={t('contact:drawer.personalData.kyc')}
          icon={<ContactCardIcon />}
          collapsedValues={personalDataCollapsedValues}
          isInitiallyOpen={activeSection === 'kyc-information-and-personal-data-form'}
          data-testid="collapsible-personal-data"
        >
          <Controller
            name={`preferredLanguage`}
            control={control}
            render={({ field }) => (
              <FormStack>
                <SingleAutocomplete
                  {...field}
                  label={t('contact:preferredLang')}
                  disabled={!watchedContactQualified}
                  noOptionsPlaceholder={t('contact:preferredLang.noOptionsPlaceholder')}
                  sx={{ flex: 1 }}
                  options={
                    allEnums?.enumsLanguage
                      ?.map((lang) => translateLanguageOption(t, lang))
                      .sort(sortByDescriptionUndefinedFirst) || []
                  }
                />
              </FormStack>
            )}
          />
          <Controller
            name="birthDate"
            control={control}
            render={({ field }) => (
              <FormStack>
                <EVDatePicker
                  label={t('contact:drawer.birthDate.kyc')}
                  error={!!errors.birthDate}
                  disabled={!watchedContactQualified}
                  helperText={errors.birthDate?.message}
                  {...field}
                />
              </FormStack>
            )}
          />
          <FormStack>
            <TextField
              label={t('contact:drawer.placeOfBirth.kyc')}
              error={!!errors.placeOfBirth}
              helperText={errors.placeOfBirth?.message}
              disabled={!watchedContactQualified}
              {...register('placeOfBirth')}
            />
          </FormStack>
          <Controller
            name={`nationality`}
            control={control}
            render={({ field }) => (
              <FormStack>
                <SingleAutocomplete
                  {...field}
                  label={t('contact:drawer.nationality.kyc')}
                  disabled={!watchedContactQualified}
                  noOptionsPlaceholder={t('contact:nationality.noOptionsPlaceholder')}
                  options={countryOptions}
                  sx={{ flex: 1 }}
                />
              </FormStack>
            )}
          />
          <FormStack>
            <TextField
              label={t('contact:drawer.idNumber.kyc')}
              error={!!errors.idNumber}
              helperText={errors.idNumber?.message}
              disabled={!watchedContactQualified}
              {...register('idNumber')}
            />
          </FormStack>
        </EVDrawerCollapsible>
        <EVDrawerCollapsible
          title={t('contact:drawer.commentsAndTags')}
          icon={<ChatIcon />}
          collapsedValues={commentAndTagsCollapsedValues}
          isInitiallyOpen={activeSection === 'comment-tag-form'}
        >
          <CommentForm />
          <TagsForm />
        </EVDrawerCollapsible>
        {!withoutRelationships && (
          <EVDrawerCollapsible
            data-testid="collapsible-relationships-details"
            title={t('contact:drawer.relationship')}
            icon={<UserDoubleIcon />}
            collapsedValues={relationshipCollapsedValues}
            isInitiallyOpen={activeSection === 'relationship-form'}
          >
            <RelationshipForm />
          </EVDrawerCollapsible>
        )}
        <EVDrawerCollapsible
          title={t('contact:drawer.householdInformation')}
          icon={<HouseHoldIcon />}
          collapsedValues={householdInformationCollapsedValues}
          isInitiallyOpen={activeSection === 'household-information-form'}
        >
          <HouseholdForm />
        </EVDrawerCollapsible>
        <EVDrawerCollapsible
          title={t('contact:drawer.company')}
          icon={<CompanyIcon />}
          collapsedValues={companyCollapsedValues}
        >
          <FormStack direction={'column'} gap={3}>
            <Controller
              name="isCompany"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  value={value}
                  control={
                    <Switch disabled={!watchedContactQualified} color="secondary" checked={value} onChange={onChange} />
                  }
                  label={t('contact:isCompany')}
                />
              )}
            />
            <TextField
              label={<RequiredLabel text={t('contact:company')} />}
              error={!!errors.company}
              helperText={errors.company?.message}
              disabled={!watchIsCompany || !watchedContactQualified}
              {...register('company')}
            />
            <TextField
              label={t('contact:division')}
              error={!!errors.division}
              helperText={errors.division?.message}
              disabled={!watchIsCompany || !watchedContactQualified}
              {...register('division')}
            />
          </FormStack>
        </EVDrawerCollapsible>
      </FormSection>
    </Stack>
  );
};
