import { SendBulkEmailData } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMediaQuery } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { ComposeBulkEmailStep } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ComposeBulkEmailStep';
import { PreviewBulkEmailStep } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailStep';
import { NylasIntegratedEmails } from 'components/emails/utils';
import { ErrorDisplay } from 'components/error/ErrorDisplay';
import { ConfirmCloseDialog } from 'components/general/EVDialog/StandardDialogs/ConfirmCloseDialog';
import { EVModalBase } from 'components/general/EVModal/ModalComponents/EVModalBase';
import { EVModalContainer } from 'components/general/EVModal/ModalComponents/EVModalContainer';
import { EVModalHeader } from 'components/general/EVModal/ModalComponents/EVModalHeader';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { theme } from 'theme';
import {
  useCreateComposeBulkEmailDefaultValues,
  useCreatePreviewBulkEmailDefaultValues,
} from 'util/defaultValues/createSendBulkEmailDefaultValues';
import { useTranslation } from 'util/i18next';
import { PreviewBulkEmailFormData, useComposeBulkEmailSchema } from 'util/schemas/sendBulkEmailSchema';

interface SendBulkEmailDialogProps {
  initialData: SendBulkEmailData;
  onClose: () => void;
  from: string | null;
  possibleFromEmails: NylasIntegratedEmails[];
}

type SEND_BULK_EMAIL_STEP = 'compose' | 'preview';

export function SendBulkEmailDialog({ onClose, initialData, from, possibleFromEmails }: SendBulkEmailDialogProps) {
  const { t } = useTranslation(['communication']);
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const [step, setStep] = useState<SEND_BULK_EMAIL_STEP>('compose');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [previewBulkEmailDefaultValues, setPreviewBulkEmailDefaultValues] = useState<PreviewBulkEmailFormData>();
  const { activeShopSettings, activeShop } = useActiveShop();
  const preferredShopLanguage = activeShopSettings?.defaultPreferredLanguage;

  const composeSchema = useComposeBulkEmailSchema();

  const { createComposeBulkEmailDefaultValues, error: loadingComposeError } = useCreateComposeBulkEmailDefaultValues();

  const methods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(composeSchema),
    defaultValues: () =>
      createComposeBulkEmailDefaultValues({
        from: from || '',
        data: initialData,
        activeShopId: activeShop.id,
        preferredShopLanguage,
      }),
  });
  const isLoadingComposeDefaultValues = methods.formState.isLoading;
  const isDirty = methods.formState.isDirty;
  const isExposeSending = methods.watch('isExposeSending');

  const {
    createPreviewBulkEmailDefaultValues,
    isLoading: isLoadingPreviewDefaultValues,
    error: loadingPreviewError,
  } = useCreatePreviewBulkEmailDefaultValues();

  const onPreview = methods.handleSubmit(async (composeData) => {
    const defaultValues = await createPreviewBulkEmailDefaultValues({
      composeData,
      activeShopId: activeShop.id,
      preferredShopLanguage,
    });
    setPreviewBulkEmailDefaultValues(defaultValues);
    setStep('preview');
  });

  function handleClose() {
    if (isDirty) {
      setIsConfirmationOpen(true);
    } else {
      onClose();
    }
  }

  function getContent() {
    if (isLoadingComposeDefaultValues || isLoadingPreviewDefaultValues) {
      return <LinearProgress />;
    }

    const error = loadingComposeError || loadingPreviewError;
    if (error) {
      return <LoadingDefaultValuesError onClose={onClose} error={error} isExposeSending={isExposeSending} />;
    }

    switch (step) {
      case 'compose':
        return (
          <ComposeBulkEmailStep onPreview={onPreview} onClose={handleClose} possibleFromEmails={possibleFromEmails} />
        );
      case 'preview':
        return (
          <PreviewBulkEmailStep
            defaultValues={previewBulkEmailDefaultValues!}
            onClose={handleClose}
            possibleFromEmails={possibleFromEmails}
            onBackToCompose={() => setStep('compose')}
            onCloseAfterSend={onClose}
          />
        );
    }
  }

  const confirmationTitle = isExposeSending
    ? t('communication:sendBulkEmailDialog.abortConfirmationExpose')
    : t('communication:sendBulkEmailDialog.abortConfirmation');

  const minimizedTitle = methods.watch('isExposeSending')
    ? t('communication:sendBulkEmailDialog.titleExpose')
    : t('communication:sendBulkEmailDialog.title');

  return (
    <FormProvider {...methods}>
      <EVModalBase
        canBeMinimizedWithId={'emailBulk'}
        minimizedTitle={minimizedTitle}
        onClose={handleClose}
        sxPaperFullWidth={isDesktop ? { minWidth: '900px', width: '1096px' } : {}}
      >
        {getContent()}
      </EVModalBase>
      <ConfirmCloseDialog
        isOpen={isConfirmationOpen}
        title={confirmationTitle}
        onConfirm={onClose}
        onCancel={() => setIsConfirmationOpen(false)}
      />
    </FormProvider>
  );
}

function LoadingDefaultValuesError({
  onClose,
  isExposeSending,
  error,
}: {
  isExposeSending?: boolean;
  onClose: () => void;
  error: unknown;
}) {
  const { t } = useTranslation(['communication']);
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const desktopTitle = isExposeSending
    ? t('communication:sendBulkEmailDialog.titleExpose')
    : t('communication:sendBulkEmailDialog.title');
  const mobileTitle = isExposeSending
    ? t('communication:sendBulkEmailDialog.titleShortExpose')
    : t('communication:sendBulkEmailDialog.titleShort');

  return (
    <>
      <EVModalHeader title={isDesktop ? desktopTitle : mobileTitle} onClose={onClose} />
      <EVModalContainer>
        <ErrorDisplay
          title={t('communication:sendBulkEmailDialog.loadingErrorTitle')}
          message={t('communication:sendBulkEmailDialog.loadingError')}
          showDetailsForError={error}
        />
      </EVModalContainer>
    </>
  );
}
