const filterPropertyUtags = (propertyUtags?: string[]) =>
  propertyUtags?.filter((utag) => !utag.toUpperCase().startsWith('D-'));

export const sanitizeDrawerPropertyUtag = <T extends { propertyUtag?: string }>(initialProperties: T): T => {
  const { propertyUtag, ...rest } = initialProperties;
  return {
    ...rest,
    ...(!propertyUtag || propertyUtag.toUpperCase().startsWith('D-') ? {} : { propertyUtag }),
  } as T;
};

export const sanitizeDrawerPropertyUtags = <T extends { propertyUtags?: string[] }>(initialProperties: T): T => {
  return {
    ...initialProperties,
    propertyUtags: filterPropertyUtags(initialProperties.propertyUtags),
  };
};
