import { SxProps } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

export function LinkButton({
  to,
  children,
  target,
  sx,
  onClick,
}: {
  to: string;
  target?: string;
  sx?: SxProps;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
} & PropsWithChildren) {
  return (
    <MuiLink
      to={to}
      component={Link}
      target={target}
      onClick={onClick}
      sx={{
        whiteSpace: 'nowrap',
        color: theme.palette.text.primary,
        textDecoration: 'underline',

        '&:hover': {
          color: theme.palette.primary.main,
        },
        ...sx,
      }}
    >
      {children}
    </MuiLink>
  );
}
