import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryClient } from 'api/queryClient';
import { PostRequestBody, PostResponseData, PutRequestBody, fetchClient } from 'api/rest/fetch-client';

export type CreateEmailSignatureRequest = PostRequestBody<'/api/v1/email-signatures'>;
export type CreateEmailSignatureResponse = PostResponseData<'/api/v1/email-signatures'>;

export const useCreateEmailSignature = () => {
  const queryClient = useQueryClient();
  const { mutate: createEmailSignature, ...rest } = useMutation(
    ({ requestBody }: { requestBody: CreateEmailSignatureRequest }) =>
      fetchClient
        .POST('/api/v1/email-signatures', {
          body: requestBody,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { createEmailSignature, ...rest };
};

export type DeleteEmailSignatureRequest = PostRequestBody<'/api/v1/email-signatures/{email-signature-id}'>;
export type DeleteEmailSignatureResponse = PostResponseData<'/api/v1/email-signatures/{email-signature-id}'>;

export const useDeleteEmailSignature = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteEmailSignature, ...rest } = useMutation(
    ({ signatureId }: { signatureId: string }) =>
      fetchClient
        .DELETE('/api/v1/email-signatures/{email-signature-id}', {
          body: undefined,
          params: {
            path: {
              'email-signature-id': signatureId,
            },
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { deleteEmailSignature, ...rest };
};

export type EditEmailSignatureRequest = PutRequestBody<'/api/v1/email-signatures/{email-signature-id}'>;
export type EditEmailSignatureResponse = PostResponseData<'/api/v1/email-signatures/{email-signature-id}'>;

export const useEditEmailSignature = () => {
  const queryClient = useQueryClient();
  const { mutate: editEmailSignature, ...rest } = useMutation(
    ({ requestBody, signatureId }: { requestBody: EditEmailSignatureRequest; signatureId: string }) =>
      fetchClient
        .PUT('/api/v1/email-signatures/{email-signature-id}', {
          body: requestBody,
          params: {
            path: {
              'email-signature-id': signatureId,
            },
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { editEmailSignature, ...rest };
};

export type SetDefaultEmailSignatureRequest = PostRequestBody<'/api/v1/email-signatures/default-email-signature'>;
export type SetDefaultEmailSignatureResponse = PostResponseData<'/api/v1/email-signatures/default-email-signature'>;

export const useSetDefaultEmailSignature = () => {
  const queryClient = useQueryClient();
  const { mutate: setDefaultEmailSignature, ...rest } = useMutation(
    ({ shopId, emailSignatureId }: { shopId: string; emailSignatureId: string }) =>
      fetchClient
        .POST('/api/v1/email-signatures/default-email-signature', {
          body: {
            emailSignatureId,
            shopId,
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { setDefaultEmailSignature, ...rest };
};

export type CreateEmailTemplateRequest = PostRequestBody<'/api/v1/email-templates'>;
export type CreateEmailTemplateResponse = PostResponseData<'/api/v1/email-templates'>;

export const useCreateEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { mutate: createEmailTemplate, ...rest } = useMutation(
    ({ body }: { body: CreateEmailTemplateRequest }) =>
      fetchClient
        .POST('/api/v1/email-templates', {
          body,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { createEmailTemplate, ...rest };
};

export type DeleteEmailTemplateRequest = PostRequestBody<'/api/v1/email-templates/{email-template-id}'>;
export type DeleteEmailTemplateResponse = PostResponseData<'/api/v1/email-templates/{email-template-id}'>;

export const useDeleteEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteEmailTemplate, ...rest } = useMutation(
    ({ emailTemplateId }: { emailTemplateId: string }) =>
      fetchClient
        .DELETE('/api/v1/email-templates/{email-template-id}', {
          params: {
            path: {
              'email-template-id': emailTemplateId,
            },
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { deleteEmailTemplate, ...rest };
};

export type EditEmailTemplateRequest = PutRequestBody<'/api/v1/email-templates/{email-template-id}'>;
export type EditEmailTemplateResponse = PostResponseData<'/api/v1/email-templates/{email-template-id}'>;

export const useEditEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { mutate: editEmailTemplate, ...rest } = useMutation(
    ({ emailTemplateId, body }: { emailTemplateId: string; body: EditEmailTemplateRequest }) =>
      fetchClient
        .PUT('/api/v1/email-templates/{email-template-id}', {
          body,
          params: {
            path: {
              'email-template-id': emailTemplateId,
            },
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { editEmailTemplate, ...rest };
};

export type ResolveEmailTemplateRequest = PostRequestBody<'/api/v1/email-templates/resolution'>;
export type ResolveEmailTemplateResponse = PostResponseData<'/api/v1/email-templates/resolution'>;

export const useResolveEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { mutate: resolveEmailTemplate, ...rest } = useMutation(
    ({ body }: { body: ResolveEmailTemplateRequest }) =>
      fetchClient
        .POST('/api/v1/email-templates/resolution', {
          body,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { resolveEmailTemplate, ...rest };
};

export type SetDefaultNylasIntegrationRequest = PostRequestBody<'/api/v1/nylas/default-integration'>;
export type SetDefaultNylasIntegrationResponse = PostResponseData<'/api/v1/nylas/default-integration'>;

export const useSetDefaultNylasIntegration = () => {
  const queryClient = useQueryClient();
  const { mutate: setDefaultNylasIntegration, ...rest } = useMutation(
    ({ body }: { body: SetDefaultNylasIntegrationRequest }) =>
      fetchClient
        .POST('/api/v1/nylas/default-integration', {
          body: body,
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { setDefaultNylasIntegration, ...rest };
};

export type DeleteNylasIntegrationCommandRequest = PostRequestBody<'/api/v1/nylas/{nylas-email-integration-id}'>;
export type DeleteNylasIntegrationCommandResponse = PostResponseData<'/api/v1/nylas/{nylas-email-integration-id}'>;

export const useDeleteNylasIntegration = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteNylasIntegration, ...rest } = useMutation(
    ({ nylasEmailIntegrationId }: { nylasEmailIntegrationId: string }) =>
      fetchClient
        .DELETE('/api/v1/nylas/{nylas-email-integration-id}', {
          params: {
            path: {
              'nylas-email-integration-id': nylasEmailIntegrationId,
            },
          },
        })
        .then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { deleteNylasIntegration, ...rest };
};

export const useResolveEmailSignature = () => {
  const {
    mutate: resolveEmailSignature,
    mutateAsync: resolveEmailSignatureAsync,
    ...rest
  } = useMutation(({ shopId, emailSignatureId }: { shopId: string; emailSignatureId?: string }) =>
    fetchClient
      .GET('/api/v1/email-signatures/email-signature-resolution', {
        params: {
          query: {
            shopId: shopId,
            emailSignatureId,
          },
        },
      })
      .then((resp) => resp.data),
  );
  return { resolveEmailSignature, resolveEmailSignatureAsync, ...rest };
};

export function useResolvedSignature(params: { shopId: string; emailSignatureId?: string }) {
  const { resolveEmailSignatureAsync } = useResolveEmailSignature();
  const { data, ...rest } = useQuery({
    queryKey: ['email', 'useResolvedSignature', params],
    queryFn: async () => await resolveEmailSignatureAsync(params),
  });
  return { signature: data?.data, ...rest };
}

export type LinkEmailThreadRequest = PostRequestBody<'/api/v1/email/link'>;

export const useLinkEmailThread = () => {
  const { mutate: linkEmailThread, ...rest } = useMutation(
    (request: LinkEmailThreadRequest) =>
      fetchClient.POST('/api/v1/email/link', { body: request }).then((resp) => resp.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email', 'useEmailMessagesForThread'] });
      },
    },
  );
  return { linkEmailThread, ...rest };
};
