import { BuyerToRentSpecification } from 'api/graphql/generated/graphql';
import { Feature, FeatureCollection, GeoJsonProperties, MultiPolygon, Polygon, Position } from 'geojson';
import type { LatLngBounds } from 'leaflet';
import Leaflet from 'leaflet';
import { BoundingBox, PlacesAddressSearchResult } from 'util/places/types';

// Maximum URL length by google static maps API:
// https://developers.google.com/maps/documentation/maps-static/start#url-size-restriction
const MAX_URL_LENGTH = 16384;
// This is the length of a URL with only one polygon point serialized
const MINIMAL_INITIAL_URL_LENGTH = 282;

export const serializeCoordinates = (coordinates?: Position[][]) =>
  coordinates?.map((coordinate) => coordinate.map((x) => `${x[1]},${x[0]}`).join('|'));

export const serializeGeoJsonFeatureToUrl = (features?: Feature<Polygon>[]) => {
  const geojsonPathSpecifications = '&path=color:0x3388ffff|weight:2|fillcolor:0x3388ff33|';

  return (
    features
      ?.map((feature) => {
        const coordinatesString = serializeCoordinates(feature?.geometry?.coordinates)?.join(geojsonPathSpecifications);

        return `${geojsonPathSpecifications}${coordinatesString}`;
      })
      .join('') ?? ''
  );
};

export const isPolygonStringShortEnoughForGoogleStaticMapUrl = (polygonString?: string) => {
  return (polygonString?.length || 0) < MAX_URL_LENGTH - MINIMAL_INITIAL_URL_LENGTH;
};

export const canRenderPolygonAsStaticMapUrl = (polygon?: Polygon | MultiPolygon): boolean => {
  if (!polygon) {
    return true;
  }

  if (polygon.type === 'Polygon') {
    const coordinatesString = serializeCoordinates(polygon.coordinates);
    return isPolygonStringShortEnoughForGoogleStaticMapUrl(coordinatesString?.join(''));
  }

  if (polygon.type === 'MultiPolygon') {
    const coordinatesString = polygon.coordinates.map((polygonCoordinates) => serializeCoordinates(polygonCoordinates));
    return isPolygonStringShortEnoughForGoogleStaticMapUrl(coordinatesString?.join(''));
  }

  return false;
};

export const canRenderGeojsonAsStaticMapUrl = (geojson?: FeatureCollection<Polygon>): boolean => {
  const polygonString = serializeGeoJsonFeatureToUrl(geojson?.features);
  return isPolygonStringShortEnoughForGoogleStaticMapUrl(polygonString);
};

export const getOverallCoordinates = (
  details?: PlacesAddressSearchResult[],
  selectedPlaceId?: string,
): BoundingBox | undefined => {
  if (!details?.length) {
    return;
  }

  if (details.length === 1) {
    return details[0]?.boundingBox;
  }

  if (selectedPlaceId) {
    return details.find((place) => place.placeId === selectedPlaceId)?.boundingBox;
  }

  return details
    .filter((place) => !!place.boundingBox)
    .reduce(
      (coords, place) => ({
        northEast: {
          lat: Math.max(Number(coords?.northEast.lat), place.boundingBox!.northEast.lat),
          lng: Math.max(Number(coords?.northEast.lng), place.boundingBox!.northEast.lng),
        },
        southWest: {
          lat: Math.min(Number(coords?.southWest.lat), place.boundingBox!.southWest.lat),
          lng: Math.min(Number(coords?.southWest.lng), place.boundingBox!.southWest.lng),
        },
      }),
      details?.[0]?.boundingBox,
    );
};

export const formatToGeoJSON = (
  polygons: BuyerToRentSpecification['customSearchDemandPolygons'] | undefined,
): FeatureCollection<Polygon, GeoJsonProperties> => ({
  type: 'FeatureCollection',
  features:
    polygons?.map((polygon, index) => ({
      type: 'Feature',
      properties: {
        id: polygon.id,
        name: polygon.name,
        index,
      },
      geometry: polygon.polygon,
    })) || [],
});

export const calculateOptimalZoomLevel = (bounds: LatLngBounds, width = 200) => {
  const GLOBE_WIDTH = 256; // a constant in Google's map projection

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const latSpan = ne.lat - sw.lat;
  const lngSpan = ne.lng - sw.lng;
  const latZoom = Math.log2((width * 360) / (latSpan * GLOBE_WIDTH));
  const lngZoom = Math.log2((width * 3 * 360) / (lngSpan * GLOBE_WIDTH));
  const optimalZoom = Math.min(latZoom, lngZoom);
  return Math.floor(optimalZoom);
};

export const formulateStaticMapProps = (
  placeAddresses?: PlacesAddressSearchResult[],
  geojson?: FeatureCollection<Polygon, GeoJsonProperties>,
) => {
  if (!placeAddresses && !geojson?.features?.length) {
    return {};
  }
  const bboxBounds =
    placeAddresses?.reduce((boundingBoxes: LatLngBounds[], { boundingBox }) => {
      if (boundingBox) {
        boundingBoxes.push(Leaflet.latLngBounds(boundingBox.northEast, boundingBox.southWest));
      }
      return boundingBoxes;
    }, []) || [];

  const outerBounds = Leaflet.geoJson(geojson).getBounds() || bboxBounds[0];
  for (let i = 0; i < bboxBounds?.length; i++) {
    outerBounds.extend(Leaflet.latLngBounds(bboxBounds[i]!.getNorthEast(), bboxBounds[i]!.getSouthWest()));
  }
  const center = outerBounds?.getCenter();

  return {
    center,
    zoomLevel: calculateOptimalZoomLevel(outerBounds),
    paths: calculatePathsForStaticMap(placeAddresses, geojson),
  };
};

export const calculatePathsForStaticMap = (
  placeAddresses?: PlacesAddressSearchResult[],
  geojson?: FeatureCollection<Polygon>,
) => {
  const boundingBoxPathSpecifications = '&path=color:0x444444ff|weight:2|fillcolor:0x44444433|';

  const bboxPaths =
    placeAddresses
      ?.map((placeAddress) => {
        const hasPolygon = placeAddress?.polygon && placeAddress?.polygon?.coordinates?.length > 0;
        if (hasPolygon) {
          const coordinatesString = placeAddress?.polygon?.coordinates?.[0]
            ?.map((coordinate) => coordinate?.map((x) => `${x[1]},${x[0]}`).join('|'))
            .join(boundingBoxPathSpecifications);
          return `${boundingBoxPathSpecifications}${coordinatesString}`;
        }
        const { southWest, northEast } = placeAddress.boundingBox || {};
        if (!southWest || !northEast) {
          return '';
        }
        return `${boundingBoxPathSpecifications}${southWest.lat},${southWest.lng}|${northEast.lat},${southWest.lng}|${northEast.lat},${northEast.lng}|${southWest.lat},${northEast.lng}|${southWest.lat},${southWest.lng}`;
      })
      .join('') ?? '';

  const geojsonPaths = serializeGeoJsonFeatureToUrl(geojson?.features);

  return `${bboxPaths}${geojsonPaths}`;
};

export const convertFeaturesToRequestFormat = (features?: Feature<Polygon, GeoJsonProperties>[]) =>
  features?.length
    ? features.map((feature) => ({
        id: feature.properties?.id,
        name: feature.properties?.name,
        polygon: {
          ...feature.geometry,
          type: 'POLYGON' as const,
        },
      }))
    : undefined;
