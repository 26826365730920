import { LanguageEnum } from 'api/graphql/generated/graphql';
import { globalEditorState } from 'components/forms/RichTextEditor/blots/GlobalEditorState';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { ExposeProperty } from 'util/email';

export interface ExposeWidgetProps {
  properties: ExposeProperty[];
  alreadyProposedProperties?: string[];
  setProperties?: (properties: ExposeProperty[]) => void;
  enableRemove?: boolean;
  hidePrice?: boolean;
  language: LanguageEnum;
}

export function useSyncExposeFormStateToEditorState<T extends FieldValues>(getter: (data: T) => ExposeWidgetProps) {
  const { watch, getValues } = useFormContext<T>();
  const [lastProps, setLastProps] = useState<ExposeWidgetProps | undefined>();

  const updateLastProps = useCallback(
    (data: T) => {
      const props = getter(data);
      if (!isEqual(lastProps, props)) {
        setLastProps(cloneDeep(props));
      }
    },
    [getter, lastProps],
  );

  watch((data) => updateLastProps(data as T));

  useEffect(() => {
    updateLastProps(getValues());
  }, [updateLastProps, getValues]);

  useEffect(() => {
    if (lastProps) {
      globalEditorState.setProps('expose', 'global', lastProps);
    }
    return () => globalEditorState.clearProps('expose', 'global');
  }, [lastProps]);
}
