import { Box, Button, ButtonBase, SxProps, Typography } from '@mui/material';
import { ErrorInfoModal, toErrorDetails } from 'components/general/ErrorInfoModal/ErrorInfoModal';
import { PropsWithChildren, useState } from 'react';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import ErrorIcon from './error.svg?react';
import ErrorIconSmall from './error_small.svg?react';

export const ErrorDisplay = ({
  title,
  message,
  buttonText,
  iconSize,
  onClick,
  showDetailsForError,
  sx,
  children,
}: PropsWithChildren<{
  title: string;
  message?: string;
  showDetailsForError?: unknown;
  buttonText?: string;
  iconSize?: 'medium' | 'small';
  onClick?: () => void;
  sx?: SxProps;
}>) => {
  const [showErrorDetails, setShowErrorDetails] = useState(false);
  const { t } = useTranslation(['user']);

  return (
    <>
      <Box
        sx={{
          marginX: 'auto',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          flexGrow: 1,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(9.25),
          paddingBottom: theme.spacing(9.25),
          ...sx,
        }}
      >
        <Box
          sx={{
            marginX: 'auto',
          }}
        >
          {iconSize === 'small' ? (
            <ErrorIconSmall width={theme.spacing(12)} color={theme.palette.error.main} />
          ) : (
            <ErrorIcon width={theme.spacing(18.75)} color={theme.palette.error.main} />
          )}
        </Box>
        <Typography variant="h1" marginTop={theme.spacing(3)}>
          {title}
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary} marginTop={theme.spacing(3)}>
          {message}
          {showDetailsForError ? (
            <>
              {' '}
              <ButtonBase
                sx={{ textDecoration: 'underline', fontSize: 'inherit' }}
                onClick={() => setShowErrorDetails(true)}
              >
                {t('user:errorInfoModal.button.label')}
              </ButtonBase>
            </>
          ) : null}
        </Typography>
        {children}
        {onClick && (
          <Button
            variant="contained"
            sx={{
              width: 'fit-content',
              marginX: 'auto',
              marginTop: theme.spacing(4),
            }}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        )}
      </Box>
      {showErrorDetails && (
        <ErrorInfoModal
          message={title}
          errorInfo={toErrorDetails(showDetailsForError)}
          onClose={() => setShowErrorDetails(false)}
        />
      )}
    </>
  );
};
