import { Box, IconButton, Stack, StackProps, Tab, TabProps, Tabs, TabsProps, useMediaQuery } from '@mui/material';
import { useModalContext } from 'components/general/EVModal/ModalComponents/EVModalContext';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import MenuIcon from 'components/icons/menu.svg?react';
import WarningIcon from 'components/icons/warning.svg?react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { theme } from 'theme';
import { mergeSx } from 'util/styleUtils';

export const EVModalTabs = ({ children, sx, defaultValue, ...props }: TabsProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const [title, setTitle] = useState('');
  const { currentTab, setCurrentTab, isMenuExpanded, toggleMenuExpanded } = useModalContext();
  useEffect(() => {
    setCurrentTab(defaultValue as string);
  }, [defaultValue, setCurrentTab]);
  useEffect(() => {
    setTitle(document.querySelector('[aria-label="tabs"] .Mui-selected')?.textContent || '');
  }, [currentTab]);
  if (!currentTab) {
    return null;
  }
  return (
    <Box>
      {isMobile && (
        <Stack direction={'row'} alignItems="center" justifyContent="space-between" mt={-1} mb={1}>
          {title}
          <IconButton onClick={toggleMenuExpanded}>{isMenuExpanded ? <CrossIcon /> : <MenuIcon />}</IconButton>
        </Stack>
      )}
      <Tabs
        onChange={(el: SyntheticEvent, value: string) => {
          setTitle((el.target as HTMLElement).innerText);
          setCurrentTab(value);
          if (isMobile) {
            toggleMenuExpanded();
          }
        }}
        value={currentTab}
        orientation="vertical"
        aria-label="tabs"
        variant="scrollable"
        sx={{
          '.MuiTabs-flexContainer': {
            gap: 1,
          },
          mb: 2,
          mx: 0,
          display: isMenuExpanded || !isMobile ? 'flex' : 'none',
          transition: 'height 300ms linear',
          ...(isMobile
            ? {
                py: 2,
                borderTop: `1px solid ${theme.palette.shade.grey4}`,
                borderBottom: `1px solid ${theme.palette.shade.grey4}`,
              }
            : {}),
          ...sx,
        }}
        {...props}
      >
        {children}
      </Tabs>
    </Box>
  );
};

export const EVModalTab = ({ sx, icon, value, disabled, label, ...props }: TabProps) => {
  const { isInvalid, currentTab } = useModalContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  return (
    <Tab
      sx={mergeSx(
        {
          pr: 2,
          m: 0,
          width: isMobile ? '100%' : '200px',
          minHeight: '36px',
          alignItems: 'center',
          fontSize: theme.typography.body4,
          textTransform: 'none',
          textAlign: 'left',
          justifyContent: 'start',
          '&:hover': {
            background: theme.palette.tertiary.active,
          },
          '&.Mui-selected': {
            color: isInvalid && currentTab === value ? theme.palette.warning.main : theme.palette.text.primary,
            background: theme.palette.tertiary.active,
          },
        },
        sx,
      )}
      label={label ?? value}
      value={value}
      disabled={!!(disabled || (isInvalid && currentTab !== value))}
      icon={isInvalid && currentTab === value ? <WarningIcon /> : icon}
      iconPosition="start"
      {...props}
    />
  );
};

export const EVModalContent = ({ sx, children, value, ...props }: StackProps & { value?: string }) => {
  const { currentTab } = useModalContext();

  if (value && currentTab !== value) {
    return null;
  }

  return (
    <Stack
      direction="column"
      gap={2}
      sx={{
        m: 0,
        flexGrow: 1,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
};
