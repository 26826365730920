import { Box, Switch, Typography } from '@mui/material';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';

export const RecipientPickerLeadsFilter = ({
  activeLeads,
  setActiveLeads,
}: {
  activeLeads: boolean;
  setActiveLeads: (e: boolean) => void;
}) => {
  const { t } = useTranslation(['communication']);

  return (
    <Box
      onClick={(event) => event.stopPropagation()}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '12px',
        borderBottom: 1,

        backgroundColor: theme.palette.backgroundExtension.grey1,
        borderBottomColor: theme.palette.shade.grey4,
      }}
    >
      <Switch
        checked={activeLeads}
        onChange={() => setActiveLeads(!activeLeads)}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography variant="body3" sx={{ marginRight: 0.5, whiteSpace: 'nowrap' }}>
        {t('communication:recipientPickerFilter.activeLabel')}
      </Typography>
    </Box>
  );
};
